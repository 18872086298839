import React from 'react'

import flash from '/lib/flash'

import { Link } from 'react-router-dom'

import { state_options } from '/models/ticket'

# components
import ExternalLink   from '/components/external-link'
import ListingPhoto   from '/components/listing-photo'
import Avatar         from '/components/avatar'
import Pager          from '/components/pager/history-pager'
import Spinner        from '/components/spinner'
import DropdownMenu   from '/components/dropdown-menu'
import Datetime       from '/components/datetime'
import StateLabel     from '/components/state-label'
import Filters        from '/components/filters'


import { withEvent, withThirdPartyTickets, withThirdPartyTicketDelete } from './queries'

export default \
withEvent \
withThirdPartyTickets \
withThirdPartyTicketDelete \
class Tickets extends React.Component

  render: ->
    <div className="Tickets">
      {@filters()}
      {if @props.thirdPartyTicketsLoading
        <Spinner/>
      else
        <div className="tickets">
          {@row(ticket) for ticket in @props.third_party_tickets}
        </div>
      }
    </div>

  row: (ticket)->
    <div className="ticket-row" key={ticket._key}>
      <div>
        <Link className="btn btn-default" to={"/tickets/#{ticket.id}"}>
          More info
        </Link>
      </div>
      <div className="buyer">
        {ticket.first_name} {ticket.last_name}
      </div>
      <div className="email">
        <a href={"mailto:#{ticket.email}"}>{ticket.email}</a>
      </div>
      <div className="token">
        {ticket.token}
      </div>
      <div>
        <Datetime date={ticket.created_at} format='MMM d, yyyy'/>
      </div>
      <div>
        <StateLabel model={ticket}/>
      </div>
      <div>
        <button className="btn btn-xs btn-warning" onClick={@deleteTicket.bind @, ticket.id}>Delete</button>
      </div>
    </div>

  filters: ->
    <Filters>
      <Filters.Search/>
      <Filters.State options={state_options}/>
      <span className="pull-right">
        <Pager meta={@props.thirdPartyTicketsPagination} buffer={2}/>
      </span>
    </Filters>

  deleteTicket: (id) ->
    {third_party_tickets, errors} = await @props.thirdPartyTicketDelete id
    if errors?.length?
      flash.error errors
    else
      flash.success "Ticket delete successfully"
      this.props.thirdPartyTicketsRefetch
