import { graphql } from 'react-apollo'
import Models           from '/models/index'

#
# Queries
#
import index    from './queries/index.gql'

export withPaymentPlans = graphql index,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.payment_plans or {}
    paymentPlans: Models.Ticket.parse nodes or []
    paymentPlansPagination: pagination
    paymentPlansLoading: data.loading


#
# Mutations
#
import tickets_rpc from '/mutations/tickets/rpc.gql'

export withTicketsRPC = graphql tickets_rpc,
  props: ({ mutate })->
    tickets_rpc: (id, method)->
      {data: {ticket_rpc: {ticket, errors}}} = \
      await mutate variables: {id, method}
      {ticket, errors}
