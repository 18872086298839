import humanize from 'underscore.string/humanize'

import Model from '/lib/model'

$w = (str)-> (t for t in str.split(' ') when t isnt '')

export default \
class PrintingLog extends Model
  @className: 'PrintingLog'

  @belongsTo 'user',    model: 'User'

export pages = $w 'orders_and_attendees add_an_order'
export page_options = value: page, label: humanize page for page in pages

export sources = $w 'web'
export source_options = value: source, label: humanize source for source in sources
