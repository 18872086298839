import React from 'react'
import './styles'
import PropTypes from 'prop-types'


export default \
class Empty extends React.Component
  @propTypes:
    name:    PropTypes.string.isRequired

  render: ->
    {name, children} = @props
    <div className="Empty">
      <h1>⚠️ {name} not found ⚠️</h1>
      { children or "Please double check the ID in the URL" }
    </div>
 
