import React from 'react'
import PropTypes  from 'prop-types'
import Text         from './text'

import { withRouter }   from 'react-router-dom'

export default withRouter class AttributeFilter extends Text
  @propTypes:
    name:  PropTypes.string.isRequired

  onKeyUp: (evt)=>
    super arguments...
    term = evt if 'string' is typeof evt
    term ?= evt.target?.value
    val = term or null
    @apply val if term is '' or evt.keyCode is 13

  apply: (val)=>
    filters = Object.assign {}, @props.location.state?.filters or {}
    filters[@props.name] = val
    @props.history.push @props.location.pathname, Object.assign {}, @props.location.state, {filters}, page: 1

  defaultValue: ->
    @props.location.state?.filters?[@props.name] or ''
