import React from 'react'

import { Link } from 'react-router-dom'

import isFuture   from 'date-fns/isFuture'
import isPast     from 'date-fns/isPast'

# components
import ReactSelect  from '/components/react-select'
import Spinner      from '/components/spinner'
import Pager        from '/components/pager/history-pager'
import StateLabel   from '/components/state-label'
import List         from '/components/list'
import Filters      from '/components/filters'
import EventCard    from '/components/event-card'

import { state_options } from '/models/event'

import {
  withUser
  withEvents
} from './queries'

export default \
withUser \
withEvents \
class Events extends React.Component

  render: ->
    return <Spinner/> if @props.userLoading
    <div className="Events">
      {@filters()}
      {@events()}
      {@pager()}
    </div>

  filters: ->
    <Filters>
      <Filters.Search/>
      <Filters.State options={state_options}/>
      <span className="pull-right">
        <Pager meta={@props.eventsPagination} buffer={2}/>
      </span>
    </Filters>

  events: ->
    return <Spinner/> if @props.eventsLoading
    <List>
      {@header()}
      {for evt in @props.events by -1
        <List.Row key={evt._key}>
          <List.Cell className="id">
            <Link className="btn btn-default" to={"/events/#{evt.id}"}>
              More info
            </Link>
          </List.Cell>
          <List.Cell className="event">
            <EventCard event={evt} />
          </List.Cell>
          <List.Cell className="num_tickets">
            <Link to={"/events/#{evt.id}/tickets"} title="Tickets Sold">
              {evt.num_tickets || '-'}
            </Link>
            {' / '}
            <Link to={"/events/#{evt.id}/cost_items"} title="Cost Items Sold">
              {evt.num_cost_items || '-'}
            </Link>
          </List.Cell>
          <List.Cell className="actions">
            <StateLabel model={evt}/>
          </List.Cell>
        </List.Row>
      }
    </List>

  header:->
    <List.HeaderRow>
      <List.Header className="id"> Id </List.Header>
      <List.Header className="event"> Event </List.Header>
      <List.Header className="num_tickets"> Tickets / Cost-Items </List.Header>
      <List.Header className="actions"> State </List.Header>
    </List.HeaderRow>

  pager: ->
    <span className="text-center">
      <Pager meta={@props.eventsPagination} buffer={4}/>
    </span>
