import Model from '/lib/model'
import FeeRule from '/models/fee-rule'

export default \
class Fee extends Model
  @className: 'Fee'
  constructor: ->
    super arguments...
    @code                     = null

  @hasMany 'rules', model: 'FeeRule'

  currencyComparisons = [
    {
      label: 'Currency equals'
      comparison: 'is_currency'
    }
  ]

  countryComparisons = [
    {
      label: 'Country equals'
      comparison: 'is_country'
    }
  ]

  @ruleTypes = [
    {
      type: 'basic'
      label: 'Basic'
      conditions: [
        {
          property: 'currency'
          comparisons: currencyComparisons
          required: true
          multiple: false
        },
        {
          property: 'country'
          comparisons: countryComparisons
          required: false
          multiple: false
        }
      ]
    }
  ]

  @blankRules = ->
    [
      FeeRule.parse({
        type: 'basic'
        amount: null
        calculation: 'flat'
        conditions: [
          {
            property: 'currency'
            comparison: 'is_currency'
            value: null
          }
        ]
      })
    ]

  @conditionFields = ->
    [
      {
        property: 'country'
        comparison: 'is_country'
        value: null
      },
      {
        property: 'currency'
        comparison: 'is_currency'
        value: null
      }
    ]
