import React from 'react'
import PropTypes from 'prop-types'

import Fee from '/models/fee'
import BasicRule from '/components/order-fee/rule'
import Condition from '/components/order-fee/condition'
import Comparison from '/components/order-fee/comparison'
import CountrySelect from '/components/order-fee/country-select'
import CurrencySelect from '/components/order-fee/currency-select'
import Generic from '/components/order-fee/generic'

components =
  country: CountrySelect
  currency: CurrencySelect

export default \
class NewCode extends React.Component

  @propTypes:
    fee:        PropTypes.object
    onSave:     PropTypes.func
    onCancel:   PropTypes.func
    errors:     PropTypes.array

  constructor: (props) ->
    super(...arguments)
    @state =
      fee: Fee.parse(@props.fee or
        rules: [Fee.blankRules().find (rule) -> rule.type is 'basic']
      )
      errors: props.errors

  handleFeePropertyChange: ({property, value}) =>
    @state.fee[property] = value
    @setState fee: @state.fee

  handleRulePropertyChange: ({property, value, ruleIndex}) =>
    @state.fee.rules[ruleIndex][property] = value
    @setState fee: @state.fee

  handleConditionPropertyChange: ({property, value, ruleIndex, conditionIndex}) =>
    @state.fee.rules[ruleIndex].conditions[conditionIndex][property] = value
    @setState fee: @state.fee

  addCondition: ({ruleIndex, property}) =>
    @state.fee.rules[ruleIndex].conditions.push Fee.conditionFields().find((field) -> field.property is property)
    @setState fee: @state.fee

  removeCondition: ({ruleIndex, conditionIndex}) =>
    @state.fee.rules[ruleIndex].conditions.splice conditionIndex, 1
    @setState fee: @state.fee

  addRule: (type) =>
    @state.fee.rules.push Fee.blankRules().find((rule) -> rule.type is type)
    @setState fee: @state.fee

  onSave: (event) =>
    event.preventDefault()
    @props.onSave @state.fee

  handleCancelModal: (event) =>
    event.preventDefault()
    @props.onCancel()

  fieldError: (fieldName) =>
    {errors} = @props
    return unless errors?.length
    errors.find((error) -> error.key is fieldName)

  renderErrorFor: (fieldName) =>
    return unless @fieldError(fieldName)
    <span className="help-block">
      {@fieldError(fieldName).message}
    </span>

  render: ->
    <div className="NewCode">
      <form>
        <div>
          <div className={"form-group #{if @fieldError('code') then 'has-error' else ''}"}>
            <label className="commission-label required">Code</label>
            <div className="input-group code-input">
              <span className="input-group-addon">
                OLF-
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Code name"
                onChange={(event) => @handleFeePropertyChange({ property: 'code', value: event.target.value}) }
                value={@state.fee.code or ""}
                required
              />
            </div>
            {@renderErrorFor("code")}
          </div>
          <hr className="section-line" />
          {@state.fee.rules.map((rule, ruleIndex) =>
            <div key={ruleIndex}>
              {<hr className="section-line" /> if ruleIndex}
              <BasicRule
                rule={rule}
                types={Fee.ruleTypes}
                addCondition={(property) => @addCondition({ ruleIndex: ruleIndex, property: property })}
                error={@renderErrorFor("rules.#{ruleIndex}.amount")}
                onAmountChange={(value) => @handleRulePropertyChange({ property: "amount", value: value, ruleIndex: ruleIndex })}
                conditions={rule.conditions.map((condition, conditionIndex) =>
                  settings = Fee.ruleTypes.find(
                    (definition) => definition.type == rule.type
                  ).conditions.find(
                    (definition) => definition.property == condition.property
                  )

                  ValueComponent = components[condition.property] || Generic;

                  comparisonComponent = <Comparison
                    value={condition.comparison}
                    hidden={settings.comparisons.length == 1}
                    options={settings.comparisons}
                    onChange={(value) => @handleConditionPropertyChange({
                      property: "comparison",
                      value: value,
                      ruleIndex: ruleIndex,
                      conditionIndex: conditionIndex
                    })}
                  />

                  valueComponent = <>
                    <ValueComponent
                      value={condition.value}
                      onChange={(value) => @handleConditionPropertyChange({
                        property: "value",
                        value: value,
                        ruleIndex: ruleIndex,
                        conditionIndex: conditionIndex
                      })}
                    />
                    {@renderErrorFor("rules.#{ruleIndex}.conditions.#{conditionIndex}.value")}
                  </>

                  <Condition
                    property={condition.property}
                    removable={!settings.required}
                    removeCondition={() => @removeCondition({ ruleIndex: ruleIndex, conditionIndex: conditionIndex })}
                    hasError={!!@fieldError("rules.#{ruleIndex}.conditions.#{conditionIndex}.value")}
                    comparisonComponent={comparisonComponent}
                    valueComponent={valueComponent}
                    key={conditionIndex}
                  />
                )}
              />
            </div>
          )}
          {Fee.ruleTypes.map((definition, idx) =>
            <div key={idx}>
              <button type="button" className="btn btn-primary rule-button" onClick={() => @addRule(definition.type)}>
                Add {definition.label} rule
              </button>
            </div>
          )}
        </div>

        <div className="footer-section">
          <hr className="section-line" />
          <div className='footer-buttons'>
            <button className='btn btn-default' onClick={(event) => @handleCancelModal(event)}>Cancel</button>
            <button className='btn btn-primary' onClick={(event) => @onSave(event)}>Create</button>
          </div>
        </div>
      </form>
    </div>
