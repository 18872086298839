import Model from '/lib/model'
import currencies from '/lib/currencies'

export default \
class Image extends Model
  @className: 'Image'
  constructor: ->
    super arguments...
    @id               = null
    @uploadcare_id    = null
    @original_width   = null
    @original_height  = null
    @crop_width       = null
    @crop_height      = null
    @crop_x           = null
    @crop_y           = null
    @url              = null
    @url_500          = null
    @url_160          = null
    @url_50           = null
    @blur             = null
    @blur_strength    = null
    @is_gif           = null

  @computed 'options', ->
    ret = {}

    if @is_gif
      ret.resize = '1440x'
      ret.format = 'jpg'

    if @blur
      ret.blur = blur_strength: ( @blur_strength || 100 )

    ret


  @computed 'url',
    set: (url)-> # ignore writes
    get: ->
      ret = [process.env.UPLOADCARE, @id]

      for key, val of @options
        switch key
          when 'resize', 'quality', 'progressive', 'format', 'rotate'
            ret.push [ '-', key, val ]...
          when 'blur'
            ret.push [ '-', key, val['blur_strength'] ]...
          when 'preview'
            ret.push [ '-', key, val['two_dimensions'] ]...
          when 'scale_crop'
            if val?['center']
              ret.push [ '-', key, val['two_dimensions'], 'center']...
            else
              ret.push [ '-', key, val['two_dimensions'] ]...
          else
            throw new Error("You passed #{option} but that's not a valid imageUrlFor option")

      ret.join '/'
