import React from 'react'
import PropTypes from 'prop-types'
import './styles'

import flash from '/lib/flash'

export default \
class Pager extends React.Component
  @propTypes:
    meta:     PropTypes.object
    setPage:  PropTypes.func.isRequired

  @defaultProps:
    buffer: 3

  range: ->
    page = @props.meta.current_page

    minPage = Math.max 1, page - @props.buffer
    maxPage = Math.min page + @props.buffer, @props.meta.total_pages || 1

    [minPage..maxPage]

  setPage: (page=1)->
    @props.setPage? page

  onJumpSubmit: (e) ->
    e?.preventDefault()
    jumpToPage = parseInt( @jumpTo.value, 10 )
    if (typeof jumpToPage == "number") && jumpToPage > 0 && jumpToPage <= @props.meta.total_pages
      @setPage jumpToPage
    else
      flash.error "Jump to page between 1 and #{@props.meta.total_pages}"

  render: ->
    return null unless @props.meta?.current_page
    return null if @props.meta?.total_pages is 1
    <nav className="Pager">
      <ul className="pagination">

        <li className={'disabled' unless @props.meta.prev_page}>
          <a aria-label="First" onClick={@setPage.bind(@, 1)}>
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>

        <li className={'disabled' unless @props.meta.prev_page}>
          <a aria-label="Previous" onClick={@setPage.bind(@, @props.meta.prev_page)}>
            <span aria-hidden="true">&lsaquo;</span>
          </a>
        </li>

        {unless @range()[0] is 1
          <li key={"prev-elips"} className='disabled'>
            <span>
              ...
            </span>
          </li>
        }

        {for page in @range()
          <li key={"pager-page-#{page}"} className={'active' if page is @props.meta.current_page}>
            <a onClick={@setPage.bind(@, page)}>
              {page}
            </a>
          </li>
        }

        {unless @range()[-1] is @props.meta.total_pages || 1
          <li key={"next-elips"} className='disabled'>
            <span>
              ...
            </span>
          </li>
        }

        <li className={'disabled' unless @props.meta.next_page}>
          <a aria-label="Next" onClick={@setPage.bind(@, @props.meta.next_page)}>
            <span aria-hidden="true">&rsaquo;</span>
          </a>
        </li>

        <li className={'disabled' unless @props.meta.next_page}>
          <a aria-label="Last" title={@props.meta.total_pages} onClick={@setPage.bind(@, @props.meta.total_pages)}>
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>

        <li>
          <form className="jump-to" onSubmit={(e) => @onJumpSubmit(e)}>
            <input
              ref={(el)=> @jumpTo = el}
              type="text"
              className="form-control jump-input"
              placeholder="Go To"
            />
          </form>
        </li>

      </ul>
    </nav>
