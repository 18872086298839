import React from 'react'
import PropTypes from 'prop-types'
import './styles'

import flash        from '/lib/flash'
import withAbility  from '/lib/with-ability'

# compoenents
import Modal            from 'react-bootstrap/lib/Modal'
import ModalState       from '/components/modal-state'
import { Link }         from 'react-router-dom'
import Translator       from '/components/translator'
import Price            from '/components/price'
import Datetime         from '/components/datetime'
import ReactSelect      from '/components/react-select'
import Can              from '/components/can'
import FraudFlag    from '/components/fraud-flag'
import AddSteward       from './add-steward'

# icons
import FaTwitter    from 'react-icons/lib/fa/twitter'
import FaFacebook   from 'react-icons/lib/fa/facebook'
import FaGoogle     from 'react-icons/lib/fa/google'
import FaPlus       from 'react-icons/lib/fa/plus'

# utils
import format   from 'date-fns/format'
import humanize from 'underscore.string/humanize'

import {
  icp_options
  role_options
} from '/models/user'

import {
  withUser
  withUsersRPC
  withUpdateUserIcp
  withUpdateUserRole
  withUpdateUserEmail
  withUpdateUserManualRef
  withUpdateUserSignupSource
  withUpdateBulkMessageLimit
  withRemoveSteward
  withAddToSafelist
} from './queries'

export default \
withUser \
withUsersRPC \
withUpdateUserIcp \
withUpdateUserRole \
withUpdateUserEmail \
withUpdateUserManualRef \
withUpdateUserSignupSource \
withUpdateBulkMessageLimit \
withRemoveSteward \
withAbility \
withAddToSafelist \
class UserAside extends React.Component
  @propTypes:
    id:         PropTypes.string

  #
  # actions
  #
  confirmEmail: (evt)=>
    evt.preventDefault()
    {user, errors} = await @props.updateEmail @props.user.id, @newEmail.value
    if errors?.length
      flash.error errors
    else
      @newEmail.value = ''
      flash.success "User updated successfully"

  updateIcp: (opt)->
    {user, errors} = await @props.updateIcp @props.user.id, opt?.value
    if errors?.length
      flash.error errors
    else
      flash.success "User updated successfully"

  updateRole: (opt)->
    {user, errors} = await @props.updateRole @props.user.id, opt?.value
    if errors?.length
      flash.error errors
    else
      flash.success "User updated successfully"

  updateManualRef: (evt)=>
    evt.preventDefault()
    {user, errors} = @props.updateManualRef @props.user.id, @newManualRef.value
    if errors?.length
      flash.error errors
    else
      @newManualRef.value = ''
      flash.success "User updated successfully"

  updateSignupSource: (evt)=>
    evt.preventDefault()
    {user, errors} = @props.updateSignupSource @props.user.id, @newSignupSource.value
    if errors?.length
      flash.error errors
    else
      @newSignupSource.value = ''
      flash.success "User updated successfully"

  rpc: (id, method)->
    {user, errors} = await @props.users_rpc id, method
    if errors?.length
      flash.error errors
    else
      flash.success "User updated successfully"

  removeSteward: (steward_id)=>
    {user, errors} = await @props.removeSteward @user.id, steward_id
    if errors?.length
      flash.error errors
    else
      flash.success "Client Service Rep removed"
      @props.close?()

  addToSafelist: (evt)=>
    evt.preventDefault()
    {ip_address, expires_at, errors} = await @props.addToSafelist @safelistIP.value
    if errors?.length
      flash.error errors
    else
      flash.success "Safelist added"

  updateBulkMessageLimit: (evt)=>
    evt.preventDefault()
    {user, errors} = await @props.updateBulkMessageLimit @props.user.id, parseInt @newBulkMessageLimit.value
    if errors?.length
      flash.error errors
    else
      flash.success "User updated successfully"

  #
  # renders
  #
  render: ->
    return null unless @props.id?
    <aside className="UserAside">
      {@header()}
      {@userInfo()}
      {@hostInfo()}
      {@buyerInfo()}
      <Can action='manage_tmol' subject='User'>
        {@externalSyncInfo()}
      </Can>
    </aside>

  headerStyle: ->
    backgroundImage: "url(#{@user.cover_photo_url})" if @user.cover_photo_url

  header: ->
    return null if @props.userLoading
    @user = @props.user
    <header className="user-header" style={@headerStyle()}>
      <div className="overlay">
        <Link to={"/users/#{@user.slug}"}>
          <img src={@user.image_url}/>
          <h2>
            {@user.name}
            <FraudFlag value={@user.failed_orders_percentage}/>
          </h2>
          <h3>{@user.location?.address}</h3>
        </Link>
        <div className="social-row">
          {<a href={@user.twitter?.url}   target="_new"   title={"@#{@user.twitter?.name}"}><FaTwitter/></a> if @user.twitter?.url}
          {<a href={@user.facebook?.url}  target="_new"   title={@user.facebook?.name}><FaFacebook/></a> if @user.facebook?.url}
          {<a href={@user.google?.url}    target="_new"   title={@user.google?.name}><FaGoogle/></a> if @user.google?.url}
        </div>
      </div>
    </header>

  userInfo: ->
    return null if @props.userLoading
    @user = @props.user

    <section className="user-info" title="user info">
      <dl>
        <dt>ID</dt>
        <dd className="mono">
          {@user.id}
        </dd>

        <dt>Slug</dt>
        <dd className="mono">
          {@user.slug_id}
        </dd>

        <dt>Email</dt>
        <dd>
          <a href="mailto:#{@user.email}">{@user.email}</a>
          <form className="form-inline" onSubmit={@confirmEmail}>
            <div className="input-group">
              <input
                type="email"
                className="form-control input-sm"
                defaultValue={@props.user.unconfirmed_email}
                placeholder="Update Email"
                ref={(i)=> @newEmail = i}
              />
              <span className="input-group-btn">
                <button type="submit" className="btn btn-sm btn-default">Confirm</button>
              </span>
            </div>
          </form>
        </dd>

        <dt>Display Host Contact Details</dt>
        <dd>
          {
            if @user.messageable
              <div>
                <Can action='hide_contact_details!' subject='User'>
                  <button className="btn btn-xs btn-warning" onClick={@rpc.bind @, @user.id, 'hide_contact_details!'}>
                    yes
                  </button>
                </Can>
                <Can.Not action='hide_contact_details!' subject='User'>
                  <span>yes</span>
                </Can.Not>
              </div>
            else
              <div>
                <Can action='show_contact_details!' subject='User'>
                  <button className="btn btn-xs btn-warning" onClick={@rpc.bind @, @user.id, 'show_contact_details!'}>
                    no
                  </button>
                </Can>
                <Can.Not action='show_contact_details!' subject='User'>
                  <span>no</span>
                </Can.Not>
              </div>
          }
        </dd>

        <dt>Needs Completion?</dt>
        <dd>
          <Translator value={@user.needs_completion}/>
        </dd>

        <dt>Account Created</dt>
        <dd>
          <Datetime date={@user.created_at}/>
        </dd>

        <dt>Location</dt>
        <dd>
          {@user.location?.address}
        </dd>

        <dt>Verification</dt>
        <dd>
          {
            if @user.verified_at
              <div>
                <span className="label label-success label-sm">Verified</span>
                <br/>
                <Datetime date={@user.verified_at}/>
              </div>
            else
              <div>
                <span>Unverified</span>
                {' '}
                <button className="btn btn-xs btn-default" onClick={@rpc.bind @, @user.id, 'verify!'}>
                  Verify user
                </button>
              </div>
          }
        </dd>

        <dt>Role</dt>
        <dd>
          <Can action='assign_roles' subject='User'>
            <ReactSelect
              className="filter-selector state-selector"
              options={role_options}
              onChange={@updateRole.bind @}
              value={@user.role}
              clearable={false}
              placeholder="Select Role"
            />
          </Can>
          <Can.Not action='assign_roles' subject='User'>{@user.role}</Can.Not>
          {
            if @user.role == 'Spammer'
              <div>
                <button className="btn btn-xs btn-warning" onClick={@rpc.bind @, @user.id, 'unmark_as_spammer!'}>
                  Unmark as spammer
                </button>
              </div>
            else
              <div>
                <button className="btn btn-xs btn-warning" onClick={@rpc.bind @, @user.id, 'mark_as_spammer!'}>
                  Mark as spammer
                </button>
              </div>
          }
          {
            if @user.banned
              <div>
                <button className="btn btn-xs btn-warning" onClick={@rpc.bind @, @user.id, 'unmark_as_banned!'}>
                  Unban User
                </button>
              </div>
            else
              <div>
                <button className="btn btn-xs btn-warning" onClick={@rpc.bind @, @user.id, 'mark_as_banned!'}>
                  Ban User
                </button>
              </div>
          }
        </dd>
        <dt>GDPR State</dt>
        <dd>{@user.gdpr_state}</dd>

        <dt>Manual Ref</dt>
        <dd>
          <span>{@user.manual_ref}</span>
          <Can action='add_ref' subject='User'>
            <form className="form-inline" onSubmit={@updateManualRef}>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control input-sm"
                  defaultValue={@user.manual_ref}
                  placeholder="Add a manual ref"
                  ref={(i)=> @newManualRef = i}
                />
                <span className="input-group-btn">
                  <button type="submit" className="btn btn-sm btn-default">Save</button>
                </span>
              </div>
            </form>
          </Can>
        </dd>

        <dt>Signup Source</dt>
        <dd>
          <span>{@user.signup_source}</span>
          <Can action='update_signup_source' subject='User'>
            <form className="form-inline" onSubmit={@updateSignupSource}>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control input-sm"
                  defaultValue={@user.signup_source}
                  placeholder="Add a signup source"
                  ref={(i)=> @newSignupSource = i}
                />
                <span className="input-group-btn">
                  <button type="submit" className="btn btn-sm btn-default">Save</button>
                </span>
              </div>
            </form>
          </Can>
        </dd>

        <dt>Last Known IP</dt>
        <dd>
          <form className="form-inline" onSubmit={@addToSafelist}>
            <div className="input-group">
              <input
                type="text"
                className="form-control input-sm"
                style={fontFamily: 'monospace'}
                defaultValue={@user.current_sign_in_ip}
                placeholder="Safelist an IP address"
                ref={(i)=> @safelistIP = i}
              />
              <span className="input-group-btn">
                <button type="submit" className="btn btn-sm btn-default">Add to Safelist</button>
              </span>
            </div>
          </form>
        </dd>

      </dl>
    </section>

  externalSyncInfo: ->
    return null if @props.userLoading
    @user = @props.user
    <section className="user-info" title="External Syncing">
      <dl>
        <dt>Ticketmaster International</dt>
        <dd>
          {
            if @user.allow_on_mfol
              <div>
                <p><small>This will remove all of this Host's events from our Ticketmaster International feeds that aren't marked for adding at the Listing level as well. It is up to Ticketmaster themselves to see that these aren't in the field and remove them from their respective websites.</small></p>
                <button className="btn btn-xs btn-warning" onClick={@rpc.bind @, @user.id, 'hide_on_mfol'}>
                  Remove from MFOL Feed
                </button>
              </div>
            else
              <div>
                <p><small>This will add <b>all</b> of this host's events to our MFOL feeds. These feeds are consumed by Ticketmaster International and events will appear on local Ticketmaster sites (ticketmaster.no, ticketmaster.de). Think carefully before taking this action.</small></p>
                <button className="btn btn-xs btn-success" onClick={@rpc.bind @, @user.id, 'show_on_mfol'}>
                  Add to MFOL Feed
                </button>
              </div>
          }
        </dd>
      </dl>
    </section>

  hostInfo: ->
    return null if @props.userLoading
    @user = @props.user
    return null unless @user.num_listings
    <section className="host-info" title="host info">
      <dl>
        <dt>
          <Link to={"/users/#{@user.slug}/listings"}>
            Hosted Listings
          </Link>
        </dt>
        <dd>
          <Link to={"/users/#{@user.slug}/listings"}>
            {Number @user.num_listings}
          </Link>
        </dd>
        <dt>ICP</dt>
        <dd>
          <ReactSelect
            disabled={@props.cannot 'update_icp', 'User'}
            className="filter-selector state-selector"
            options={icp_options}
            onChange={@updateIcp.bind @}
            value={@user.icp}
            clearable={false}
            placeholder="Select ICP"
          />
        </dd>

        <dt>Client Service Reps</dt>
        <dd>
          {if @user.stewards.length
            <ul className="stewards">
              {for s in @user.stewards
                <li key={s.id}>
                  {s.name}
                  <button
                    className="btn btn-default btn-xs"
                    title="Remove client service rep"
                    onClick={@removeSteward.bind @, s.id}
                  >
                    ×
                  </button>
                </li>
              }
            </ul>
          }
          <Can action='manage' subject='Stewardship'>
            {@addStewardModal()}
          </Can>
        </dd>

        <dt>Booking protect</dt>
        <dd>
          {
            if @user.booking_protect
              <button className="btn btn-xs btn-success" onClick={@rpc.bind @, @user.id, 'toggle_booking_protect!'}>
                enabled
              </button>
            else
              <button className="btn btn-xs btn-warning" onClick={@rpc.bind @, @user.id, 'toggle_booking_protect!'}>
                disabled
              </button>
          }
        </dd>

        <dt>Bulk Message Limit</dt>
        <dd>
          <Can.Not action='update_bulk_message_limit' subject='User'>
            {@user.bulk_message_limit}
          </Can.Not>
          <Can action='update_bulk_message_limit' subject='User'>
            <form className="form-inline" onSubmit={@updateBulkMessageLimit}>
              <div className="input-group">
                <input
                  type="number"
                  step="100"
                  min="1000"
                  className="form-control input-sm"
                  defaultValue={@user.bulk_message_limit}
                  ref={(i)=> @newBulkMessageLimit = i}
                />
                <span className="input-group-btn">
                  <button type="submit" className="btn btn-sm btn-default">Save</button>
                </span>
              </div>
            </form>
          </Can>
        </dd>

      </dl>
    </section>

  buyerInfo: ->
    return null if @props.userLoading
    @user = @props.user
    return null unless @user.num_tickets
    <section className="buyer-info" title="buyer info">
      <dl>
        <Link to={"/users/#{@user.slug}/tickets"}>
          <dt>Tickets Purchased</dt>
          <dd>
            {Number @user.num_tickets}
          </dd>
      </Link>

      </dl>
    </section>

  addStewardModal: =>
    <ModalState
      render={({open, toggle})=>
        <span>
          <button
            className="btn btn-default btn-xs"
            onClick={toggle}
          >
            + Add a Client Service Rep
          </button>

          <Modal
            show={open}
            onHide={toggle}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Assign a Client Service Rep
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='clearfix'>
              <AddSteward
                close={toggle}
                host={@props.user}
              />
            </Modal.Body>
          </Modal>
        </span>
      }/>
