import React from 'react'

import { Link }         from 'react-router-dom'
import { formatMoney }  from 'accounting'
import currencies       from '/lib/currencies'

# components
import Spinner      from '/components/spinner'

import { withKickbackRates } from './queries'

export default withKickbackRates class Kickback extends React.Component

  render: ->
    return <Spinner/> if @props.loading || @props.listing?.created_at == null
    return @dateGate() if @props.listing.created_at < @releaseDate()
    @kickback()

  dateGate: ->
    <div className="Kickback">
      <div className="message">
        This feature is only available for listings created after {@releaseDate()}.
      </div>
    </div>

  releaseDate: ->
    # there is no backfill for this data so we need to date-gate it
    '2018-12-15'

  kickback: ->
    <div className="Kickback">
      <header>
        <h1>Fee Breakdown (BETA)</h1>
      </header>
      <div className="message">
        All amounts shown are in the settlement currency of the listing.
      </div>
      <section className="content">
        <h1>Listing</h1>
        {@listingTable()}
      </section>
      <section className="content">
        <h1>Events</h1>
        {@eventTable()}
      </section>
    </div>

  listingTable: ->
    @table(@props.rates)

  eventTable: ->
    <div>
      {for event in @props.events
        <div key={event.id}>
          <div className="event-table-title">
            <div>
              <Link className="btn btn-default" to={"/events/#{event.id}/summary"}>
                More info
              </Link>
            </div>
            <div>
              {event.when}
            </div>
          </div>
          <div>
            {@table(event.rates)}
          </div>
        </div>
      }
    </div>

  formatAmount: (amount)->
    formatMoney amount, symbol: currencies[@props.listing.base_currency].replace @props.listing.base_currency, ''

  sumRateValues: (rates, index)->
    sum = 0
    for rate in rates
      sum += rate[index]
    sum

  header: ->
    <tr>
      <th className="id">Rate ID</th>
      <th className="name">Name</th>
      <th className="type">Num. confirmed</th>
      <th className="type">Num. Paid</th>
      <th className="type">Total processing fee</th>
      <th className="type">Total service fee flat</th>
      <th className="type">Total service fee percent</th>
      <th className="type">Net sales</th>
    </tr>

  table: (rates)->
    <table className="table" data-loading={@props.loading}>
      <thead>
        {@header()}
      </thead>
      <tbody>
        {for rate in rates
          <tr key={rate.identifier} className="KickbackRow">
            <td className="id">
              <div className="id-container">
                {rate.identifier}
              </div>
            </td>
            <td className="name">{rate.name}</td>
            <td className="type">{rate.num_confirmed}</td>
            <td className="type">{rate.num_paid}</td>
            <td className="type">
              <div>{@formatAmount(rate.total_service_fee_broker)}</div>
            </td>
            <td className="type">
              <div>{@formatAmount(rate.total_service_fee_flat)}</div>
            </td>
            <td className="type">
              <div>{@formatAmount(rate.total_service_fee_percent)}</div>
            </td>
            <td className="type">{@formatAmount(rate.net_sales)}</td>
          </tr>
        }
        <tr className="KickbackRow kickback-totals">
          <td className="id"></td>
          <td className="totals">Totals:</td>
          <td className="type">{@sumRateValues(rates, "num_confirmed")}</td>
          <td className="type">{@sumRateValues(rates, "num_paid")}</td>
          <td className="type">
            {@formatAmount(@sumRateValues(rates, "total_service_fee_broker"))}
          </td>
          <td className="type">
            {@formatAmount(@sumRateValues(rates, "total_service_fee_flat"))}
          </td>
          <td className="type">
            {@formatAmount(@sumRateValues(rates, "total_service_fee_percent"))}
          </td>
          <td className="type">{@formatAmount(@sumRateValues(rates, "net_sales"))}</td>
        </tr>
      </tbody>
    </table>
