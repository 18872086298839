import React from 'react'
import './styles'

import HeaderRow from './header-row'
import Header from './header'
import Row  from './row'
import Cell from './cell'

export default \
class List extends React.Component
  @displayName: 'List'

  @HeaderRow:     HeaderRow
  @Header:        Header
  @Row:           Row
  @Cell:          Cell

  render: ->
    { children, className='', props... } = @props
    <div className="List #{className}" {props...} >
      {children}
    </div>
