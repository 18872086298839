import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { ApolloLink, concat } from 'apollo-link'
import { InMemoryCache }      from 'apollo-cache-inmemory'

httpLink = new HttpLink(uri: "#{process.env.WEB}/admin/graphql")

authMiddleware = new ApolloLink (operation, forward)->
  # add the authorization to the headers
  if token = localStorage.access_token
    operation.setContext headers: authorization: "Bearer #{token}"

  forward operation

cache = new InMemoryCache

export client = new ApolloClient cache: cache, link: concat(authMiddleware, httpLink)
export { ApolloProvider } from 'react-apollo'
