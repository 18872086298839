import React from 'react'
import './styles'

import { Link, Route, Switch, Redirect } from 'react-router-dom'

import DocumentTitle from 'react-document-title'

# routes
import Summary       from './summary'
import CostItems     from './cost-items'
import Changes       from './changes'
import Emails        from './emails'
import Notes         from './notes'
import PrintingLogs  from './printing-logs'

import UserAside      from '/components/user-aside'
import ExternalLink   from '/components/external-link'
import Spinner        from '/components/spinner'

import {
  Tabs
  TabLink
  TabContent
} from '/components/tabs'

import {
  withTicket
  withActiveNotes
} from './queries'

export default \
withActiveNotes \
withTicket \
class Ticket extends React.Component

  render: ->
    return <Spinner/> if @props.ticketLoading
    <div className="Ticket">
      <DocumentTitle title={"Ticket #{@props.ticket.id}"}/>
      <header>
        <h1>
          Ticket {@props.ticket.id}
          <ExternalLink url={@props.ticket.url}/>
        </h1>
      </header>

      <main>

        <section className="content">
          <Tabs>
            <TabLink to={"#{@props.match.url}/summary"}>Summary</TabLink>
            <TabLink to={"#{@props.match.url}/cost_items"}>
              Cost Items <span className="badge">{@props.ticket.num_cost_items}</span>
            </TabLink>
            <TabLink to={"#{@props.match.url}/changes"}>Changes</TabLink>
            <TabLink to={"#{@props.match.url}/emails"}>Emails</TabLink>
            <TabLink to={"#{@props.match.url}/notes"}>
              Notes <span className="badge"> {@props.notes.length} </span>
            </TabLink>
            <TabLink to={"#{@props.match.url}/printing_logs"}>
              Printing History <span className="badge"> {@props.ticket.num_printing_logs} </span>
            </TabLink>
            <TabContent>

              <Switch>
                <Route path='/tickets/:id/summary'        component={Summary}/>
                <Route path='/tickets/:id/cost_items'     component={CostItems}/>
                <Route path='/tickets/:id/changes'        component={Changes}/>
                <Route path='/tickets/:id/emails'         component={Emails}/>
                <Route path='/tickets/:id/notes'          component={Notes}/>
                <Route path='/tickets/:id/printing_logs'  component={PrintingLogs}/>
                <Redirect from="#{@props.match.url}" to="#{@props.match.url}/summary" />
              </Switch>

            </TabContent>
          </Tabs>
        </section>

        <UserAside id={@props.ticket.user?.id}/>
      </main>

    </div>
