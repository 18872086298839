import React from 'react'
import PropTypes from 'prop-types'

# flag images
import aud from '/assets/images/flags/flag-aud.png'
import brl from '/assets/images/flags/flag-brl.png'
import cad from '/assets/images/flags/flag-cad.png'
import chf from '/assets/images/flags/flag-chf.png'
import dkk from '/assets/images/flags/flag-dkk.png'
import eur from '/assets/images/flags/flag-eur.png'
import gbp from '/assets/images/flags/flag-gbp.png'
import hkd from '/assets/images/flags/flag-hkd.png'
import ire from '/assets/images/flags/flag-ire.png'
import jpy from '/assets/images/flags/flag-jpy.png'
import mxn from '/assets/images/flags/flag-mxn.png'
import nok from '/assets/images/flags/flag-nok.png'
import nzd from '/assets/images/flags/flag-nzd.png'
import sek from '/assets/images/flags/flag-sek.png'
import sgd from '/assets/images/flags/flag-sgd.png'
import usd from '/assets/images/flags/flag-usd.png'

import defaultFlag from '/assets/images/flags/flag-nil.png'

flags =
  aud: aud
  brl: brl
  cad: cad
  chf: chf
  dkk: dkk
  eur: eur
  gbp: gbp
  hkd: hkd
  ire: ire
  jpy: jpy
  mxn: mxn
  nok: nok
  nzd: nzd
  sek: sek
  sgd: sgd
  usd: usd

export default \
class Flag extends React.Component
  @propTypes:
    currency: PropTypes.string.isRequired

  render: ->
    { className=''} = @props
    <img
      className="Flag #{@props.currency} #{className}"
      src={flags[@props.currency] or defaultFlag}
    />
