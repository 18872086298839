import Model from '/lib/model'

export default class Vat extends Model
  @className: 'Vat'
  constructor: ->
    super arguments...
    @name         = null
    @country_code = null
    @province     = null
    @percent      = null
    @tax_id       = null

  @belongsTo 'business_info', model: 'BusinessInfo'

  @computed 'vat_label', ->
    location = [@country_code, @province].join(' ')
    "#{@name} #{@percent}% (#{location})"

  @computed 'errors', ->
    errors = []
    for name in ['name', 'tax_id', 'percent']
      errors.push(name) unless @[name] || @[name] == 0
    errors
