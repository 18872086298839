import React from 'react'

import flash from '/lib/flash'

import { Link } from 'react-router-dom'

import sortBy from 'underscore-es/sortBy'

import {
  base_currency_options
  state_options
} from '/models/chargeback'

# components
import Spinner      from '/components/spinner'
import List         from '/components/list'
import Datetime     from '/components/datetime'
import StateActions from '/components/state-label/actions'
import UserCard     from '/components/user-card'
import Price        from '/components/price'
import ListingPhoto from '/components/listing-photo'
import Pager        from '/components/pager/history-pager'
import Filters      from '/components/filters'
import StateLabel   from '/components/state-label'

import { withUser, withChargebacks } from './queries'

export default \
withUser \
withChargebacks \
class Chargebacks extends React.Component

  rpc: (id, method)->
    {chargeback, errors} = await @props.chargebacks_rpc id, method
    if errors?.length
      flash.error errors
    else
      flash.success "Chargeback updated successfully"

  render: ->
    <div className="Chargebacks">
      {@filters()}
      {@list()}
      {@pager()}
    </div>

  filters: ->
    <Filters>
      <Filters.Search/>
      <Filters.State options={state_options}/>
      <Filters.Currency options={base_currency_options}/>
      <span className="pull-right">
        <Pager meta={@props.chargebacksPagination} buffer={2}/>
      </span>
    </Filters>

  list: ->
    # TODO: replace ticket with TicketCard when component is made
    return <Spinner/> if @props.chargebacksLoading

    <List>
      {@header()}
      {for chargeback in sortBy @props.chargebacks, 'created_at' by -1
        <List.Row key={chargeback._key}>

          <List.Cell className="id">
            {chargeback.id}
          </List.Cell>

          <List.Cell className="stripe-id">
            {chargeback.stripe_charge_id}
          </List.Cell>

          <List.Cell className="event">
            <Link to={"/ticket/#{chargeback.ticket.id}/summary"}>
              <ListingPhoto
                image_url={chargeback.ticket.listing.image_url}
                listing={chargeback.ticket.listing}
                size={40}
              />
                <div className="listing-details">
                  <span className="title">{chargeback.ticket.listing.title}</span>
                  <small className="when">{chargeback.ticket.event.when}</small>
                </div>
            </Link>
          </List.Cell>

          <List.Cell className="amount">
            <Price amount={chargeback.amount} currency={chargeback.display_currency}/>
          </List.Cell>

          <List.Cell className="fee">
            <Price amount={chargeback.fee} currency={chargeback.display_currency}/>
          </List.Cell>

          <List.Cell className="requested_at">
            <Datetime date={chargeback.created_at} format='MMM d, yyyy'/>
          </List.Cell>

          <List.Cell className="status">
            <StateLabel model={chargeback}/>
          </List.Cell>

        </List.Row>
      }
    </List>

  header: ->
    <List.HeaderRow>
      <List.Header className="id"> Id </List.Header>
      <List.Header className="stripe-id">Stripe Charge Id</List.Header>
      <List.Header className="event"> Event </List.Header>
      <List.Header className="amount"> Amount </List.Header>
      <List.Header className="fee"> Fee </List.Header>
      <List.Header className="requested_at"> Requested </List.Header>
      <List.Header className="status"> Status </List.Header>
    </List.HeaderRow>

  pager: ->
    <div className="text-center">
      <Pager meta={@props.chargebacksPagination} buffer={4}/>
    </div>
