import Model            from '/lib/model'

export default \
class Installment extends Model
  @className: 'Installment'
  constructor: ->
    super arguments...
    @id               = null # "5751b0abc6d1c694faed4b40"
    @state            = null # paid
    @paid_at          = null
    @base_amount      = null
    @src_amount       = null

  @belongsTo 'ticket',    model: 'Ticket'
