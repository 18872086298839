import React from 'react'

import flash from '/lib/flash'

import isFuture   from 'date-fns/isFuture'
import isPast     from 'date-fns/isPast'

# components
import Spinner      from '/components/spinner'
import StateLabel   from '/components/state-label'
import List         from '/components/list'
import Price        from '/components/price'
import Fee          from '/components/fee'
import ReactSelect  from '/components/react-select'
import UserCard     from '/components/user-card'
import RulesCell    from '/components/order-fee/rules-cell'
import { state_options } from '/models/event'
import find  from 'underscore-es/find'

import {
  withListing
  withRates
  withOrderFee
  updateReferral
  updateRate
  withOrderLevelCodes
  withCommissionCodes
  updateListingOrderLevelCode
  deleteListingOrderLevelCode
} from './queries'

import withAbility  from '/lib/with-ability'

export default \
withRates \
withListing \
withOrderFee \
updateReferral \
updateRate \
withOrderLevelCodes \
withCommissionCodes \
updateListingOrderLevelCode \
deleteListingOrderLevelCode \
withAbility \
class Rates extends React.Component

  constructor: (props) ->
    super(props)
    @state =
      commissionCodeSelectDisabled: false

  render: ->
    <div>
      <div className="Rates">
        <h2>Listing</h2>
        {@listing()}
      </div>
      <div className="Rates">
        <h2>Order</h2>
        {@orderCodes()}
      </div>
      <div className="Rates">
        <h2>Rates</h2>
        {@rates()}
      </div>
    </div>

  orderLevelFeeCodeOptions: =>
    @props.olfCodes.map ({code}) -> value: code, label: code

  updateListingOrderLevelCode: (id, code) =>
    if code
      await @props.updateListingOrderLevelCode id, code?.value
    else
      await @props.deleteListingOrderLevelCode id

    if errors?.length
      flash.error errors
    else
      flash.success "Listing level fee code updated successfully"

  orderCodes: ->
    return <Spinner/> if @props.orderCodesLoading
    <List>
      <List.HeaderRow>
        <List.Header className="id">
          Per Order Fee
        </List.Header>
        <List.Header className="id">
          Order Fee Code
        </List.Header>
      </List.HeaderRow>
      <List.Row>
        {
          !@props.listing.order_fee && <List.Cell>
            No applicable per order fee
          </List.Cell>
        }
        {
          @props.listing.order_fee && <List.Cell>
            <RulesCell rules={@props.listing?.order_fee?.rules} />
          </List.Cell>
        }
        <List.Cell className="order-fee-code">
          <ReactSelect
            className="rate-selector"
            name='order-level-code'
            options={@orderLevelFeeCodeOptions()}
            multi={false}
            value={@props.listing?.order_fee?.code || ""}
            onChange={@updateListingOrderLevelCode.bind @, @props.listing.id}
            placeholder={@props.listing.user.organization.order_fee?.code || "Select a code"}
          />
        </List.Cell>
      </List.Row>
    </List>

  rates: ->
    return <Spinner/> if @props.ratesLoading
    <List>
      <List.HeaderRow>
        <List.Header className="id">
          ID
        </List.Header>
        <List.Header className="name">
          Name
        </List.Header>
        <List.Header className="price">
          Price
        </List.Header>
        <List.Header className="fees">
          Processing Fee
        </List.Header>
        <List.Header className="fees">
          Service Fee
        </List.Header>
        <List.Header className="fees">
          Rebate User
        </List.Header>
        <List.Header className="commission-code">
          Commission Code
        </List.Header>
      </List.HeaderRow>
      {for rate, index in @props.rates
        <List.Row key={index}>
          <List.Cell className="id">
            {rate.id}
          </List.Cell>
          <List.Cell className="name" title={rate.name}>
            {rate.name}
          </List.Cell>
          <List.Cell className="price">
            <Price amount={rate.price} currency={@props.listing.src_currency} showCurrency={false}/>
          </List.Cell>
          <List.Cell className="fees">
            {@calculateBrokerFee(rate.commission_code_attribute)}
          </List.Cell>
          <List.Cell className="fees">
            <Fee
              flat={@calculateFeeProps(rate, "flat")}
              percent={@calculateFeeProps(rate, "percent")}
              currency={@props.listing.src_currency}
            />
            <small>
              <Fee
                flat={@calculateFeeProps(rate, "rebate_flat")}
                percent={@calculateFeeProps(rate, "rebate_percent")}
                ticket_percent={@calculateFeeProps(rate, "rebate_cost_item_percent")}
                currency={@props.listing.src_currency}
                rebate={true}
              />
            </small>
          </List.Cell>
          <List.Cell className="fees">
            <UserCard user={@calculateFeeProps(rate, "rebate_user")} />
          </List.Cell>
          <List.Cell className="commission-code">
            <ReactSelect
              className="rate-selector"
              name='commission-code'
              options={@props.codes.map?((e) => {value: e.code, label: e.code})}
              multi={false}
              value={rate.commission_code_attribute}
              onChange={@updateRate.bind @, rate.id}
              placeholder={@props.referral || @accountCommissionCode() || "Select..."}
              disabled={@props.cannot('manage_com_code','Listing') or @state.commissionCodeSelectDisabled}
            />
          </List.Cell>
        </List.Row>
      }
    </List>

  listing: ->
    <List>
      <List.HeaderRow>
        <List.Header className="id">
          ID
        </List.Header>
        <List.Header className="fees">
          Processing Fee
        </List.Header>
        <List.Header className="fees">
          Service Fee
        </List.Header>
        <List.Header className="fees">
          Rebate User
        </List.Header>
        <List.Header className="commission-code">
          Commission Code
        </List.Header>
      </List.HeaderRow>
      <List.Row>
        <List.Cell className="id">
          {@props.listing.id}
        </List.Cell>
        <List.Cell className="fees">
          {@calculateBrokerFee(@props.listing.referral)}
        </List.Cell>
        <List.Cell className="fees">
          <Fee
            flat={@calculateFeeProps(@props.listing, "flat")}
            percent={@calculateFeeProps(@props.listing, "percent")}
            currency={@props.listing.src_currency}
          />
          <small>
            <Fee
              flat={@calculateFeeProps(@props.listing, "rebate_flat")}
              percent={@calculateFeeProps(@props.listing, "rebate_percent")}
              ticket_percent={@calculateFeeProps(@props.listing, "rebate_cost_item_percent")}
              currency={@props.listing.src_currency}
              rebate={true}
            />
          </small>
        </List.Cell>
        <List.Cell className="fees">
          <UserCard user={@calculateFeeProps(@props.listing, "rebate_user")} />
        </List.Cell>
        <List.Cell className="commission-code">
          <ReactSelect
            className="rate-selector"
            name='commission-code'
            options={@props.codes.map?((e) => {value: e.code, label: e.code})}
            multi={false}
            value={@props.referral}
            onChange={@updateReferral.bind @, @props.listing.id}
            placeholder={@props.referral  || @accountCommissionCode() || "Select..."}
            disabled={@props.cannot('manage_com_code','Listing') or @state.commissionCodeSelectDisabled}
          />
        </List.Cell>
      </List.Row>
    </List>

  accountCommissionCode: ->
    organization = @props.listing.user.organization
    code = @props.codes.find?((e) => e.code == organization.commission_code_attribute) || @props.codes.find?((e) => e.code == organization.plan.commission_code)
    code?.code

  updateRate: (rate_id, item) =>
    @setState commissionCodeSelectDisabled: true
    {rate, errors} = await @props.updateRate rate_id, item?.value or ""
    if errors?.length
      flash.error errors
    else
      flash.success "Rate commission code updated successfully"
    @setState commissionCodeSelectDisabled: false

  updateReferral: (listing_id, item) =>
    @setState commissionCodeSelectDisabled: true
    {listing, errors} = await @props.updateReferral listing_id, if item then item.value else ""
    if errors?.length
      flash.error errors
    else
      flash.success "Listing commission code updated successfully"
    @setState commissionCodeSelectDisabled: false

  calculateFeeProps: (rate, type) =>
    code = @fallbackCode(rate.commission_code_attribute || @props.referral || @accountCommissionCode())
    if code
      if code then code[type] else null
    else
      -1

  calculateBrokerFee: (commission_code) =>
    code = @fallbackCode(commission_code || @props.referral || @accountCommissionCode())
    if code then "#{(code["broker_fee"] * 100).toFixed(2)}%" else 'FALLBACK'

  fallbackCode: (commission_code) =>
    ret = find @props.codes, (code) =>
      code.code == commission_code &&
      code.currency == @props.listing.src_currency &&
      code.country == @props.listing.country_code

    ret ||= find @props.codes, (code) =>
      code.code == commission_code &&
      code.currency == @props.listing.src_currency &&
      !code.country?

    ret ||= find @props.codes, (code) =>
      code.currency == @props.listing.src_currency &&
      code.code == commission_code

    ret
