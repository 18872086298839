import React from 'react'

import flash from '/lib/flash'

import CommissionCode, { base_currency_options } from '/models/commission-code'
import ReactSelect  from '/components/react-select'

import humanize from 'underscore.string/humanize'
import debounce from 'underscore-es/debounce'

import { withTicketmasterAttractions, withCreateAttraction } from './queries'

import SearchResults  from './search_results'
import AttractionForm from './attraction-form'

export default \
withTicketmasterAttractions \
withCreateAttraction \
class NewAttraction extends React.Component
  constructor: ->
    super arguments...
    @state =
      attraction: undefined

  changeSearch: (evt)=>
    @search evt.target?.value

  search: debounce (term)->
    @setState search: term
  , 1000

  changeAttraction: (attraction)=>
    @setState attraction: attraction

  createAttraction: (attraction)=>
    {attraction, errors} = await @props.createAttraction attraction
    if errors?.length?
      flash.error errors
    else
      flash.success "Attraction created succesfully"
      @props.cancel()

  render: ->
    <div className="NewAttraction">
      <div className="AttractionSearch">
        <h2>Find a Ticketmaster Attraction</h2>
        <label>
          Search Term:
          <input className="form-control filter-input input-sm" onChange={@changeSearch} type="text" placeholder="Attraction Search Term"/>
        </label>
        {if @state.search
          <SearchResults search={@state.search} changeAttraction={@changeAttraction} />
        }
      </div>
      {if @state.attraction
        <AttractionForm attraction={@state.attraction} action={@createAttraction} />
      }
    </div>
