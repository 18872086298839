import CurrentUser from '/models/current-user'
import store from '/store'
import * as Sentry from '@sentry/browser'

import flash from '/lib/flash'

# Consts
export SIGN_IN_STARTED = 'SIGN_IN_STARTED'
export SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export SIGN_IN_ERRORED = 'SIGN_IN_ERRORED'
export GET_USER_STARTED = 'GET_USER_STARTED'
export GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export GET_USER_ERRORED = 'GET_USER_ERRORED'
export CHECK_TOKEN_STARTED = 'CHECK_TOKEN_STARTED'
export CHECK_TOKEN_SUCCESS = 'CHECK_TOKEN_SUCCESS'
export CHECK_TOKEN_ERRORED = 'CHECK_TOKEN_ERRORED'
export SIGN_OUT = 'SIGN_OUT'

# Setup hook to add token to each request
$.ajaxSetup
  beforeSend: (xhr) =>
    if localStorage.access_token
      xhr.setRequestHeader 'Authorization', "Bearer #{localStorage.access_token}"
      xhr.withCredentials = true
    else
      console.log 'no token'

# Actions
export signOut = ->
  localStorage.removeItem 'access_token'
  store.dispatch type: SIGN_OUT

export signIn = (email, password) ->
  store.dispatch type: SIGN_IN_STARTED

  return $.ajax(
    type: 'POST'
    url: "#{process.env.WEB}/oauth/token"
    dataType: 'json'
    data:
      username: email
      password: password
      grant_type: 'password'
    crossDomain: true
    xhrFields:
      withCredentials: true
  )
  .done (data)->
    localStorage.access_token = data.access_token
    store.dispatch type: SIGN_IN_SUCCESS, payload: data.access_token
    await getUser()
  .fail -> store.dispatch type: SIGN_IN_ERRORED

export signInFromOkta = (token) ->
  store.dispatch type: SIGN_IN_STARTED

  $.ajax(
    type: 'POST'
    url: "#{process.env.WEB}/admin/v1/users/sign_in_from_okta"
    dataType: 'json'
    data:
      token: token
    crossDomain: true
    xhrFields:
      withCredentials: true
  )
  .done (data)->
    localStorage.access_token = data.access_token
    store.dispatch type: SIGN_IN_SUCCESS, payload: data.access_token
    await getUser()
  .fail -> store.dispatch type: SIGN_IN_ERRORED

registerSentryUser = (user)->
  try
    { id, email, name } = user
    Sentry.configureScope (scope)->
      scope.setUser { id, email, name }
  catch # ignore

getUser = ->
  store.dispatch type: GET_USER_STARTED

  $.getJSON "#{process.env.WEB}/api/v2/current_user"
  .done (data) ->
    user = CurrentUser.parse data.current_user
    if user.is_admin
      registerSentryUser user
      store.dispatch type: GET_USER_SUCCESS, payload: user
      flash.success "Welcome back, #{user.name}"
    else
      flash.error "#{user.name} is not an admin account"
      store.dispatch type: GET_USER_ERRORED
      signOut()
  .fail (err) ->
    console.log err
    store.dispatch type: GET_USER_ERRORED

export checkToken = ->
  return unless localStorage.access_token

  store.dispatch type: CHECK_TOKEN_STARTED

  $.getJSON "#{process.env.WEB}/oauth/token/info"
  .done ->
    store.dispatch type: CHECK_TOKEN_SUCCESS
    await getUser()
  .fail -> store.dispatch type: CHECK_TOKEN_ERRORED
