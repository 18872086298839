import React from 'react'

import flash from '/lib/flash'

#assets
import { Link } from 'react-router-dom'

# components
import Modal                        from 'react-bootstrap/lib/Modal'
import CreateAdvance                from '/components/create-advance'
import CreateAdvanceBtn             from '/components/create-advance/btn'
import CreatePartnershipPayment     from '/components/create-partnership-payment'
import CreatePartnershipBtnPayment  from '/components/create-partnership-payment/btn'
import Spinner                      from '/components/spinner'
import Price                        from '/components/price'
import List                         from '/components/list'
import Flag                         from '/components/flag'
import Can                          from '/components/can'

import FaPlus       from 'react-icons/lib/fa/plus'

import {
  withUser
  withUserBalances
  withCreateAdvance
  withCreatePartnershipPayment
  withCreateServiceInvoice
} from './queries'

export default \
withUser \
withUserBalances \
withCreateAdvance \
withCreatePartnershipPayment \
withCreateServiceInvoice \
class Balances extends React.Component
  constructor: (props)->
    super arguments...
    @state =
      advanceModalOpen:             false
      partnershipPaymentModalOpen:  false
      currencySelected:             null

  ##actions
  createServiceInvoice: (balance) =>
    return unless confirm "Confirm invoice to be sent to #{@props.user.name}"
    obj =
      currency: balance.currency_code
      user_id: @props.user.id

    {service_invoice_create, errors} = await @props.createServiceInvoice(obj)
    return flash.error errors if errors?.length
    flash.success "Service Invoice created successfully"

  createAdvance: (toggle, obj)->
    {advance, errors} = await @props.createAdvance obj
    if errors?.length
      flash.error errors
    else
      flash.success "Advance created successfully"
      @toggleAdvanceModal()

  createPartnershipPayment: (toggle, obj)->
    {partnership_payment, errors} = await @props.createPartnershipPayment obj
    if errors?.length
      flash.error errors
    else
      flash.success "Partnership Payment created successfully"
      @togglePartnershipPaymentModal()

  toggleAdvanceModal: =>
    @setState advanceModalOpen: not @state.advanceModalOpen

  togglePartnershipPaymentModal: =>
    @setState partnershipPaymentModalOpen: not @state.partnershipPaymentModalOpen

  balanceNotAvailableForCurrency: (curr) =>
    {payment_balance, advanceable_balance, pending_incidental_balance} = (b for b in @props.balances when b.currency_code is curr)[0]

    # we don't owe them money
    return true if advanceable_balance <= 0

    # they owe _us_ money already - resolve that first
    return true if pending_incidental_balance > 0

    # default: we can give them an advance
    false

  advanceDisabled: (curr) =>
    'disabled' if @balanceNotAvailableForCurrency(curr)

  invoiceDisabled: (balance) =>
    return if balance.payment_balance < 0
    'disabled'

  currencyToggle: (curr = null) =>
    return if @balanceNotAvailableForCurrency(curr)
    @toggleAdvanceModal()
    @setState currencySelected: curr

  currencyPartnershipPaymentToggle: (curr = null) =>
    @togglePartnershipPaymentModal()
    @setState currencySelected: curr

  ##renders
  render: ->
    <div className="Balances">
      {@list()}
    </div>

  list: ->
    return <Spinner /> if @props.balancesLoading

    <List>
      {@header()}
      {for balance in @props.balances

        <List.Row key={balance.currency_code}>
            <List.Cell className="currency">
              <Flag className="small-flag" currency={balance.currency_code.toLowerCase()} />
              {balance.currency_code}
              <small> {balance.currency_name} </small>
            </List.Cell>
            <List.Cell className="balance #{if balance.advanceable_isNegative then 'negative' else ''}">
              <Price
                amount={balance.advanceable_balance}
                currency={balance.currency_code or ''}
                showFree={false}
              />
            </List.Cell>
            <List.Cell className="balance #{if balance.payment_isNegative then 'negative' else ''}">
              <Price
                amount={balance.payment_balance}
                currency={balance.currency_code or ''}
                showFree={false}
              />
            </List.Cell>
            <Can action='create' subject='ServiceInvoice'>
              <List.Cell className="create">
                <button
                  className="btn btn-xs btn-info create-invoice-btn #{@invoiceDisabled(balance)}"
                    onClick={@createServiceInvoice.bind @, balance}>
                    <FaPlus />
                    {" Generate Invoice - #{balance.currency_code}"}
                </button>
              </List.Cell>
            </Can>
            <Can action='create' subject='Advance'>
              <List.Cell className="create">
                <CreateAdvanceBtn className="btn btn-xs btn-info create-advance-btn #{@advanceDisabled(balance.currency_code)}" onClick={@currencyToggle} model={balance}>
                    <FaPlus />
                    {" " + balance.currency_code}
                </CreateAdvanceBtn>
              </List.Cell>
            </Can>
            <Can action='create_partnership_payment' subject='User'>
              <List.Cell className="create">
                <CreatePartnershipBtnPayment
                  className="btn btn-xs btn-info create-partnership-payment-btn"
                  onClick={@currencyPartnershipPaymentToggle}
                  currencyCode={balance.currency_code}
                >
                  <FaPlus />
                  {" " + balance.currency_code}
                </CreatePartnershipBtnPayment>
              </List.Cell>
            </Can>
        </List.Row>
      }
      <Modal show={@state.advanceModalOpen} onHide={@toggleAdvanceModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Create a new advance
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='clearfix'>
          <CreateAdvance
            action={@createAdvance.bind @}
            cancel={@toggleAdvanceModal}
            user={@props.user}
            currency={@state.currencySelected}
          />
        </Modal.Body>
      </Modal>

      <Modal show={@state.partnershipPaymentModalOpen} onHide={@togglePartnershipPaymentModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Create a new Partnership Payment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='clearfix'>
          <CreatePartnershipPayment
            action={@createPartnershipPayment.bind @}
            cancel={@togglePartnershipPaymentModal}
            user={@props.user}
            currency={@state.currencySelected}
          />
        </Modal.Body>
      </Modal>
    </List>

  header: ->
    <List.HeaderRow>
      <List.Header className="currency"> Currency </List.Header>
      <List.Header className="balance"> Advanceable Balance </List.Header>
      <List.Header className="balance"> Payment Balance </List.Header>
      <Can action='create' subject='ServiceInvoice'>
        <List.Header className="create"> Service Invoice </List.Header>
      </Can>
      <Can action='create' subject='Advance'>
        <List.Header className="create"> Create Advance </List.Header>
      </Can>
      <Can action='create_partnership_payment' subject='User'>
        <List.Header className="header create"> Create Partnership Payment </List.Header>
      </Can>
    </List.HeaderRow>
