import Notes  from '/components/notes'

import {
  withTicket
  withNotes
} from './queries'

export default \
withTicket \
withNotes \
Notes
