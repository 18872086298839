import React from 'react'
import flash from '/lib/flash'

import { Link } from 'react-router-dom'
import { base_currency_names } from '/lib/currencies'

# components
import Spinner      from '/components/spinner'
import List         from '/components/list'
import Datetime     from '/components/datetime'
import StateActions from '/components/state-label/actions'
import Avatar       from '/components/avatar'
import Price        from '/components/price'
import ListingPhoto from '/components/listing-photo'
import Flag         from '/components/flag'

import { withUser, withStripeConnectPermissions, withSyncPaymentMethods } from './queries'

export default \
withUser \
withStripeConnectPermissions \
withSyncPaymentMethods \
class PaymentProcessing extends React.Component

  render: ->
    <div className="PaymentProcessing">
      {@sync()}
      {@list()}
    </div>

  list: ->
    return <Spinner/> if @props.stripeConnectPermissionsLoading
    <List>
      {@header()}
      { for country in base_currency_names

        # Determining if we should display 'Stripe Connect' or 'Universe Payments'
        stripeConnected = false
        for stripe in @props.stripeConnectPermissions
          if stripe.currency == country.currencyCode.toLowerCase()
            stripeConnected = true
            break

        <List.Row key={country.currencyCode.toLowerCase()}>
          <List.Cell className="flag">
            <Flag currency={country.currencyCode.toLowerCase()} />
          </List.Cell>
          <List.Cell className="currencyName">
            { country.currencyName }
          </List.Cell>
          <List.Cell className="method">
            { if (stripeConnected)
                'Stripe Connect'
              else 'Universe Payments' }
              <small>
              { if (stripeConnected)
                  <a href="https://dashboard.stripe.com/applications/users/#{stripe.stripe_user_id}" target="_new">{ stripe.stripe_user_id }</a>
                else '' }
              </small>
          </List.Cell>
        </List.Row>
      }
    </List>

  header: ->
    <List.HeaderRow>
      <List.Header className="flag">  </List.Header>
      <List.Header className="currencyName"> Currency </List.Header>
      <List.Header className="method"> Default Preference </List.Header>
    </List.HeaderRow>

  sync: ->
    <div className='sync-container'>
      <button className='btn btn-default' onClick={@sync_payment_methods} disabled={@props.disabled}>Sync Payment Methods</button>
    </div>

  sync_payment_methods: =>
    @setState disabled: true
    try
      { user, errors } = await @props.syncPaymentMethods @props.user.id
      if errors?.length
        flash.error errors
      else
        flash.success "Payment methods sync was successful"
    finally
      @setState disabled: false
