import React from 'react'
import Select       from './select'

import { withRouter }   from 'react-router-dom'

export default withRouter class EntityFilter extends Select
  @defaultProps:
    placeholder:  'All Markets'
    label:        'Market'
    name:         'entity_id'

  value: ->
    @props.location.state?.filters?.entity_id or []
