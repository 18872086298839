import Model from '/lib/model'
import humanize from 'underscore.string/humanize'

export default \
class Subscription extends Model
  @className: 'Subscription'

  @computed 'category', ->
    for cat, ids of category_map
      return cat if @id in ids
    'other'

  @computed 'is_subscribed', ->
    !@unsubscribed_at


export category_map =
  global: [
    "global"
  ]

  confirmation_emails: [
    "tickets"
  ]

  booking_emails: [
    "event_reminders"
    "cancellation_and_refund"
    "payment_issues"
    "ticket_transfers"
    "buyer_waitlist"
  ]

  account_emails: [
    "account"
    "a_user_messaged_you"
  ]

  host_emails: [
    "booking_notifications"
    "emails_about_your_event"
  ]

  marketing_emails: [
    "weekly_curated_emails"
    "marketing"
  ]

export categories = Object.keys category_map
