import Model from '/lib/model'
import {base_currency_names} from '/lib/currencies'
import { formatMoney } from 'accounting'
import humanize from 'underscore.string/humanize'

export default \
class Balance extends Model
  @className: 'Balance'

  @computed 'currency_name', ->
    for pair in base_currency_names
      if @currency_code == pair.currencyCode
        return pair.currencyName

  @computed 'payment_isNegative', ->
    @payment_balance < 0

  @computed 'advanceable_isNegative', ->
    @advanceable_balance < 0

  @computed 'isNotEmpty', ->
    !(@payment_balance == 0 && @advanceable_balance == 0)
