import React from 'react'

import flash from '/lib/flash'

import { Link } from 'react-router-dom'

import isFuture   from 'date-fns/isFuture'
import isPast     from 'date-fns/isPast'
import debounce   from 'underscore-es/debounce'

# components
import ReactSelect  from '/components/react-select'
import Spinner      from '/components/spinner'
import Toggle       from '/components/toggle'

import { state_options } from '/models/event'

import { withTicketmasterVenues, withUpdateTicketmasterVenue } from '../queries'

export default \
withTicketmasterVenues \
withUpdateTicketmasterVenue \
class TicketmasterVenue extends React.Component
  constructor: ->
    super arguments...
    @state =
      showSearch: false
      venue: null
      saving: false

  showSearch: =>
    @setState showSearch: true

  search: debounce (term) ->
    @props.history.push @props.location.pathname, Object.assign {}, @props.location.state, venue_search: term
  , 500

  debounceSearch: (evt) =>
    @search evt.target?.value

  setTicketmasterVenue: (evt) =>
    {id, name} = @props.ticketmaster_venues.find (venue)-> venue.id is evt.value
    @setState venue: {id, name}

  updateTicketmasterVenue: (evt) =>
    @setState saving: true
    @props.history.push @props.location.pathname, Object.assign {}, @props.location.state, venue_search: ""
    @props.updateTicketmasterVenue @props.listing.id, @state.venue.id, @state.venue.name
    .then((data) =>
      flash.success "Venue Updated Successfully"
      @setState showSearch: false, saving: false
    ).catch (err)->
      flash.error "Venue update failed"

  clearTicketmasterVenue: (evt) =>
    @props.history.push @props.location.pathname, Object.assign {}, @props.location.state, venue_search: ""
    @props.updateTicketmasterVenue @props.listing.id, null, null
    .then (data)=>
      flash.success "Venue Updated Successfully"
      @setState showSearch: false
    .catch (err)->
      flash.error "Venue update failed"

  venue_options: =>
    (for venue in @props.ticketmaster_venues
      value: venue.id
      label:
        <div>
          <strong>{venue.name}</strong>
          <br/>
          <small>{venue.city}, {venue.country}</small>
        </div>
    )
  
  hide_from_ticketmaster: (hide=false)=>
    {listing, errors} = await @props.rpc @props.listing.id, "#{if hide then '' else 'un'}hide_from_ticketmaster"
    if errors?.length
      flash.error errors
    else
      flash.success "Listing updated successfully"

  render: ->
    <div className="TicketmasterVenue">
      <section>
        <h1>Ticketmaster Venue</h1>
        <h3>Current Venue: <b>{@props.listing.ticketmaster_venue_name || "None"}</b></h3>
        {if @state.showSearch && !@state.saving
          <div className="venue-selector">
            <h3>Choose a Venue</h3>
            <label>Search Venue Name
              <input
                className="form-control filter-input input-sm"
                type="search"
                placeholder={@props.location.state?.venue_search || "Begin typing a venue name..."}
                onChange={@debounceSearch}
              />
            </label>
            {if @props.ticketmasterVenuesLoading
              <Spinner/>
            else if @props.ticketmaster_venues.length > 0
              <div className="venue-results">
                <label>Search Results:
                  <ReactSelect
                    className="filter-selector state-selector"
                    options={@venue_options()}
                    value={@state.venue?.id}
                    onChange={@setTicketmasterVenue}
                  />
                </label>
                {if @state.venue
                  <div>
                    New Venue: {@state.venue.name} (@state.venue.id)
                  </div>
                  <div>
                    <button className="btn btn-primary btn-md" onClick={@updateTicketmasterVenue}>Save Venue</button>
                  </div>
                }
              </div>
            else
              <div>No Results Found</div>
            }
          </div>
        else if @state.saving
          <Spinner/>
        else
          <div>
            <div className="btn-container">
              <button className="btn btn-default" onClick={@showSearch}>
                Change Venue
              </button>
            </div>
            <div>
              <Toggle
                disabled={!@props.listing.ticketmaster_venue_name}
                value={!@props.listing.hidden_from_ticketmaster}
                onChange={@hide_from_ticketmaster.bind @, not @props.listing.hidden_from_ticketmaster}
              />
              {' '}
              Display listing on Ticketmaster (Host) via this venue page. Changes may take a few minutes to apply.
            </div>
          </div>
        }
      </section>
    </div>
