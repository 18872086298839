import { connect } from 'react-redux'

# provide state
mapStateToProps = (state)->
  {session, flash} = state

# provide actions
import { signIn, signInFromOkta, signOut, checkToken } from '/actions/session'
import { flashDismiss } from '/actions/flash'

mapDispatchToProps = ->
  login: signIn
  loginOkta: signInFromOkta
  logout: signOut
  autologin: checkToken
  flashDismiss: flashDismiss

export default connect mapStateToProps, mapDispatchToProps
