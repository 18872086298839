import React from 'react'
import './styles'

import format from 'date-fns/format'

import { Link, Route, Switch, Redirect } from 'react-router-dom'

import DocumentTitle from 'react-document-title'

# routes
import Summary            from './summary'
import Tickets            from './tickets'
import CostItems          from './cost-items'
import Changes            from './changes'
import SeatHolds          from './seat-holds'
import Notes              from './notes'
import ThirdPartyTickets  from './third-party-tickets'
import Emails             from './emails'


# components
import ListingAside from '/components/listing-aside'
import Spinner      from '/components/spinner'
import ExternalLink from '/components/external-link'
import Can          from '/components/can'
import {
  Tabs
  TabLink
  TabContent
} from '/components/tabs'

import {
  withEvent
  withActiveNotes
} from './queries'

import withAbility      from '/lib/with-ability'

export default \
withActiveNotes \
withEvent \
withAbility \
class Event extends React.Component

  render: ->
    return <Spinner/> if @props.eventLoading
    @event = @props.event
    <div className="Event">
      <DocumentTitle title={"#{@event.listing.title} - #{format @event.startTime, "MMM d, yyyy 'at' h:mm a"}"}/>
      <header>
        <h1>
          {@event.listing.title} - {format @event.startTime, "MMM d, yyyy 'at' h:mm a"} ({@event.tz_code})
          <ExternalLink url={@event.listing.url}/>
          <small>{@event.id}</small>
        </h1>
      </header>

      <main>

        <section className="content">
          <Tabs>
            <TabLink to={"#{@props.match.url}/summary"}>Summary</TabLink>
            <TabLink to={"#{@props.match.url}/tickets"}>
              Tickets <span className="badge">{@event.num_tickets}</span>
            </TabLink>
            <TabLink to={"#{@props.match.url}/cost_items"}>
              Cost Items <span className="badge">{@event.num_cost_items}</span>
            </TabLink>
            <TabLink to={"#{@props.match.url}/emails"}>Emails</TabLink>
            <TabLink to={"#{@props.match.url}/changes"}>Changes</TabLink>
            <Can action='manage_seat_holds' subject='Event'>
              <TabLink to={"#{@props.match.url}/seat_holds"}>Seat Holds</TabLink>
            </Can>
            <TabLink to={"#{@props.match.url}/notes"}>
              Notes <span className="badge"> {@props.notes.length} </span>
            </TabLink>
            <Can action='manage_tpts' subject='Event'>
              <TabLink to={"#{@props.match.url}/tpts"}>
                Third Party Tickets
              </TabLink>
            </Can>
            <TabContent>
              <Switch>
                <Route path='/events/:id/summary'     component={Summary}/>
                <Route path='/events/:id/tickets'     component={Tickets}/>
                <Route path='/events/:id/cost_items'  component={CostItems}/>
                <Route path='/events/:id/emails'      component={Emails}/>
                <Route path='/events/:id/changes'     component={Changes}/>
               {<Route path='/events/:id/seat_holds'  component={SeatHolds}/>           if @props.can 'manage_seat_holds', 'Event'}
                <Route path='/events/:id/notes'       component={Notes}/>
               {<Route path='/events/:id/tpts'        component={ThirdPartyTickets}/>   if @props.can 'manage_tpts', 'Event'}
                <Redirect from="#{@props.match.url}" to="#{@props.match.url}/summary" />
              </Switch>
            </TabContent>

          </Tabs>
        </section>

        <ListingAside listing={@event.listing}/>
      </main>

    </div>
