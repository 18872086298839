import React from 'react'
import './styles'

import humanize from 'underscore.string/humanize'

import Spinner      from '/components/spinner'
import UserCard     from '/components/user-card'
import Datetime     from '/components/datetime'
import Filters      from '/components/filters'
import Pager        from '/components/pager/history-pager'
import List         from '/components/list'

import { page_options, source_options } from '/models/printing-log'

import {
  withPrintingLogs
  withTicket
} from './queries'

export default \
withPrintingLogs \
withTicket \
class PrintingLogs extends React.Component
  render: ->
    <div className="PrintingLogs">
      {@filters()}
      {@list()}
    </div>

  filters: ->
    <Filters>
      <Filters.Search />
      <Filters.List
        name='page'
        label='Page'  
        options={page_options}
      />
      <Filters.List
        name='source'
        label='Source'  
        options={source_options}
      />
      <span className="pull-right">
        <Pager meta={@props.printing_logsPagination} buffer={2} />
      </span>
    </Filters>

  list: ->
    return <Spinner /> if @props.printing_logsLoading
    <List>
      {@header()}
      {for printing_log in @props.printing_logs
        <List.Row key={printing_log._key}>
          <List.Cell className="id">
            <samp title={printing_log.id}>
              {printing_log.id[-6...]}
            </samp>
          </List.Cell>
          <List.Cell className="by_who">
            <UserCard user={printing_log.user} />
          </List.Cell>
          <List.Cell className="source">
            <span className="label label-xs label-source #{printing_log.source}">
              {humanize printing_log.source}
            </span>
          </List.Cell>
          <List.Cell className="page">
            <span className="label label-xs label-page #{printing_log.page}">
              {humanize printing_log.page}
            </span>
          </List.Cell>
          <List.Cell className="date">
            <Datetime date={printing_log.created_at} />
          </List.Cell>
        </List.Row>
      }
    </List>

  header: ->
    <List.HeaderRow>
      <List.Header className="id"> ID </List.Header>
      <List.Header className="by_who"> By who </List.Header>
      <List.Header className="source"> Source </List.Header>
      <List.Header className="page"> Page </List.Header>
      <List.Header className="date"> Date & Time </List.Header>
    </List.HeaderRow>
