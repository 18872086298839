import Model from '/lib/model'
import humanize from 'underscore.string/humanize'
import titleize from 'underscore.string/titleize'

import flash from '/lib/flash'

export default \
class User extends Model
  @className: 'User'
  constructor: ->
    super arguments...
    @cover_photo_url  = null # "https://s3.amazonaws.com/uniiverse_production/cover_photos/56564c88c67a555248000d72/full/sa1.png?1448496267"
    @created_at       = null # "2015-05-23T05:38:18Z"
    @locked_at        = null
    @description      = null # "Salsation is the future!"
    @first_name       = null # "SALSATION FITNESS"
    @gender           = null # ""
    @has_avatar       = null # true
    @id               = null # "5560124ae991953a76002101"
    @image_url        = null # "https://s3.amazonaws.com/uniiverse_production/photos/556012786da9d7567b00091d/original/sa2.jpg?1432359557"
    @image_url_50     = null # "https://s3.amazonaws.com/uniiverse_production/photos/556012786da9d7567b00091d/thumb/sa2.jpg?1432359557"
    @image_url_160    = null # "https://s3.amazonaws.com/uniiverse_production/photos/556012786da9d7567b00091d/square/sa2.jpg?1432359557"
    @image_url_500    = null # "https://s3.amazonaws.com/uniiverse_production/photos/556012786da9d7567b00091d/medium/sa2.jpg?1432359557"
    @last_name        = null # ""
    @slug             = null # "salsation-fitness-YV5SN"
    @updated_at       = null # "2016-01-14T01:37:13Z"
    @location         = null # {}
    # @city             = null # "Toronto"
    # @country          = null # "CA"
    @needs_completion = null
    @email            = null
    @num_listings     = null
    @num_tickets      = null
    @allow_on_mfol    = null # false

    @twitter          = null # {}
    @facebook         = null # {}
    @google           = null # {}
    @gdpr_state       = null # false
    @commission_code_currencies = []
    @listing_currencies = []

  @hasMany 'listings',      model: 'Listing'
  @hasMany 'tickets',       model: 'Ticket'
  @hasMany 'cost_items',    model: 'CostItem'
  @hasMany 'withdrawals',   model: 'Withdrawal'
  @hasMany 'chargebacks',   model: 'Chargeback'
  @hasMany 'advances',      model: 'Advance'
  @hasMany 'balances',      model: 'Balance'
  @hasMany 'subscriptions', model: 'Subscription'
  @hasMany 'stewards',      model: 'User'

  @belongsTo 'organization', model: 'Organization'

  @computed 'name', ->
    [ @first_name, @last_name ].join ' '

  @computed 'slug_id', ->
    @slug?.split?('-')?.pop?() or @id

  @computed 'url', ->
    "#{process.env.MULTIVERSE}/users/#{@slug}"

  @computed 'su_url', ->
    "#{process.env.WEB}/admin/v1/users/#{@slug}/su"

  @computed 'is_confirmed', ->
    !!@confirmed_at

  @computed 'is_locked', ->
    !!@locked_at

  @computed 'state', ->
    return 'deleted'      if @deleted_at
    return 'locked'       if @locked_at
    return 'unconfirmed'  unless @confirmed_at
    'active'

  @computed 'transitions', ->
    ret = {}
    for tr in state_actions[@state] or []
      ret[tr] = transition_name_overrides?[tr] or humanize tr
    ret

$w = (str)-> (t for t in str.split(' ') when t isnt '')
export state_actions =
  active:       $w 'send_reset_password_instructions deactivate'
  locked:       $w 'unlock send_unlock_instructions deactivate'
  unconfirmed:  $w 'confirm send_confirmation_instructions send_reset_password_instructions deactivate'
  deleted:      $w 'reactivate'

export transition_name_overrides =
  deactivate: "Deactivate account"
  reactivate: "Reactivate account"
  unlock: "Unlock account"
  confirm: "Confirm account"

export states = [
  'active'
  'locked'
  'unconfirmed'
  'deleted'
]
export state_options = value: state, label: humanize state for state in states

export roles = [
  'User'
  'Developer'
  'Admin'
  'Sales'
  'SalesAdmin'
  'Finance'
  'FinanceAdmin'
  'SuperAdmin'
  'Agent'
  'Spammer'
]
export role_options = value: role, label: titleize humanize role for role in roles

export icps = [
  'timed_entry'
  'independent_festivals'
  'one_offs'
  'clubs'
  'clubs_promoter'
  'clubs_venue'
  'sports'
  'other'
  'test'
]
export icp_options = value: icp, label: humanize icp for icp in icps
