import Model        from '/lib/model'
import humanize     from 'underscore.string/humanize'
import underscored  from 'underscore.string/underscored'

export default \
class Change extends Model
  @className: 'Change'

  @belongsTo 'changeset', model: 'Changeset'

  ###
  id
  created_at
  target_type
  target_id
  attr_names
  diff
  ###

  @computed 'summary', ->
    if @attr_names.length < 5
      "Changed: #{@attr_names.join ', '}"
    else
      "#{@attr_names.length} fields changed"

  @computed 'admin_target_link', ->
    "/#{underscored @target_type}s/#{@target_id}"

