import { graphql } from 'react-apollo'
import Models      from '/models/index'

#
# Queries
#
import index from './queries/index.gql'

export withCostItems = graphql index,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.cost_items or {}
    cost_items: Models.CostItem.parse nodes or []
    cost_itemsPagination: pagination
    cost_itemsLoading: data.loading

#
# Mutations
#
import cost_items_rpc from '/mutations/cost_items/rpc.gql'

export withCostItemsRPC = graphql cost_items_rpc,
  props: ({ mutate })->
    cost_items_rpc: (id, method)->
      {data: {cost_item_rpc: {cost_item, errors}}} = \
      await mutate variables: {id, method}
      {cost_item, errors}
