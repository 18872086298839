import { graphql } from 'react-apollo'
import Models           from '/models/index'

#
# Queries
#

import index        from './queries/index.gql'

export withNotes = graphql index,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.notes or {}
    notes: Models.Note.parse nodes or []
    notesPagination: pagination
    notesLoading: data.loading


#
# Mutations
#

import notes_rpc                                    from '/mutations/notes/rpc.gql'

export withNotesRPC = graphql notes_rpc,
  props: ({ mutate })->
    notes_rpc: (id, method)->
      {data: {note_rpc: {note, errors}}} = \
      await mutate variables: {id, method}
      {note, errors}
