import { graphql } from 'react-apollo'
import Attraction       from '/models/attraction'

#
# Queries
#

import index        from './queries/index.gql'
import ticketmasterAttractions from './queries/ticketmaster_attractions.gql'

export withAttractions = graphql index,
  options: (props)->
    { page, search } = props.location.state or {}
    variables:
      page: page or 1
      search: search or undefined
  props: ({props, data})->
    { nodes, pagination } = data.attractions or {}
    attractions: Attraction.parse nodes or []
    attractionsPagination: pagination
    attractionsLoading: data.loading

export withTicketmasterAttractions = graphql ticketmasterAttractions,
  options: (props)->
    attraction_search = props.search or ""
    variables:
      search: attraction_search
  props: ({props, data})->
    { nodes, pagination } = data.ticketmaster_attractions or {}
    ticketmaster_attractions: Models.Attraction.parse nodes or []
    ticketmasterAttractionsLoading: data.loading

#
# Mutations
#

import createAttraction from '/mutations/attractions/create.gql'
import updateAttraction from '/mutations/attractions/update.gql'

export withCreateAttraction = graphql createAttraction,
  props: ({ mutate })->
    createAttraction: (obj)->
      {data: {attraction_create: {attraction, errors}}} = \
      await mutate variables: obj
      {attraction, errors}

export withUpdateAttraction = graphql updateAttraction,
  props: ({ mutate })->
    updateAttraction: (obj)->
      {data: {attraction_update: {attraction, errors}}} = \
      await mutate variables: obj
      {attraction, errors}
