import store from '/store'
export flashDismiss = (id)->
  store.dispatch Object.assign {}, {id}, type: 'FLASH_DISMISS'

export flashClear = ->
  store.dispatch type: 'FLASH_CLEAR'

export flashAdd = (level, content, ttl=5000)->
  # add message
  store.dispatch Object.assign {}, {level, content, ttl}, type: 'FLASH_ADD'
  # set up auto-dismiss in TTL
  [..., msg] = store.getState().flash
  setTimeout ->
    flashDismiss msg.id
  , ttl
