import Notes  from '/components/notes'

import {
  withListing
  withNotes
} from './queries'

export default \
withListing \
withNotes \
Notes
