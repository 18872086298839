import { graphql } from 'react-apollo'
import Models           from '/models/index'

#
# queries
#

import index   from './queries/index.gql'
import order_level_fee_create from '/mutations/order_level_fees/create.gql'

export withOrderLevelCodes = graphql index,
  options: (props)->
    { page, per_page, search } = props.location.state or {}
    variables:
      page: page or 1
      per_page: per_page or 10
      search: search or undefined
  props: ({props, data})->
    { order_level_fees, loading, refetch } = data or {}
    { nodes, pagination } = order_level_fees or {}
    codes:            nodes
    codesLoading:     loading
    codesPagination:  pagination
    codesRefetch:     refetch

export withOrderLevelCodeCreate = graphql order_level_fee_create,
  props: ({ mutate })->
    orderFeeCodeCreate: (fee)->
      payload =
        code: fee.code
        rules_attributes: fee.rules.map (rule) =>
          amount: rule.amount
          calculation: rule.calculation
          type: rule.type
          conditions_attributes: rule.conditions.map (condition) =>
            comparison: condition.comparison
            property: condition.property
            value: condition.value

      { data: { order_level_fee_create: { fee, errors } } } = \
      await mutate variables: order_level_fee: payload
      {fee, errors}
