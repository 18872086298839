import React from 'react'
import PropTypes from 'prop-types'

import DropdownMenu from '/components/dropdown-menu'
import humanize from 'underscore.string/humanize'
import FaPlusCircle from 'react-icons/lib/fa/plus-circle'

export default \
class BasicRule extends React.Component

  @propTypes:
    rule:           PropTypes.object
    conditions:     PropTypes.array
    addCondition:   PropTypes.func
    onAmountChange: PropTypes.func
    error:          PropTypes.string

  render: ->
    { error } = @props
    properties = @props.rule.availableProperties()
    <div>
      <h5 className="section-heading">Basic Rule</h5>
      <div className={"form-group d-flex #{error && 'has-error'}"}>
        <label className="control-label commission-label required flex-fill">Per order fee amount</label>
        <div className="fee-amount">
          <input
            type="number"
            min="0"
            step="0.01"
            className="form-control"
            placeholder="Enter value"
            value={@props.rule.amount or ""}
            onChange={(event) => @props.onAmountChange(event.target.value)}
            required
          />
          {error}
        </div>
      </div>
      <h5 className="section-heading">Properties</h5>
      <div className="d-flex flex-column">
        {@props.conditions}
      </div>
      <div className="add-property">
        {<div className={"properties #{!Object.keys(properties).length ? "hidden" : ""}"}>
          <DropdownMenu
            toggle={
              <div>
                <a>
                  <p className="property-text">Add Property <FaPlusCircle /></p>
                </a>
              </div>
            }
            actions={
              properties.reduce(
                (object, property) => ({ ...object, [humanize(property)]: () => @props.addCondition(property) }),
                {}
              )
            }
          />
        </div>}
      </div>
    </div>
