import React from 'react'

import { withAuth } from '@okta/okta-react'

import Spinner      from '/components/spinner'

export default \
withAuth \
class ImplicitCallback extends React.Component
  componentDidMount: ->
    # only needed for callback flow?
    try
      await @props.auth.handleAuthentication()

    # if we can't get a token, try to restart the login process
    unless token = await @props.auth.getIdToken()
      if localStorage.loginAttempts
        @props.logout()
      else
        localStorage.loginAttempts = 1
        # try to log in again
        @props.auth.login '/'
      return

    localStorage.removeItem 'loginAttempts'
    await @props.login token
    dest = localStorage.okta_redirect
    localStorage.removeItem 'okta_redirect'
    window.location.href = dest or '/'

  render: ->
    <Spinner/>
