import React from 'react'
import PropTypes from 'prop-types'
import Balance from '/models/balance'#import own model

export default \
class CreateAdvanceBtn extends React.Component
  @propTypes:
    model:    PropTypes.instanceOf(Balance).isRequired
    onClick:  PropTypes.func.isRequired

  onClick: =>
    @props.onClick(@props.model.currency_code)

  render: ->
    { children, className=''} = @props
    <button className="#{className} advance-btn" onClick={@onClick}>
      {children}
    </button>
