import Model from '/lib/model'

export default class Entity extends Model
  @className: 'Entity'
  constructor: ->
    super arguments...
    @company_name = null
    @country_code = null
    @percent      = null
    @name         = null
