import {
  SIGN_IN_SUCCESS,
  SIGN_IN_ERRORED,
  GET_USER_ERRORED,
  CHECK_TOKEN_ERRORED,
  SIGN_OUT,
} from '/actions/session';

const initialState = null;

const resetTokenList = [
  SIGN_IN_ERRORED,
  GET_USER_ERRORED,
  CHECK_TOKEN_ERRORED,
  SIGN_OUT,
];

export default (state = initialState, action) => {
  if (action.type === SIGN_IN_SUCCESS) {
    localStorage.access_token = action.payload;
    return action.payload;
  } else if (resetTokenList.includes(action.type)) {
    localStorage.removeItem('access_token');
    return initialState;
  }

  return state;
};
