import Model from '/lib/model'
import currencies, { base_currencies } from '/lib/currencies'

export default \
class Advance extends Model
  @className: 'Advance'
  #   @amount         = null # 25.00
  #   @description    = null # "CAP495"
  #   @currency       = null # "USD"
  #   @user_id        = null # "USD"

  @belongsTo 'user',    model: 'User'

  # do we have enough info to create this code?
  @computed 'isValidForCreate', ->
    !! @amount and !! @description and !! @currency and !! @user_id

# export base_currencies
export base_currency_options = value: cur, label: cur for cur in base_currencies
