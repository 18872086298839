import React from 'react'
import './styles'

import { Link } from 'react-router-dom'

# componennts
import Pager      from '/components/pager/history-pager'
import Spinner    from '/components/spinner'
import Filters    from '/components/filters'
import List       from '/components/list'
import Datetime   from '/components/datetime'
import StateLabel from '/components/state-label'
import Price      from '/components/price'
import UserCard   from '/components/user-card'

import {
  base_currency_options
  state_options
} from '/models/chargeback'

import { withChargebacks }  from './queries'

export default \
withChargebacks \
class Chargebacks extends React.Component

  render: ->
    <div className="Chargebacks">
      <header>
        <h1>Chargebacks</h1>
      </header>
        <section className="content">
        {@filters()}
        {@list()}
        {@pager()}
      </section>
    </div>

  filters: ->
    <Filters>
      <Filters.Search/>
      <Filters.State options={state_options}/>
      <span className="pull-right">
        <Pager meta={@props.chargebacksPagination} buffer={2}/>
      </span>
      <Filters.Currency options={base_currency_options}/>
    </Filters>

  list: ->
    return <Spinner/> if @props.chargebacksLoading
    <List>
      {@header()}
      {for c in @props.chargebacks
        <List.Row key={c._key}>

          <List.Cell className="user">
            <UserCard user={c.user} />
          </List.Cell>

          <List.Cell className="stripe-id">
            {c.stripe_charge_id}
          </List.Cell>

          <List.Cell className="amount">
            <Price amount={c.amount} currency={c.display_currency}/>
          </List.Cell>

          <List.Cell className="fee">
            <Price amount={c.fee} currency={c.display_currency}/>
          </List.Cell>

          <List.Cell className="date-of-dispute">
            <Datetime date={c.created_at}/>
          </List.Cell>

          <List.Cell className="status">
            <StateLabel model={c}/>
          </List.Cell>

        </List.Row>

      }
    </List>

  header: ->
    <List.HeaderRow>
      <List.Header className="user">Host</List.Header>
      <List.Header className="stripe-id">Stripe Charge Id</List.Header>
      <List.Header className="amount">Amount</List.Header>
      <List.Header className="fee">Fee</List.Header>
      <List.Header className="date-of-dispute">Created At</List.Header>
      <List.Header className="status">Status</List.Header>
    </List.HeaderRow>

  pager: ->
    <div className="text-center">
      <Pager meta={@props.chargebacksPagination} buffer={4}/>
    </div>
