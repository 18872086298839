import { graphql } from 'react-apollo'
import Models           from '/models/index'

#
# queries
#

import index   from './queries/index.gql'

export withCountries = graphql index,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { countries, loading, refetch } = data or {}
    { nodes, pagination } = countries or {}
    countries:            Models.Country.parse nodes or []
    countriesPagination:  pagination
    countriesLoading:     loading
    countriesRefetch:     refetch

#
# mutations
#

import rpc from '/mutations/countries/rpc.gql'

export withRPC = graphql rpc,
  props: ({ mutate })->
    rpc: (id, method)->
      {data: {country_rpc: {country, errors}}} = \
      await mutate variables: {id, method}
      {country, errors}
