import React from 'react'
import PropTypes from 'prop-types'
import './styles'

export default \
class Avatar extends React.Component
  @propTypes:
    user:       PropTypes.object.isRequired
    size:       PropTypes.number
    image_url:  PropTypes.string

  @defaultProps:
    size: 50

  styles: ->
    width: @props.size
    height: @props.size

  initials: =>
    [ @props.user.first_name?.charAt(0) or '', @props.user.last_name?.charAt(0) or '' ].join ''

  avatarUrl: ->
    # get the best size of image to show
    # closest without going over (The Bob Barker Rule™)
    src_size = (i for i in [50,160,500] when i >= @props.size)?[0]
    attr_name = if src_size then "image_url_#{src_size}" else "image_url"
    @props.user[attr_name] || @placeholder()

  render: ->
    <img
      className="Avatar"
      src={@props.image_url or @avatarUrl()}
      alt={@props.user.name}
      style={@styles()}
    />

  placeholder: =>
    "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='#{@props.size}' height='#{@props.size}'><circle cx='50%' cy='50%' r='50%' fill='%23ccc'></circle><text x='50%' y='50%' dominant-baseline='central' text-anchor='middle' fill='%23666' style='font-family: sans-serif;'>#{@initials()}</text></svg>"
