import React from 'react'
import './styles'

import { Link } from 'react-router-dom'

import Pager          from '/components/pager/history-pager'
import Spinner        from '/components/spinner'
import StateLabel     from '/components/state-label'
import Datetime       from '/components/datetime'
import List           from '/components/list'
import Filters        from '/components/filters'

import { state_options } from '/models/transfer'

import { withTransfers, withTransfersRPC } from './queries'

export default \
withTransfers \
withTransfersRPC \
class Transfers extends React.Component

  render: ->
    <div className="Transfers">
      <header>
        <h1>Transfers</h1>
      </header>
      <section className="content">
        {@filters()}
        {@list()}
        <div className="text-center">
          <Pager meta={@props.transfersPagination} buffer={4}/>
        </div>
      </section>
    </div>

  filters: ->
    <Filters>
      <Filters.Search/>
      <Filters.State options={state_options}/>
      <span className="pull-right">
        <Pager meta={@props.transfersPagination} buffer={2}/>
      </span>
    </Filters>

  list: ->
    return <Spinner/> if @props.transfersLoading
    <List>
      {@header()}
      {for transfer in @props.transfers
        original_cost_item_id = transfer.original_cost_item.id
        new_cost_item_id = transfer.new_cost_item_id || null
        <List.Row key={transfer._key}>
          <List.Cell className="id">
            <samp className="btn btn-default">More info</samp>
          </List.Cell>
          <List.Cell className="transfer-to">
            {transfer.first_name} {transfer.last_name}
            <br/>
            {transfer.email}
          </List.Cell>
          <List.Cell className="event-name">{transfer.original_cost_item.listing.title}</List.Cell>
          <List.Cell className="transferred-date">
            <Datetime date={transfer.created_at} format='MMM dd, yyyy'/>
          </List.Cell>
          <List.Cell className="accepted-date">
            {if transfer.state is 'accepted'
              <Datetime date={transfer.updated_at} format='MMM dd, yyyy'/>
            }
          </List.Cell>
          <List.Cell className="accepted-by">
            {transfer.accepted_by_name}
            <br/>
            {transfer.accepted_by_email}
          </List.Cell>
          <List.Cell className="original_cost_item">
            <a className="btn btn-default" href={"#{process.env.MULTIVERSE}/cost_items/#{original_cost_item_id}"} target="_blank">
              <samp>...{original_cost_item_id.slice -4}</samp>
            </a>
          </List.Cell>
          <List.Cell className="new-cost-item">
            {if new_cost_item_id
              <a className="btn btn-default" href={"#{process.env.MULTIVERSE}/cost_items/#{new_cost_item_id}"} target="_blank">
                <samp>...{new_cost_item_id.slice -4}</samp>
              </a>
            }
          </List.Cell>
          <List.Cell className="buyer">
            {transfer.original_cost_item.buyer.name}
            <br/>
            {transfer.original_cost_item.buyer.email}
            </List.Cell>
          <List.Cell className="state">
            <StateLabel model={transfer}/>
          </List.Cell>
        </List.Row>
      }
    </List>

  header: ->
    <List.HeaderRow>
      <List.Header className="id">id</List.Header>
      <List.Header className="transfer-to">Transfer To</List.Header>
      <List.Header className="event-name">Event Name</List.Header>
      <List.Header className="transferred-date">Transferred</List.Header>
      <List.Header className="accepted-date">Accepted</List.Header>
      <List.Header className="accepted-by">Accepted By</List.Header>
      <List.Header className="original_cost_item">Original Cost Item</List.Header>
      <List.Header className="new-cost-item">New Cost Item</List.Header>
      <List.Header className="buyer">Buyer</List.Header>
      <List.Header className="state">Status</List.Header>
    </List.HeaderRow>
