import React from 'react'
import Select       from './select'

import { withRouter }   from 'react-router-dom'

export default withRouter class TypeFilter extends Select
  @defaultProps:
    placeholder:  'All Types'
    label:        'Type'
    name:         'type'

  value: ->
    @props.location.state?.filters?.type or []
