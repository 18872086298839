import Model from '/lib/model'

export default \
class Attraction extends Model
  @className: 'Attraction'
  constructor: ->
    super arguments...
    @id               = null
    @legacy_id        = null
    @name             = null
    @data             = {}
    @keys             = ['homepage', 'facebook', 'itunes', 'spotify', 'youtube']

  surfaceData: ->
    @ticketmasterImage = @ticketmaster_image_url

    for key in @keys
      id = "#{key}_id"
      url = "#{key}_url"

      if @data[url]?
        @[url] = @data[url]
      else if @data[id]?
        @[id] = @data[id]

  buryData: ->
    delete @ticketmasterImage

    for key in @keys
      id = "#{key}_id"
      url = "#{key}_url"

      if @[id] or @data[id]
        @data[id] = @[id]
        delete @[id]

      if @[url] or @data[url]
        @data[url] = @[url]
        delete @[url]

    if @legacy_id or @data.legacyId
      @data.legacyId = @legacy_id
