import Model from '/lib/model'
import humanize   from 'underscore.string/humanize'
$w = (str)-> (t for t in str.split(' ') when t isnt '')

export default \
class AccessKey extends Model
  @className: 'AccessKey'

  @belongsTo 'user',      model: 'User'
  @belongsTo 'listing',   model: 'Listing'

export states = $w 'active inactive used'
export state_options = value: state, label: humanize state for state in states
