import React from 'react'

import List from '/components/list'

export default \
class SeatsChecklist extends React.Component
  render: ->
    <section>
      <List className="seats-checklist">
        <List.Row>
          <List.Cell>
            User has a Seats.io subaccount?
          </List.Cell>
          <List.Cell>
            {if @props.subaccountValid then "✅" else "❌"}
          </List.Cell>
        </List.Row>
        <List.Row>
          <List.Cell>
            Listing has been linked to a Seats.io chart (map)?
          </List.Cell>
          <List.Cell>
            {if @props.chartKeyLinked then "✅" else "❌"}
          </List.Cell>
        </List.Row>
      </List>

      <hr />
    </section>
