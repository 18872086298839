import Model from '/lib/model'
import currencies, { base_currencies } from '/lib/currencies'
import { country_codes } from '/lib/countries'
import { formatMoney } from 'accounting'
import humanize         from 'underscore.string/humanize'

export default \
class CommissionCode extends Model
  @className: 'CommissionCode'
  constructor: ->
    super arguments...
    @broker_fee               = null # 0.03
    @code                     = null # "CAP495"
    @country                  = null # null
    @currency                 = null # "USD"
    @flat                     = null # 0.99
    @id                       = null # 1
    @max                      = null # 4.95
    @percent                  = null # 0.02
    @rebate_max               = null # 0.1
    @rebate_flat              = null # 1
    @rebate_percent           = null # 0.1
    @rebate_cost_item_percent = null # 0.1
    @rebate_user_id           = null # "aowidjou1h2"
    @state                    = null

  @belongsTo 'rebate_user', model: 'User'

  @computed 'actual_percent',
    get: ->
      @visualPercent(@percent)
    set: (val)->
      @percent = @setPercent(val)

  @computed 'actual_broker_fee',
    get: ->
      @visualPercent(@broker_fee)
    set: (val)->
      @broker_fee = @setPercent(val)

  @computed 'actual_rebate_percent',
    get: ->
      @visualPercent(@rebate_percent)
    set: (val)->
      @rebate_percent =  @setPercent(val)

  @computed 'actual_rebate_cost_item_percent',
    get: ->
      @visualPercent(@rebate_cost_item_percent)
    set: (val)->
      @rebate_cost_item_percent = @setPercent(val)

  visualPercent: (val)->
    "#{(val * 100).toFixed(2).replace(/0+$/,'').replace(/\.$/,'')} %" if val

  setPercent: (val)->
    parseFloat(val.replace(/[^\d.]/,'') || 0) / 100

  # do we have enough info to create this code?
  @computed 'isValidForCreate', ->
    !! @code and @currency

  @computed 'key', ->
    [@currency, @id].join '-'

  @computed 'transitions', ->
    ret = {}
    for tr in state_actions[@state]
      ret[tr] = transition_name_overrides?[tr] or humanize tr
    ret

export states = [
  'pending'
  'approved'
  'denied'
]

export state_options = value: state, label: humanize state for state in states

$w = (str)-> (t for t in str.split(' ') when t isnt '')
export state_actions =
  pending:   $w 'approve deny'
  approved:  $w 'deny'
  denied:    $w 'approve'

# export base_currencies
export base_currency_options = value: cur, label: cur for cur in base_currencies
export currency_options = value: cur, label: (if cur == 'ALL' then 'ALL (Albanian Lek)' else cur) for cur, v of currencies
export country_code_options = value: cur, label: cur for cur in country_codes
