import {
  SIGN_IN_STARTED,
  SIGN_IN_ERRORED,
  SIGN_IN_SUCCESS,
  GET_USER_STARTED,
  GET_USER_SUCCESS,
  GET_USER_ERRORED,
  CHECK_TOKEN_STARTED,
  CHECK_TOKEN_SUCCESS,
  CHECK_TOKEN_ERRORED
} from '/actions/session';

const initialState = false;

const requestStarters = [
  SIGN_IN_STARTED, GET_USER_STARTED, CHECK_TOKEN_STARTED
];
const requestFinishers = [
  SIGN_IN_SUCCESS, SIGN_IN_ERRORED,
  GET_USER_SUCCESS, GET_USER_ERRORED,
  CHECK_TOKEN_SUCCESS, CHECK_TOKEN_ERRORED
];

export default (state = initialState, action) => {
  if (requestStarters.includes(action.type)) {
    return true;
  } else if (requestFinishers.includes(action.type)) {
    return initialState;
  }

  return state;
};
