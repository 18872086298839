import React      from 'react'
import PropTypes  from 'prop-types'

import humanize from 'underscore.string/humanize'

export default \
class BaseFilter extends React.Component
  @propTypes:
    name:         PropTypes.string.isRequired
    placeholder:  PropTypes.string
    label:        PropTypes.string
    # value:        PropTypes.array.isRequired

  @defaultProps:
    placeholder:  'Please select'
    label:        'Filter'

  onChange: (obj={})=>
    @props.history.push @props.location.pathname, Object.assign {}, @props.location.state, obj

  label: ->
    @props.label or humanize @props.name

  render: ->
    'Extend me'
