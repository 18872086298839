import React from 'react'
import Base         from './base'

import { withRouter }   from 'react-router-dom'

export default withRouter class Amount extends Base

  @defaultProps:
    name:         'amount'
    type:         'amount'
    label:        'Amount'

  onKeyUp: (evt)=>
    evt.preventDefault()
    if (evt.key is 'Enter')
      filters = Object.assign {}, @props.location.state?.filters or {}
      filters["amount"] =
        gte: @minInput?.value or null
        lte: @maxInput?.value or null
      @onChange Object.assign {}, @props.location.state, {filters}, page: 1

  render: ->
    <div className="Filter form-group amount">

      <label> Amount: </label>

      <input
        key={"min"}
        ref={(i)=> @minInput = i}
        type="number"
        defaultValue={@props.location.state?.filters?.amount?.gte or ''}
        className="form-control min"
        onKeyUp={@onKeyUp}
        placeholder="Min"
      />

      <span className="to"> — </span>

      <input
        key={"max"}
        ref={(i)=> @maxInput = i}
        type="number"
        defaultValue={@props.location.state?.filters?.amount?.lte or ''}
        className="form-control max"
        onKeyUp={@onKeyUp}
        placeholder="Max"
      />

  </div>
