import { graphql } from 'react-apollo'
import Models           from '/models/index'

#
# Queries
#

import index                    from './queries/index.gql'

export withServiceInvoices = graphql index,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.service_invoices or {}
    serviceInvoices: Models.ServiceInvoice.parse nodes or []
    serviceInvoicesPagination: pagination
    serviceInvoicesLoading: data.loading

#
# Mutations
#

import service_invoices_rpc     from '/mutations/service_invoices/rpc.gql'

export withServiceInvoicesRPC = graphql service_invoices_rpc,
  props: ({ mutate })->
    service_invoices_rpc: (id, method)->
      mutate variables: {id, method}
