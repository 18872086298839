import React from 'react'
import PropTypes from 'prop-types'
import './styles'

import Models from '/models/index'

import { Link }     from 'react-router-dom'
import Avatar       from '/components/avatar'

export default \
class UserCard extends React.Component
  @propTypes:
    user:   PropTypes.instanceOf(Models.User)

  render: ->
    return null unless u = @props.user
    <div className="UserCard">
      <Link to={"/users/#{u.slug}"}>
        <Avatar user={u} image_url={u.image_url} size={40}/>
        <div className="user-details">
          <span className="name">{u.name}</span>
          {unless u.role is 'User'
            <span className="label label-xs label-role #{u.role}">{u.role}</span>
          }
          <br/>
          <small className="email">{u.email}</small>
          {@props.children}
        </div>
      </Link>
    </div>
