import React from 'react'

import {
  # flashDismiss
  flashClear
  flashAdd
} from '/actions/flash'

typeIsArray = Array.isArray || ( value ) -> return {}.toString.call( value ) is '[object Array]'

flash = (level, content, ttl)->
  flashAdd level, content, ttl

body = (msg, title)->
  <div>
    {<strong>{title}</strong> if title?}
    {<div key={text}>{text}</div> for text in msg if typeIsArray msg}
    {<div>{msg}</div> unless typeIsArray msg}
  </div>

export success   = (msg, title)-> flash 'success', body(msg, title), 5000
export info      = (msg, title)-> flash 'info',    body(msg, title), 5000
export warning   = (msg, title)-> flash 'warning', body(msg, title), 10000
export danger    = (msg, title)-> flash 'danger',  body(msg, title), 30000
export error     = (msg, title)-> flash 'error',   body(msg, title), 30000
export clear     = flashClear

export default {
  success
  info
  warning
  danger
  error
  clear
}
