import Model from '/lib/model'

import md5 from 'blueimp-md5'

export default \
class CurrentUser extends Model
  @className: 'CurrentUser'
  @computed 'name', ->
    [ @first_name, @last_name ].join ' '

  @computed 'avatar_url', ->
    "//gravatar.com/avatar/#{md5 @email}?d=mm" # d: blank, identicon
