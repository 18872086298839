import React from 'react'
import './styles'

import { Link } from 'react-router-dom'

# componennts
import Pager          from '/components/pager/history-pager'
import Spinner        from '/components/spinner'
import WithdrawalRow  from '/components/withdrawal-row'
import Filters        from '/components/filters'
import List           from '/components/list'

# for filtering (react-select)
import { state_options } from '/models/withdrawal'
import {
  withWithdrawals
  withEntities
} from './queries'

export default \
withWithdrawals \
withEntities \
class Withdrawals extends React.Component
  render: ->
    <div className="Withdrawals">
      <header>
        <h1>Withdrawals</h1>
      </header>
      <section className="content">
        {@filters()}
        {@list()}
        {@pager()}
      </section>
    </div>

  filters: ->
    return null if @props.entitiesLoading
    entity_options = (value: e.id, label: e.company_name for e in @props.entities)

    <Filters>
      <Filters.Search/>
      <Filters.State options={state_options}/>
      <Filters.Amount/>
      <Filters.Entity options={entity_options}/>
      <span className="pull-right">
        <Pager meta={@props.withdrawalsPagination} buffer={2}/>
      </span>
    </Filters>

  list: ->
    return <Spinner/> if @props.withdrawalsLoading
    <List>
      <WithdrawalRow.Header/>
      {for withdrawal in @props.withdrawals
        <WithdrawalRow
          key={withdrawal._key}
          withdrawal={withdrawal}
        />
      }
    </List>

  pager:->
    <div className="text-center">
      <Pager meta={@props.withdrawalsPagination} buffer={4}/>
    </div>
