import React from 'react'

import { Link } from 'react-router-dom'

import { state_options } from '/models/discount-code'

# components
import Spinner      from '/components/spinner'
import Pager        from '/components/pager/history-pager'
import StateLabel   from '/components/state-label'
import List         from '/components/list'
import Filters      from '/components/filters'
import Empty        from '/components/empty'

import {
  withListing
  withDiscountCodes
} from './queries'

export default \
withListing \
withDiscountCodes \
class Events extends React.Component

  render: ->
    return <Spinner/> if @props.listingLoading
    <div className="DiscountCodes">
      {@filters()}
      {@list()}
      {@pager()}
    </div>

  filters: ->
    <Filters>
      <Filters.Attribute name="code"/>
      <Filters.State options={state_options}/>
      <span className="pull-right">
        <Pager meta={@props.discount_codesPagination} buffer={2}/>
      </span>
    </Filters>

  list: ->
    return <Spinner/> if @props.discount_codesLoading
    return <Empty name="Discount Codes">
      No discount codes match your criteria
    </Empty> unless @props.discount_codes?.length

    <List>
      {@header()}
      {for dc in @props.discount_codes
        <List.Row key={dc._key}>
          <List.Cell className="code">
            {dc.code}
          </List.Cell>
          <List.Cell className="discount">
            {dc.discount}
          </List.Cell>
          <List.Cell className="uses">
            {dc.num_redemptions or 0} / {dc.quantity or 'unlimited'}
          </List.Cell>
          <List.Cell className="applies_to">
            {
              title = (rate.name for rate in dc.rates or []).join "\n"
              switch dc.rates?.length or 0
                when 0
                  <span>all rates</span>
                when 1
                  <span title={title}>1 rate</span>
                else
                  <span title={title}>{dc.rates.length} rates</span>
            }
          </List.Cell>
          <List.Cell className="actions">
            <StateLabel model={dc}/>
          </List.Cell>
        </List.Row>
      }
    </List>

  header:->
    <List.HeaderRow>
      <List.Header className="code"> Code </List.Header>
      <List.Header className="discount"> Discount </List.Header>
      <List.Header className="uses"> Uses </List.Header>
      <List.Header className="applies_to"> Applies to </List.Header>
      <List.Header className="actions"> </List.Header>
    </List.HeaderRow>

  pager: ->
    <span className="text-center">
      <Pager meta={@props.discount_codesPagination} buffer={4}/>
    </span>
