import React from 'react'
import './styles'

import { Link } from 'react-router-dom'

# componennts
import Pager          from '/components/pager/history-pager'
import Spinner        from '/components/spinner'
import List           from '/components/list'
import Filters        from '/components/filters'
import Modal          from 'react-bootstrap/lib/Modal'

import NewAttraction  from './new-attraction'
import EditAttraction from './edit-attraction'
import AttractionForm from './attraction-form'

import FaPlus   from 'react-icons/lib/fa/plus'

import { withAttractions } from './queries'

export default \
withAttractions \
class Attractions extends React.Component

  constructor: (props)->
    super arguments...
    @state =
      modalOpen:  false

  toggle: =>
    @setState modalOpen: not @state.modalOpen

  render: ->
    <div className="Attractions">
      <header>
        <h1>Attractions</h1>
        {@newAttraction()}
      </header>
      <section className="content">
        {@filters()}
        {@list()}
        {@pager()}
      </section>
    </div>

  filters: ->
    <Filters>
      <Filters.Search placeholder="Search Attraction ID or Currency"/>
      <span className="pull-right">
        <Pager meta={@props.attractionsPagination} buffer={2}/>
      </span>
    </Filters>

  list: ->
    return <Spinner/> if @props.attractionsLoading
    <List>
      {@header()}
      {for attraction in @props.attractions
        <List.Row key={attraction._key}>
          <List.Cell className="id">
            {attraction.id}
          </List.Cell>
          <List.Cell className="name">
            {attraction.name}
          </List.Cell>
          <List.Cell className="discovery_id">
            {attraction.discovery_id}
          </List.Cell>
          <List.Cell className="legacy_id">
            {attraction.legacy_id}
          </List.Cell>
          <List.Cell className="edit">
            <EditAttraction attraction={attraction} />
          </List.Cell>
        </List.Row>
      }
    </List>

  header: ->
    <List.HeaderRow>
      <List.Header className="id">ID</List.Header>
      <List.Header className="name">Name</List.Header>
      <List.Header className="discovery_id">Discover ID</List.Header>
      <List.Header className="legacy_id">Legacy ID</List.Header>
      <List.Header className="edit">Edit</List.Header>
    </List.HeaderRow>

  pager: ->
    <div className="text-center">
      <Pager meta={@props.attractionsPagination} buffer={4}/>
    </div>

  newAttraction: ->
    <span>
      <button className='btn btn-default NewAttractionButton' onClick={@toggle.bind @, 'modalOpen'}>
        <FaPlus/>
        {' '}
        New Attraction
      </button>
      <Modal
        show={@state.modalOpen}
        onHide={@toggle}
        className={'AddAttractionModal'} 
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Import a New Attraction
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='clearfix'>
          <NewAttraction
            cancel={@toggle}
          />
        </Modal.Body>
      </Modal>
    </span>
