import React from 'react'
import './styles'

import flash from '/lib/flash'

import { Link }             from 'react-router-dom'
import { state_options }    from '/models/withdrawal'

# components
import Spinner              from '/components/spinner'
import Pager                from '/components/pager/history-pager'
import Filters              from '/components/filters'
import List                 from '/components/list'
import WithdrawalRow        from '/components/withdrawal-row'


import ViewDetailsContent   from '/components/withdrawal-row/view-details-content'

import { withUser, withWithdrawals, withWithdrawalsRPC } from './queries'

export default \
withUser \
withWithdrawals \
withWithdrawalsRPC \
class Withdrawals extends React.Component

  rpc: (id, method)->
    {withdrawal, errors} = await @props.withdrawals_rpc id, method
    if errors?.length
      flash.error errors
    else
      flash.success "Withdrawal updated successfully"

  render: ->
    <div className="Withdrawals">
      {@filters()}
      {@list()}
      {@pager()}
    </div>

  filters: ->
    <Filters>
      <Filters.Search/>
      <Filters.State options={state_options}/>
      <Filters.Amount/>
      <span className="pull-right">
        <Pager meta={@props.withdrawalsPagination} buffer={2}/>
      </span>
    </Filters>

  list: ->
    return <Spinner/> if @props.withdrawalsLoading
    <List>
      <WithdrawalRow.Header/>
      {for withdrawal in @props.withdrawals
        <WithdrawalRow
          key={withdrawal._key}
          withdrawal={withdrawal}
        />
      }
    </List>

  pager:->
    <div className="text-center">
      <Pager meta={@props.withdrawalsPagination} buffer={4}/>
    </div>
