import Model from '/lib/model'
import humanize   from 'underscore.string/humanize'
import { formatMoney } from 'accounting'
$w = (str)-> (t for t in str.split(' ') when t isnt '')

export default \
class DiscountCode extends Model
  @className: 'DiscountCode'

  @belongsTo 'user',      model: 'User'
  @belongsTo 'listing',   model: 'Listing'

  @computed 'discount', ->
    switch @discount_type
      when "percent" then  "#{@percent * 100} %"
      when "fixed" then  formatMoney @fixed

export states = $w 'active inactive used'
export state_options = value: state, label: humanize state for state in states
