import React from 'react'

import flash from '/lib/flash'

import { withEvent, withReserveSeats } from './queries'
import SeatsChart    from '/components/seats/seats-chart'
import ButtonBar     from '/components/button-bar'
import Spinner       from '/components/spinner'

BOOK_SEATS = 'BOOK_SEATS'
RELEASE_SEATS = 'RELEASE_SEATS'

SEATS_STATE_TO_ACTION =
  BOOK_SEATS: 'hold',
  RELEASE_SEATS: 'release'

export default \
withEvent \
withReserveSeats \
class SeatHolds extends React.Component

  constructor: ->
    super arguments...
    @state =
      selectedSeats: []
      seatsState: BOOK_SEATS

  render: ->
    return <Spinner/> if @props.eventLoading
    return <div className='no-seats'>Only for reserved seating listings</div> if !@props.event.listing.seats_chart_key
    <div className='seat-holds'>
      <ButtonBar
        buttons={[
            {
              text: 'Hold Seats',
              isActive: @state.seatsState == BOOK_SEATS,
              onClick:=> @setState seatsState: BOOK_SEATS
            },
            {
              text: 'Release Seats',
              isActive: @state.seatsState == RELEASE_SEATS,
              onClick:=> @setState seatsState: RELEASE_SEATS
            }
          ]}
      />
      <div className='seat-select-copy'>Please select the seats to {if @state.seatsState == BOOK_SEATS then 'hold' else 'release'} (hold shift to multiselect)</div>
      <SeatsChart
        seatsState={@state.seatsState}
        eventId={@props.event.id}
        seatsPublicKey={@props.event.listing.seats_public_key}
        updateSelectedSeats={@updateSelectedSeats}
      />
      {@state.selectedSeats.length > 0 &&
        <button className='btn btn-primary seat-hold-button' onClick={=>@reserveSeats(@props.event.id, @state.selectedSeats, @state.seatsState)}>
          {if @state.seatsState == BOOK_SEATS then "Hold #{@state.selectedSeats.length} Seats" else "Release #{@state.selectedSeats.length} Seats"}
        </button>
      }
      <div className='seat-label'>
        Seats Selected: &nbsp;
        {@state.selectedSeats.map (seat, index) ->
          <span key={index}>{seat}, &nbsp;</span>
        }
      </div>
    </div>

  updateSelectedSeats: (selectedSeats)=>
    @setState selectedSeats: selectedSeats

  reserveSeats: (id, seats_to_hold, seats_state) ->
    {event, errors} = await @props.reserveSeats id, seats_to_hold, SEATS_STATE_TO_ACTION[seats_state]
    if errors?.length
      flash.error errors
    else
      window.seatsioChart.clearSelection()
      flash.success "Seats successfully released or held"
