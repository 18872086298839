import Model from '/lib/model'
import currencies from '/lib/currencies'
import { formatMoney } from 'accounting'
import humanize from 'underscore.string/humanize'

export default \
class Listing extends Model
  @className: 'Listing'
  constructor: ->
    super arguments...
    @id                               = null # "56994b80c6d1c6e7b70000a2",
    @slug                             = null # "1BVPXW",
    @slug_param                       = null # "access-key-test-event-tickets-toronto-1BVPXW",
    @title                            = null # "access key test event",
    @privacy                          = null # "public",
    @state                            = null # "posted",
    @address                          = null # "220 Yonge St, Toronto, ON, CA",
    @social_deal_facebook_enabled     = null # false,
    @social_deal_facebook_percent     = null # 0.05,
    @social_deal_google_enabled       = null # true,
    @social_deal_google_percent       = null # 0.05,
    @social_deal_twitter_enabled      = null # true,
    @social_deal_twitter_message      = null # null,
    @social_deal_twitter_percent      = null # 0.05,
    @description                      = null # "sdkaskdjhaskjdh asjkhd asj dasjd hsdkaskdjhaskjdh asjkhd asj dasjd hsdkaskdjhaskjdh asjkhd asj dasjd hsdkaskdjhaskjdh asjkhd asj dasjd h",
    @description_html                 = null # "<p>sdkaskdjhaskjdh asjkhd asj dasjd hsdkaskdjhaskjdh asjkhd asj dasjd hsdkaskdjhaskjdh asjkhd asj dasjd hsdkaskdjhaskjdh asjkhd asj dasjd h</p>",
    @category_id                      = null # "54242e9e54c531ef8800001e",
    @category_key                     = null # "fashion",
    @created_at                       = null # "2016-01-15T19:41:54Z",
    @updated_at                       = null # "2016-01-15T23:49:15Z",
    @hashtag                          = null # "",
    @google_analytics_id              = null # null,
    @google_analytics_ecommerce       = null # false,
    @featured_score                   = null # 0,
    @base_score                       = null # 1.0204,
    @base_multiplier                  = null # 2.1175,
    @show_avatars_of_bookers          = null # true,
    @pseudonym                        = null # null,
    @price                            = null # 5,
    @capacity                         = null # null,
    @provider                         = null # "stripe",
    @provider_key                     = null # "pk_test_UB02NrFCDcZiiwrA2dsGlfNF",
    @has_locked_rates                 = null # true,
    @website                          = null # null,
    @hide_date                        = null # false,
    @reviewed_at                      = null # null,
    @base_currency                    = null # "CAD",
    @src_currency                     = null # "CAD",
    @host_id                          = null # "50bc4ca12078f91587017abd",
    @needs_approval                   = null # false,
    @has_photos                       = null # false,
    @free                             = null # false,
    @image_url                        = null # "https://ucarecdn.com/3f7dc642-f67c-4a56-aa56-8a364ea0f018/-/resize/1200x/-/crop/900x900/0,0/-/inline/yes/",
    @image_url_500                    = null # "https://ucarecdn.com/3f7dc642-f67c-4a56-aa56-8a364ea0f018/-/resize/1200x/-/crop/900x900/0,0/-/format/jpeg/-/scale_crop/500x500/center/-/progressive/yes/-/inline/yes/",
    @image_url_160                    = null # "https://ucarecdn.com/3f7dc642-f67c-4a56-aa56-8a364ea0f018/-/resize/1200x/-/crop/900x900/0,0/-/format/jpeg/-/scale_crop/160x160/center/-/progressive/yes/-/inline/yes/",
    @image_url_50                     = null # "https://ucarecdn.com/3f7dc642-f67c-4a56-aa56-8a364ea0f018/-/resize/1200x/-/crop/900x900/0,0/-/format/jpeg/-/scale_crop/50x50/center/-/progressive/yes/-/inline/yes/",
    @is_active                        = null # true,
    @mailchimp_api_key                = null # null,
    @mailchimp_list_id                = null # null,
    @latitude                         = null # 43.65334095235955,
    @longitude                        = null # -79.3805894075311,
    @area                             = null # "Downtown",
    @city                             = null # "Toronto",
    @province                         = null # "ON",
    @country                          = null # "CA",
    @postal                           = null # "M5B 2H1",
    @venue_name                       = null # "Toronto Eaton Centre",
    @foursquare_id                    = null # null,
    @stripe_approved_host             = null # true,
    @ticketmaster_venue_id            = null # "KovZpZAFFE1A",
    @ticketmaster_venue_name          = null # "Air Canada Centre"
    @has_seats                        = null # false
    @fan_facing_move_order            = null # false
    @order_fee                        = null # null

    @send_purchase_notifications_immediately  = null # false,

    @num_rates                        = null
    @num_events                       = null

  @belongsTo 'host', model: 'User'
  @belongsTo 'user', model: 'User'
  @hasMany 'events', model: 'Event'
  @hasMany 'rates', model: 'Rate'
  @belongsTo 'event', model: 'Event'
  @hasMany 'attractions', model: 'Attraction'

  @belongsTo 'cover_photo', model: 'Image'

  @belongsTo 'vat', model: 'Vat'

  @computed 'formattedPrice', ->
    return 'Free' unless @price
    formatMoney @price, symbol: currencies[@base_currency].replace @base_currency, ''

  @computed 'url', ->
    "#{process.env.MULTIVERSE}/events/#{@slug_param}"

  @computed 'paymentProvider', ->
    switch @provider
      when 'stripe'
        'Universe Payments'
      when 'stripe_connect'
        'Stripe Connect'

  @computed 'transitions', ->
    actions = state_actions[@state] or []

    # REMOVED FOR NOW SINCE THE ORDER FEE SERVICE IS BEING FLAKY - this option will always be present, even if it's not applicable
    # # filter out cancel_retaining_order_fee! if not present on the listing
    # actions = (a for a in actions when a isnt 'cancel_retaining_order_fee!') unless @order_fee

    ret = {}
    for tr in actions
      ret[tr] = transition_name_overrides?[tr] or humanize tr
    ret

export states = [
  'presubmitted'
  'draft'
  'posted'
  'expired'
  'inactive'
  'archived'
  'hidden'
  'declined'
]

export state_options = value: state, label: humanize state for state in states

$w = (str)-> (t for t in str.split(' ') when t isnt '')
export state_actions =
  presubmitted: $w 'submit back_to_draft hide archive'
  draft:        $w 'submit hide archive'
  posted:       $w 'back_to_draft decline inactivate hide expire archive cancel! cancel_retaining_order_fee!'
  expired:      $w 'back_to_draft decline inactivate hide archive unexpire cancel! cancel_retaining_order_fee!'
  inactive:     $w 'back_to_draft activate hide archive cancel! cancel_retaining_order_fee!'
  archived:     $w 'back_to_draft decline inactivate hide unexpire cancel! cancel_retaining_order_fee!'
  hidden:       $w 'back_to_draft archive'
  declined:     $w 'back_to_draft hide archive cancel! cancel_retaining_order_fee!'

export transition_name_overrides =
  'cancel!': "Cancel Listing (Initiates Mass Refunds)"
  'cancel_retaining_order_fee!': "Cancel Listing, Mass Refund, Universe Retains Per Order Fees"
