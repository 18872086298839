import React from 'react'
import './styles'

export default \
class HeaderRow extends React.Component
  @displayName: 'HeaderRow'

  render: ->
    { children, className='', props... } = @props
    <div className="HeaderRow #{className}" {props...} >
      {children}
    </div>
