import React from 'react'
import PropTypes    from 'prop-types'

import Base         from './base'
import Toggle       from '/components/toggle'

import { withRouter }   from 'react-router-dom'

export default \
withRouter \
class ToggleFilter extends Base
  @propTypes:
    value:        PropTypes.bool
    size:         PropTypes.string

  @defaultProps:
    size:   'medium'

  onChange: (val)=>
    filters = Object.assign {}, @props.location.state?.filters or {}
    filters[@props.name] = val
    super Object.assign {}, @props.location.state, {filters}, page: 1

  value: ->
    @props.location.state?.filters?[@props.name]

  render: ->
    <div className="Filter form-group toggle">
      <label>{@label()}</label>
      <Toggle
        value={@value()}
        onChange={@onChange}
        size={@props.size}
      />
    </div>
