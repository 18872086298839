import Notes  from '/components/notes'

import {
  withEvent
  withNotes
} from './queries'

export default \
withEvent \
withNotes \
Notes
