import React from 'react'
import './styles'

import flash from '/lib/flash'

import { Link } from 'react-router-dom'

import Spinner      from '/components/spinner'
import Datetime     from '/components/datetime'
import StateActions from '/components/state-label/actions'
import StateLabel   from '/components/state-label'
import UserCard     from '/components/user-card'
import Filters      from '/components/filters'
import Pager        from '/components/pager/history-pager'
import List         from '/components/list'
import Price        from '/components/price'
import ExternalLink from '/components/external-link'
import EventCard    from '/components/event-card'

# for filtering
import { state_options } from '/models/cost-item'

import { withCostItems, withCostItemsRPC } from './queries'

export default \
withCostItems \
withCostItemsRPC \
class CostItems extends React.Component
  rpc: (id, method)=>
    {cost_item, errors} = await @props.cost_items_rpc id, method
    flash.error errors if errors?.length

  render: ->
    <div className="CostItems">
      <header>
        <h1>Cost Items</h1>
      </header>
      <section className="content">
        {@filters()}
        {@list()}
        {@pager()}
      </section>
    </div>

  filters: ->
    <Filters>
      <Filters.Search/>
      <Filters.State options={state_options}/>
      <span className="pull-right">
        <Pager meta={@props.cost_itemsPagination} buffer={2}/>
      </span>
    </Filters>

  list: ->
    return <Spinner/> if @props.cost_itemsLoading
    <List>
      {@header()}
      {for cost_item in @props.cost_items
        <List.Row key={cost_item._key}>
          <List.Cell className="id">
            <ExternalLink url={"#{process.env.WEB}/dashboard/ticket/#{cost_item.id}"} />
          </List.Cell>
          <List.Cell className="qr-code">
            {cost_item.qr_code}
          </List.Cell>
          <List.Cell className="check-in-state">
            <StateLabel
              model={cost_item}
              type='CheckInState'
              state={cost_item.check_in_state}
              title={cost_item.checked_in_at or ''}
            />
          </List.Cell>
          <List.Cell className="listing">
            <EventCard event={cost_item.event} />
          </List.Cell>
          <List.Cell className="user">
            <UserCard user={cost_item.buyer} />
          </List.Cell>
          <List.Cell className="rate">
            {cost_item.rate?.name}
          </List.Cell>
          <List.Cell className="price">
            <Price amount={cost_item.src_price} currency={cost_item.src_currency}/>
          </List.Cell>
          <List.Cell className="created-at">
            <Datetime date={cost_item.created_at}/>
          </List.Cell>
          <List.Cell className="actions">
            <StateActions
              model={cost_item}
              onClick={@rpc.bind @, cost_item.id}
            />
          </List.Cell>
        </List.Row>
      }
    </List>

  header: ->
    <List.HeaderRow>
      <List.Header className="id"> Link </List.Header>
      <List.Header className="qr-code"> QR Code </List.Header>
      <List.Header className="check-in-state"> Check-In State </List.Header>
      <List.Header className="listing"> Listing </List.Header>
      <List.Header className="user"> User </List.Header>
      <List.Header className="rate"> Ticket Type </List.Header>
      <List.Header className="price"> Price </List.Header>
      <List.Header className="created-at"> Created At </List.Header>
      <List.Header className="actions"> Actions </List.Header>
    </List.HeaderRow>

  pager:->
    <div className="text-center">
      <Pager meta={@props.cost_itemsPagination} buffer={4}/>
    </div>
