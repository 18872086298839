import React from 'react'

import flash from '/lib/flash'

import { Link } from 'react-router-dom'

import { state_options } from '/models/listing'

# components
import ExternalLink from '/components/external-link'
import ListingPhoto from '/components/listing-photo'
import Spinner      from '/components/spinner'
import Datetime     from '/components/datetime'
import StateLabel   from '/components/state-label'
import StateActions from '/components/state-label/actions'
import Price        from '/components/price'
import Pager        from '/components/pager/history-pager'
import Filters      from '/components/filters'
import List         from '/components/list'
import FraudFlag    from '/components/fraud-flag'


import { withUser, withListings, withListingsRPC } from './queries'

export default \
withUser \
withListings \
withListingsRPC \
class Listings extends React.Component

  rpc: (id, method)->
    {listing, errors} = await @props.listings_rpc id, method
    if errors?.length
      flash.error errors
    else
      flash.success "Listing updated successfully"

  render: ->
    <div className="Listings">
      {@filters()}
      {@list()}
      {@pager()}
    </div>

  filters: ->
    <Filters>
      <Filters.Search/>
      <Filters.State options={state_options}/>
      <span className="pull-right">
        <Pager meta={@props.listingsPagination} buffer={2}/>
      </span>
    </Filters>

  list: ->
    return <Spinner/> if @props.listingsLoading
    <List>
      {@header()}
      {for listing in @props.listings
        <List.Row key={listing._key}>
          <List.Cell className="id">
            <Link className="btn btn-default" to={"/listings/#{listing.id}"}>
              More info
            </Link>
          </List.Cell>
          <List.Cell className="listing">
            <ListingPhoto
              image_url={listing.image_url}
              listing={listing}
              size={40}
            />
            <Link to={"/listings/#{listing.id}"}>
              {listing.title}
            </Link>
            <FraudFlag value={listing.failed_orders_percentage}/>
          </List.Cell>
          <List.Cell className="price">
            <Price amount={listing.price} currency={listing.src_currency}/>
          </List.Cell>
          <List.Cell className="created_at">
            <Datetime date={listing.created_at} format='MMM d, yyyy'/>
          </List.Cell>
          <List.Cell className="actions">
            <StateActions
              model={listing}
              onClick={@rpc.bind @, listing.id}
            />
          </List.Cell>
        </List.Row>
      }
    </List>

  header: ->
    <List.HeaderRow>
      <List.Header className="id">id</List.Header>
      <List.Header className="listing">Listing</List.Header>
      <List.Header className="price">Price</List.Header>
      <List.Header className="created_at">Created At</List.Header>
      <List.Header className="actions">Actions</List.Header>
    </List.HeaderRow>

  pager: ->
    <div className="text-center">
      <Pager meta={@props.listingsPagination} buffer={4}/>
    </div>
