import {
  SIGN_IN_ERRORED
  GET_USER_SUCCESS
  GET_USER_ERRORED
  SIGN_OUT
} from '/actions/session'

export default (state=null, action)->
  { type, payload } = action
  switch type
    when GET_USER_SUCCESS
      payload
    when SIGN_IN_ERRORED, GET_USER_ERRORED, SIGN_OUT
      null
    else
      state
