import React from 'react'
import PropTypes from 'prop-types'
import './styles'

import { Link }     from 'react-router-dom'
import UserIcon     from 'react-icons/lib/fa/user'
import EventIcon    from 'react-icons/lib/fa/calendar-o'
import ListingIcon  from 'react-icons/lib/fa/calendar-plus-o'
import TicketIcon   from 'react-icons/lib/fa/ticket'

export default \
class NoteWarning extends React.Component
  @propTypes:
    notes:    PropTypes.array

  @defaultProps:
    notes:    []

  icon: (type) ->
    switch type
      when "user" then  <UserIcon/>
      when "event" then  <EventIcon/>
      when "listing" then  <ListingIcon/>
      when "ticket" then  <TicketIcon/>

  render: ->
    return null unless @props.notes?.length
    <div className="NoteWarning">
      {for note in @props.notes
        <Link
          key={note.id}
          to={"/#{note.target_type}s/#{note.target_id}/summary"}
          title="#{note.target_type} notes"
        >
        {@icon(note.target_type)}
        </Link>
      }
    </div>
