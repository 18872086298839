import React from 'react'
import PropTypes from 'prop-types'

import { country_code_options } from '/models/commission-code'

import ReactSelect  from '/components/react-select'

export default \
class Generic extends React.Component

  constructor: (props) ->
    super arguments...

  render: ->
    <input
        type="text"
        className="form-control"
        placeholder="Placeholder"
        value={@props.value}
        onChange={event => @props.onChange(event.target.value)}
        required
    />
