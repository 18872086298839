import React from 'react'
import PropTypes from 'prop-types'
import './styles'

export default \
class ListingPhoto extends React.Component
  @propTypes:
    listing:    PropTypes.object
    size:       PropTypes.number
    image_url:  PropTypes.string

  @defaultProps:
    size:       50

  styles: ->
    width: @props.size
    height: @props.size

  image_url: ->
    # get the best size of image to show
    # closest without going over
    src_size = (i for i in [50,160,500] when i >= @props.size)?[0]

    attr_name = if src_size then "image_url_#{src_size}" else "image_url"
    @props.listing[attr_name] || @placeholder()

  render: ->
    <img
      className="ListingPhoto"
      src={@props.image_url or @image_url()}
      alt={@props.listing.title}
      style={@styles()}
    />

  placeholder: =>
    "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='#{@props.size}' height='#{@props.size}'><rect x='0' y='0' width='100%' height='100%' rx='10%' fill='%23ccc'></rect><text x='50%' y='50%' dominant-baseline='central' text-anchor='middle' fill='%23666' style='font-family: sans-serif;'>📅</text></svg>"
