import React        from 'react'
import Listing      from '/models/listing'
import { graphql }  from 'react-apollo'

#
# Queries
#

import show       from './queries/listing.gql'

export withListing = graphql show,
  options: (props) ->
    variables:
      id: props.listing.id
  props: ({props, data}) ->
    listing: Listing.parse data.listing
    listingLoading: data.loading


#
# Mutations
#

import listings_rpc            from '/mutations/listings/rpc.gql'

export withListingsRPC = graphql listings_rpc,
  props: ({ mutate })->
    listings_rpc: (id, method)->
      {data: {listing_rpc: {listing, errors}}} = \
      await mutate variables: {id, method}
      {listing, errors}
