import React from 'react'
import PropTypes    from 'prop-types'

import Base         from './base'

export default \
class Text extends Base
  @propTypes:
    placeholder:  PropTypes.string

  @defaultProps:
    placeholder:  ''
    type:         'text'

  constructor: ->
    super arguments...
    @state =
      hasFocus: false
      selectionStart: 0
      selectionEnd: 0

  onKeyUp: (evt)=>
    @setState
      selectionStart: @input.selectionStart
      selectionEnd:   @input.selectionEnd

  onFocus: =>
    @setState hasFocus: true

  onBlur: =>
    @setState hasFocus: false

  onChange: (evt)=>
    super "#{@props.name}": evt.target?.value or null

  defaultValue: ->
    @props.value or ''

  componentDidUpdate: =>
    if @state.hasFocus
      @input.focus()
      @input.selectionStart = @state.selectionStart
      @input.selectionEnd = @state.selectionEnd

  render: ->
    <div className="Filter form-group #{@props.type}">
      <label>{@label()}</label>
      <input
        ref={(i)=> @input = i}
        type={@props.type}
        defaultValue={@defaultValue()}
        key={@defaultValue()}
        className="form-control"
        onFocus={@onFocus}
        onBlur={@onBlur}
        onKeyUp={@onKeyUp}
        placeholder={@props.placeholder}
      />
    </div>
