import React from 'react'
import '../styles'

import flash from '/lib/flash'

import { Link } from 'react-router-dom'

import ReactSelect    from '/components/react-select'
import Spinner        from '/components/spinner'
import Modal          from 'react-bootstrap/lib/Modal'
import ModalState     from '/components/modal-state'
import Can            from '/components/can'
import List           from '/components/list'
import Filters        from '/components/filters'
import Pager          from '/components/pager/history-pager'
import Price          from '/components/price'
import UserCard       from '/components/user-card'
import StateActions   from '/components/state-label/actions'
import RulesCell      from '/components/order-fee/rules-cell'

import NewCode        from './new-code'

import CommissionCode from '/models/commission-code'

import FaPlus   from 'react-icons/lib/fa/plus'

import { currency_options } from '/models/commission-code'
import { state_options } from '/models/commission-code'
import currencies, { base_currencies } from '/lib/currencies'
import { formatMoney } from 'accounting'

import {
  withOrderLevelCodes
  withOrderLevelCodeCreate
} from './queries'

export default \
withOrderLevelCodes \
withOrderLevelCodeCreate \
class OrderCommissionCodes extends React.Component

  constructor: (props)->
    super arguments...
    @state =
      modalOpen:  false
      disabled: false

  commissionCodeCreate: (olf) =>
    return {} unless olf

    @setState disabled: true, errors: []
    {fee, errors} = await @props.orderFeeCodeCreate olf

    if errors?.length
      @setState(errors: errors)
    else
      flash.success "Order level fee code created"
      @setState modalOpen: false
      @props.codesRefetch()
    @setState disabled: false
    {fee, errors}

  render: ->
    @codes = @props.codes
    <div className="OrderLevelFeeCodes">
      <header>
        <h1>Order Fees</h1>
        {@newCode()}
      </header>
      <section className="content">
        {@filters()}
        {@list()}
        {@pager()}
      </section>
    </div>

  toggle: =>
    @setState modalOpen: not @state.modalOpen

  newCode: ->
    <Can action='create' subject='CommissionCode'>
      <span>
        <button className='btn btn-default' onClick={@toggle}>
          <FaPlus/>
          {' '}
          New Order Fee
        </button>
        <Modal
          show={@state.modalOpen}
          onHide={@toggle}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Create a new order fee
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='clearfix NewCodeModal'>
            <NewCode
              errors={@state.errors}
              onSave={@commissionCodeCreate}
              onCancel={@toggle}
            />
          </Modal.Body>
        </Modal>
      </span>
    </Can>

  filters: ->
    <Filters>
      <Filters.Search/>
      <span className="pull-right">
        <Pager meta={@props.codesPagination} buffer={2}/>
      </span>
    </Filters>

  pager: ->
    <div className="text-center">
      <Pager meta={@props.codesPagination} buffer={4}/>
    </div>

  list: ->
    return <Spinner/> if @props.codesLoading
    <List>
      {@header()}
      {for code in @props.codes
        <List.Row key={code.code}>
          <List.Cell className="code">
            {code.code}
          </List.Cell>
          <List.Cell className="fees">
            <RulesCell rules={code.rules} />
          </List.Cell>
          <List.Cell className="actions">
            <button role="button" aria-haspopup="true" aria-expanded="false" type="button" className="StateActions CommissionCode approved btn btn-default">
              Approved
            </button>
          </List.Cell>
        </List.Row>
      }
    </List>

  header: ->
    <List.HeaderRow>
      <List.Header className="code">Code</List.Header>
      <List.Header className="fees">Fees</List.Header>
      <List.Header className="actions">Status</List.Header>
    </List.HeaderRow>
