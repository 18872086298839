import React from 'react'

import flash from '/lib/flash'

import { Link } from 'react-router-dom'

# stores
import { state_options } from '/models/cost-item'

# components
import Avatar       from '/components/avatar'
import ListingPhoto from '/components/listing-photo'
import ReactSelect  from '/components/react-select'
import ExternalLink from '/components/external-link'
import Spinner      from '/components/spinner'
import Datetime     from '/components/datetime'
import StateActions from '/components/state-label/actions'
import Pager        from '/components/pager/history-pager'
import Filters      from '/components/filters'
import List         from '/components/list'

import {
  withUser
  withCostItems
  withCostItemsRPC
} from './queries'

export default \
withUser \
withCostItems \
withCostItemsRPC \
class CostItems extends React.Component
  rpc: (id, method)=>
    {cost_item, errors} = await @props.cost_items_rpc id, method
    flash.error errors if errors?.length

  render: ->
    return <Spinner/> if @props.cost_itemsLoading
    <div className="CostItems">
      {@filters()}
      {@list()}
      {@pager()}
    </div>

  filters: ->
    <Filters>
      <Filters.Search/>
      <Filters.State options={state_options}/>
      <span className="pull-right">
        <Pager meta={@props.cost_itemsPagination} buffer={2}/>
      </span>
    </Filters>

  list: ->
    <List>
      {@header()}
      {for cost_item in @props.cost_items
        <List.Row key={cost_item._key}>
          <List.Cell className="id">
            <Link className="btn btn-default" to={"/tickets/#{cost_item.ticket.id}/cost_items"}>
              More info
            </Link>
            <ExternalLink url={"#{process.env.WEB}/dashboard/ticket/#{cost_item.id}"} />
          </List.Cell>
          <List.Cell className="qr-code">
            {cost_item.qr_code}
          </List.Cell>
          <List.Cell className="listing">
            <ListingPhoto
              image_url={cost_item.listing.image_url}
              listing={cost_item.listing}
              size={40}
            />
            <div className="listing-details">
              <Link to={"/listings/#{cost_item.listing.id}"}>
                <span className="title">{cost_item.listing.title}</span>
                <small>{cost_item.rate.name}</small>
              </Link>
            </div>
          </List.Cell>
          <List.Cell className="created_at">
            <Datetime date={cost_item.created_at} format='MMM d, yyyy'/>
          </List.Cell>
          <List.Cell className="actions">
            <StateActions
              model={cost_item}
              onClick={@rpc.bind @, cost_item.id}
            />
          </List.Cell>
        </List.Row>
      }
    </List>

  header: ->
    <List.HeaderRow>
      <List.Header className="id"> Id </List.Header>
      <List.Header className="qr-code"> QR Code </List.Header>
      <List.Header className="listing"> Listing </List.Header>
      <List.Header className="created_at"> Created </List.Header>
      <List.Header className="actions"> Actions </List.Header>
    </List.HeaderRow>

  pager: ->
    <div className="text-center">
      <Pager meta={@props.cost_itemsPagination} buffer={4}/>
    </div>
