import Model from '/lib/model'

export default class BusinessInfo extends Model
  @className: 'BusinessInfo'
  constructor: ->
    super arguments...
    @name                         = null
    @country_code                 = null
    @province                     = null
    @address                      = null
    @address_secondary            = null
    @city                         = null
    @zip                          = null
    @tax_id                       = null

    # build model associations
    @vat                          = null
    @entity                       = null

  @hasOne 'vat',       model: 'Vat'
  @belongsTo 'entity', model: 'Entity'

  @computed 'label', ->
    "#{@name} (#{@country_code}) -- #{@vat?.name} #{@vat?.percent}%"

  @computed 'errors', ->
    errors = []
    for name in ['name', 'country_code', 'address', 'city', 'zip', 'tax_id']
      errors.push(name) unless @[name]
    [errors..., @vat?.errors.map((name) -> "vat_#{name}")...]
