import React from 'react'
import flash from '/lib/flash'

# components
import ReactSelect  from '/components/react-select'
import Toggle       from '/components/toggle'
import List         from '/components/list'

import {
  withListing
  withVats
  withUpdateTaxOverride
} from './queries'

export default \
withListing \
withVats \
withUpdateTaxOverride \
class Taxes extends React.Component
  constructor: ->
    super arguments...
    @state =
      taxSelectDisabled: false

  render: ->
    return null unless @props.listing.country_code == "NO"
    <div>
      <div className="Rates">
        <h2>Service fee tax overrides</h2>
        {@vat()}
      </div>
    </div>

  updateTaxOverride: (listing_id, item) =>
    @setState taxSelectDisabled: true
    {rate, errors} = await @props.updateTaxOverride listing_id, item?.value or null
    if errors?.length
      flash.error errors
    else
      flash.success "Listing service fee tax override updated successfully"
    @setState taxSelectDisabled: false

  vat: ->
    <List>
      <List.HeaderRow />
      <List.Row>
        <List.Cell className="setting">
          Vat override tax rate
        </List.Cell>
        <List.Cell className="select">
          <ReactSelect
            className='rate-selector'
            options={value: vat.id, label: vat.vat_label for vat in @props.vats}
            onChange={@updateTaxOverride.bind @, @props.listing.id}
            value={@props.listing.vat?.id}
            disabled={@state.taxSelectDisabled}
          />
        </List.Cell>
      </List.Row>
    </List>
