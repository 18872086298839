import React from 'react'
import PropTypes from 'prop-types'

import ReactSelect  from '/components/react-select'

export default \
class Comparison extends React.Component

  @propTypes =
    onChange: PropTypes.func

  constructor: (props) ->
    super arguments...

  render: ->
    <div className={if @props.hidden then  "hidden" else ""}>
      <ReactSelect
        className="filter-selector state-selector" 
        clearable={false}
        value={@props.value}
        options={@props.options.map((comparison) => { value: comparison.comparison, label: comparison.label })}
        placeholder="Select one"
        onChange={(obj) => @props.onChange(obj.value)}
        required 
      />
    </div>
