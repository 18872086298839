import React from 'react'

import humanize from 'underscore.string/humanize'
import pluck    from 'underscore-es/pluck'

import Attraction from '/models/attraction'

export default \
class EditAttraction extends React.Component
  constructor: ->
    super arguments...
    if @props.attraction
      attraction = Attraction.parse(@props.attraction)
      attraction.surfaceData()

    @state =
      attraction: attraction or undefined

  saveAttraction: (evt)=>
    evt.preventDefault()
    attraction = @generateAttraction()
    @props.action(attraction)

  generateAttraction: () =>
    @state.attraction.buryData()
    @state.attraction

  updateValue: (key, evt) =>
      # handle text inputs
      value = if evt?.target?.value? then evt?.target?.value else evt
      # handle react select inputs
      value = pluck value, 'value' if Array.isArray value
      # set the state
      attraction = @state.attraction
      attraction[key] = value
      @setState attraction: attraction

  formRow: (name, value, placeholder) =>
    label = name.replace /(_id|_url)$/, ''

    <div className="form-group" key={name}>
      <label className="control-label">
        {humanize label}
        <input
          type="text"
          className="form-control"
          placeholder={placeholder or name}
          value={value or ''}
          onChange={@updateValue.bind @, name}
        />
      </label>
    </div>

  render: ->
    return null unless @state.attraction
    <div>
      <div className="AttractionForm">
        <h2>Fill in Attraction Details</h2>
        <label>
          Discovery ID
          <input type="text" className="form-control" value={@state.attraction?.discovery_id or ''} disabled="disabled" />
        </label>
        <label>
          Name
          <input type="text" className="form-control" value={@state.attraction?.name or ''} disabled="disabled" />
        </label>
        {if @state.attraction.ticketmasterImage
          <img className="attractionImage" src={@state.attraction.ticketmasterImage} />
        }
        <div className="form-group" key='bio'>
          <label className="control-label">About
            <textarea
              type="text"
              className="form-control attraction_bio"
              value={@state.attraction.bio}
              onChange={@updateValue.bind @, 'bio'}
            />
          </label>
        </div>
        {@formRow 'legacy_id', @state.attraction?.legacy_id, 'Legacy ID'}
        {@formRow 'facebook_url', @state.attraction?.facebook_url or @state.attraction?.facebook_id, 'Facebook ID/URL'}
        {@formRow 'homepage_url', @state.attraction?.homepage_url, 'Homepage URL'}
        {@formRow 'itunes_url', @state.attraction?.itunes_url or @state.attraction?.itunes_id, 'iTunes ID/URL'}
        {@formRow 'spotify_url', @state.attraction?.spotify_url or @state.attraction?.spotify_id, 'Spotify ID/URL'}
        {@formRow 'youtube_url', @state.attraction?.youtube_url or @state.attraction?.youtube_id, 'YouTube ID/URL'}

        <button className="btn btn-primary btn-md" onClick={@saveAttraction}>Save</button>
      </div>
    </div>
