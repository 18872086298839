export default \
class Ability
  constructor: (rules=[])->
    @rules = (new Rule rule for rule in rules)

  # the final matching permission is
  # the last word on the user's ability
  can: (action, subject)=>
    (rule.allow for rule in @rules when rule.match action, subject)?.pop?() or false

  cannot: (action, subject)=>
    not @can action, subject

class Rule
  constructor: ({subjects=[], actions=[], allow=false})->
    @allow = allow
    @subjects = subjects
    @actions = actions
      # alias expansion (read for now)
      .join ' '
      .replace /\bread\b/, 'read list show'
      .split /\s+/

  match: (action, subject)->
    (action in @actions or 'manage' in @actions) and
    (subject in @subjects or 'all' in @subjects)
