import React from 'react'
import PropTypes from 'prop-types'
import './styles'

export default \
class ButtonBar extends React.Component
  @propTypes:
    buttons: PropTypes.arrayOf(PropTypes.shape(
      text: PropTypes.string,
      isActive: PropTypes.bool,
      onClick: PropTypes.func
    ))

  render: ->
    <div className="button-bar">
      {@props.buttons.map((button, idx) =>
        <div key={idx} className="button #{if button.isActive then 'active'}" onClick={button.onClick}>
          {button.text}
        </div>
      )}
    </div>
