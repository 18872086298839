import React from 'react'

import flash from '/lib/flash'

export default \
class ButtonCreateSubaccount extends React.Component
  submitCreateSubaccount: (evt)=>
    evt.preventDefault()

    {user, errors} = await @props.createSeatsSubaccount @props.userId
    if errors?.length
      flash.error errors
      flash.error "Creating a Seats.io subaccount failed"
    else
      flash.success "Created a Seats.io subaccount successfully"

  render: ->
    <button type="submit" className="btn btn-sm btn-default" onClick={@submitCreateSubaccount}>Create Seats.io Subaccount</button>
