export base_currency_names = [
  {
    currencyCode: "USD"
    currencyName: "United States Dollar"
  },
  {
    currencyCode: "CAD"
    currencyName: "Canadian Dollar"
  },
  {
    currencyCode: "GBP"
    currencyName: "British Pound"
  },
  {
    currencyCode: "EUR"
    currencyName: "Euro"
  },
  {
    currencyCode: "AUD"
    currencyName: "Australian Dollars"
  },
  {
    currencyCode: "BRL"
    currencyName: "Brazilian Real"
  },
  {
    currencyCode: "CHF"
    currencyName: "Swiss Franc"
  },
  {
    currencyCode: "DKK"
    currencyName: "Danish Krone"
  },
  {
    currencyCode: "NOK"
    currencyName: "Norwegian Krone"
  },
  {
    currencyCode: "NZD"
    currencyName: "New Zealand Dollar"
  },
  {
    currencyCode: "SEK"
    currencyName: "Swedish Krona"
  },
  {
    currencyCode: "MXN"
    currencyName: "Mexican Peso"
  },
  {
    currencyCode: "JPY"
    currencyName: "Japanese Yen"
  },
  {
    currencyCode: "HKD"
    currencyName: "Hong Kong Dollar"
  },
  {
    currencyCode: "SGD"
    currencyName: "Singapore Dollar"
  }
]

export base_currencies = (c.currencyCode for c in base_currency_names)

export default {
  AED: "\u062f.\u0625 AED"
  AFN: "\u060b AFN"
  ALL: "L ALL"
  AMD: "\u0564\u0580. AMD"
  ANG: "\u0192 ANG"
  AOA: "Kz AOA"
  ARS: "$ ARS"
  AUD: "$ AUD"
  AWG: "\u0192 AWG"
  AZN: " AZN"
  BAM: "\u041a\u041c BAM"
  BBD: "$ BBD"
  BDT: "\u09f3 BDT"
  BGN: "\u043b\u0432 BGN"
  BHD: "\u0628.\u062f BHD"
  BIF: "Fr BIF"
  BMD: "$ BMD"
  BND: "$ BND"
  BOB: "Bs. BOB"
  BRL: "R$  BRL"
  BSD: "$ BSD"
  BTC: "B\u20e6 BTC"
  BTN: "Nu. BTN"
  BWP: "P BWP"
  BYR: "Br BYR"
  BZD: "$ BZD"
  CAD: "$ CAD"
  CDF: "Fr CDF"
  CHF: "Fr CHF"
  CLF: "UF CLF"
  CLP: "$ CLP"
  CNY: "\u00a5 CNY"
  COP: "$ COP"
  CRC: "\u20a1 CRC"
  CUC: "$ CUC"
  CUP: "$ CUP"
  CVE: "$ CVE"
  CZK: "K\u010d CZK"
  DJF: "Fdj DJF"
  DKK: "kr DKK"
  DOP: "$ DOP"
  DZD: "\u062f.\u062c DZD"
  EEK: "KR EEK"
  EGP: "\u062c.\u0645 EGP"
  ERN: "Nfk ERN"
  ETB: "Br ETB"
  EUR: "\u20ac EUR"
  FJD: "$ FJD"
  FKP: "\u00a3 FKP"
  GBP: "\u00a3 GBP"
  GEL: "\u10da GEL"
  GGP: " GGP"
  GHS: "\u20b5 GHS"
  GIP: "\u00a3 GIP"
  GMD: "D GMD"
  GNF: "Fr GNF"
  GTQ: "Q GTQ"
  GYD: "$ GYD"
  HKD: "$ HKD"
  HNL: "L HNL"
  HRK: "kn HRK"
  HTG: "G HTG"
  HUF: "Ft HUF"
  IDR: "Rp IDR"
  ILS: "\u20aa ILS"
  IMP: " IMP"
  INR: "\u003CU+20B9\u003E INR"
  IQD: "\u0639.\u062f IQD"
  IRR: "\ufdfc IRR"
  ISK: "kr ISK"
  JEP: "\u00a3 JEP"
  JMD: "$ JMD"
  JOD: "\u062f.\u0627 JOD"
  JPY: "\u00a5 JPY"
  KES: "KSh KES"
  KGS: "som KGS"
  KHR: "\u17db KHR"
  KMF: "Fr KMF"
  KPW: "\u20a9 KPW"
  KRW: "\u20a9 KRW"
  KWD: "\u062f.\u0643 KWD"
  KYD: "$ KYD"
  KZT: "\u3012 KZT"
  LAK: "\u20ad LAK"
  LBP: "\u0644.\u0644 LBP"
  LKR: "\u20a8 LKR"
  LRD: "$ LRD"
  LSL: "L LSL"
  LTL: "Lt LTL"
  LVL: "Ls LVL"
  LYD: "\u0644.\u062f LYD"
  MAD: "\u062f.\u0645. MAD"
  MDL: "L MDL"
  MGA: "Ar MGA"
  MKD: "\u0434\u0435\u043d MKD"
  MMK: "K MMK"
  MNT: "\u20ae MNT"
  MOP: "P MOP"
  MRO: "UM MRO"
  MTL: "\u20a4 MTL"
  MUR: "\u20a8 MUR"
  MVR: "MVR MVR"
  MWK: "MK MWK"
  MXN: "$ MXN"
  MYR: "RM MYR"
  MZN: "MTn MZN"
  NAD: "$ NAD"
  NGN: "\u20a6 NGN"
  NIO: "C$ NIO"
  NOK: "kr NOK"
  NPR: "\u20a8 NPR"
  NZD: "$ NZD"
  OMR: "\u0631.\u0639. OMR"
  PAB: "B/. PAB"
  PEN: "S/. PEN"
  PGK: "K PGK"
  PHP: "\u20b1 PHP"
  PKR: "\u20a8 PKR"
  PLN: "z\u0142 PLN"
  PYG: "\u20b2 PYG"
  QAR: "\u0631.\u0642 QAR"
  RON: "Lei RON"
  RSD: "\u0420\u0421\u0414 RSD"
  RUB: "\u0440. RUB"
  RWF: "FRw RWF"
  SAR: "\u0631.\u0633 SAR"
  SBD: "$ SBD"
  SCR: "\u20a8 SCR"
  SDG: "\u00a3 SDG"
  SEK: "kr SEK"
  SGD: "$ SGD"
  SHP: "\u00a3 SHP"
  SLL: "Le SLL"
  SOS: "Sh SOS"
  SRD: "$ SRD"
  STD: "Db STD"
  SVC: "\u20a1 SVC"
  SYP: "\u00a3S SYP"
  SZL: "L SZL"
  THB: "\u0e3f THB"
  TJS: "\u0405\u041c TJS"
  TMT: "m TMT"
  TND: "\u062f.\u062a TND"
  TOP: "T$ TOP"
  TRY: "TL TRY"
  TTD: "$ TTD"
  TWD: "$ TWD"
  TZS: "Sh TZS"
  UAH: "\u20b4 UAH"
  UGX: "USh UGX"
  USD: "$ USD"
  UYU: "$ UYU"
  UZS: " UZS"
  VEF: "Bs F VEF"
  VND: "\u20ab VND"
  VUV: "Vt VUV"
  WST: "T WST"
  XAF: "Fr XAF"
  XAG: "oz t XAG"
  XAU: "oz t XAU"
  XCD: "$ XCD"
  XDR: "SDR XDR"
  XOF: "Fr XOF"
  XPD: " XPD"
  XPF: "Fr XPF"
  XPT: " XPT"
  YER: "\ufdfc YER"
  ZAR: "R ZAR"
  ZMK: "ZK ZMK"
  ZMW: "ZK ZMW"
  ZWL: "$ ZWL"
}
