import Model from '/lib/model'
import currencies from '/lib/currencies'
import { formatMoney } from 'accounting'

export default \
class EventReport extends Model
  @className: 'EventReport'
  constructor: ->
    super arguments...
    @id                           = null # "5187cb4d68eacb1a300008c8"
    @cost_items_sold              = null # 4
    @ticket_items_sold            = null # 4
    @invitations_sent             = null # 0
    @tickets_sold                 = null # 2
    @total_amount_discount        = null # 0
    @total_amount_gross           = null # 200
    @total_commission_included    = null # 0
    @total_fee                    = null # 0
    @total_fulfillment_amount     = null # 200
    @sales_by_date                = null # [{date: "2013-05-10", rate_id: "51789a5fc9d00f062f000fcb", total_amount_net: 200,…}]
    @tickets_available_by_rate    = null # [{rate_id: "51789a5fc9d00f062f000fcb", total: 1}]
    @tickets_by_date              = null # [{date: "2013-05-10", rate_id: "51789a5fc9d00f062f000fcb", total: 4}]
    @invitations_by_date          = null # []

    # @id                         = null # "51789a5fc9d00f062f000fc7"
    # @base_currency              = null # "GBP"
    # @ticket_items_sold          = null # 163
    # @cost_items_sold            = null # 163
    # @invitations_sent           = null # 57000
    # @slug                       = null # "graven-feathers-letterpress-card-making-workshop-tickets-london-2VWWZ"
    # @src_currency               = null # "GBP"
    # @state                      = null # "posted"
    # @tickets_report_id          = null # "54b588b054c5311cc1000205"
    # @tickets_sold               = null # 128
    # @title                      = null # "Graven Feather's Letterpress Card Making Workshop"
    # @total_amount_discount      = null # 0
    # @total_amount_gross         = null # 520
    # @total_commission_included  = null # -37.28
    # @total_fee                  = null # 67.6
    # @total_fulfillment_amount   = null # 550.32
    # @unapproved_count           = null # 0

    # @invitations_by_date        = null # [{date: "2015-02-20", total_sent_by_host: 0, total_sent_by_other: 57000}]
    # @sales_by_date              = null # [{date: "2013-05-10", rate_id: "51789a5fc9d00f062f000fcb", total_amount_net: 0,…},…]
    # @tickets_available_by_rate  = null # [{rate_id: "51789a5fc9d00f062f000fcb", total: 0}]
    # @tickets_by_date            = null # [{date: "2013-05-10", rate_id: "51789a5fc9d00f062f000fcb", total: 4},…]

    # # @rate_ids                   = null # ["51789a5fc9d00f062f000fcb"]

  @belongsTo 'listing', model: 'Listing'

  @computed 'baseCurrency', ->
    @listing?.base_currency || 'USD'

  @computed 'amountGross', ->
    formatMoney @total_amount_gross, symbol: currencies[@baseCurrency].replace @baseCurrency, ''

  @computed 'fulfillmentAmount', ->
    formatMoney @total_fulfillment_amount, symbol: currencies[@baseCurrency].replace @baseCurrency, ''
