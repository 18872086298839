import React from 'react'

import flash from '/lib/flash'

export default \
class ButtonSync extends React.Component
  submitSync: (evt)=>
    evt.preventDefault()

    {listing, errors} = await @props.updateSeatsCapacity @props.listingId
    if errors?.length
      flash.error errors
      flash.error "Seats syncing failed"
    else
      flash.success "Seats syncing performed successfully"

  render: ->
    <button type="submit" className="btn btn-sm btn-default" onClick={@submitSync}>Sync Capacities</button>
