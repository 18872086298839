import React from 'react'
import { Link } from 'react-router-dom'

import uniq         from 'underscore-es/uniq'

# components
import Spinner      from '/components/spinner'
import Datetime     from '/components/datetime'
import Pager        from '/components/pager/history-pager'
import Filters      from '/components/filters'
import List         from '/components/list'
import Change       from '/components/change'

attr_suggestions = []

export default \
class Changes extends React.Component

  render: ->
    <div className="Changes">
      {@filters()}
      {@list()}
      {@pager()}
    </div>

  list: ->
    return <Spinner/> if @props.changesLoading
    <List>
      {@header()}
      {@row(change) for change in @props.changes}
    </List>

  row: (change)->
    <List.Row key={change.id}>
      <List.Cell className="info">
        <Change change={change}/>
      </List.Cell>
      <List.Cell className="ctx">
        <span className="label label-default">
          {change.ctx}
        </span>
      </List.Cell>
      <List.Cell className="created_at">
        <Datetime date={change.created_at} format='MMM d, yyyy'/>
      </List.Cell>
    </List.Row>

  attr_suggestions: =>
    attr_suggestions = uniq @props.changes.reduce ((memo, ch)-> memo.concat ch.attr_names), attr_suggestions
    (value: t, label: t for t in attr_suggestions)

  filters: ->
    <Filters>
      <Filters.Tags name="attr_names" label="Attributes" options={@attr_suggestions()}/>
      <span className="pull-right">
        <Pager meta={@props.changesPagination} buffer={2}/>
      </span>
    </Filters>

  header:->
    <List.HeaderRow>
      <List.Header className="info"> Id </List.Header>
      <List.Header className="ctx"> CTX </List.Header>
      <List.Header className="created_at"> Created </List.Header>
    </List.HeaderRow>


  pager: ->
    <div className="text-center">
      <Pager meta={@props.changesPagination} buffer={4}/>
    </div>
