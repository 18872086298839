import { graphql }  from 'react-apollo'
import Models       from '/models/index'

import index        from './queries/index.gql'

export withReportLogs = graphql index,
  options: (props)->
    { page, filters } = props.location.state or {}
    variables:
      page: page or 1
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.report_logs or {}
    report_logs: Models.ReportLog.parse nodes or []
    report_logsPagination: pagination
    report_logsLoading: data.loading
