import React from 'react'
import PropTypes from 'prop-types'
import './styles'

import ReactDropdownMenu from 'react-dd-menu'
import 'react-dd-menu/dist/react-dd-menu.css'

export default \
class DropdownMenu extends React.Component
  constructor: ->
    super arguments...
    @state =
      open: false

  @propTypes:
    actions:  PropTypes.object.isRequired
    toggle:   PropTypes.element
    align:    PropTypes.string

  @defaultProps:
    actions: {}
    align: 'right'


  btn: ->
    <span onClick={(->@setState open: not @state.open).bind @}>
      {if @props.toggle
        @props.toggle
      else
        <button className="btn btn-default btn-sm">
          Actions
          <span className="caret"></span>
        </button>
      }
    </span>


  render: ->
    <div className="DropdownMenu">
      <ReactDropdownMenu
        isOpen={@state.open}
        close={(->@setState open: false).bind @}
        toggle={@btn()}
        align={@props.align}
      >
        {(for name, fn of @props.actions
          <li key={name}>
            <a onClick={fn}>{name}</a>
          </li>
        )}
      </ReactDropdownMenu>
    </div>

  # render: ->
  #   <div className="btn-group DropdownMenu">
  #     <button type="button" className="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown">
  #       Actions
  #       <span className="caret"></span>
  #       <span className="sr-only">Toggle Dropdown</span>
  #     </button>
  #     {if @props.actions
  #       <ul className="dropdown-menu" role="menu">
  #         {(for name, fn of @props.actions
  #           <li key={name}>
  #             <a onClick={fn}>{name}</a>
  #           </li>
  #         )}
  #       </ul>
  #     }
  #   </div>
