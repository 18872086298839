import { graphql } from 'react-apollo'
import Models           from '/models/index'

#
# Queries
#

import rpc                  from '/mutations/events/rpc.gql'
import index                from './queries/index.gql'
import show                 from './queries/show.gql'
import report               from './queries/report.gql'
import tickets              from './queries/tickets.gql'
import cost_items           from './queries/cost-items.gql'
import changes              from './queries/changes.gql'
import notes                from './queries/notes.gql'
import third_party_tickets  from './queries/third_party_tickets.gql'
import emails               from './queries/emails.gql'
import email_stats          from './queries/email-stats.gql'

export withRPC = graphql rpc,
  props: ({ mutate })->
    rpc: (id, method)->
      {data: {event_rpc: {event, errors}}} = \
      await mutate variables: {id, method}
      {event, errors}

export withEvents = graphql index,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.events or {}
    events: Models.Event.parse nodes or []
    eventsPagination: pagination
    eventsLoading: data.loading

export withEvent = graphql show,
  options: (props)->
    { id } = props.match.params
    variables: { id }
  props: ({props, data})->
    event: Models.Event.parse data.event
    eventLoading: data.loading

export withNotes = graphql notes,
  options: (props)->
    { id } = props.match.params
    { state } = props.location.state?.filters or {}
    variables:
      id: id
      states: state or []
  props: ({props, data})->
    notes: Models.Note.parse data.event?.notes or []
    notesLoading: data.loading
    notesRefetch: data.refetch

export withActiveNotes = graphql notes,
  options: (props)->
    { id } = props.match.params
    variables:
      id: id
      states: ['active']
  props: ({props, data})->
    notes: Models.Note.parse data.event?.notes or []
    notesLoading: data.loading
    notesRefetch: data.refetch

export withTickets = graphql tickets,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.event?.tickets or {}
    tickets: Models.Ticket.parse nodes
    ticketsPagination: pagination
    ticketsLoading: data.loading

export withCostItems = graphql cost_items,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.event?.cost_items or {}
    cost_items: Models.CostItem.parse nodes
    cost_itemsPagination: pagination
    cost_itemsLoading: data.loading

export withChanges = graphql changes,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.event?.changes or {}
    changes: Models.Change.parse nodes or []
    changesPagination: pagination
    changesLoading: data.loading

export withReport = graphql report,
  options: (props)->
    { id } = props.match.params
    variables: { id }
  props: ({props, data})->
    report: Models.EventReport.parse data.event?.report
    reportLoading: data.loading

export withThirdPartyTickets = graphql third_party_tickets,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.event?.third_party_tickets or {}
    third_party_tickets: Models.ThirdPartyTicket.parse nodes
    thirdPartyTicketsPagination: pagination
    thirdPartyTicketsLoading: data.loading
    thirdPartyTicketsRefetch: data.refetch

export withEmails = graphql emails,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.event?.emails or {}
    emails: Models.Email.parse nodes or []
    emailsPagination: pagination
    emailsLoading: data.loading

export withEmailStats = graphql email_stats,
  options: (props)->
    { id } = props.match.params
    variables: { id }
  props: ({props, data})->
    emailStats: data.event?.email_stats
    emailStatsLoading: data.loading

#
# Mutations
#

import rateCheckInsReset      from '/mutations/events/rate_checkins_reset.gql'
import reserveSeats           from '/mutations/events/reserve_seats.gql'
import thirdPartyTicketDelete from '/mutations/third_party_tickets/delete.gql'
import tickets_rpc            from '/mutations/tickets/rpc.gql'
import tickets_resend         from '/mutations/tickets/resend.gql'
import cost_items_rpc         from '/mutations/cost_items/rpc.gql'

export withRateCheckInsReset = graphql rateCheckInsReset,
  props: ({ mutate })->
    rateCheckInsReset: (id, rate_id)->
      {data: {event_rate_reset_checkins: {event, errors}}} = \
      await mutate variables: {id, rate_id}
      {event, errors}

export withReserveSeats = graphql reserveSeats,
  props: ({ mutate })->
    reserveSeats: (id, seats, action)->
      {data: {event_reserve_seats: {event, errors}}} = \
      await mutate variables: {id, seats, action}
      {event, errors}

export withThirdPartyTicketDelete = graphql thirdPartyTicketDelete,
  props: ({ mutate })->
    thirdPartyTicketDelete: (id)->
      {data: {third_party_ticket_delete: {third_party_ticket, errors}}} = \
      await mutate variables: {id}
      { third_party_ticket, errors }

export withCostItemsRPC = graphql cost_items_rpc,
  props: ({ mutate })->
    cost_items_rpc: (id, method)->
      {data: {cost_item_rpc: {cost_item, errors}}} = \
      await mutate variables: {id, method}
      {cost_item, errors}

export withTicketsRPC = graphql tickets_rpc,
  props: ({ mutate })->
    tickets_rpc: (id, method)->
      {data: {ticket_rpc: {ticket, errors}}} = \
      await mutate variables: {id, method}
      {ticket, errors}

export withTicketsResend = graphql tickets_resend,
  props: ({ mutate })->
    tickets_resend: (id)->
      {data: {ticket_resend: {ticket, errors}}} = \
      await mutate variables: {id}
      {ticket, errors}
