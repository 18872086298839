import Model from '/lib/model'

export default \
class FeeCondition extends Model
  @className: 'FeeCondition'
  constructor: ->
    super arguments...
    @property                 = null
    @comparison               = null
    @value                    = null
    