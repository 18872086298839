import { graphql }   from 'react-apollo'
import Models        from '/models/index'

import create_query from '/mutations/notes/create.gql'
import rpc          from '/mutations/notes/rpc.gql'

export withCreateNote = graphql create_query,
  props: ({ mutate })->
    create_note: (note)->
      {data: {note_create: {note, errors}}} = \
      await mutate variables: {note}
      {note, errors}

export withRPC = graphql rpc,
  props: ({ mutate })->
    rpc: (id, method)->
      {data: {note_rpc: {note, errors}}} = \
      await mutate variables: {id, method}
      {note, errors}

