import React from 'react'

SEATS_DIV_ID = 'seats-chart'
SEATSIO_SCRIPT_ID = 'seatsio-script-id'
SEATSIO_PUBLIC_KEY = 'cf0216a4-bb23-4a7b-82ad-cb1fd726fee5'
SEATS_SOURCE_URL = 'https://cdn.seatsio.net/chart.js'

export default \
class SeatsChart extends React.Component
  componentDidMount: =>
    @loadSeatsio()

  componentWillUnmount: =>
    if window.seatsioChart
      @unmountSeatsioChart()

  componentDidUpdate: (prevProps) =>
    if window.seatsioChart and @props.seatsState != prevProps.seatsState
      window.seatsioChart.clearSelection()
      window.seatsioChart.changeConfig extraConfig: @props.seatsState

  render: ->
    <div>
      <div id={SEATS_DIV_ID} />
    </div>

  loadSeatsio: =>
    if window.document.body.querySelector "##{SEATSIO_SCRIPT_ID}"
      @renderSeatsioChart()
      return
    # load
    loadPromise = new Promise (resolve, reject) ->
      loadScript = document.createElement('script')
      loadScript.src = SEATS_SOURCE_URL
      loadScript.id = SEATSIO_SCRIPT_ID
      window.document.body.appendChild loadScript
      loadScript.addEventListener 'load', -> resolve()
      loadScript.addEventListener 'error', (e) -> reject(e)
    # render
    loadPromise.then () => @renderSeatsioChart()

  renderSeatsioChart: =>
    new seatsio.SeatingChart(
      divId: SEATS_DIV_ID
      publicKey: @props.seatsPublicKey || SEATSIO_PUBLIC_KEY
      event: @props.eventId
      isObjectSelectable: (object, defaultValue, extraConfig) ->
        if object.objectType() == 'GeneralAdmissionArea'
          true
        else if extraConfig == 'RELEASE_SEATS'
          !defaultValue
        else
          defaultValue
      onObjectSelected: () =>
        @props.updateSelectedSeats window.seatsioChart.selectedObjects
      onObjectDeselected: () =>
        @props.updateSelectedSeats window.seatsioChart.selectedObjects
      onChartRendered: (chart) ->
        window.seatsioChart = chart
      objectColor: (object) ->
        if object.status == 'booked' then '#ddd' else object.category.color
      objectIcon: (object, defaultIcon) ->
        switch
          when object.selected then 'check'
          when object.status == 'manually_held' then 'hand-paper-o'
          else defaultIcon
      tooltipInfo: (object) ->
        # ga area tooltip
        if object.objectType == 'GeneralAdmissionArea'
          return "[b]#{object.numBooked}[/b] spots have been booked and/or held" +
          "[br/][br/][b]#{object.numSelected}[/b] spots to hold or release"

        # seat tooltip
        status = switch
          when object.status == 'manually_held' then 'held'
          when object.status == 'free' then 'unbooked'
          else object.status
        "This seat's [i]status[/i] is [pre]" + status + "[/pre]"
      showRowLabels: true
      showRowLines: true
      multiSelectEnabled: true
    ).render()

  unmountSeatsioChart: ->
    window.seatsioChart.destroy()
    window.seatsioChart = null
