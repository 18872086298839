import { combineReducers } from 'redux';

import access_token from './access_token';
import loading from './loading';
import user from './user';

export default combineReducers({
  access_token,
  loading,
  user,
});
