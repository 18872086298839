import React from 'react'
import './styles'

import { Link, Route, Switch, Redirect }  from 'react-router-dom'

import DocumentTitle from 'react-document-title'

# routes
import Advances             from './advances'
import Balances             from './balances'
import Changes              from './changes'
import Chargebacks          from './chargebacks'
import CostItems            from './cost-items'
import Emails               from './emails'
import Listings             from './listings'
import Events               from './events'
import Notes                from './notes'
import PartnershipPayments  from './partnership-payments'
import PaymentProcessing    from './payment-processing'
import Plan                 from './plan'
import Subscriptions        from './subscriptions'
import Summary              from './summary'
import Tickets              from './tickets'
import Withdrawals          from './withdrawals'
import Taxes                from './taxes'

# components
import Can            from '/components/can'
import withAbility    from '/lib/with-ability'
import ExternalLink   from '/components/external-link'
import Spinner        from '/components/spinner'
import UserAside      from '/components/user-aside'
import {
  Tabs
  TabLink
  TabContent
} from '/components/tabs'

import { withUser, withActiveNotes }   from './queries'

export default \
withActiveNotes \
withUser \
withAbility \
class User extends React.Component
  render: ->
    return <Spinner/> if @props.userLoading
    @user = @props.user

    <div className="User">
      <DocumentTitle title={"#{@user.name}"}/>
      <header>
        <h1>
          {@user.name} <ExternalLink url={@user.url}/> <small>{@user.slug}</small>
          <Can action='su' subject='User'>
            <a className="btn btn-default btn-sm" target="_new" href={@user.su_url}>Login as {@user.name}</a>
          </Can>
        </h1>
      </header>

      <main>

        <section className="content">
          <Tabs>
            <TabLink to={"#{@props.match.url}/summary"}>
              Summary
            </TabLink>
            <TabLink to={"#{@props.match.url}/listings"}>
              Hosted Listings <span className="badge">{@user.num_listings}</span>
            </TabLink>
            <TabLink to={"#{@props.match.url}/events"}>
              Hosted Events <span className="badge">{@user.num_events}</span>
            </TabLink>
            <TabLink to={"#{@props.match.url}/tickets"}>
              Tickets <span className="badge">{@user.num_tickets}</span>
            </TabLink>
            <TabLink to={"#{@props.match.url}/cost_items"}>
              Cost Items <span className="badge">{@user.num_cost_items}</span>
            </TabLink>
            <Can action='read' subject='Withdrawal'>
              <TabLink to={"#{@props.match.url}/withdrawals"}>
                Withdrawals <span className="badge">{@user.num_withdrawals}</span>
              </TabLink>
            </Can>
            <TabLink to={"#{@props.match.url}/emails"}>
              Emails <span className="badge">{@user.num_emails}</span>
            </TabLink>
            <Can action='read' subject='Chargeback'>
              <TabLink to={"#{@props.match.url}/chargebacks"}>
                Chargebacks <span className="badge">{@user.num_chargebacks}</span>
              </TabLink>
            </Can>
            <Can action='read' subject='Advance'>
              <TabLink to={"#{@props.match.url}/advances"}>
                Advances <span className="badge">{@user.num_advances}</span>
              </TabLink>
            </Can>
            <TabLink to={"#{@props.match.url}/payment_processing"}>
              Payment Processing
            </TabLink>
            <TabLink to={"#{@props.match.url}/balances"}>
              Balances
            </TabLink>
            <Can action='read' subject='PartnershipPayment'>
              <TabLink to={"#{@props.match.url}/partnership_payments"}>
                Partnership Payments
              </TabLink>
            </Can>
            <TabLink to={"#{@props.match.url}/subscriptions"}>
              Email Subscriptons
            </TabLink>
            <TabLink to={"#{@props.match.url}/changes"}>
              Changes
            </TabLink>
            <Can action='read' subject='Plan'>
              <TabLink to={"#{@props.match.url}/plan"}>
                Plan
              </TabLink>
            </Can>
            <TabLink to={"#{@props.match.url}/taxes"}>
              Taxes
            </TabLink>
            <TabLink to={"#{@props.match.url}/notes"}>
              Notes <span className="badge"> {@props.notes.length} </span>
            </TabLink>
            <TabContent>

              <Switch>
                <Route path='/users/:id/summary'              component={Summary}/>
                <Route path='/users/:id/listings'             component={Listings}/>
                <Route path='/users/:id/events'               component={Events}/>
                <Route path='/users/:id/tickets'              component={Tickets}/>
                <Route path='/users/:id/cost_items'           component={CostItems}/>
               {<Route path='/users/:id/withdrawals'          component={Withdrawals}/>          if @props.can 'read', 'Withdrawal'}
                <Route path='/users/:id/emails'               component={Emails}/>
               {<Route path='/users/:id/chargebacks'          component={Chargebacks}/>          if @props.can 'read', 'Chargeback'}
               {<Route path='/users/:id/advances'             component={Advances}/>             if @props.can 'read', 'Advance'}
                <Route path='/users/:id/payment_processing'   component={PaymentProcessing}/>
                <Route path='/users/:id/balances'             component={Balances}/>
               {<Route path='/users/:id/partnership_payments' component={PartnershipPayments}/>  if @props.can 'read', 'PartnershipPayment'}
                <Route path='/users/:id/subscriptions'        component={Subscriptions}/>
                <Route path='/users/:id/changes'              component={Changes}/>
               {<Route path='/users/:id/plan'                 component={Plan}/>                 if @props.can 'read', 'Plan'}
                <Route path='/users/:id/taxes'                component={Taxes}/>
                <Route path='/users/:id/notes'                component={Notes}/>
                <Redirect from="#{@props.match.url}" to="#{@props.match.url}/summary" />
              </Switch>

            </TabContent>
          </Tabs>

        </section>

        <UserAside id={@user.id}/>
      </main>

    </div>
