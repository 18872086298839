import React from 'react'
import Text         from './text'

import { withRouter }   from 'react-router-dom'

export default withRouter class SearchFilter extends Text
  @defaultProps:
    name:         'search'
    type:         'search'
    placeholder:  'Search'
    label:        'Search'

  onKeyUp: (evt)=>
    super arguments...
    term = evt if 'string' is typeof evt
    term ?= evt.target?.value
    val = term or null
    @doSearch val if term is '' or evt.keyCode is 13

  doSearch: (val)=>
    @props.history.push @props.location.pathname, Object.assign {}, @props.location.state, "#{@props.name}": val, page: 1

  defaultValue: ->
    @props.location.state?[@props.name] or ''
