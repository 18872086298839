import React from 'react'
import Select       from './select'

import { withRouter }   from 'react-router-dom'

export default withRouter class CurrencyFilter extends Select
  @defaultProps:
    placeholder:  'All Currencies'
    label:        'Currency'
    name:         'currency'

  value: ->
    @props.location.state?.filters?.currency or []
