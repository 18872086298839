import React     from 'react'
import PropTypes from 'prop-types'

import humanize from 'underscore.string/humanize'

import ReactSelect from '/components/react-select'
import List        from '/components/list'
import Toggle      from '/components/toggle'

import Vat          from '/models/vat'
import BusinessInfo from '/models/business-info'

import { country_options } from '/lib/countries'
import { province_options } from '/lib/provinces'

export default \
class NewBusinessInfo extends React.Component
  @propTypes =
    business_info:    PropTypes.object
    cancel:           PropTypes.func.isRequired
    save:             PropTypes.func.isRequired
    disabled:         PropTypes.bool.isRequired
    organization_id:  PropTypes.string.isRequired
    errors:           PropTypes.object
    entities:         PropTypes.arrayOf(PropTypes.object)

  constructor: (props)->
    super arguments...
    if @props.business_info
      @state =
        business_info: BusinessInfo.parse @props.business_info
        errors: []
    else
      @state =
        business_info: BusinessInfo.parse { organization_id: @props.organization_id }
        errors: []

  cancel: (evt)=>
    evt.preventDefault()
    @setState
      business_info: BusinessInfo.parse { organization_id: @props.organization_id }
    @props.cancel()

  save: (evt)=>
    evt.preventDefault()
    {errors} = await @props.save @state.business_info
    if errors?.length
      @setState errors: errors
    else
      @cancel evt

  vat_percent_value: =>
    percent = @state.business_info.vat.percent
    if percent || percent == 0 then percent else ''

  render: ->

    updateBusinessInfo = (key, scope, evt)=>
      # handle text inputs
      value = if evt?.target?.value? then evt?.target?.value else evt
      # handle react select inputs
      value = evt.value if evt?.value?
      # update the value
      if scope == "vat" || scope == "entity"
        @state.business_info["#{scope}"]["#{key}"] = value
      else
        @state.business_info["#{key}"] = value

      if scope == "entity" && key == "id"
        entity = @props.entities.filter((e) => e.id == value)[0]
        @state.business_info.vat.name = entity?.vat?.name
        @state.business_info.vat.percent = entity?.vat?.percent

      # setState
      @setState business_info: @state.business_info

    showRequired = (key, label) =>
      <div>
        <span className="error">*&nbsp;</span>{label}
      </div>

    errorInput = (key) =>
      "error-input" if @state.errors?.includes key

    <div className="User">
      <div className="VAT">
        <form>
          <List className="modal-list">
            <List.HeaderRow />
            <List.Row>
              <List.Cell className="setting">
                Contracting With
              </List.Cell>
              <List.Cell className="select">
                <ReactSelect
                  className='rate-selector'
                  options={{value: entity.id, label: "#{entity.company_name} (#{entity.country_code})"} for entity in @props.entities}
                  onChange={updateBusinessInfo.bind @, 'id', 'entity'}
                  value={@state.business_info.entity?.id}
                  disabled={@state.disabled}
                />
              </List.Cell>
            </List.Row>
            <List.Row>
              <List.Cell className="setting">
                {showRequired 'vat_name', 'Tax Name'}
              </List.Cell>
              <List.Cell className="select">
                <input
                  type="text"
                  className="form-control #{errorInput 'vat_name'}"
                  onChange={updateBusinessInfo.bind @, 'name', 'vat'}
                  value={@state.business_info.vat.name || ''}
                  disabled={@state.disabled}
                />
              </List.Cell>
            </List.Row>
            <List.Row>
              <List.Cell className="setting">
                {showRequired 'vat_percent', 'Tax Percent (%)'}
              </List.Cell>
              <List.Cell className="select">
                <input
                  type="text"
                  className="form-control #{errorInput 'vat_percent'}"
                  onChange={updateBusinessInfo.bind @, 'percent', 'vat'}
                  value={@vat_percent_value()}
                  disabled={@state.disabled}
                />
              </List.Cell>
            </List.Row>
            <List.Row>
              <List.Cell className="setting">
                {showRequired 'name', 'Company Name'}
              </List.Cell>
              <List.Cell className="select">
                <input
                  type="text"
                  className="form-control #{errorInput 'name'}"
                  onChange={updateBusinessInfo.bind @, 'name', 'business_info'}
                  value={@state.business_info.name || ''}
                  disabled={@state.disabled}
                />
              </List.Cell>
            </List.Row>
            <List.Row>
              <List.Cell className="setting">
                {showRequired 'address', 'Address 1'}
              </List.Cell>
              <List.Cell className="select">
                <input
                  type="text"
                  className="form-control #{errorInput 'address'}"
                  onChange={updateBusinessInfo.bind @, 'address', 'business_info'}
                  value={@state.business_info.address || ''}
                  disabled={@state.disabled}
                />
              </List.Cell>
            </List.Row>
            <List.Row>
              <List.Cell className="setting">
                Address 2
              </List.Cell>
              <List.Cell className="select">
                <input
                  type="text"
                  className="form-control #{errorInput 'address_secondary'}"
                  onChange={updateBusinessInfo.bind @, 'address_secondary', 'business_info'}
                  value={@state.business_info.address_secondary || ''}
                  disabled={@state.disabled}
                />
              </List.Cell>
            </List.Row>
            <List.Row>
              <List.Cell className="setting">
                {showRequired 'city', 'City'}
              </List.Cell>
              <List.Cell className="select">
                <input
                  type="text"
                  className="form-control #{errorInput 'city'}"
                  onChange={updateBusinessInfo.bind @, 'city', 'business_info'}
                  value={@state.business_info.city || ''}
                  disabled={@state.disabled}
                />
              </List.Cell>
            </List.Row>
            <List.Row>
              <List.Cell className="setting">
                {showRequired 'zip', 'ZIP / Postal Code'}
              </List.Cell>
              <List.Cell className="select">
                <input
                  type="text"
                  className="form-control #{errorInput 'zip'}"
                  onChange={updateBusinessInfo.bind @, 'zip', 'business_info'}
                  value={@state.business_info.zip || ''}
                  disabled={@state.disabled}
                />
              </List.Cell>
            </List.Row>
            <List.Row>
              <List.Cell className="setting">
                {showRequired 'country_code', 'Country of Business Location'}
              </List.Cell>
              <List.Cell className="select">
                <ReactSelect
                  className="rate-selector #{errorInput 'country_code'}"
                  options={country_options}
                  onChange={updateBusinessInfo.bind @, 'country_code', 'business_info'}
                  value={@state.business_info.country_code}
                  disabled={@state.disabled}
                />
              </List.Cell>
            </List.Row>
            <List.Row>
              <List.Cell className="setting">
                Province of Business Location
              </List.Cell>
              <List.Cell className="select">
                <ReactSelect
                  className="rate-selector #{errorInput 'province'}"
                  options={province_options}
                  onChange={updateBusinessInfo.bind @, 'province', 'business_info'}
                  value={@state.business_info.province}
                  disabled={@state.disabled}
                />
              </List.Cell>
            </List.Row>
            <List.Row>
              <List.Cell className="setting">
                {showRequired 'tax_id', 'Tax Number for Business Location'}
              </List.Cell>
              <List.Cell className="select">
                <input
                  type="text"
                  className="form-control #{errorInput 'tax_id'}"
                  onChange={updateBusinessInfo.bind @, 'tax_id', 'business_info'}
                  value={@state.business_info.tax_id || ''}
                  disabled={@state.disabled}
                />
              </List.Cell>
            </List.Row>
            <List.Row>
              <List.Cell className="setting">
                {showRequired 'vat_country_code', 'Event Location Country'}
              </List.Cell>
              <List.Cell className="select">
                <ReactSelect
                  className="rate-selector #{errorInput 'vat_country_code'}"
                  options={country_options}
                  onChange={updateBusinessInfo.bind @, 'country_code', 'vat'}
                  value={@state.business_info.vat.country_code}
                  disabled={@state.disabled}
                />
              </List.Cell>
            </List.Row>
            <List.Row>
              <List.Cell className="setting">
                Event Location Province
              </List.Cell>
              <List.Cell className="select">
                <ReactSelect
                  className="rate-selector #{errorInput 'vat_province'}"
                  options={province_options}
                  onChange={updateBusinessInfo.bind @, 'province', 'vat'}
                  value={@state.business_info.vat.province}
                  disabled={@state.disabled}
                />
              </List.Cell>
            </List.Row>
            <List.Row>
              <List.Cell className="setting">
                {showRequired 'vat_tax_id', 'Tax Number for Event Country'}
              </List.Cell>
              <List.Cell className="select">
                <input
                  type="text"
                  className="form-control #{errorInput 'vat_tax_id'}"
                  onChange={updateBusinessInfo.bind @, 'tax_id', 'vat'}
                  value={@state.business_info.vat.tax_id || ''}
                  disabled={@state.disabled}
                />
              </List.Cell>
            </List.Row>
            <List.Row>
              <List.Cell className="setting">
                {showRequired 'vat_name', 'Name for Tax on Universe Service Charge'}
              </List.Cell>
              <List.Cell className="select">
                <input
                  type="text"
                  className="form-control #{errorInput 'vat_name'}"
                  onChange={updateBusinessInfo.bind @, 'name', 'vat'}
                  value={@state.business_info.vat.name || ''}
                  disabled={@state.disabled}
                />
              </List.Cell>
            </List.Row>
            <List.Row>
              <List.Cell className="setting">
                {showRequired 'vat_percent', 'Tax Percent (%) on Universe Service Charge'}
              </List.Cell>
              <List.Cell className="select">
                <input
                  type="text"
                  className="form-control #{errorInput 'vat_percent'}"
                  onChange={updateBusinessInfo.bind @, 'percent', 'vat'}
                  value={@vat_percent_value()}
                  disabled={@state.disabled}
                />
              </List.Cell>
            </List.Row>
          </List>
          <br/>
          <div className='buttons'>
            <button className='btn btn-default' onClick={@cancel} disabled={@props.disabled}>Cancel</button>
            <button className='btn btn-primary' onClick={@save} disabled={@props.disabled}>
              {if @props.business_info then 'Save' else 'Create'}
            </button>
          </div>
        </form>
      </div>
    </div>
