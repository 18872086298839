import { graphql } from 'react-apollo'

import withdrawal       from '/models/withdrawal'

#
# Queries
#

import index        from './queries/index.gql'
import show         from './queries/show.gql'
import changes      from './queries/changes.gql'
import entities     from './queries/entities.gql'

export withWithdrawals = graphql index,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.withdrawals or {}
    withdrawals: Models.Withdrawal.parse nodes or []
    withdrawalsPagination: pagination
    withdrawalsLoading: data.loading

export withWithdrawal = graphql show,
  options: (props)->
    { id } = props.match.params
    variables:
      id: id
  props: ({props, data})->
    withdrawal: Models.Withdrawal.parse data.withdrawal
    withdrawalLoading: data.loading

export withChanges = graphql changes,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.withdrawal?.changes or {}
    changes: Models.Change.parse nodes or []
    changesPagination: pagination
    changesLoading: data.loading

export withEntities = graphql entities,
  props: ({props, data})->
    entities: Models.Entity.parse data.entities
    entitiesLoading: data.loading

#
# Mutations
#

import withdrawals_rpc              from '/mutations/withdrawals/rpc.gql'

export withWithdrawalsRPC = graphql withdrawals_rpc,
  props: ({ mutate })->
    withdrawals_rpc: (id, method)->
      {data: {transfer_rpc: {transfer, errors}}} = \
      await mutate variables: {id, method}
      {transfer, errors}
