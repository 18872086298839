export justifications = [
  "PROVIDING_PRODUCTS_AND_SERVICES",
  "EVENT_MANAGEMENT",
  "MARKET_RESEARCH_AND_AGGREGATED_ANALYTICS",
  "PREVENT_UNLAWFUL_BEHAVIOR",
  "MARKETING",
  "CLIENT_SERVICES",
  "STAFF_ADMINISTRATION"
]

export piiInfos = [
  "NAME",
  "ALIAS",
  "ADDRESS",
  "UNIQUE_IDENTIFIER",
  "IP_ADDRESS",
  "EMAIL",
  "PHONE",
  "ACCOUNT_NAME",
  "SOCIAL_SECURITY_NUMBER",
  "DRIVERS_LICENSE_NUMBER",
  "PASSPORT_NUMBER",
  "RACE",
  "ETHNICITY",
  "GENDER",
  "COMMERCIAL_INFORMATION",
  "RECORDS_OF_PROPERTY",
  "PRODUCTS_PROVIDED",
  "SERVICES_PROVIDED",
  "PURCHASING_HISTORIES_OR_TENDENCIES",
  "CONSUMING_HISTORIES_OR_TENDENCIES",
  "BIOMETRIC_DATA",
  "BROWSING_HISTORY",
  "SEARCH_HISTORY",
  "GEOLOCATION_DATA",
  "ELECTRONIC_INFORMATION",
  "OTHER"
]


export targets = [
  'AMPLITUDE',
  'GLOBEE',
  'HYPERWALLET',
  'EMBEDDED_THIRD_PARTY',
  'EMBEDDED_THIRD_PARTY',
  'SIFTSCIENCE',
  'STRIPE',
  'YESWARE',
  'ZENDESK'
]
