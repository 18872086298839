import React from 'react'
import './styles'

import flash from '/lib/flash'

import { Link } from 'react-router-dom'

# componennts
import Pager        from '/components/pager/history-pager'
import DropdownMenu from '/components/dropdown-menu'
import Spinner      from '/components/spinner'
import Datetime     from '/components/datetime'
import StateActions from '/components/state-label/actions'
import List         from '/components/list'
import Filters      from '/components/filters'
import Can          from '/components/can'
import UserCard     from '/components/user-card'
import NoteWarning  from '/components/note/warning'

import { role_options } from '/models/user'

import {
  withUsers,
  withUsersRPC
} from './queries'

export default \
withUsers \
withUsersRPC \
class Users extends React.Component

  rpc: (id, method)=>
    {user, errors} = await @props.users_rpc id, method
    flash.error errors if errors?.length

  render: ->
    <div className="Users">
      <header>
        <h1>Users</h1>
      </header>
      <section className="content">
        <Can action="list" subject="User">
          {@filters()}
          {@list()}
          {@pager()}
        </Can>
      </section>
    </div>

  filters: ->
    <Filters>
      <Filters.Search/>
      <Filters.Role options={role_options}/>
      <span className="pull-right">
        <Pager meta={@props.usersPagination} buffer={2}/>
      </span>
    </Filters>


  list: ->
    return <Spinner/> if @props.usersLoading
    <List>
      {@header()}
      {for user in @props.users
        <List.Row key={user._key}>
          <List.Cell className="id">
            <Link className="btn btn-default" to={"/users/#{user.slug}"}>
              More info
            </Link>
            <NoteWarning notes={user.related_notes}/>
          </List.Cell>
          <List.Cell className="user">
            <UserCard user={user} />
          </List.Cell>
          <List.Cell className="created">
            <Datetime date={user.created_at}/>
          </List.Cell>
          <List.Cell className="state">
            <StateActions
              model={user}
              onClick={@rpc.bind @, user.id}
            />
          </List.Cell>
        </List.Row>
      }
    </List>

  header: ->
    <List.HeaderRow>
      <List.Header className="id"></List.Header>
      <List.Header className="user">User</List.Header>
      <List.Header className="created">Created</List.Header>
      <List.Header className="state">State</List.Header>
    </List.HeaderRow>

  pager: ->
    <div className="text-center">
      <Pager meta={@props.usersPagination} buffer={4} />
    </div>
