import React from 'react'
import '../styles'

import flash from '/lib/flash'

import { Link } from 'react-router-dom'

import ReactSelect    from '/components/react-select'
import Spinner        from '/components/spinner'
import Modal          from 'react-bootstrap/lib/Modal'
import ModalState     from '/components/modal-state'
import Can            from '/components/can'
import List           from '/components/list'
import Filters        from '/components/filters'
import Pager          from '/components/pager/history-pager'
import Price          from '/components/price'
import UserCard       from '/components/user-card'
import StateActions   from '/components/state-label/actions'

import NewCode        from './new-code'

import CommissionCode from '/models/commission-code'

import FaPlus   from 'react-icons/lib/fa/plus'

import { currency_options } from '/models/commission-code'
import { state_options } from '/models/commission-code'
import { base_currencies } from '/lib/currencies'

import {
  withCommissionCodes
  withCommissionCodeCreate
  withCommissionCodeUpdate
  withCommissionCodeRPC
} from './queries'

export default \
withCommissionCodes \
withCommissionCodeCreate \
withCommissionCodeUpdate \
withCommissionCodeRPC \
class ItemCommissionCodes extends React.Component

  constructor: (props)->
    super arguments...
    @state =
      modalOpen:  false
      disabled: false

  clean_commission_code: (cc)->
    cc = CommissionCode.parse cc

    # ugh, figure this out properly
    cc.flat        = parseFloat(cc.flat) if cc.flat?
    cc.max         = parseFloat(cc.max)  if cc.max?
    cc.rebate_max  = parseFloat(cc.rebate_max) if cc.rebate_max?
    cc.rebate_flat = parseFloat(cc.rebate_flat)  if cc.rebate_flat?

    if cc.isValidForCreate
      cc
    else
      flash.warning 'You need to provide code, flat, percent, and at least one currency.'
      null

  commissionCodeUpdate: (cc)=>
    return unless cc = @clean_commission_code cc

    @setState disabled: true
    {commission_code, errors} = await @props.commissionCodeUpdate cc
    if errors?.length
      flash.error errors
    else
      flash.success "Commission Code saved"
    @setState disabled: false
    {commission_code, errors}

  commissionCodeCreate: (cc)=>
    return {} unless cc = @clean_commission_code cc

    @setState disabled: true
    {commission_code, errors} = await @props.commissionCodeCreate cc
    if errors?.length
      flash.error errors
    else
      flash.success "Commission Code created"
      @props.codesRefetch()
    @setState disabled: false
    {commission_code, errors}

  rpc: (id, method)->
      {commission_code, errors} = await @props.commission_code_rpc id, method
      if errors?.length
        flash.error errors
      else
        flash.success "CommissionCode updated successfully"

  render: ->
    @codes = @props.codes
    <div className="CommissionCodes">
      <header>
        <h1>Per Item Fees</h1>
        {@newCode()}
      </header>
      <section className="content">
        {@filters()}
        {@list()}
        {@pager()}
      </section>
    </div>

  toggle: =>
    @setState modalOpen: not @state.modalOpen

  newCode: ->
    <Can action='create' subject='CommissionCode'>
      <span>
        <button className='btn btn-default' onClick={@toggle}>
          <FaPlus/>
          {' '}
          New Code
        </button>
        <Modal
          show={@state.modalOpen}
          onHide={@toggle}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Create a new commission code
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='clearfix'>
            <NewCode
              save={@commissionCodeCreate}
              cancel={@toggle}
              disabled={@state.disabled}
            />
          </Modal.Body>
        </Modal>
      </span>
    </Can>

  filters: ->
    <Filters>
      <Filters.Search/>
      <Filters.Currency options={currency_options}/>
      <Filters.State options={state_options}/>
      <span className="pull-right">
        <Pager meta={@props.codesPagination} buffer={2}/>
      </span>
    </Filters>

  pager: ->
    <div className="text-center">
      <Pager meta={@props.codesPagination} buffer={4}/>
    </div>

  list: ->
    return <Spinner/> if @props.codesLoading
    <List>
      {@header()}
      {for code in @props.codes
        <List.Row key={code._key}>
          <List.Cell className="currency">
            {code.currency}
            {if code.country
              <span className="label label-info">{code.country}</span>
            }
          </List.Cell>
          <List.Cell className="code">
            {code.code}
          </List.Cell>
          <List.Cell className="flat">
            {<Price amount={code.flat} currency={code.currency} showCurrency={false}/> if code.flat}
            <small>
              {<Price amount={-code.rebate_flat} currency={code.currency} showCurrency={false} showFree={false}/> if code.rebate_flat > 0}
            </small>
          </List.Cell>
          <List.Cell className="max">
            {<Price amount={code.max} currency={code.currency} showCurrency={false} showFree={false} /> if code.max?}
            <small>
              {<Price amount={-code.rebate_max} currency={code.currency} showCurrency={false} showFree={false}/> if code.rebate_max?}
            </small>
          </List.Cell>
          <List.Cell className="percent">
            {code.actual_percent}
            <small>
              {"(#{code.actual_rebate_percent})" if code.actual_rebate_percent?}
              {"(#{code.actual_rebate_cost_item_percent} of ticket)" if code.actual_rebate_cost_item_percent?}
            </small>
          </List.Cell>
          <List.Cell className="broker_fee">
            {code.actual_broker_fee}
          </List.Cell>
          <List.Cell className="rebate_user_id">
            <UserCard user={code.rebate_user} />
          </List.Cell>
          <List.Cell className="actions">
            <StateActions
              model={code}
              onClick={@rpc.bind @, code.id}
            />
          </List.Cell>
          <Can action='update' subject='CommissionCode'>
            <List.Cell className="actions">
              {@editModal code}
            </List.Cell>
          </Can>
        </List.Row>
      }
    </List>

  header: ->
    <List.HeaderRow>
      <List.Header className="currency">Currency</List.Header>
      <List.Header className="code">Code</List.Header>
      <List.Header className="flat">Flat</List.Header>
      <List.Header className="max">Max</List.Header>
      <List.Header className="percent">Percent</List.Header>
      <List.Header className="broker_fee">CC Fee</List.Header>
      <List.Header className="rebate_user_id">Rebate User</List.Header>
      <List.Header className="actions"></List.Header>
      <Can action='update' subject='CommissionCode'>
        <List.Header className="actions"></List.Header>
      </Can>
    </List.HeaderRow>

  editModal: (code)->
    <ModalState
      render={({open, toggle})=>
        <span>
          <button
            className="btn btn-default"
            onClick={toggle}
          >
            Edit
          </button>

          <Modal
            show={open}
            onHide={toggle}
            className="test"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Edit
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='clearfix'>
              <NewCode
                save={@commissionCodeUpdate}
                cancel={toggle}
                commission_code={code}
                disabled={@state.disabled}
              />
            </Modal.Body>
          </Modal>
        </span>
      }/>
