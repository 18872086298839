import React from 'react'

# components
import ReactSelect  from '/components/react-select'
import Spinner      from '/components/spinner'

import { withTicketmasterAttractions } from './queries'

export default \
withTicketmasterAttractions \
class SearchResults extends React.Component
  constructor: ->
    super arguments...
    @state =
      attraction: undefined

  setTicketmasterAttraction: (evt)=>
    return unless id = evt?.value
    for attraction in @props.ticketmaster_attractions when attraction.discovery_id is id
      @setState { attraction }
      @props.changeAttraction attraction
      return

  render: ->
    <div className="AttractionSearchResults">
      { if @props.ticketmaster_attractions.length > 0
        <label>Search Results:
          <ReactSelect
            className="filter-selector state-selector"
            options={value: cur.discovery_id, label: cur.name for cur in @props.ticketmaster_attractions}
            value={@state.attraction?.discovery_id}
            placeholder="Select from Results"
            onChange={@setTicketmasterAttraction}
          />
        </label>
      else if @props.ticketmasterAttractionsLoading
        <Spinner />
      else
        <div>No Results Found</div>
      }
    </div>
