import React from 'react'

import flash from '/lib/flash'

import { Link } from 'react-router-dom'

# stores
import { state_options } from '/models/cost-item'

# components
import ExternalLink   from '/components/external-link'
import ListingPhoto   from '/components/listing-photo'
import Pager          from '/components/pager/history-pager'
import Spinner        from '/components/spinner'
import DropdownMenu   from '/components/dropdown-menu'
import Datetime       from '/components/datetime'
import StateActions   from '/components/state-label/actions'
import Filters        from '/components/filters'
import UserCard       from '/components/user-card'
import List           from '/components/list'
import Price          from '/components/price'


import {
  withCostItems
  withCostItemsRPC
} from './queries'

export default \
withCostItems \
withCostItemsRPC \
class CostItems extends React.Component
  rpc: (id, method)=>
    {cost_item, errors} = await @props.cost_items_rpc id, method
    flash.error errors if errors?.length

  render: ->
    return <Spinner/> if @props.cost_itemsLoading
    <div className="CostItems">
      {@filters()}
      <div className="cost-items">
        {@list()}
      </div>
    </div>

  filters: ->
    <Filters>
      <Filters.Search/>
      <Filters.State options={state_options}/>
      <span className="pull-right">
        <Pager meta={@props.cost_itemsPagination} buffer={2}/>
      </span>
    </Filters>

  list: ->
    return <Spinner/> if @props.cost_itemsLoading
    <List>
      {for cost_item in @props.cost_items
        <List.Row key={cost_item._key}>
          <List.Cell className="id">
            <Link className="btn btn-default" to={"/cost_items/#{cost_item.id}"}>
              More info
            </Link>
          </List.Cell>
          <List.Cell className="qr-code">
            {cost_item.qr_code}
          </List.Cell>
          <List.Cell className="user">
            <UserCard user={cost_item.user} />
          </List.Cell>
          <List.Cell className="rate">
            {cost_item.rate?.name}
          </List.Cell>
          <List.Cell className="price">
            <Price amount={cost_item.src_price} currency={cost_item.src_currency}/>
          </List.Cell>
          <List.Cell className="created-at">
            <Datetime date={cost_item.created_at}/>
          </List.Cell>
          <List.Cell className="actions">
            <StateActions
              model={cost_item}
              onClick={@rpc.bind @, cost_item.id}
            />
          </List.Cell>
        </List.Row>
      }
    </List>
