import React from 'react'
import PropTypes from 'prop-types'

import humanize from 'underscore.string/humanize'

import FeeCondition from '/models/fee-condition'
import FaTrashO from 'react-icons/lib/fa/trash-o'

export default \
class Condition extends React.Component
  @propTypes =
    feeCondition: PropTypes.object
    onChange: PropTypes.func

  constructor: (props) ->
    super arguments...
    feeCondition: FeeCondition.parse @props.condition or {}

  render: ->
    <div className="form-group condition #{if @props.hasError then 'has-error'}">
      <label className="control-label required property-name">
        {humanize(@props.property)}
      </label>
      <div className="comparison">
        {@props.comparisonComponent}
      </div>
      <div className={"value #{@props.removable && 'without-trash'}"}>
        {@props.valueComponent}
      </div>
      {
        @props.removable && <div className="trash-can">
          <FaTrashO onClick={@props.removeCondition} />
        </div>
      }
    </div>
