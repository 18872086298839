import React from 'react'
import './styles'

import flash from '/lib/flash'

import { Link } from 'react-router-dom'

# componennts
import UserCard       from '/components/user-card'
import Pager          from '/components/pager/history-pager'
import DropdownMenu   from '/components/dropdown-menu'
import Datetime       from '/components/datetime'
import Filters        from '/components/filters'
import List           from '/components/list'
import Spinner        from '/components/spinner'
import StateLabel     from '/components/state-label'
import StateActions   from '/components/state-label/actions'
import Price          from '/components/price'
import withSession    from '/lib/with-session'
import Can            from '/components/can'


import FaCloudDownload  from 'react-icons/lib/fa/cloud-download'

import { state_options } from '/models/service-invoice'

import { withServiceInvoices, withServiceInvoicesRPC } from './queries'

export default \
withServiceInvoices \
withServiceInvoicesRPC \
withSession \
class ServiceInvoices extends React.Component

  rpc: (id, method)->
    promise = if method == 'pay'
      if confirm 'Are you sure this invoice has been paid in Stripe?'
        @props.service_invoices_rpc id, method
    else
      @props.service_invoices_rpc id, method

    promise.then (data)->
      flash.success "Service Invoice updated successfully"
    .catch (err)->
      flash.error "Service Invoice update failed"

  render: ->
    <div className="ServiceInvoices">
      <header>
        <h1>Service Invoices</h1>
      </header>
      <section className="content">
        {@filters()}
        {@list()}
        {@pager()}
      </section>
    </div>

  filters: ->
    <Filters>
      <Filters.Search/>
      <Filters.State options={state_options}/>
      <span className="pull-right">
        <Pager meta={@props.serviceInvoicesPagination} buffer={2}/>
      </span>
    </Filters>


  list: ->
    return <Spinner/> if @props.servicesInvoicesLoading
    <List>
      {@header()}
      {for serviceInvoice in @props.serviceInvoices
        <List.Row key={serviceInvoice._key}>
          <List.Cell className="id id-col">
            <samp className="btn btn-default" title={serviceInvoice.id}>
              More info
            </samp>
          </List.Cell>
          <List.Cell className="user user-col">
            <UserCard user={serviceInvoice.user} />
          </List.Cell>
          <List.Cell className="currency currency-col">
            <div>
              {serviceInvoice.currency}
            </div>
          </List.Cell>
          <List.Cell className="amount">
            {if serviceInvoice.formattedPrice
              serviceInvoice.formattedPrice
            }
          </List.Cell>
          <List.Cell className="invoice-url invoice-url-col">
            {if serviceInvoice.invoice_url
              <a href={serviceInvoice.invoice_url} target="_blank">
                ...{serviceInvoice?.invoice_url?.match(/invst(.*)/)?[0]}
              </a>
            }
          </List.Cell>
          <List.Cell className="created-at created-at-col">
            <Datetime date={serviceInvoice.created_at}/>
          </List.Cell>

          <List.Cell className="actions">
            <Can action='create' subject='ServiceInvoice'>
              <StateActions
                model={serviceInvoice}
                onClick={@rpc.bind @, serviceInvoice.id}
              />
            </Can>
            <Can.Not action='create' subject='ServiceInvoice'>
              <StateLabel model={serviceInvoice}/>
            </Can.Not>
          </List.Cell>

        </List.Row>
      }
    </List>

  header: ->
    <List.HeaderRow>
      <List.Header className="id">id</List.Header>
      <List.Header className="user">User</List.Header>
      <List.Header className="currency">Currency</List.Header>
      <List.Header className="amount">Amount Due</List.Header>
      <List.Header className="invoice-url">Invoice URL</List.Header>
      <List.Header className="created-at">Created At</List.Header>
      <List.Header className="actions">Actions</List.Header>
    </List.HeaderRow>

  pager: ->
    <div className="text-center">
      <Pager meta={@props.serviceInvoicesPagination} buffer={4}/>
    </div>
