# lifted from `ms`

S = 1
M = S * 60
H = M * 60
D = H * 24
W = D * 7
Y = D * 365.25

export default (str)->
  str = String str
  return if str.length > 100

  return unless match = ///
    ^
    # num
    (-?(?:\d+)?\.?\d+)
    # space?
    \s*
    # unit
    (
      seconds?|secs?|s|
      minutes?|mins?|m|
      hours?|hrs?|h|
      days?|d|
      weeks?|w|
      years?|yrs?|y
    )?
    $
    ///i.exec str

  [str, num, unit='s'] = match

  return unless num = parseFloat num

  switch
    when /seconds?|secs?|s/i.test unit
      num * S
    when /minutes?|mins?|m/i.test unit
      num * M
    when /hours?|hrs?|h/i.test unit
      num * H
    when /days?|d/i.test unit
      num * D
    when /weeks?|w/i.test unit
      num * W
    when /years?|yrs?|y/i.test unit
      num * Y
    else
      undefined
