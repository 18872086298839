import React from 'react'

import {
  withListing
  withUpdateSeatsChartKey
  withUpdateSeatsCapacity
  withRates
  withCreateSeatsSubaccount
  withOrganization
} from './queries'

# components
import Spinner                  from '/components/spinner'

# local components
import RateTable                from './components/seats/rate-table'
import FormChartKey             from './components/seats/form-link-chart-key'
import ButtonSync               from './components/seats/button-sync'
import ButtonCreateSubaccount   from './components/seats/button-create-subaccount'
import SeatsInfo                from './components/seats/seats-info'
import SeatsChecklist           from './components/seats/seats-checklist'


export default \
withListing \
withUpdateSeatsChartKey \
withUpdateSeatsCapacity \
withRates \
withCreateSeatsSubaccount \
withOrganization \
class Seats extends React.Component
  render: ->
    return <Spinner /> if @props.listingLoading or @props.ratesLoading

    <div className="Seats">
      <SeatsChecklist
        subaccountValid={@props.organization.seats_subaccount_valid}
        chartKeyLinked={@props.listing.seats_chart_key}
      />
      { if @props.organization.seats_subaccount_valid then @withSubaccount() else @withoutSubaccount() }
    </div>

  # helpers
  renderActions: ->
    <h1 className="actions">ACTIONS: </h1>

  renderDetails: ->
    <SeatsInfo
      seats_designer_key={@props.organization.seats_designer_key}
      seats_subaccount_id={@props.organization.seats_subaccount_id}
      seats_chart_key={@props.listing.seats_chart_key}
    />

  withSubaccount: ->
    <section>
      {@renderActions()}
      {if @props.listing.seats_chart_key
        @actionsWithChart()
      else
        @actionsWithoutChart()
      }
      <hr />
      {@renderDetails()}
    </section>

  withoutSubaccount: ->
    <section>
      {@renderActions()}
      {@actionsWithoutAccount()}
      <hr />
      {@renderDetails()}
    </section>

  actionsWithoutAccount: ->
    <span>
      <ButtonCreateSubaccount
        userId={@props.listing.user.id}
        createSeatsSubaccount={@props.createSeatsSubaccount}
      />
    </span>

  actionsWithChart: ->
    <span>
      <ButtonSync
        listingId={@props.listing.id}
        updateSeatsCapacity={@props.updateSeatsCapacity}
      />
      <hr />
      <RateTable
        rates={@props.rates}
      />
    </span>

  actionsWithoutChart: ->
    <span>
      <FormChartKey
        listingId={@props.listing.id}
        updateChartKey={@props.updateChartKey}
      />
    </span>
