import React from 'react'

import List from '/components/list'

export default \
class RateTable extends React.Component
  render: ->
    <List>
      <List.HeaderRow className="top">
        <List.Header>
          Rates
        </List.Header>
      </List.HeaderRow>
      <List.HeaderRow>
        <List.Header className="id">
          ID
        </List.Header>
        <List.Header className="name">
          Name
        </List.Header>
        <List.Header className="capacity">
          Capacity
        </List.Header>
      </List.HeaderRow>

      { for rate, index in @props.rates
        <List.Row key={rate.id}>
          <List.Cell className="id">
            {rate.id}
          </List.Cell>
          <List.Cell className="name" title={rate.name}>
            {rate.name}
          </List.Cell>
          <List.Cell className="capacity">
            {rate.capacity}
          </List.Cell>
        </List.Row> }
    </List>
