import React from 'react'
import './styles'

import Datetime from '/components/datetime'
import Na       from '/components/na'

export default \
class ViewDetailsContent extends React.Component
  render: ->
    w = @props.withdrawal

    <div className="WithdrawalModalContent">
      <form className="form-horizontal">
        <div className="form-group">
          <label className="control-label">Address:</label>
          <p className="form-control-static">{w.address}</p>
        </div>

        <div className="form-group">
          <label className="control-label">City:</label>
          <p className="form-control-static">{w.city}</p>
        </div>

        <div className="form-group">
          <label className="control-label">Postal Code:</label>
          <p className="form-control-static">{w.postal_code}</p>
        </div>

        <div className="form-group">
          <label className="control-label">Province:</label>
          <p className="form-control-static">{w.province}</p>
        </div>

        <div className="form-group">
          <label className="control-label">Country:</label>
          <p className="form-control-static">{w.country}</p>
        </div>

        <div className="form-group">
          <label className="control-label">Approved at:</label>
          <p className="form-control-static">
            {if w.approved_at
              <Datetime date={w.approved_at} format=' MMM dd, yyyy h:mm A' />
            else
              <Na/>
            }
          </p>
        </div>

        <div className="form-group">
          <label className="control-label">Withdrawal ID:</label>
          <p className="form-control-static">{w.id}</p>
        </div>

        <div className="form-group">
          <label className="control-label">HyperWallet ID:</label>
          <p className="form-control-static">{w.hyperwallet_token or <Na/>}</p>
        </div>

        <div className="form-group">
          <label className="control-label">HyperWallet User ID:</label>
          <p className="form-control-static">{w.hyperwallet_user_token or <Na/>}</p>
        </div>

        <div className="form-group">
          <label className="control-label">Error:</label>
          <p className="form-control-static">
            <pre>{w.withdrawal_error}</pre>
          </p>
        </div>
      </form>

    </div>
