import React from 'react'
import './styles'

import flash from '/lib/flash'

import { Link, Route, Switch, Redirect } from 'react-router-dom'

import DocumentTitle from 'react-document-title'

# routes
import Summary          from './summary'
import Events           from './events'
import Rates            from './rates'
import Duplication      from './duplication'
import Changes          from './changes'
import Emails           from './emails'
import Ticketmaster     from './ticketmaster'
import Seats            from './seats'
import BinRestrictions  from './bin_restrictions'
import Kickback         from './kickback'
import Notes            from './notes'
import Import           from './import'
import DiscountCodes    from './discount_codes'
import AccessKeys       from './access_keys'
import Taxes            from './taxes'

# components
import { Tabs, TabLink, TabContent } from '/components/tabs'

import UserAside        from '/components/user-aside'
import ExternalLink     from '/components/external-link'
import Spinner          from '/components/spinner'
import StateActions     from '/components/state-label/actions'
import Can              from '/components/can'

import {
  withListing
  withRPC
  withActiveNotes
} from './queries'

import withAbility      from '/lib/with-ability'

export default \
withActiveNotes \
withListing \
withRPC \
withAbility \
class Listing extends React.Component

  rpc: (method)=>
    {listing, errors} = await @props.rpc @props.listing.id, method
    if errors?.length
      flash.error errors
    else
      flash.success "Listing updated successfully"

  render: ->
    @listing = @props.listing
    return <Spinner/> if @props.listingLoading or not @listing
    <div className="Listing">
      <DocumentTitle title={"#{@listing.title}"}/>
      <header>
        <h1>
          {@listing.title}
          <ExternalLink url={@listing.url}/>
          <StateActions
            className="pull-right"
            model={@listing}
            onClick={@rpc}
            position="left"
          />
          <div className="spam-button">
            {
              if @listing.spam_reviewed
                <div>
                  <button className="btn btn-xs btn-warning" onClick={@rpc.bind @, 'unmark_as_spam!'}>
                    Unmark as spam
                  </button>
                </div>
              else
                <div>
                  <button className="btn btn-xs btn-warning" onClick={@rpc.bind @, 'mark_as_spam!'}>
                    Mark as spam
                  </button>
                </div>
            }
          </div>
        </h1>
      </header>
      <main>

        <section className="content">
          <Tabs>
            <TabLink to={"#{@props.match.url}/summary"}>Summary</TabLink>
            <TabLink to={"#{@props.match.url}/events"}>
              Events <span className="badge">{@listing.num_events}</span>
            </TabLink>
            <TabLink to={"#{@props.match.url}/emails"}>Emails</TabLink>
            <TabLink to={"#{@props.match.url}/rates"}>
              Service Fees <span className="badge">{@listing.num_rates}</span>
            </TabLink>
            <TabLink to={"#{@props.match.url}/discount_codes"}>Discount Codes</TabLink>
            <TabLink to={"#{@props.match.url}/access_keys"}>Access Keys</TabLink>
            <TabLink to={"#{@props.match.url}/notes"}>
              Notes <span className="badge"> {@props.notes.length} </span>
            </TabLink>
            <Can action='duplicate' subject='Listing'>
              {<TabLink to={"#{@props.match.url}/duplication"}>Duplication</TabLink> unless @listing.rate_type is 'pass'}
            </Can>
            <TabLink to={"#{@props.match.url}/changes"}>Changes</TabLink>
           {<TabLink to={"#{@props.match.url}/ticketmaster"}>Ticketmaster</TabLink> if @props.can 'manage_tm_venue', 'Listing'}
           {<TabLink to={"#{@props.match.url}/seats"}>Seats</TabLink> if @props.can 'manage_seats', 'Listing'}
           {<TabLink to={"#{@props.match.url}/bin_restrictions"}>BIN Restriction</TabLink> if @props.can 'manage_bins', 'Listing'}
           {<TabLink to={"#{@props.match.url}/kickback"}>Fee Breakdown</TabLink> if @props.can 'manage_kickbacks', 'Listing'}
            <TabLink to={"#{@props.match.url}/taxes"}>Taxes</TabLink>
            {<TabLink to={"#{@props.match.url}/import"}>Import Tickets</TabLink> if @props.can 'import_tickets', 'Listing'}

            <TabContent>
              <Switch>
                <Route path='/listings/:id/summary'            component={Summary}/>
                <Route path='/listings/:id/events'             component={Events}/>
                <Route path='/listings/:id/emails'             component={Emails}/>
                <Route path='/listings/:id/rates'              component={Rates}/>
                <Route path='/listings/:id/discount_codes'     component={DiscountCodes}/>
                <Route path='/listings/:id/access_keys'        component={AccessKeys}/>
                <Route path='/listings/:id/notes'              component={Notes}/>
                <Route path='/listings/:id/duplication'        component={Duplication}/>
                <Route path='/listings/:id/changes'            component={Changes}/>
               {<Route path='/listings/:id/ticketmaster'       component={Ticketmaster}/>     if @props.can 'manage_tm_venue', 'Listing'}
               {<Route path='/listings/:id/seats'              component={Seats}/>            if @props.can 'manage_seats', 'Listing'}
               {<Route path='/listings/:id/bin_restrictions'   component={BinRestrictions}/>  if @props.can 'manage_bins', 'Listing'}
               {<Route path='/listings/:id/kickback'           component={Kickback}/>         if @props.can 'manage_kickbacks', 'Listing'}
                <Route path='/listings/:id/taxes'              component={Taxes}/>
               {<Route path='/listings/:id/import'             component={Import}/>           if @props.can 'import_tickets', 'Listing'}
                <Redirect from="#{@props.match.url}" to="#{@props.match.url}/summary" />
              </Switch>
            </TabContent>
          </Tabs>
        </section>
        <UserAside id={@listing.user?.id}/>
      </main>
    </div>
