import React from 'react'

import { graphql } from 'react-apollo'

export default \
class ModalState extends React.Component
  constructor: (props)->
    super arguments...
    @state =
      open: false

  toggle: =>
    @setState open: not @state.open

  render: ->
    @props.render open: @state.open, toggle: @toggle

#
# Usage:
#
###
  render: ->

    <ModalState render={({open, toggle})->
      [
        <button onClick={toggle}>
          open modal!
        </button>

        <Modal
          show={open}
          onHide={toggle}
        >

          <Modal.Header closeButton>
            <Modal.Title>
              My Cool Modal
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='clearfix'>
            Awesome modal content!
          </Modal.Body>

        </Modal>
      ]
    }/>
###
