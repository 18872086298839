import React from 'react'
import PropTypes from 'prop-types'

import { country_code_options } from '/models/commission-code'

import ReactSelect  from '/components/react-select'

export default \
class CountrySelect extends React.Component

  render: ->
    <ReactSelect
      className="filter-selector state-selector"
      options={country_code_options}
      multi={false}
      clearable={false}
      onChange={(event) => @props.onChange(event.value)}
      value={@props.value}
      placeholder="Select one"
    />
