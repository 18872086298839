import redux, { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'

import rootReducer from '/reducers'

middleware = [ thunk ]
unless process.env.NODE_ENV is 'production'
  middleware.push createLogger()

export default createStore rootReducer, {}, applyMiddleware middleware...
