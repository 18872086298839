import React from 'react'
import PropTypes from 'prop-types'
import './styles'

import currencies from '/lib/currencies'
import { formatMoney } from 'accounting'

export default \
class Price extends React.Component
  @propTypes:
    amount:       PropTypes.number
    currency:     PropTypes.string.isRequired
    showCurrency: PropTypes.bool
    showFree:     PropTypes.bool

  @defaultProps:
    showCurrency: true
    showFree: true

  symbol: =>
    str = @props.currency
    currencies[str]?.replace(str, '') or str

  render: ->
    return null unless @props.currency
    <span className="Price">
      <span className="amount">
        {if @props.amount || !@props.showFree
          formatMoney @props.amount, symbol: @symbol(), format: { pos: "%s %v", neg: "(%s %v)", zero: "%s %v" }
        else
         'Free'
        }
      </span>
      {if @props.amount and @props.showCurrency
        <div className="currency">
          {@props.currency}
        </div>
      }
    </span>
