import React from 'react'
import PropTypes from 'prop-types'
import './styles'

# actions
import { flashDismiss } from '/actions/flash'

# components
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'

export default \
class Flash extends React.Component
  @propTypes:
    messages: PropTypes.array.isRequired

  render: ->
    <div id="flash-messages">
      <CSSTransitionGroup
        transitionName="flash"
        transitionEnterTimeout={200}
        transitionLeaveTimeout={200}
      >
        {@message(msg) for msg in @props.messages}
      </CSSTransitionGroup>
    </div>

  message: (msg)->
    <div
      key={msg.id}
      className="flash-message #{msg.level}"
      onClick={flashDismiss.bind null, msg.id}
    >
      {msg.content}
    </div>
