export stripZone = (str)->
  if str?.length
    parsed_date = str.match /\d{2,4}/g

    # support date strings without times (i.e. yyyy-MM-dd); assume midnight
    if parsed_date.length == 3
      parsed_date[3] = parsed_date[4] = parsed_date[5] = 0

    # IMPORTANT: initialize the base date object to the 1st of the month.
    # by default, it will initialize to Today, but this causes bugs on the last day of
    # months with 31 days, when the next month has 30 days.
    # i.e. on Oct 31, parsing a November date pushes it +1 month into December,
    # since assigning date=31, month=10 (november) results in December 1st.
    ret = new Date(2001, 0, 1, 0, 0, 0)
    for key, index in ['FullYear','Month','Date','Hours','Minutes','Seconds']
      if key == 'Month'
        # months are zero-indexed because JavaScript™
        ret["set#{key}"](parseInt(parsed_date[index], 10) - 1)
      else
        ret["set#{key}"](parsed_date[index])
    ret
