import React from 'react'
import PropTypes from 'prop-types'

export default \
class CreatePartnershipPaymentBtn extends React.Component
  @propTypes:
    currencyCode:     PropTypes.string.isRequired
    onClick:          PropTypes.func.isRequired

  onClick: =>
    @props.onClick(@props.currencyCode)

  render: ->
    { children, className=''} = @props
    <button className="#{className} partnership-payment-btn" onClick={@onClick}>
      {children}
    </button>
