import React from 'react'
import PropTypes from 'prop-types'
import './styles'

import humanize from 'underscore.string/humanize'
import Model from '/lib/model'

import DropdownButton   from 'react-bootstrap/lib/DropdownButton'
import MenuItem         from 'react-bootstrap/lib/MenuItem'
import StateLabel       from './index'

import withAbility    from '/lib/with-ability'

export default \
withAbility \
class StateActions extends React.Component
  constructor: ->
    super arguments...
    @state =
      inFlight: false

  @propTypes:
    model:    PropTypes.instanceOf(Model).isRequired
    onClick:  PropTypes.func.isRequired
    position: PropTypes.oneOf ['right', 'left', null]

  @defaultProps:
    position: 'right'


  onClick: (method, name, verify=false)->
    return unless confirm "#{name}?" if verify

    @setState inFlight: true

    @props.onClick method
    .then =>
      @setState inFlight: false
    .catch =>
      @setState inFlight: false

  render: ->
    cn = @props.model.constructor.className
    { className, model: { state, transitions } } = @props
    title = if @state.inFlight then 'saving...' else humanize state

    attrs = {}
    attrs.pullRight = true if @props.position is 'right'

    if Object.keys(transitions)?.length
      <DropdownButton
        {attrs...}
        id={"#{cn}-#{@props.model._key}-actions"}
        className="StateActions #{cn} #{state} #{className}"
        title={title}
      >
        {for action, name of transitions
          if action is '---'
            <MenuItem key={name} divider />
          else
            [_, method, verify] = action.match /([a-z_]+)(!)?/
            has_permission = @props.can method, cn
            onclick = @onClick.bind(@, method, name, !!verify) if has_permission

            <MenuItem
              key={method}
              onClick={onclick}
              disabled={not has_permission}
            >
              {name}
            </MenuItem>
        }
      </DropdownButton>
    else
      <StateLabel {@props...}/>
