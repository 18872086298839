import Model from '/lib/model'
import currencies, { base_currencies, base_currency_names } from '/lib/currencies'

export default \
class PartnershipPayment extends Model
  @className: 'PartnershipPayment'
  #   @amount         = null # 25.00
  #   @description    = null # "CAP495" - capped at 255 chars in boxoffice
  #   @currency       = null # "USD"
  #   @user_id        = null # "USD"
  #   @created_at     = null # 14th June 2018

  # do we have enough info to create this code?
  @computed 'isValidForCreate', ->
    # ensure description isn't too long
    return false unless @description?.length <= 255
    # ensure all fields have been filled out
    @amount and @description and @currency and @user_id

  @computed 'currency_name', ->
    for pair in base_currency_names
      if @currency == pair.currencyCode
        return pair.currencyName

# export base_currencies
export base_currency_options = value: cur, label: cur for cur in base_currencies
