import React from 'react'
import PropTypes from 'prop-types'
import './styles'

import flash from '/lib/flash'

# components
import { Link }     from 'react-router-dom'
import Translator   from '/components/translator'
import Avatar       from '/components/avatar'
import ListingPhoto from '/components/listing-photo'
import StateLabel   from '/components/state-label'
import Can          from '/components/can'
import FraudFlag    from '/components/fraud-flag'

import {
  withListing
  withListingsRPC
} from './queries'

export default \
withListing \
withListingsRPC \
class ListingAside extends React.Component
  @propTypes:
    listing:  PropTypes.object

  #
  # actions
  #
  rpc: (id, method) ->
    {listing, errors} = await @props.listings_rpc id, method
    if errors?.length
      flash.error errors
    else
      flash.success "Listing updated successfully"

  headerStyle: ->
    backgroundImage: "url(#{@listing.cover_photo.url})" if @listing.cover_photo?.url

  render: ->
    return null if @props.listingLoading
    @listing = @props.listing
    <aside className="ListingAside">
      <header className="listing-header" style={@headerStyle()}>
        <div className="overlay">
          <Link to={"/listings/#{@listing.id}"}>
            <ListingPhoto listing={@listing} size={50}/>
            <h2>
              {@listing.title}
              <FraudFlag value={@listing.failed_orders_percentage}/>
            </h2>
          </Link>
          <StateLabel model={@listing}/>
        </div>
      </header>

      {@listingInfo()}

    </aside>


  listingInfo: ->
    @listing = @props.listing
    <section className="listing-info" title="listing info">
      <dl>

        <dt>ID</dt>
        <dd className="mono">
          {@listing.id}
        </dd>

        <dt>Slug</dt>
        <dd className="mono">
          {@listing.slug}
        </dd>

        <dt>Currency</dt>
        <dd>
          {@listing.base_currency}
        </dd>

        <dt>Payment Provider</dt>
        <dd>
          {@listing.paymentProvider}
        </dd>

        <Can action='enable_sepa' subject='Listing'>
          <dd>
          {
            unless @listing.sepa
              <div>
                <button className="btn btn-xs btn-success" onClick={@rpc.bind @, @listing.id, 'enable_sepa!'}>
                  Enable SEPA
                </button>
              </div>
            else
              <div>
                <button className="btn btn-xs btn-warning" onClick={@rpc.bind @, @listing.id, 'disable_sepa!'}>
                  Disable SEPA
                </button>
              </div>
          }
          </dd>
        </Can>

        <dt>Display Host Contact Details</dt>
        <dd>
          <Translator value={@listing.user.messageable}/>
        </dd>

      </dl>
    </section>

