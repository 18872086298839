import React from 'react'

import flash from '/lib/flash'

#assets
import { Link } from 'react-router-dom'

import { categories } from '/models/subscription'

# components
import Modal    from 'react-bootstrap/lib/Modal'
import Spinner  from '/components/spinner'
import List     from '/components/list'
import humanize from 'underscore.string/humanize'
import titleize from 'underscore.string/titleize'

import { withUser, withSubscriptions, withUpdateSubscriptions } from './queries'

export default \
withUser \
withSubscriptions \
withUpdateSubscriptions \
class Subscriptions extends React.Component

  toggleSubscripton: (sub)=>
    {errors} = await @props.update_subscriptions @props.user.id, sub.id, !sub.is_subscribed
    if errors?.length
      flash.error errors
    else
      flash.success "Email Preferences updated successfully"

  ##renders
  render: ->
    <div className="Subscriptions">
      <h2>Email Subscriptons</h2>
      {@list()}
    </div>

  list: ->
    return <Spinner /> if @props.subscriptionsLoading

    <div>
      {for category in categories
        <div key={category}>
          <h3>{titleize humanize category}</h3>
          {for sub in @props.subscriptions when category is sub.category
            <div key={sub.id} className="Subscription">
              <input
                id={sub.id}
                type="checkbox"
                defaultChecked={sub.is_subscribed}
                onChange={@toggleSubscripton.bind @, sub}
              />
              <label htmlFor={sub.id}>{sub.label}</label>
            </div>
          }
        </div>
      }
    </div>
