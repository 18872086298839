import React from 'react'
import './styles'

import { Link } from 'react-router-dom'

# componennts
import Pager        from '/components/pager/history-pager'
import Spinner      from '/components/spinner'
import Datetime     from '/components/datetime'
import List         from '/components/list'
import Price        from '/components/price'
import Filters      from '/components/filters'
import UserCard     from '/components/user-card'

import { withAdvances } from './queries'
import { base_currency_options } from '/models/advance'

export default \
withAdvances \
class Advances extends React.Component

  render: ->
    <div className="Advances">
      <header>
        <h1>Advances</h1>
      </header>
      <section className="content">
        {@filters()}
        {@list()}
        {@pager()}
      </section>
    </div>

  filters: ->
    <Filters>
      <Filters.Search placeholder="Search Advance ID or Currency"/>
      <Filters.Currency options={base_currency_options}/>
      <span className="pull-right">
        <Pager meta={@props.advancesPagination} buffer={2}/>
      </span>
    </Filters>

  list: ->
    return <Spinner/> if @props.advancesLoading
    <List>
      {@header()}
      {for advance in @props.advances
        <List.Row key={advance._key}>
          <List.Cell className="id">
            {advance.id}
          </List.Cell>
          <List.Cell className="user">
            <UserCard user={advance.user} />
          </List.Cell>
          <List.Cell className="description">
            {advance.description}
          </List.Cell>
          <List.Cell className="amount">
            <Price amount={advance.amount} currency={advance.currency}/>
          </List.Cell>
          <List.Cell className="created-at">
            <Datetime date={advance.created_at}/>
          </List.Cell>
        </List.Row>
      }
    </List>

  header: ->
    <List.HeaderRow>
      <List.Header className="id">id</List.Header>
      <List.Header className="user">User</List.Header>
      <List.Header className="description">Description</List.Header>
      <List.Header className="amount">Amount</List.Header>
      <List.Header className="created-at">Created At</List.Header>
    </List.HeaderRow>

  pager: ->
    <div className="text-center">
      <Pager meta={@props.advancesPagination} buffer={4}/>
    </div>
