import React from 'react'
import PropTypes from 'prop-types'
import Price     from '/components/price'
import './styles'

export default \
class RulesCell extends React.Component
  @propTypes:
    rules: PropTypes.arrayOf(PropTypes.object)

  @defaultProps:
    rules: []

  fetchPropertyValue: (rule, property) ->
    condition = rule.conditions.find((condition) => condition.property == property)
    condition?.value

  render: ->
    <div className="RulesCell">
      {@props.rules.map((rule, index) =>
        amount = rule.amount
        currency = @fetchPropertyValue(rule, 'currency')
        country = @fetchPropertyValue(rule, 'country')
        <div key={index} className="rule">
          <div>
            <span>
              {if currency then <Price amount={amount} currency={currency} showCurrency={false} /> else amount}
            </span>
            {
              currency && <>
                <span> {currency}</span>
                {country && <span className="country-badge">{country}</span>}
              </>
            }
          </div>
        </div>
      )}
    </div>
