import React from 'react'
import './styles'

import { Link } from 'react-router-dom'
import debounce   from 'underscore-es/debounce'

import Pager        from '/components/pager/history-pager'
import Spinner      from '/components/spinner'
import StateLabel   from '/components/state-label'
import List         from '/components/list'
import Filters      from '/components/filters'
import NoteWarning  from '/components/note/warning'
import EventCard    from '/components/event-card'
import StateActions from '/components/state-label/actions'

import { state_options } from '/models/event'

import { withEvents, withRPC } from './queries'

export default \
withEvents \
withRPC \
class Events extends React.Component

  rpc: (id, method)->
    {event, errors} = await @props.rpc id, method
    if errors?.length
      flash.error errors
    else
      flash.success "Event updated successfully"

  render: ->
    <div className="Events">
      <header>
        <h1>Events</h1>
      </header>
      <section className="content">
        {@filters()}
        {@table()}
        <div className="text-center">
          <Pager meta={@props.eventsPagination} buffer={4}/>
        </div>
      </section>
    </div>

  filters: ->
    <Filters>
      <Filters.Search/>
      <Filters.State options={state_options}/>
      <span className="pull-right">
        <Pager meta={@props.eventsPagination} buffer={2}/>
      </span>
    </Filters>

  table: ->
    return <Spinner/> if @props.eventsLoading
    <List>
      {@header()}
      {for evt in @props.events
        <List.Row key={evt._key}>
          <List.Cell className="id">
            <Link className="btn btn-default" to={"/events/#{evt.id}"} title={evt.id}>
              More info
            </Link>
            <NoteWarning notes={evt.related_notes}/>
          </List.Cell>
          <List.Cell className="listing">
            <EventCard event={evt} />
          </List.Cell>
          <List.Cell className="tickets">
            <Link to={"/events/#{evt.id}/tickets"} title="Tickets Sold">
              {evt.num_tickets || '-'}
            </Link>
            {' / '}
            <Link to={"/events/#{evt.id}/cost_items"} title="Cost Items Sold">
              {evt.num_cost_items || '-'}
            </Link>
          </List.Cell>
          <List.Cell className="actions">
            <StateActions
              model={evt}
              onClick={@rpc.bind @, evt.id}
            />
          </List.Cell>
        </List.Row>
      }
    </List>

  header: ->
    <List.HeaderRow>
      <List.Header className="id"></List.Header>
      <List.Header className="listing">Listing</List.Header>
      <List.Header className="tickets">Tickets</List.Header>
      <List.Header className="actions">State</List.Header>
    </List.HeaderRow>
