import React from 'react'
import './styles'

import flash from '/lib/flash'

import { Link } from 'react-router-dom'

import {
  state_options
} from '/models/note'

# componennts
import Pager        from '/components/pager/history-pager'
import Spinner      from '/components/spinner'
import Datetime     from '/components/datetime'
import List         from '/components/list'
import Filters      from '/components/filters'
import StateActions from '/components/state-label/actions'
import UserCard     from '/components/user-card'
import Modal        from 'react-bootstrap/lib/Modal'
import ModalState   from '/components/modal-state'
import Note         from '/components/note'

import {
  withRPC
} from './queries'

export default \
withRPC \
class Notes extends React.Component

  rpc: (id, method)=>
    {note, errors} = await @props.rpc id, method
    flash.error errors if errors?.length

  render: ->
    <div className="Notes">
      {@filters()}
      {@list()}
    </div>

  filters: ->
    <Filters>
      <Filters.State options={state_options}/>
      <span className="pull-right">
        {@createNote()}
      </span>
    </Filters>

  list: ->
    return <Spinner/> if @props.notesLoading
    <List>
      {@header()}
      {for note in @props.notes
        <List.Row key={note._key}>
          <List.Cell className="text">
            {note.text}
          </List.Cell>
          <List.Cell className="user">
            <UserCard user={note.user}/>
          </List.Cell>
          <List.Cell className="created-at">
            <Datetime date={note.created_at}/>
          </List.Cell>
          <List.Cell className="actions">
            <StateActions
              model={note}
              onClick={@rpc.bind @, note.id}
            />
          </List.Cell>
        </List.Row>
      }
    </List>

  header: ->
    <List.HeaderRow>
      <List.Header className="text">Text</List.Header>
      <List.Header className="user">Author</List.Header>
      <List.Header className="created-at">Created At</List.Header>
      <List.Header className="actions">Actions</List.Header>
    </List.HeaderRow>

  createNote: ->
    # 🍌🍌🍌🍌
    {ticket, organization, listing, event, user} = @props
    subject = ticket or organization or listing or event or user

    <ModalState
      render={({open, toggle})=>
        [
          <button
            key={'icon'}
            className='btn btn-primary'
            onClick={toggle}
          >
            add note
          </button>

          <Modal
            key={'modal'}
            show={open}
            onHide={toggle}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Add Note
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='clearfix'>
              <Note.Create subject={subject} refetch={=> toggle(); @props.notesRefetch()}/>
            </Modal.Body>
          </Modal>
        ]
      }/>
