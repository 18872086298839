import React from 'react'

import flash from '/lib/flash'

import { Link } from 'react-router-dom'

import isSameDay  from 'date-fns/isSameDay'
import startOfDay from 'date-fns/startOfDay'
import format     from 'date-fns/format'

import sortBy     from 'underscore-es/sortBy'

import Papa       from 'papaparse'

import InfiniteCalendar, {
  Calendar,
  withMultipleDates,
} from 'react-infinite-calendar'
import 'react-infinite-calendar/styles.css'

import FaCloudDownload  from 'react-icons/lib/fa/cloud-download'

import Tabs         from 'react-bootstrap/lib/Tabs'
import Tab          from 'react-bootstrap/lib/Tab'
import ProgressBar  from 'react-bootstrap/lib/ProgressBar'
import Alert        from 'react-bootstrap/lib/Alert'

MultipleDatesCalendar = withMultipleDates Calendar

# components
import ReactSelect  from '/components/react-select'
import Spinner      from '/components/spinner'
import StateActions from '/components/state-label/actions'
import List         from '/components/list'

import { state_options } from '/models/listing'

date_formats = [
  'MMMM do, yyyy'
  'MMMM do'
  'do MMMM yyyy'
  'do MMMM'
  'dd/MM/yy'
  'dd/MM/yyyy'
  'MM/dd/yy'
  'MM/dd/yyyy'
]
date_format_options = (value: fmt, label: format (new Date), fmt for fmt in date_formats)

import { withListing, withDuplications, withDuplicate, withRPC } from './queries'

export default \
withListing \
withRPC \
withDuplications \
withDuplicate \
class Duplications extends React.Component

  constructor: ->
    super arguments...
    @state =
      dates: []
      title: undefined
      dup_access_keys: false
      dup_discount_codes: false
      dup_attractions: false
      dup_private_note: false
      dup_rate_upgrades: false
      dup_host_fields: false
      dup_referral_codes: false
      dup_google_analytics_4_id: false
      dup_google_ads_trackers: false
      dup_facebook_pixels: false
      dup_tiktok_pixels: false
      dup_mailchimp_api_key: false
      date_format: null
      inflight: false
      submits_total: 0
      submits_completed: 0

  duped_dates: =>
    (dupe.event.startTime for dupe in @props.duplications when dupe.state isnt 'hidden')

  duplicate: =>
    @setState inflight: true
    await Promise.all (for date in @state.dates
      {listing, errors} = await @props.duplicate
        id: @props.listing.id
        date: format(date,'MMMM dd yyyy')
        title: @title(date)
        dup_access_keys: @state.dup_access_keys
        dup_discount_codes: @state.dup_discount_codes
        dup_attractions: @state.dup_attractions
        dup_private_note: @state.dup_private_note
        dup_rate_upgrades: @state.dup_rate_upgrades
        dup_host_fields: @state.dup_host_fields
        dup_referral_codes: @state.dup_referral_codes
        dup_google_analytics_4_id: @state.dup_google_analytics_4_id
        dup_google_ads_trackers: @state.dup_google_ads_trackers
        dup_facebook_pixels: @state.dup_facebook_pixels
        dup_tiktok_pixels: @state.dup_tiktok_pixels
        dup_mailchimp_api_key: @state.dup_mailchimp_api_key
      flash.error errors if errors?.length
    )
    flash.success "Listing duplicated successfully"
    @setState inflight: false, dates: []
    @props.duplicationsRefetch()

  rpc: (id, method)->
    {listing, errors} = await @props.rpc id, method
    if errors?.length
      flash.error errors
    else
      flash.success "Listing updated successfully"

  can_duplicate: =>
    !@state.inflight and @state.title and @state.dates?.length

  title: (date)=>
    title = @state.title ?= @props.listing.title
    if date and @state.date_format
      return "#{title} - #{format date, @state.date_format}"
    else
      title

  calendarChange: (date, selected)=>
    dates = \
    if date.toString() in (d.toString() for d in selected)
      (d for d in selected when date.toString() isnt d.toString())
    else
      dates = @state.dates
      dates.push date
      dates

    @setState dates: dates
    dates

  downloadCSV: =>
    data = []
    for listing in sortBy @props.duplications, ( (l)-> l.event.startTime )
      data.push(
        date: format listing.event.startTime, 'yyyy-MM-dd'
        link: listing.url
      )
    csv = Papa.unparse data

    csvData = new Blob [csv], type: 'text/csv;charset=utf-8;'
    csvURL = window.URL.createObjectURL csvData
    tempLink = document.createElement 'a'
    tempLink.href = csvURL
    tempLink.setAttribute 'download', 'duplications.csv'
    tempLink.click()

  noDuplicationBecauseSeats: ->
    <div className="seatsMessage">
      Duplicating a listing is not possible when using reserved seating.
    </div>

  listings_to_submit: =>
    (dupe for dupe in @props.duplications when dupe.state is 'draft')

  submit_all: =>
    l2s = @listings_to_submit()
    @setState submits_total: l2s.length, submits_completed: 0
    for dupe in @listings_to_submit()
      await @props.rpc dupe.id, 'submit'
      @setState submits_completed: @state.submits_completed + 1
    flash.success "#{@state.submits_completed} listings submitted successfully"
    setTimeout =>
      @setState submits_total: 0, submits_completed: 0
    , 3000

  render: ->
    return <Spinner/> if @props.listingLoading
    return @noDuplicationBecauseSeats() if @props.listing?.has_seats

    <div className="Duplications">
      {@form()}
      <br/>
      <Tabs defaultActiveKey={1} id="duplications">
        <Tab eventKey={1} title="Existing Duplications">
          {@bulkSubmit()}
          {@dupeList()}
        </Tab>
        <Tab eventKey={2} title="Output for copying">
          {@dupeTable()}
        </Tab>
      </Tabs>

    </div>

  form: ->
    <div className="dupeForm">
      <div className="left calendar">

        <InfiniteCalendar
          Component={MultipleDatesCalendar}
          selected={@state.dates}
          disabledDates={@duped_dates()}
          interpolateSelection={@calendarChange}
          displayOptions={{showHeader: false}}
        />
      </div>
      <div className="right form">

        <div className="form-group">
          <label className="control-label">title</label>
          <input
            className="form-control"
            type="text"
            value={@state.title ?= @props.listing.title}
            onChange={(evt)=> @setState title: evt.target.value }
          />
        </div>

        <div className="form-group">
          <label htmlFor="add_date" className="control-label">Add date to title</label>
          <ReactSelect
            className="date-format-selector"
            options={date_format_options}
            value={@state.date_format}
            placeholder="No date in title"
            onChange={(obj)=> @setState date_format: obj?.value}
          />
        </div>

        <br/>
        {@dupCheckbox "dup_access_keys",            "Duplicate Access Keys",                  !@props.listing.num_access_keys}
        {@dupCheckbox "dup_discount_codes",         "Duplicate Discount Codes",               !@props.listing.num_discount_codes}
        {@dupCheckbox "dup_attractions",            "Duplicate Attractions",                  !@props.listing.num_attractions}
        {@dupCheckbox "dup_private_note",           "Duplicate Additional Order Message",     !@props.listing.private_note}
        {@dupCheckbox "dup_rate_upgrades",          "Duplicate Ticket Upgrades",              !@props.listing.num_rate_upgrades}
        {@dupCheckbox "dup_host_fields",            "Duplicate Checkout Questions",            @props.listing.num_host_fields < 4}
        {@dupCheckbox "dup_referral_codes",         "Duplicate Affiliate Tracking",           !@props.listing.num_referral_codes}
        {@dupCheckbox "dup_google_analytics_4_id",  "Duplicate Google Analytics",             !@props.listing.google_analytics_4_id}
        {@dupCheckbox "dup_google_ads_trackers",    "Duplicate Google Ads (AdWords)",         !@props.listing.num_google_ads_trackers}
        {@dupCheckbox "dup_facebook_pixels",        "Duplicate Facebook Pixel Integration",   !@props.listing.num_facebook_pixels}
        {@dupCheckbox "dup_tiktok_pixels",          "Duplicate Tiktok Pixel Integration",     !@props.listing.num_tiktok_pixels}
        {@dupCheckbox "dup_mailchimp_api_key",      "Duplicate Mailchimp Integration",        !@props.listing.mailchimp_api_key}

        {if @state.dates?.length
          <div>
            <h3>Pending duplications</h3>
            <ul>
              {for date in @state.dates
                <li key={date.toString()}>
                  <span style={{color: '#808080'}}>{format date, 'MMMM do, yyyy'}</span>
                  {' - '}
                  {@title date}
                </li>
              }
            </ul>
          </div>
        }

        <button
          disabled={not @can_duplicate()}
          className="btn btn-primary"
          onClick={@duplicate}
        >
          {if @state.inflight
            "Duplicating..."
          else
            "Duplicate listing"
          }
        </button>
      </div>
    </div>

  dupCheckbox: (name, title, disabled) =>
    <div className="form-group">
      <input
        disabled={disabled}
        type="checkbox"
        checked={!disabled && @state[name]}
        onChange={=>@setState "#{name}": not @state[name]}
        id={name}
        style={{marginRight: '1em'}}
      />
      <label htmlFor={name} className="control-label">{title}</label>
    </div>

  dupeList: ->
    return <Spinner/> if @props.duplicationsLoading
    <List>
      {for listing in @props.duplications by -1
        <List.Row key={listing._key}>
          <List.Cell className="id">
            <Link className="btn btn-default" to={"/listings/#{listing.id}"}>
              More info
            </Link>
          </List.Cell>
          <List.Cell className="what">
            <Link to={"/listing/#{listing.id}"}>
              {listing.title}
            </Link>
          </List.Cell>
          <List.Cell className="when">
            <Link to={"/listing/#{listing.id}"}>
              {format listing.event.startTime, 'MMMM do, yyyy'}
            </Link>
          </List.Cell>
          <List.Cell className="actions">
            <StateActions
              model={listing}
              onClick={@rpc.bind @, listing.id}
            />
          </List.Cell>
        </List.Row>
      }
    </List>

  dupeTable: ->
    return <Spinner/> if @props.duplicationsLoading
    <div>
      <button
        className='btn btn-primary'
        onClick={@downloadCSV}>
        <FaCloudDownload/>
        {' '}
        Download CSV
      </button>
      <table className="table table-striped">
        <tbody>
          {for listing in sortBy @props.duplications, ( (l)-> l.event.startTime )
            <tr key={listing._key}>
              <td>
                {format listing.event.startTime, 'yyyy-MM-dd'}
              </td>
              <td>
                {listing.url}
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>

  bulkSubmit: ->
    l2p = @listings_to_submit()
    return null unless l2p.length or @state.submits_total
    <Alert bsStyle="warning" style={margin: "1em"}>
      {if @state.submits_total
        percent = 100 * @state.submits_completed / @state.submits_total
        [
          <p>Processing...</p>
          <ProgressBar>
            <ProgressBar active bsStyle="success" now={percent} key={1} />
            <ProgressBar active bsStyle="danger" now={0} key={2} />
          </ProgressBar>
        ]
      else
        [
          <p key={1}>
            <strong>There are {l2p.length} listings in draft</strong>
          </p>
          <p key={2}>
            <button className="btn btn-default" onClick={@submit_all}>Submit all</button>
          </p>
        ]
      }
    </Alert>
