import Model from '/lib/model'
import Fee from './fee'

export default \
class FeeRule extends Model
  @className: 'FeeRule'
  constructor: ->
    super arguments...
    @type                     = 'basic'
    @amount                   = null
    @calculation              = 'flat'

  @hasMany 'conditions', model: 'FeeCondition'

  availableProperties: -> 
    conditions = Fee.ruleTypes.find((ruleType) => ruleType.type == @type).conditions
    usedConditions = new Set(@conditions.map((condition) => condition.property))
    
    conditions.reduce(
      (properties, conditionType) => 
        canAdd = conditionType.multiple || !usedConditions.has(conditionType.property)
        return properties if !canAdd
        [properties..., conditionType.property]
      ,
      []
    )
  