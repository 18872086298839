import React from 'react'
import flash from '/lib/flash'

# components
import ReactSelect from '/components/react-select'
import Toggle      from '/components/toggle'
import List        from '/components/list'
import Modal       from 'react-bootstrap/lib/Modal'
import ModalState  from '/components/modal-state'

import Models      from '/models/index'

import NewBusinessInfo from '/components/users/new-business-info'

import {
  withUser
  withEntities
  withUpdateOrganizationForceHostPaysCommission
  withUpdateBusinessInfo
  withCreateBusinessInfo
  withDeleteBusinessInfo
} from './queries'

export default \
withUser \
withEntities \
withUpdateOrganizationForceHostPaysCommission \
withUpdateBusinessInfo \
withCreateBusinessInfo \
withDeleteBusinessInfo \
class Taxes extends React.Component
  constructor: ->
    super arguments...
    @state =
      inFlight: false
      organization: @props.user.organization
      modalOpen:  false
      disabled: false

  render: ->
    <div>
      <div className="Summary">
        {@organization_settings()}
        {@business_infos()}
      </div>
    </div>

  clean_business_info: (info)->
    info = Models.BusinessInfo.parse info

    info.vat.percent = parseFloat(info.vat.percent)

    if info.errors?.length
      { business_info: null, errors: info.errors }
    else
      { business_info: info, errors: [] }

  updateOrganizationForceHostPaysCommission: (evt)=>
    @setState disabled: true
    # handle text inputs
    value = if evt?.target?.value? then evt?.target?.value else evt
    # handle react select inputs
    value = evt.value if evt?.value?
    # update the value
    { organization, errors } = await @props.updateOrganizationForceHostPaysCommission @state.organization.id, value
    if errors?.length
      flash.error errors
    else
      flash.success "Organization updated successfully"
    @setState disabled: false

    @state.organization["force_host_to_pay_commission"] = value

    @setState
      organization: @state.organization
      saving: false

  contractingEntityName: =>
      entity = @props.user.organization.business_info?.entity
      if entity
        "#{entity.company_name} (#{entity.country_code})"
      else
        <div>
          <i>Self Serve</i>
          <div>A business info must be added before assigning a contracting entity.</div>
        </div>

  businessInfoCreate: (business_info) =>
    {business_info, errors} = @clean_business_info business_info
    if errors?.length
      flash.error "Some required business info fields are missing"
      {business_info, errors}
    else
      @setState disabled: true
      {business_info, errors} = await @props.createBusinessInfo business_info
      if errors?.length
        flash.error errors
      else
        flash.success "Business info created"
        @props.userRefetch()
      @setState disabled: false
      {business_info, errors}

  businessInfoUpdate: (business_info) =>
    {business_info, errors} = @clean_business_info business_info
    if errors?.length
      flash.error "Some required business info fields are missing"
      {business_info, errors}
    else
      @setState disabled: true
      {business_info, errors} = await @props.updateBusinessInfo business_info.id, business_info
      if errors?.length
        flash.error errors
      else
        flash.success "Business info updated"
      @setState disabled: false
      {business_info, errors}

  businessInfoDelete: (id) =>
    @setState disabled: true
    {business_info, errors} = await @props.deleteBusinessInfo id
    if errors?.length
      flash.error errors
    else
      flash.success "Business info deleted"
      @props.userRefetch()
    @setState disabled: false
    {business_info, errors}

  organization_settings: ->
    <div className="VAT">
      <h2>
        Organization settings
      </h2>
      <List>
        <List.HeaderRow />
        <List.Row>
          <List.Cell className="setting">
            Force host to absorb fees on all listings and add ons
          </List.Cell>
          <List.Cell className="toggle">
            <Toggle
              onChange={@updateOrganizationForceHostPaysCommission.bind @}
              value={@state.organization.force_host_to_pay_commission}
              disabled={@state.disabled}
            />
          </List.Cell>
        </List.Row>
        <List.Row>
          <List.Cell className="setting">
            Contracting with
          </List.Cell>
          <List.Cell className="select">
            {@contractingEntityName()}
          </List.Cell>
        </List.Row>
      </List>
    </div>

  business_infos: ->
    business_info = @props.user.organization.business_info
    <div className="VAT">
      <h2>
        Summary of registered business
      </h2>
      {if business_info
        <List>
          {@business_info_headers()}
          <List.Row key={business_info.id}>
            <List.Cell className="setting">
              {business_info.name}
            </List.Cell>
            <List.Cell className="setting">
              {business_info.country_code}
            </List.Cell>
            <List.Cell className="setting">
              {business_info.vat.name}
            </List.Cell>
            <List.Cell className="setting">
              {business_info.vat.tax_id}
            </List.Cell>
            <List.Cell className="setting">
              {"#{business_info.vat.percent}%"}
            </List.Cell>
            <List.Cell className="buttons">
              {@editModal business_info}
              <button className='btn btn-danger' onClick={@businessInfoDelete.bind @, business_info.id} disabled={@state.disabled}>Delete</button>
            </List.Cell>
          </List.Row>
        </List>
      else
        <div className='save-button-container'>
          {@newBusinessInfo()}
        </div>
      }
    </div>

  business_info_headers: ->
    <List.HeaderRow>
      <List.Cell>
        Business name
      </List.Cell>
      <List.Cell>
        Country
      </List.Cell>
      <List.Cell>
        Tax name
      </List.Cell>
      <List.Cell>
        Tax number
      </List.Cell>
      <List.Cell>
        Tax percent
      </List.Cell>
      <List.Cell className="buttons">
      </List.Cell>
    </List.HeaderRow>

  toggle: =>
    @setState modalOpen: not @state.modalOpen

  editModal: (business_info) =>
    <ModalState
      render={({open, toggle})=>
        <span>
          <button
            className="btn btn-default"
            onClick={toggle}
          >
            Edit
          </button>

          <Modal
            show={open}
            onHide={toggle}
            className="test"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Edit Business Info
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='clearfix'>
              <NewBusinessInfo
                save={@businessInfoUpdate}
                cancel={toggle}
                business_info={business_info}
                organization_id={@state.organization.id}
                disabled={@state.disabled}
                entities={@props.entities}
              />
            </Modal.Body>
          </Modal>
        </span>
      }/>

  newBusinessInfo: ->
    <span>
      <button className='btn btn-primary' onClick={@toggle}>
        New Business Info
      </button>
      <Modal
        show={@state.modalOpen}
        onHide={@toggle}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Add new business info
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='clearfix'>
          <NewBusinessInfo
            save={@businessInfoCreate}
            cancel={@toggle}
            organization_id={@state.organization.id}
            disabled={@state.disabled}
            entities={@props.entities}
          />
        </Modal.Body>
      </Modal>
    </span>
