import Model from '/lib/model'
import humanize from 'underscore.string/humanize'

export default \
class Transfer extends Model
  @className: 'Transfer'
  constructor: ->
    super arguments...
    @id               = null # "5751b0abc6d1c694faed4b40"
    @created_at       = null # "2016-06-03T16:30:36.045Z"
    @updated_at       = null # "2016-06-03T16:30:37.204Z"
    @token            = null
    @state            = null
    @first_name       = null
    @last_name        = null
    @email            = null

  @belongsTo 'cost_item', model: 'CostItem'
  @belongsTo 'ticket',    model: 'Ticket'
  @hasMany 'transfers',   model: 'Transfer'

  @computed 'name', ->
    [ @first_name, @last_name ].join ' '

  # @computed 'url', ->
  #   "#{process.env.MULTIVERSE}/transfers/#{@token}"


export states = [
  'pending'
  'accepted'
  'declined'
  'cancelled'
]

export state_options = value: state, label: humanize state for state in states
