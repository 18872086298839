import React from 'react'
import './styles'

export default \
class HeroStats extends React.Component
  @defaultProps:
    data: []

  render: ->
    return null unless @props.data?.length
    <ul className="HeroStats">
      {for stat, idx in @props.data
        { name, value, tooltip } = stat
        <li
          key={idx}
          title={tooltip}
        >
          <span className="key">{name}</span>
          <span className="value">{value}</span>
        </li>
      }
    </ul>
