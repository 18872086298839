import React from 'react'
import PropTypes from 'prop-types'
import './styles'

import logo from '/assets/images/universe.png'
import okta from '/assets/images/okta.png'

import FaLock from 'react-icons/lib/fa/lock'

import { withAuth } from '@okta/okta-react'

export default \
withAuth \
class SignIn extends React.Component
  @propTypes:
    login:    PropTypes.func.isRequired

  componentDidMount: ->
    @refs.email?.focus?()

  signIn: (evt)->
    evt.preventDefault()
    @props.login @refs.email?.value, @refs.password?.value
    false

  signInOkta: =>
    localStorage.okta_redirect = window.location.href
    @props.auth.login '/'

  render: ->
    <div className="SignIn">

      <FaLock className='lock-icon'/>

      <img className="logo" src={logo} style={width: 150, marginBottom: 50, marginTop: 25}/>

      <form className="form sign-in-form" onSubmit={@signIn.bind(@)}>

        <div className="form-group">
          <label htmlFor="email" className="control-label">Email</label>
          <input
            type="email"
            className="form-control"
            ref="email"
            name="email"
            placeholder="user@school.edu"
          />
        </div>

        <div className="form-group">
          <label htmlFor="password" className="control-label">Password</label>
          <input
            type="password"
            className="form-control"
            ref="password"
            name="password"
            placeholder="password"
          />
        </div>

        <input
          type='submit'
          className='btn btn-md btn-primary'
          value='Sign In'
        />

      </form>

      <div className="help-text">
        <a onClick={@signInOkta} className="btn btn-default">
          Sign in with
          <img className="okta" src={okta}/>
        </a>
      </div>

    </div>
