import React from 'react'
import PropTypes from 'prop-types'
import './styles'
import Modal          from 'react-bootstrap/lib/Modal'

import flash from '/lib/flash'


export default \
class ContactModal extends React.Component
  @propTypes:
    ticket:       PropTypes.object
    sendMessage:  PropTypes.func.isRequired
    open:         PropTypes.bool.isRequired
    closeModal:   PropTypes.func.isRequired

  sendMessage: (evt) =>
    (evt)?.preventDefault?()
    subject = @subjectInput.value
    console.log("subject", subject)
    message = @messageInput.value
    @props.sendMessage(@props.ticket.id, subject, message, @props.modalType)
    .then (resp)->
      if (!resp.data.ticket_send_message.errors)
        flash.success "Message sent succesfully"
      else
        console.log('ERR message', resp.data.ticket_send_message.errors[0] )
        flash.error "Message not sent: #{resp.data.ticket_send_message.errors[0]}"
    .catch (err)->
      console.log('ERR', err)
      flash.error "Message not sent"
    @cancel()

  cancel: (evt)=>
    evt?.preventDefault?()

    @subjectInput.value = ''
    @messageInput.value = ''
    @props.closeModal()

  render: ->
    <Modal
      show={@props.open}
      onHide={@props.closeModal}
      className="ContactModal">
        <form className="form-inline">
          <Modal.Header>
          {
            if @props.modalType == 'Host'
              <Modal.Title>
                <b> Message to Host </b>
                  { if hostName = @props.ticket?.listing?.user?.name
                      <span className="host-name">{hostName}</span>
                  }
              </Modal.Title>
            else
              <Modal.Title>
                <b> Message to Buyer </b>
                  { if buyerName = @props.ticket?.user?.name
                      <span className="buyer-name">{buyerName}</span>
                  }
              </Modal.Title>
          }
          </Modal.Header>
          <Modal.Body>
            <label>
              Subject:
            </label>
            <br/>
            <input
              ref={(el)=> @subjectInput = el}
              type="text"
              className="form-control subject-input"
            />
            <br/>
            <label>
              Message:
            </label>
            <br/>
            <textarea
              ref={(el)=> @messageInput = el}
              className="subject-input message-input"
            />
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={@sendMessage}> Send Message </button>
            <button className="btn btn-warning" onClick={@cancel}> Cancel </button>
          </Modal.Footer>
        </form>
    </Modal>

