import React from 'react'
import './styles'

import flash from '/lib/flash'

import { Link } from 'react-router-dom'

import Datetime       from '/components/datetime'
import Filters        from '/components/filters'
import List           from '/components/list'
import Pager          from '/components/pager/history-pager'
import Price          from '/components/price'
import Spinner        from '/components/spinner'
import StateActions   from '/components/state-label/actions'
import StateLabel     from '/components/state-label'
import Na             from '/components/na'
import UserCard       from '/components/user-card'
import EventCard      from '/components/event-card'

import { state_options } from '/models/ticket'
import humanize from 'underscore.string/humanize'

import { withPaymentPlans, withTicketsRPC } from './queries'

export default \
withPaymentPlans \
withTicketsRPC \
class PaymentPlans extends React.Component

  render: ->
    <div className="PaymentPlans">
      <header>
        <h1>Payment Plans</h1>
      </header>
      <section className="content">
        {@filters()}
        {@list()}
        <div className="text-center">
          <Pager meta={@props.paymentPlansPagination} buffer={4}/>
        </div>
      </section>
    </div>

  rpc: (id, method)->
    {ticket, errors} = await @props.tickets_rpc id, method
    if errors?.length
      flash.error errors
    else
      flash.success "Ticket updated successfully"

  filters: ->
    <Filters>
      <Filters.Search/>
      <Filters.State options={state_options}/>
      <span className="pull-right">
        <Pager meta={@props.paymentPlansPagination} buffer={2}/>
      </span>
    </Filters>

  list: ->
    return <Spinner/> if @props.paymentPlansLoading
    <List>
      {for pp in @props.paymentPlans
        <List.Row key={pp._key}>
          <List.Cell className="id">
            <samp className="btn btn-default">More info</samp>
          </List.Cell>
          <List.Cell className="user">
            <UserCard user={pp.user} />
          </List.Cell>
          <List.Cell className="listing">
            <EventCard event={pp.event} />
          </List.Cell>
          <List.Cell className="date">
            <Datetime date={pp.created_at} format='MMM dd, yyyy'/>
          </List.Cell>
          {for i in [0..4]
            <List.Cell className="installments" key={i}>
              {unless inst = pp.installments[i]
                <Na/>
              else
                <div>
                  <StateLabel model={inst}/>
                  <br/>
                  <Price amount={inst.base_amount} currency={pp.base_currency}/>
                </div>
              }
            </List.Cell>
          }
          <List.Cell className="actions">
            <StateActions
              model={pp}
              onClick={@rpc.bind @, pp.id}
            />
          </List.Cell>
        </List.Row>
      }
    </List>
