import './styles'
import React from 'react'
import flash from '/lib/flash'
import ReactSelect  from '/components/react-select'
import { withManualDisclsosureCreate } from '../queries'
import { justifications, piiInfos, targets } from './utils'

selectify = (arr)->
  (value: lol, label: lol for lol in arr)

export default \
withManualDisclsosureCreate \
class ManualDisclose extends React.Component
  constructor: (props) ->
    super(props)
    @state =
      userEmails: []
      target: null
      justification: null
      pii: []

  targetDidChange: (evt) =>
    @setState target: evt.value

  justificationDidChange: (evt) =>
    @setState justification: evt.value

  piiDidChange: (evt) =>
    @setState pii: evt.map((item) => item.value)

  userIdDidChange: (evt) =>
    @setState userEmails: evt.target.value

  userEmailsDidChange: (evt)=>
    return unless evt.key is "Enter"
    emails = evt.target?.value
    formattedEmails = emails.split(',').map((item) => item.trim()).filter((item) => item)
    @setState userEmails: formattedEmails

  submitDisclosure: (evt) =>
    evt.preventDefault()
    {errors, response} = await @props.disclosureCreate @state
    if errors?.length
      flash.error errors
    else
      flash.success "Successfully processed disclosure"

  isDisabled: ->
    @state.target == null ||
    @state.justification == null ||
    @state.userEmails.length == 0 ||
    @state.pii.length == 0

  render: ->
    <div className="ManualDislcosure">
      <header>
        <h1>Manual Disclosure</h1>
      </header>
      <section className="content">
        <div>
          <div>User ID:</div>
          <div className="input-container">
            <textarea
              disabled={false}
              ref={(c)=> @textarea = c}
              className="form-control"
              defaultValue={@state.userEmails.join '\n'}
              onKeyUp={@userEmailsDidChange}
              rows={12}
              placeholder="Paste the user emails that have been provided you to in the input field below. Emails can be  separated by a comma or a space. Once complete hit enter"
            />
          </div>

          <div>Target:</div>
          <div className="input-container">
            <ReactSelect
              className="justification-selector"
              options={selectify(targets)}
              multi={false}
              clearable={false}
              value={@state.target}
              placeholder="Select Target"
              onChange={@targetDidChange}
            />
          </div>

          <div>Justification:</div>
          <div className="input-container">
            <ReactSelect
              className="justification-selector"
              options={selectify(justifications)}
              multi={false}
              clearable={false}
              value={@state.justification}
              placeholder="Select Justification"
              onChange={@justificationDidChange}
            />
          </div>

          <div>PII Data:</div>
          <div className="input-container">
            <ReactSelect
              className="pii-selector"
              options={selectify(piiInfos)}
              multi={true}
              clearable={false}
              value={@state.pii}
              placeholder="Select PII"
              onChange={@piiDidChange}
            />
          </div>
          <button
            className='btn btn-primary submit-dislcosure'
            onClick={@submitDisclosure}
            disabled={@isDisabled()}
          >
            Submit Disclosure
          </button>
        </div>
      </section>
    </div>
