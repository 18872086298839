import React from 'react'

import flash from '/lib/flash'

import { Link } from 'react-router-dom'

import { state_options } from '/models/ticket'

# components
import ExternalLink from '/components/external-link'
import ListingPhoto from '/components/listing-photo'
import Spinner      from '/components/spinner'
import Datetime     from '/components/datetime'
import StateActions from '/components/state-label/actions'
import Price        from '/components/price'
import Pager        from '/components/pager/history-pager'
import Filters      from '/components/filters'
import List         from '/components/list'
import NoteWarning  from '/components/note/warning'

import { withUser, withTickets, withTicketsRPC, withTicketsResend } from './queries'

export default \
withUser \
withTickets \
withTicketsRPC \
withTicketsResend \
class Tickets extends React.Component

  rpc: (id, method)->
    return @resend_ticket id if method is 'resend_ticket'
    {ticket, errors} = await @props.tickets_rpc id, method
    if errors?.length
      flash.error errors
    else
      flash.success "Ticket updated successfully"

  resend_ticket: (id)=>
    {ticket, errors} = await @props.tickets_resend id
    if errors?.length
      flash.error errors
    else
      flash.success "Ticket re-sent successfully"

  render: ->
    <div className="Tickets">
      {@filters()}
      {@list()}
      {@pager()}
    </div>

  filters: ->
    <Filters>
      <Filters.Search/>
      <Filters.State options={state_options}/>
      <span className="pull-right">
        <Pager meta={@props.ticketsPagination} buffer={2}/>
      </span>
    </Filters>


  list: ->
    return <Spinner/> if @props.ticketsLoading
    <List>
      {@header()}
      {for ticket in @props.tickets
        # ignore notes for the subject of this page
        ticket_notes = (n for n in ticket.related_notes when n.target_id isnt @props.match.params.id)

        <List.Row key={ticket._key}>
          <List.Cell className="id">
            <Link className="btn btn-default" to={"/tickets/#{ticket.id}"}>
              More info
            </Link>
            <NoteWarning notes={ticket_notes}/>
          </List.Cell>
          <List.Cell className="listing">
            <ListingPhoto
              image_url={ticket.listing.image_url}
              listing={ticket.listing}
              size={40}
            />
            <Link to={"/listings/#{ticket.listing.id}"}>
              {ticket.listing.title}
            </Link>
          </List.Cell>
          <List.Cell className="amount">
            {ticket.formattedPrice}
          </List.Cell>
          <List.Cell className="created_at">
            <Datetime date={ticket.created_at} format='MMM d, yyyy'/>
          </List.Cell>
          <List.Cell className="actions">
            <StateActions
              model={ticket}
              onClick={@rpc.bind @, ticket.id}
            />
          </List.Cell>
        </List.Row>
      }
    </List>

  # TODO: Add message buyer modal?
  header: ->
    <List.HeaderRow>
      <List.Header className="id">id</List.Header>
      <List.Header className="Listing">Listing</List.Header>
      <List.Header className="amount">Amount</List.Header>
      <List.Header className="created_at">Created</List.Header>
      <List.Header className="actions">Actions</List.Header>
    </List.HeaderRow>


  pager: ->
    <div className="text-center">
      <Pager meta={@props.ticketsPagination} buffer={4}/>
    </div>
