import React from 'react'

import flash from '/lib/flash'

# components
import ReactSelect  from '/components/react-select'
import Spinner      from '/components/spinner'

import { withListing, withUpdateRestrictedBins } from './queries'

export default \
withListing \
withUpdateRestrictedBins \
class BinRestrictions extends React.Component
  updateCardBins: (evt)=>
    return unless evt.key is "Enter"
    bins = evt.target?.value
    binCollection = (parseInt i.trim() for i in bins.split(/\D+/) when parseInt i)

    {listing, errors} = await @props.updateCardBins @props.listing.id, binCollection
    if errors?.length
      flash.error errors
    else
      flash.success "Listing Card BIN's updated successfully"

  clearCardBins: (evt)=>
    {listing, errors} = await @props.updateCardBins @props.listing.id, null
    if errors?.length
      flash.error errors
    else
      @textarea.value = ''
      flash.success "Listing Card BIN's cleared successfully"

  render: ->
    return <Spinner/> if @props.listingLoading
    @isStripeConnect = @props.listing.provider == 'stripe_connect'
    <div className="BinRestriction">
      <section>
        <div className="bin-restriction-copy">
          Paste the BIN/IIN numbers the event organizer has provided you to the input field below. Numbers can be  separated by a comma or a space. Once entered, checkout for this listing will be safelisted to cards with those BIN / IIN numbers. To turn off the pre-sale, delete all BIN/IIN numbers entered. Only available on Universe payments.  If a listing with a BIN/IIN enabled is switched to Stripe Connect, the presale will be turned off.
        </div>
        <textarea
          disabled={@isStripeConnect}
          ref={(c)=> @textarea = c}
          className="form-control"
          defaultValue={@props?.listing?.restricted_card_bins?.join '\n'}
          onKeyUp={@updateCardBins}
          rows={12}
        />
        <div className="btn btn-default clear-bins" onClick={@clearCardBins}>
          Clear BIN's
        </div>
      </section>
    </div>
