import React from 'react'
import './styles'

# componennts
import Spinner        from '/components/spinner'
import Empty          from '/components/empty'
import List           from '/components/list'
import WithdrawalRow  from '/components/withdrawal-row'

import { withWithdrawal } from './queries'

export default \
withWithdrawal \
class Withdrawal extends React.Component

  render: ->
    <div className="Summary">
      {@list()}
    </div>

  list: ->
    return <Spinner/> if @props.withdrawalLoading
    return <Empty name="Withdrawal" /> unless @props.withdrawal?.id
    <List>
      <WithdrawalRow.Header/>
      <WithdrawalRow
        key={@props.withdrawal._key}
        withdrawal={@props.withdrawal}
      />
    </List>
