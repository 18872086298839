import Notes  from '/components/notes'

import {
  withUser
  withNotes
} from './queries'

export default \
withUser \
withNotes \
Notes
