import React from 'react'
import './styles'

import flash from '/lib/flash'

import { Link }               from 'react-router-dom'
import { withWithdrawalsRPC } from './queries'

# components
import FaInfoCircle           from 'react-icons/lib/fa/info-circle'
import Modal                  from 'react-bootstrap/lib/Modal'
import ModalState             from '/components/modal-state'
import Datetime               from '/components/datetime'
import StateLabel             from '/components/state-label'
import StateActions           from '/components/state-label/actions'
import DropdownMenu           from '/components/dropdown-menu'
import Na                     from '/components/na'
import UserCard               from '/components/user-card'
import ViewDetailsContent     from './view-details-content'
import List                   from '/components/list'
import Price                  from '/components/price'
import FraudFlag              from '/components/fraud-flag'


export default \
withWithdrawalsRPC \
class WithdrawalRow extends React.Component
  rpc: (id, method)->
    @props.withdrawals_rpc id, method
    .then (data)->
      flash.success "Withdrawal updated successfully"
    .catch (err)->
      flash.error "Update failed"

  render: ->
    { withdrawal } = @props
    <List.Row className="WithdrawalRow" key={withdrawal._key}>
      <List.Cell className="id">
        {@viewDetailsModal(withdrawal)}
      </List.Cell>

      <List.Cell className="user">
        <UserCard user={withdrawal.user}>
          <FraudFlag value={withdrawal.user.failed_orders_percentage}/>
        </UserCard>
      </List.Cell>

      <List.Cell className="receiver">
        <pre className="receiver-details">
          {withdrawal.name}<br/>
          {withdrawal.email}<br/>
          {withdrawal.phone}<br/>
          <Datetime date={withdrawal.birthday} format='MMM dd, yyyy' />
        </pre>
      </List.Cell>

      <List.Cell className="amount">
        <Price amount={withdrawal.amount} currency={withdrawal.currency}/>
      </List.Cell>

      <List.Cell className="requested_at">
        <Datetime date={withdrawal.created_at} format='MMM d, yyyy'/>
      </List.Cell>

      <List.Cell className="paid_at">
        {if withdrawal.state is 'paid'
          [
            <small>Paid</small>
            <Datetime date={withdrawal.paid_at} format='MMM d, yyyy'/>
          ]
        else
          <Na/>
        }
      </List.Cell>

      <List.Cell className="balance">
        {if withdrawal.state is 'pending'
          <>
            <span>Balance: {withdrawal.formatted_payment_balance}</span>
            <br/>
            <span>Available: {withdrawal.formatted_approvable_balance}</span>
          </>
        }
      </List.Cell>

      <List.Cell className="actions">
        <StateActions
          model={withdrawal}
          onClick={@rpc.bind @, withdrawal.id}
        />
      </List.Cell>
    </List.Row>

  viewDetailsModal: (withdrawal)->
    <ModalState
      render={({open, toggle})->
        [
          <button className="btn btn-default" onClick={toggle}>More info</button>
          <Modal key={"modal-#{withdrawal.key}"} show={open} onHide={toggle} className="test">
            <Modal.Header closeButton>
              <Modal.Title>
                Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='clearfix'>
              <ViewDetailsContent withdrawal={withdrawal}/>
            </Modal.Body>
          </Modal>
        ]
      }/>

  @Header: ->
    <List.HeaderRow className="WithdrawalRowHeader">
      <List.Header className="id"> Id </List.Header>
      <List.Header className="user"> User </List.Header>
      <List.Header className="receiver"> Receiver Details </List.Header>
      <List.Header className="amount"> Amount </List.Header>
      <List.Header className="requested_at"> Requested </List.Header>
      <List.Header className="paid_at"> Paid </List.Header>
      <List.Header className="balance"> Balance </List.Header>
      <List.Header className="actions">  </List.Header>
    </List.HeaderRow>
