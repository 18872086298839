import React from 'react'
import './styles'

import { Link, Route, Switch, Redirect } from 'react-router-dom'

import DocumentTitle from 'react-document-title'

import UserAside        from '/components/user-aside'
import ExternalLink     from '/components/external-link'
import Spinner          from '/components/spinner'
import List             from '/components/list'
import Change           from '/components/change'
import Datetime         from '/components/datetime'
import Empty            from '/components/empty'

import humanize         from 'underscore.string/humanize'

import { withChangeset } from './queries'

export default \
withChangeset \
class Changeset extends React.Component

  render: ->
    return <Spinner/> if @props.changesetLoading
    return <Empty name="Changeset" /> unless cs = @props.changeset
    <div className="Changeset">
      <DocumentTitle title={"Changeset #{cs.id}"}/>
      <header>
        <h1>
          Changeset {cs.id}
        </h1>
      </header>
      <main>
        <section className="content">
          <h2>Details</h2>
          {@info()}
          <h2>Changes</h2>
          {@list()}
        </section>
        {<UserAside id={cs.user?.id}/> if cs.user?}
      </main>
    </div>

  info: ->
    cs = @props.changeset
    <dl>
      <dt>When</dt>
      <dd><Datetime date={cs.created_at} format='MMM d, yyyy'/></dd>
      <dt>Context</dt>
      <dd>{cs.ctx}</dd>
      {if cs.request_path?
        [
          <dt>Request Path</dt>
          <dd>{cs.request_path}</dd>
        ]
      }
      {for key, val of cs.meta when val?
        [
          <dt>{humanize key}</dt>
          <dd>{val}</dd>
        ]
      }
      {if cs.user?
        [
          <dt>Changed by</dt>
          <dd>
            <Link to={"/users/#{cs.user.id}"}>
              {cs.user.name}
            </Link>
          </dd>
        ]
      }
      {if cs.su_user?
        [
          <dt>Admin SU‘d from</dt>
          <dd>
            <Link to={"/users/#{cs.su_user.id}"}>
              {cs.su_user.name}
            </Link>
          </dd>
        ]
      }
    </dl>

  list: ->
    <List>
      {for change in @props.changeset?.changes or []
        <List.Row key={change.id}>
          <List.Cell className="target">
            <Link to={change.admin_target_link}>
              {change.target_type} {change.target_id}
            </Link>
          </List.Cell>
          <List.Cell className="info">
            <Change change={change} context={'changeset'} expanded={true}/>
          </List.Cell>
          <List.Cell className="created_at">
            <Datetime date={change.created_at} format='MMM d, yyyy'/>
          </List.Cell>
        </List.Row>
      }
    </List>
