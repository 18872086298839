import React from 'react'
import PropTypes from 'prop-types'

import flash from '/lib/flash'

import Model from '/lib/model'

import { withCreateNote } from './queries'

export default \
withCreateNote \
class Create extends React.Component
  @propTypes:
    subject:  PropTypes.instanceOf(Model).isRequired
    refetch:  PropTypes.func

  constructor: ->
    super arguments...
    @state =
      inflight: false

  componentDidMount: ->
    @text?.focus?()

  submit: =>
    @setState inflight: true

    note_attrs =
      target_type:  @props.subject.constructor.className
      target_id:    @props.subject.id
      text:         @text.value

    {note, errors} = await @props.create_note note_attrs

    if errors?.length
      flash.error errors
    else
      @text.value = ''
      @props.refetch?()
      flash.success "Note added"

    @setState inflight: false

  render: ->
    <div className="NoteCreate">
      <textarea
        className={'form-control'}
        ref={(el)=> @text = el}
      />
      <button
        className="btn btn-primary pull-right"
        onClick={@submit}
        disabled={@state.inflight}
      >
        Create Note
      </button>
    </div>
