import React from 'react'
import Base   from './base'

import { withRouter }   from 'react-router-dom'

export default withRouter class Number extends Base

  @defaultProps:
    name:         'number'
    type:         'number'
    label:        'Number'

  onKeyUp: (evt)=>
    evt.preventDefault()
    if (evt.key is 'Enter')
      filters = Object.assign {}, @props.location.state?.filters or {}
      filters[@props.name] = evt.target.value or null
      @onChange Object.assign {}, @props.location.state, {filters}, page: 1

  render: ->
    <div className="Filter form-group number">

      <label> {@label()} </label>
      <input
        key={"num"}
        type="number"
        defaultValue={@props.location.state?.filters?[@props.name] or ''}
        className="form-control number"
        onKeyUp={@onKeyUp}
        placeholder={@props.placeholder}
        min="0"
      />

    </div>
