import { graphql } from 'react-apollo'
import Models           from '/models/index'

#
# queries
#

import index   from './queries/index.gql'

export withCommissionCodes = graphql index,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { commission_codes, loading, refetch } = data or {}
    { nodes, pagination } = commission_codes or {}
    codes:            Models.CommissionCode.parse nodes or []
    codesPagination:  pagination
    codesLoading:     loading
    codesRefetch:     refetch

#
# mutations
#

import commission_code_create from '/mutations/commission-codes/create.gql'
import commission_code_update from '/mutations/commission-codes/update.gql'
import commission_code_rpc    from '/mutations/commission-codes/rpc.gql'

export withCommissionCodeCreate = graphql commission_code_create,
  props: ({ mutate })->
    commissionCodeCreate: (code)->
      {data: {commission_code_create: {commission_code, errors}}} = \
      await mutate variables: commission_code: code
      {commission_code, errors}


export withCommissionCodeUpdate = graphql commission_code_update,
  props: ({ mutate })->
    commissionCodeUpdate: (code)->
      { __typename, _rebate_user, code... } = code
      {data: {commission_code_update: {commission_code, errors}}} = \
      await mutate variables: {commission_code: code}
      {commission_code, errors}

export withCommissionCodeRPC = graphql commission_code_rpc,
  props: ({ mutate })->
    commission_code_rpc: (id, method)->
      {data: {commission_code_rpc: {commission_code, errors}}} = \
      await mutate variables: {id, method}
      {commission_code, errors}
