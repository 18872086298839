import React from 'react'
import './styles'

import * as Sentry from '@sentry/browser'

export default \
class ErrorBoundary extends React.Component
  constructor: (props)->
    super arguments...
    @state =
      error: null
      info: null

  componentDidCatch: (error, info)->
    @setState {error, info}
    Sentry?.captureException? error

  render: ->
    return @props.children or null unless @state.error?

    <div className="ErrorBoundary">
      <h1>⚠️ {@state.error.name} 😢</h1>
      <p
        style={cursor: 'pointer'}
        onClick={-> window.location.reload()}
      >
        Please reload 🔄 this page to continue
      </p>
      <h3>Details</h3>
      <pre>{@state.error.stack}</pre>
    </div>

