import React from 'react'
import PropTypes from 'prop-types'

import './styles'
import Models from '/models/index'

import ExternalLink from '/components/external-link'
import ListingPhoto from '/components/listing-photo'
import When         from '/components/when'

import { Link }     from 'react-router-dom'

export default \
class EventCard extends React.Component

  @propTypes:
    event: PropTypes.instanceOf(Models.Event).isRequired

  render: ->
    l = @props.event?.listing
    return null unless e = @props.event
    <div className="EventCard">
      <ListingPhoto image_url={l.image_url} listing={l} size={40}/>
      <div className="listing-details">
        <Link to={"/events/#{e.id}"}>
          <span className="title">{l.title}</span>
        </Link> <ExternalLink url={l.url}/>
        <br/>
        <When start={e.start_time} end={e.end_time} tz={e.tz_code}/>
        {@props.children}
      </div>
    </div>
