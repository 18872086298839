import { graphql } from 'react-apollo'
import Models           from '/models'


#
# Queries
#

import changeset from './queries/show.gql'

export withChangeset = graphql changeset,
  options: (props)->
    { page } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
  props: ({props, data})->
    changeset: Models.Changeset.parse data.changeset
    changesetLoading: data.loading
