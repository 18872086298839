export provinces = [
  "Northern Ireland",
  "Ontario",
  "Quebec",
  "Nova Scotia",
  "New Brunswick",
  "Manitoba",
  "British Columbia",
  "Price Edward Island",
  "Saskatchewan",
  "Alberta",
  "Newfoundland and Labrador",
  "Northwest Territories",
  "Yukon",
  "Nunavut"
]

export province_options = value: val, label: val for val in provinces
