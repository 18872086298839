import React from 'react'
import PropTypes from 'prop-types'
import './styles'

# routes
import { Route, Switch, Redirect } from 'react-router-dom'

import DocumentTitle from 'react-document-title'

import Listings                    from '/routes/listings'
import Listing                     from '/routes/listings/show'
import Events                      from '/routes/events'
import Event                       from '/routes/events/show'
import Users                       from '/routes/users'
import User                        from '/routes/users/show'
import Tickets                     from '/routes/tickets'
import Ticket                      from '/routes/tickets/show'
import CostItems                   from '/routes/cost-items'
import Transfers                   from '/routes/transfers'
import PaymentPlans                from '/routes/payment-plans'
import ItemCommissionCodes         from '/routes/fees/per-item'
import OrderCommissionCodes        from '/routes/fees/per-order'
import Withdrawals                 from '/routes/withdrawals'
import Withdrawal                  from '/routes/withdrawals/show'
import Emails                      from '/routes/emails'
import ICPQueue                    from '/routes/icp-queue'
import Changeset                   from '/routes/changesets/show'
import Advances                    from '/routes/advances'
import Chargebacks                 from '/routes/chargebacks'
import Attractions                 from '/routes/attractions'
import ServiceInvoices             from '/routes/service-invoices'
import Notes                       from '/routes/notes'
import ReportLogs                  from '/routes/report-logs'
import Countries                   from '/routes/countries'
import ImplicitCallback            from '/routes/implicit-callback'
import ManualDislcose              from '/routes/ccpa/manual-disclose'

# components
import NavBar         from '/components/navbar'
import SignIn         from '/components/sign-in'
import Flash          from '/components/flash'
import Spinner        from '/components/spinner'
import ErrorBoundary  from '/components/error-boundary'

import withAbility    from '/lib/with-ability'
import container      from './container'

export default \
container \
withAbility \
class Application extends React.Component
  @propTypes:
    session:    PropTypes.object.isRequired
    flash:      PropTypes.array.isRequired
    autologin:  PropTypes.func.isRequired

  constructor: (props)->
    super arguments...
    props.autologin()

  render: ->
    return <Spinner/> if @props.session.loading
    if @props.session.user?.id
      @renderApp()
    else
      @renderSignIn()

  renderSignIn: ->
    <Switch>
      <Route
        path="/implicit/callback"
        component={=>
          <ImplicitCallback
            login={@props.loginOkta}
            logout={@props.logout}
          />
      }/>
      <Route component={=>
        <div>
          <SignIn user={@props.session.user} login={@props.login}/>
          <Flash messages={@props.flash} dismissMessage={@props.flashDismiss}/>
        </div>
      }/>
    </Switch>

  renderApp: ->
    <div id="app">
      <DocumentTitle title={"Admin Panel"}/>
      <NavBar user={@props.session.user} signOut={@props.logout}/>
      <main>
        <ErrorBoundary>
          <Switch>
            <Route  path="/listings"                       component={Listings}                    exact={true}  />
            <Route  path="/listings/:id"                   component={Listing}                                   />
            <Route  path="/events"                         component={Events}                      exact={true}  />
            <Route  path="/events/:id"                     component={Event}                                     />
            <Route  path="/users"                          component={Users}                       exact={true}  />
            <Route  path="/users/:id"                      component={User}                                      />
            <Route  path="/tickets"                        component={Tickets}                     exact={true}  />
            <Route  path="/tickets/:id"                    component={Ticket}                                    />
            <Route  path="/cost-items"                     component={CostItems}                   exact={true}  />
            <Route  path="/transfers"                      component={Transfers}                   exact={true}  />
            <Route  path="/payment-plans"                  component={PaymentPlans}                exact={true}  />
            <Route  path="/fees/per-item"                  component={ItemCommissionCodes}         exact={true}  />
            <Route  path="/fees/per-order"                 component={OrderCommissionCodes}        exact={true}  />
            <Route  path="/withdrawals"                    component={Withdrawals}                 exact={true}  />
            <Route  path="/withdrawals/:id"                component={Withdrawal}                                />
            <Route  path="/emails"                         component={Emails}                      exact={true}  />
            <Route  path="/changesets/:id"                 component={Changeset}                                 />
            <Route  path="/chargebacks"                    component={Chargebacks}                 exact={true}  />
            <Route  path="/advances"                       component={Advances}                    exact={true}  />
            <Route  path="/attractions"                    component={Attractions}                               />
            <Route  path="/service-invoices"               component={ServiceInvoices}             exact={true}  />
            <Route  path="/notes"                          component={Notes}                       exact={true}  />
            <Route  path="/report-logs"                    component={ReportLogs}                  exact={true}  />
            <Route  path="/countries"                      component={Countries}                   exact={true}  />
            <Route  path="/ccpa/manual-disclose"           component={ManualDislcose}              exact={true}  />
           {<Route  path="/icp-queue"                      component={ICPQueue}                    exact={true}  /> if @props.can 'update_icp', 'User'}

            <Redirect from="/" to="/listings" />
          </Switch>
        </ErrorBoundary>
      </main>
      <Flash messages={@props.flash} dismissMessage={@props.flashDismiss}/>
    </div>
