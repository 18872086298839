import React from 'react'
import './styles'

import flash from '/lib/flash'

import { Link } from 'react-router-dom'

import ReactSelect        from '/components/react-select'
import ExternalLink       from '/components/external-link'
import ListingPhoto       from '/components/listing-photo'
import Pager              from '/components/pager/history-pager'
import Spinner            from '/components/spinner'
import Datetime           from '/components/datetime'
import Price              from '/components/price'
import StateActions       from '/components/state-label/actions'
import List               from '/components/list'
import Filters            from '/components/filters'
import UserCard           from '/components/user-card'
import NoteWarning        from '/components/note/warning'
import { state_options }  from '/models/listing'

import { withListings, withRPC } from './queries'

export default \
withListings \
withRPC \
class Listings extends React.Component

  rpc: (id, method)->
    {listing, errors} = await @props.rpc id, method
    if errors?.length
      flash.error errors
    else
      flash.success "Listing updated successfully"

  render: ->
    <div className="Listings">
      <header>
        <h1>Listings</h1>
      </header>
      <section className="content">
        {@filters()}
        {@list()}
      </section>

      <span className="text-center">
        <Pager meta={@props.listingsPagination} buffer={4}/>
      </span>
    </div>

  filters: ->
    <Filters>
      <Filters.Search/>
      <Filters.State options={state_options}/>
      <span className="pull-right">
        <Pager meta={@props.listingsPagination} buffer={2}/>
      </span>
    </Filters>

  list: ->
    return <Spinner/> if @props.listingsLoading
    <List>
      {@header()}
      {for listing in @props.listings
        <List.Row key={listing._key}>
          <List.Cell className="id">
            <Link className="btn btn-default" to={"/listings/#{listing.id}"}>
              More info
            </Link>
            <NoteWarning notes={listing.related_notes}/>
          </List.Cell>
          <List.Cell className="host">
            <UserCard user={listing.user} />
          </List.Cell>
          <List.Cell className="title">
            <Link to={"/listings/#{listing.slug_param}"}>
              <ListingPhoto image_url={listing.image_url} listing={listing} size={40}/>
              {listing.title}
            </Link>
            <ExternalLink url={"#{process.env.MULTIVERSE}/events/#{listing.slug_param}"}/>
          </List.Cell>
          <List.Cell className="created">
            <Datetime date={listing.created_at} format='MMM d, yyyy'/>
          </List.Cell>
          <List.Cell className="price">
            <Price amount={listing.price} currency={listing.src_currency}/>
          </List.Cell>
          <List.Cell className="actions">
            <StateActions
              model={listing}
              onClick={@rpc.bind @, listing.id}
            />
          </List.Cell>
        </List.Row>
      }
    </List>

  header: ->
    <List.HeaderRow>
      <List.Header className="id"></List.Header>
      <List.Header className="host">Host</List.Header>
      <List.Header className="title">Title</List.Header>
      <List.Header className="created">Created At</List.Header>
      <List.Header className="price">Price</List.Header>
      <List.Header className="actions">Actions</List.Header>
    </List.HeaderRow>
