import Model from '/lib/model'
import currencies, { base_currencies } from '/lib/currencies'
import { formatMoney } from 'accounting'

export default \
class NegativeBalance extends Model
  @className: 'NegativeBalance'
  constructor: ->
    super arguments...
    @id             = null # 123-CAD
    @user_id        = null # 123
    @amount         = null # -5.29
    @currency       = null # CAD

  @belongsTo 'user', model: 'User'
  @belongsTo 'service_invoice', model: 'ServiceInvoice'

  @computed 'key', ->
    [@currency, @id].join '-'

  @computed 'formattedPrice', ->
    symbol = currencies[@currency].replace @currency, ''
    return formatMoney '0.0', symbol: symbol unless @amount
    formatMoney @amount, symbol: symbol

export base_currency_options = value: cur, label: cur for cur in base_currencies
