import React from 'react'

import { Link } from 'react-router-dom'

import {
  state_options
  delivery_state_options
  type_options
} from '/models/email'

# components
import ExternalLink from '/components/external-link'
import ListingPhoto from '/components/listing-photo'
import Spinner      from '/components/spinner'
import Datetime     from '/components/datetime'
import StateLabel   from '/components/state-label'
import StateActions from '/components/state-label/actions'
import Price        from '/components/price'
import Pager        from '/components/pager/history-pager'
import Filters      from '/components/filters'
import List         from '/components/list'

import FaCloudDownload  from 'react-icons/lib/fa/cloud-download'

import { withUser, withEmails } from './queries'

export default \
withUser \
withEmails \
class Emails extends React.Component

  render: ->
    <div className="Emails">
      {@filters()}
      {@list()}
      {@pager()}
    </div>

  filters: ->
    <Filters>
      <Filters.Search/>
      <Filters.State label='Sending State' options={state_options}/>
      <Filters.State label='Receiver State' name='delivery_state' options={delivery_state_options}/>
      <Filters.Type options={type_options}/>
      <span className="pull-right">
        <Pager meta={@props.emailsPagination} buffer={2}/>
      </span>
    </Filters>


  list: ->
    return <Spinner/> if @props.emailsLoading
    <List>
      {@header()}
      {for email in @props.emails
        <List.Row key={email._key}>
          <List.Cell className="subject" title={email.subject}>
            {email.subject}
            <br/>
            <small>{email.type.replace /_/g, ' '}</small>
          </List.Cell>
          <List.Cell className="created_at">
            <Datetime date={email.created_at} format='MMM d, yyyy'/>
          </List.Cell>
          <List.Cell className="opens">{email.opens}</List.Cell>
          <List.Cell className="clicks">{email.clicks}</List.Cell>
          <List.Cell className="download">
            <a className="btn btn-default #{'disabled' unless email.s3_url}" href={email.s3_url}>
              <FaCloudDownload/>
            </a>
          </List.Cell>
          <List.Cell className="actions">
            <StateLabel model={email}/>
          </List.Cell>
          <List.Cell className="actions">
            <StateLabel model={email} state={email.delivery_state} title={email.delivery_message}/>
          </List.Cell>
        </List.Row>
      }
    </List>


  header: ->
    <List.HeaderRow>
      <List.Header className="subject">Subject</List.Header>
      <List.Header className="created-at">Created</List.Header>
      <List.Header className="opens">Opens</List.Header>
      <List.Header className="clicks">Clicks</List.Header>
      <List.Header className="download">Download</List.Header>
      <List.Header className="actions">Sending State</List.Header>
      <List.Header className="actions">Receiver State</List.Header>
    </List.HeaderRow>


  pager: ->
    <div className="text-center">
      <Pager meta={@props.emailsPagination} buffer={4}/>
    </div>
