import React from 'react'
import PropTypes from 'prop-types'
import './styles'

import humanize from 'underscore.string/humanize'
import Model from '/lib/model'

export default \
class StateLabel extends React.Component
  @propTypes:
    state:  PropTypes.string
    type:   PropTypes.string
    model:  PropTypes.instanceOf(Model).isRequired
    title:  PropTypes.string

  render: ->
    cn = @props.type or @props.model.constructor.className
    state = @props.state or @props.model.state
    <span className="StateLabel btn #{cn} #{state}" title={@props.title}>
      {humanize state}
    </span>
