import { graphql } from 'react-apollo'

#
# mutations
#

import disclosure_create from '/mutations/ccpa/manual-disclosure.gql'

export withManualDisclsosureCreate = graphql disclosure_create,
  props: ({ mutate })->
    disclosureCreate: (ccpaDisclosure)->
      {data: {disclosure_create: {disclosure, errors}}} = \
      await mutate variables: disclosure: ccpaDisclosure
      {success, errors}
