import { graphql } from 'react-apollo'
import Models           from '/models/index'

#
# Queries
#
import index          from './queries/index.gql'
import show           from './queries/show.gql'
import printing_logs  from './queries/printing-logs.gql'
import cost_items     from './queries/cost-items.gql'
import changes        from './queries/changes.gql'
import emails         from './queries/emails.gql'
import notes          from './queries/notes.gql'

export withTickets = graphql index,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.tickets or {}
    tickets: Models.Ticket.parse nodes or []
    ticketsPagination: pagination
    ticketsLoading: data.loading

export withTicket = graphql show,
  options: (props)->
    { id } = props.match.params
    variables: { id }
  props: ({props, data})->
    ticket: Models.Ticket.parse data.ticket
    ticketLoading: data.loading

export withNotes = graphql notes,
  options: (props)->
    { id } = props.match.params
    { state } = props.location.state?.filters or {}
    variables:
      id: id
      states: state or []
  props: ({props, data})->
    notes: Models.Note.parse data.ticket?.notes or []
    notesLoading: data.loading
    notesRefetch: data.refetch

export withActiveNotes = graphql notes,
  options: (props)->
    { id } = props.match.params
    variables:
      id: id
      states: ['active']
  props: ({props, data})->
    notes: Models.Note.parse data.ticket?.notes or []
    notesLoading: data.loading
    notesRefetch: data.refetch

export withCostItems = graphql cost_items,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.ticket?.cost_items or {}
    cost_items: Models.CostItem.parse nodes
    cost_itemsPagination: pagination
    cost_itemsLoading: data.loading

export withPrintingLogs = graphql printing_logs,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.ticket?.printing_logs or {}
    printing_logs: Models.PrintingLog.parse nodes
    printing_logsPagination: pagination
    printing_logsLoading: data.loading

export withEmails = graphql emails,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.ticket?.emails or {}
    emails: Models.Email.parse nodes or []
    emailsPagination: pagination
    emailsLoading: data.loading

export withChanges = graphql changes,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.ticket?.changes or {}
    changes: Models.Change.parse nodes or []
    changesPagination: pagination
    changesLoading: data.loading

#
# Mutations
#

import tickets_rpc                  from '/mutations/tickets/rpc.gql'
import tickets_resend               from '/mutations/tickets/resend.gql'
import tickets_send_message         from '/mutations/tickets/send_message.gql'
import cost_items_rpc               from '/mutations/cost_items/rpc.gql'

export withTicketsRPC = graphql tickets_rpc,
  props: ({ mutate })->
    tickets_rpc: (id, method)->
      {data: {ticket_rpc: {ticket, errors}}} = \
      await mutate variables: {id, method}
      {ticket, errors}

export withTicketsResend = graphql tickets_resend,
  props: ({ mutate })->
    tickets_resend: (id)->
      {data: {ticket_resend: {ticket, errors}}} = \
      await mutate variables: {id}
      {ticket, errors}

export withTicketSendMessage = graphql tickets_send_message,
  props: ({ mutate })->
    send_message: (id, subject, message, recipient) ->
      {data: {ticket_send_message: {ticket, errors}}} = \
      await mutate variables: {id, subject, message, recipient}
      {ticket, errors}

export withCostItemsRPC = graphql cost_items_rpc,
  props: ({ mutate })->
    cost_items_rpc: (id, method)->
      {data: {cost_item_rpc: {cost_item, errors}}} = \
      await mutate variables: {id, method}
      {cost_item, errors}
