import { graphql } from 'react-apollo'
import Models           from '/models/index'

#
# Queries
#
import index              from './queries/index.gql'
import show               from './queries/show.gql'
import events             from './queries/events.gql'
import report             from './queries/report.gql'
import rates              from './queries/rates.gql'
import commission_codes   from './queries/commission_codes.gql'
import duplications       from './queries/duplications.gql'
import changes            from './queries/changes.gql'
import ticketmasterVenues from './queries/ticketmaster_venues.gql'
import organization       from './queries/organization.gql'
import kickback           from './queries/kickback.gql'
import order_level_fees   from './queries/order_level_fees.gql'
import attractions        from './queries/attractions.gql'
import ticketmaster       from './queries/ticketmaster.gql'
import notes              from './queries/notes.gql'
import discount_codes     from './queries/discount_codes.gql'
import access_keys        from './queries/access_keys.gql'
import vats               from './queries/vats.gql'
import emails             from './queries/emails.gql'
import email_stats        from './queries/email-stats.gql'
import order_fee          from './queries/order-fee.gql'

export withListings = graphql index,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.listings or {}
    listings: Models.Listing.parse nodes or []
    listingsPagination: pagination
    listingsLoading: data.loading

export withListing = graphql show,
  options: (props)->
    variables:
      id: props.match.params.id
  props: ({props, data})->
    listing: Models.Listing.parse data.listing
    listingLoading: data.loading

export withNotes = graphql notes,
  options: (props)->
    { id } = props.match.params
    { state } = props.location.state?.filters or {}
    variables:
      id: id
      states: state or []
  props: ({props, data})->
    notes: Models.Note.parse data.listing?.notes or []
    notesLoading: data.loading
    notesRefetch: data.refetch

export withActiveNotes = graphql notes,
  options: (props)->
    { id } = props.match.params
    variables:
      id: id
      states: ['active']
  props: ({props, data})->
    notes: Models.Note.parse data.listing?.notes or []
    notesLoading: data.loading
    notesRefetch: data.refetch

export withTicketmasterListing = graphql ticketmaster,
  options: (props)->
    variables:
      id: props.match.params.id
  props: ({props, data})->
    listing: Models.Listing.parse data.listing
    listingLoading: data.loading

export withEvents = graphql events,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.listing?.events or {}
    events: Models.Event.parse nodes
    eventsPagination: pagination
    eventsLoading: data.loading

export withAllEvents = graphql events,
  options: (props)->
    variables:
      id: props.match.params.id
      per_page: 1000
  props: ({props, data})->
    { nodes } = data.listing?.events or {}
    events: Models.Event.parse nodes
    eventsLoading: data.loading

export withRates = graphql rates,
  options: (props)->
    variables:
      id: props.match.params.id
  props: ({props, data})->
    rates: Models.Rate.parse data.listing?.rates
    referral: data.listing?.referral
    ratesLoading: data.loading

export withOrderLevelCodes = graphql order_level_fees,
  options: (props)->
    variables:
      per_page: 100000
  props: ({props, data})->
    olfCodes: data.order_level_fees?.nodes or []
    codesLoading: data.loading

export withCommissionCodes = graphql commission_codes,
  options: (props)->
    variables:
      filters:
        currency: props.listing?.src_currency
        state: 'approved'
      per_page: 100000
  props: ({props, data})->
    { nodes } = data.commission_codes or {}
    codes: Models.CommissionCode.parse nodes or []
    codesLoading: data.loading

export withReport = graphql report,
  options: (props)->
    variables:
      id: props.match.params.id
  props: ({props, data})->
    report: Models.EventReport.parse data.listing?.report
    reportLoading: data.loading

export withDuplications = graphql duplications,
  options: (props)->
    variables:
      id: props.match.params.id
  props: ({props, data})->
    duplicationsRefetch: data.refetch
    duplications: Models.Listing.parse data.listing?.duplicated_listings or []
    duplicationsLoading: data.loading

export withChanges = graphql changes,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.listing?.changes or {}
    changes: Models.Change.parse nodes or []
    changesPagination: pagination
    changesLoading: data.loading

export withTicketmasterVenues = graphql ticketmasterVenues,
  options: (props)->
    { venue_search, filters } = props.location.state or {}
    variables:
      search: venue_search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.ticketmaster_venues or {}
    ticketmaster_venues: Models.TicketmasterVenue.parse nodes or []
    ticketmasterVenuesLoading: data.loading

export withAttractions = graphql attractions,
  options: (props)->
    { attraction_search, filters } = props.location.state or {}
    variables:
      search: attraction_search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.attractions or {}
    attractions: Models.Attraction.parse nodes or []
    attractionsLoading: data.loading

export withOrganization = graphql organization,
  options: (props)->
    variables:
      id: props.match.params.id
  props: ({props, data})->
    organization: Models.Organization.parse data.listing?.user?.organization or {}
    organizationLoading: data.loading

export withKickbackRates = graphql kickback,
  options: (props)->
    variables:
      id: props.match.params.id
  props: ({props, data})->
    { nodes, pagination } = data.listing?.events or {}
    events: Models.Event.parse nodes or {}
    rates: Models.Rate.parse data.listing?.rates or {}
    listing: Models.Listing.parse data.listing or {}
    ratesLoading: data.loading

export withDiscountCodes = graphql discount_codes,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.listing?.discount_codes or {}
    discount_codes: Models.DiscountCode.parse nodes
    discount_codesPagination: pagination
    discount_codesLoading: data.loading

export withAccessKeys = graphql access_keys,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.listing?.access_keys or {}
    access_keys: Models.AccessKey.parse nodes
    access_keysPagination: pagination
    access_keysLoading: data.loading

export withVats = graphql vats,
  options: (props)->
    variables:
      country_code: props.listing?.country_code
  props: ({props, data})->
    vats: Models.Vat.parse data.vats or []

export withEmails = graphql emails,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.listing?.emails or {}
    emails: Models.Email.parse nodes or []
    emailsPagination: pagination
    emailsLoading: data.loading

export withEmailStats = graphql email_stats,
  options: (props)->
    { id } = props.match.params
    variables: { id }
  props: ({props, data})->
    emailStats: data.listing?.email_stats
    emailStatsLoading: data.loading

export withOrderFee = graphql order_fee,
  options: (props)->
    variables:
      id: props.match.params.id
  props: ({props, data})->
    orderFee: data.listing?.order_fee or {}
    orderFeeLoading: data.loading

#
# Mutations
#

import rpc                      from '/mutations/listings/rpc.gql'
import update_rate              from '/mutations/listings/rates.gql'
import update_referral          from '/mutations/listings/referral.gql'
import duplicate                from '/mutations/listings/duplicate.gql'
import updateTicketmasterVenue  from '/mutations/listings/update_ticketmaster_venue.gql'
import updateChartKey           from '/mutations/listings/update_chart_key.gql'
import updateSeatsCapacity      from '/mutations/listings/update_seats_capacity.gql'
import syncGilaData             from '/mutations/listings/sync_gila_data.gql'
import syncAllInPricingData     from '/mutations/listings/sync_all_in_pricing_data.gql'
import updateCardBins           from '/mutations/listings/update_card_bins.gql'
import update_vat               from '/mutations/listings/update_vat.gql'
import createSeatsSubaccount    from '/mutations/users/create_seats_subaccount.gql'
import updateAttractions        from '/mutations/listings/update_attractions.gql'
import fee_assignment_update    from '/mutations/listings/fee_assignment_update.gql'
import deleteFeeAssignment      from '/mutations/listings/fee_assignment_delete.gql'
import importThirdPartyTickets  from '/mutations/third_party_tickets/import.gql'
import ticketCollisionCheck    from '/mutations/third_party_tickets/collision_check.gql'

export withRPC = graphql rpc,
  props: ({ mutate })->
    rpc: (id, method)->
      {data: {listing_rpc: {listing, errors}}} = \
      await mutate variables: {id, method}
      {listing, errors}

export updateRate = graphql update_rate,
  props: ({ mutate })->
    updateRate: (id, commission_code)->
      {data: {rate_update: {rate, errors}}} = \
      await mutate variables: {id, commission_code}
      {rate, errors}

export updateReferral = graphql update_referral,
  props: ({ mutate })->
    updateReferral: (id, referral)->
      {data: {listing_update: {listing, errors}}} = \
      await mutate variables: {id, referral}
      {listing, errors}

export withUpdateTaxOverride = graphql update_vat,
  props: ({ mutate })->
    updateTaxOverride: (id, vat_id)->
      {data: {listing_update: {listing, errors}}} = \
      await mutate variables: {id, vat_id}
      {listing, errors}

export withUpdateTicketmasterVenue = graphql updateTicketmasterVenue,
  props: ({ mutate })->
    updateTicketmasterVenue: (id, ticketmaster_venue_id, ticketmaster_venue_name)->
      {data: {listing_update_ticketmaster_venue: {listing, errors}}} = \
      await mutate variables: {id, ticketmaster_venue_id, ticketmaster_venue_name}
      {listing, errors}

export withUpdateAttractions = graphql updateAttractions,
  props: ({ mutate })->
    updateAttractions: (id, attraction_ids)->
      {data: {listing_update_attractions: {listing, errors}}} = \
      await mutate variables: {id, attraction_ids}
      {listing, errors}

export withDuplicate = graphql duplicate,
  props: ({ mutate })->
    duplicate: ({
      id,
      date,
      title,
      dup_access_keys,
      dup_discount_codes,
      dup_attractions,
      dup_private_note,
      dup_rate_upgrades,
      dup_host_fields,
      dup_referral_codes,
      dup_google_analytics_4_id,
      dup_google_ads_trackers,
      dup_facebook_pixels,
      dup_tiktok_pixels,
      dup_mailchimp_api_key
    })->
      {data: {listing_duplicate: {listing, errors}}} = \
      await mutate variables: {
        id,
        date,
        title,
        dup_access_keys,
        dup_discount_codes,
        dup_attractions,
        dup_private_note,
        dup_rate_upgrades,
        dup_host_fields,
        dup_referral_codes,
        dup_google_analytics_4_id,
        dup_google_ads_trackers,
        dup_facebook_pixels,
        dup_tiktok_pixels,
        dup_mailchimp_api_key
      }
      {listing, errors}

export withUpdateSeatsChartKey = graphql updateChartKey,
  props: ({ mutate })->
    updateChartKey: (id, seats_chart_key)->
      {data: {listing_update_seats_chart_key: {listing, errors}}} = \
      await mutate variables: {id, seats_chart_key}
      {listing, errors}

export withUpdateSeatsCapacity = graphql updateSeatsCapacity,
  props: ({ mutate })->
    updateSeatsCapacity: (id)->
      {data: {listing_sync_seats_capacity: {listing, errors}}} = \
      await mutate variables: {id}
      {listing, errors}

export withCreateSeatsSubaccount = graphql createSeatsSubaccount,
  props: ({ mutate })->
    createSeatsSubaccount: (id)->
      {data: {user_create_seats_subaccount: {user, errors}}} = \
      await mutate variables: {id}
      {user, errors}

export withSyncGilaData = graphql syncGilaData,
  props: ({ mutate })->
    syncGilaData: (id)->
      {data: {listing_sync_gila_data: {listing, errors}}} = \
      await mutate variables: {id}
      {listing, errors}

export updateListingOrderLevelCode = graphql fee_assignment_update,
  props: ({ mutate })->
    updateListingOrderLevelCode: (id, code)->
      {data: {listing_update_fee_assignment: {listing, errors}}} = \
      await mutate variables: {id, code}
      {listing, errors}

export withSyncAllInPricingData = graphql syncAllInPricingData,
  props: ({ mutate })->
    syncAllInPricingData: (id)->
      {data: {listing_sync_all_in_pricing_data: {listing, errors}}} = \
      await mutate variables: {id}
      {listing, errors}

export withUpdateRestrictedBins = graphql updateCardBins,
  props: ({ mutate })->
    updateCardBins: (id, restricted_card_bins)->
      {data: {listing_update_card_bins: {listing, errors}}} = \
      await mutate variables: {id, restricted_card_bins}
      {listing, errors}

export withThirdPartyTicketImport = graphql importThirdPartyTickets,
  props: ({ mutate })->
    importThirdPartyTickets: (source, data)->
      mutate variables: {source, data}

export withTicketCollisionCheck = graphql ticketCollisionCheck,
  props: ({ mutate })->
    ticketCollisionCheck: (tokens)->
      mutate variables: {tokens}

export deleteListingOrderLevelCode = graphql deleteFeeAssignment,
  props: ({ mutate })->
    deleteListingOrderLevelCode: (id)->
      {data: {listing_remove_fee_assignment: {listing, errors}}} = \
      await mutate variables: {id}
      {listing, errors}
