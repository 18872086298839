import React from 'react'
import './styles'

import flash from '/lib/flash'

import { Link } from 'react-router-dom'

import {
  state_options
} from '/models/note'

# componennts
import Pager        from '/components/pager/history-pager'
import Spinner      from '/components/spinner'
import Datetime     from '/components/datetime'
import List         from '/components/list'
import Filters      from '/components/filters'
import StateActions from '/components/state-label/actions'
import UserCard     from '/components/user-card'

import {
  withNotes
  withNotesRPC
} from './queries'

export default \
withNotes \
withNotesRPC \
class Notes extends React.Component

  rpc: (id, method)=>
    {note, errors} = await @props.notes_rpc id, method
    flash.error errors if errors?.length

  render: ->
    <div className="Notes">
      <header>
        <h1>Notes</h1>
      </header>
      <section className="content">
        {@filters()}
        {@list()}
        {@pager()}
      </section>
    </div>

  filters: ->
    <Filters>
      <Filters.Search/>
      <Filters.State options={state_options}/>
      <span className="pull-right">
        <Pager meta={@props.notesPagination} buffer={2}/>
      </span>
    </Filters>

  list: ->
    return <Spinner/> if @props.notesLoading
    <List>
      {@header()}
      {for note in @props.notes
        <List.Row key={note._key}>
          <List.Cell className="target">
            <Link
              className="btn btn-default"
              to={"/#{note.target_type}s/#{note.target_id}"}
            >
              {note.target_type}
            </Link>
          </List.Cell>
          <List.Cell className="text">
            {note.text}
          </List.Cell>
          <List.Cell className="user">
            <UserCard user={note.user}/>
          </List.Cell>
          <List.Cell className="created-at">
            <Datetime date={note.created_at}/>
          </List.Cell>
          <List.Cell className="actions">
            <StateActions
              model={note}
              onClick={@rpc.bind @, note.id}
            />
          </List.Cell>
        </List.Row>
      }
    </List>

  header: ->
    <List.HeaderRow>
      <List.Header className="target">Subject</List.Header>
      <List.Header className="text">Text</List.Header>
      <List.Header className="user">Author</List.Header>
      <List.Header className="created-at">Created At</List.Header>
      <List.Header className="actions">Actions</List.Header>
    </List.HeaderRow>

  pager: ->
    <div className="text-center">
      <Pager meta={@props.notesPagination} buffer={4}/>
    </div>
