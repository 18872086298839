import Model from '/lib/model'
import humanize   from 'underscore.string/humanize'
$w = (str)-> (t for t in str.split(' ') when t isnt '')

export default \
class Note extends Model
  @className: 'Note'
  constructor: ->
    super arguments...
    @id               = null # 5c8ab3f70b6edae2f2d19b4c
    @target_ids       = null # [5c8ab3f70b6edae2f2d19b4c, 5c8ab3f70b6edae2f2d19b4c]
    @text             = null # "Adriel is cool"
    @state            = null # active | resolved
    @created_at       = null # "2019-01-15T19:41:54Z",
    @updated_at       = null # "2019-01-15T23:49:15Z",

  @belongsTo 'user',    model: 'User'

  @computed 'transitions', ->
    ret = {}
    for tr in state_actions[@state] or []
      ret[tr] = transition_name_overrides?[tr] or humanize tr
    ret

export states = $w 'active resolved'

export state_options = value: state, label: humanize state for state in states

export state_actions =
  active:     $w 'resolve'
  resolved:   $w 'reactivate'
