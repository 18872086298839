import React from 'react'
import './styles'

export default \
class Cell extends React.Component
  @displayName: 'Cell'

  render: ->
    { children, className='', props... } = @props
    <div className="Cell #{className}" {props...} >
      {children}
    </div>
