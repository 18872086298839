import Model from '/lib/model'
import humanize from 'underscore.string/humanize'

export default \
class Changeset extends Model
  @className: 'Changeset'

  @belongsTo 'user',    model: 'User'

  @hasMany 'changes',   model: 'Change'
  ###
  ctx
  meta
  request_path
  user {
    id
    name
  }
  ###
