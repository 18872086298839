import React from 'react'
import './styles'

import { Link } from 'react-router-dom'

# componennts
import Datetime                     from '/components/datetime'
import Filters                      from '/components/filters'
import List                         from '/components/list'
import Pager                        from '/components/pager/history-pager'
import Price                        from '/components/price'
import Spinner                      from '/components/spinner'

import { base_currency_options }    from '/models/advance'
import { withAdvances }             from './queries'

export default \
withAdvances \
class Advances extends React.Component

  render: ->
    <div className="Advances">
      <header>
        <h1>Advances</h1>
      </header>
      <section className="content">
        {@filters()}
        {@list()}
        {@pager()}
      </section>
    </div>

  filters: ->
    <Filters>
      <Filters.Search placeholder="Search Advance ID or Currency"/>
      <Filters.Currency options={base_currency_options}/>
      <span className="pull-right">
        <Pager meta={@props.advancesPagination} buffer={2}/>
      </span>
    </Filters>

  list: ->
    return <Spinner/> if @props.advancesLoading
    <List>
      {@header()}
      {for advance in @props.advances
        <List.Row key={advance._key}>
          <List.Cell className="id">
            <List.Cell className="user">
              {advance.id}
            </List.Cell>
          </List.Cell>
          <List.Cell className="description">
            {advance.description}
          </List.Cell>
          <List.Cell className="currency">
            {advance.currency}
          </List.Cell>
          <List.Cell className="amount">
            <Price amount={advance.amount} currency={advance.currency}/>
          </List.Cell>
          <List.Cell className="created-at">
            <Datetime date={advance.created_at}/>
          </List.Cell>
        </List.Row>
      }
    </List>

  header: ->
    <List.HeaderRow>
      <List.Header className="id">id</List.Header>
      <List.Header className="description">Description</List.Header>
      <List.Header className="currency">Currency</List.Header>
      <List.Header className="amount">Amount</List.Header>
      <List.Header className="created-at">Created At</List.Header>
    </List.HeaderRow>

  pager: ->
    <div className="text-center">
      <Pager meta={@props.advancesPagination} buffer={4}/>
    </div>
