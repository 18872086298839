import { graphql } from 'react-apollo'
import Email       from '/models/email'


#
# Queries
#

import index        from './queries/index.gql'

export withEmails = graphql index,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.emails or {}
    emails: Email.parse nodes or []
    emailsPagination: pagination
    emailsLoading: data.loading
