import React from 'react'
import { Link } from 'react-router-dom'

import flash from '/lib/flash'

import Papa from 'papaparse'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import Models       from '/models/index'
import Spinner      from '/components/spinner'

import {
  withListing,
  withAllEvents,
  withRates,
  withThirdPartyTicketImport,
  withTicketCollisionCheck
} from './queries'
import withSession  from '/lib/with-session'

export default \
withListing \
withAllEvents \
withRates \
withThirdPartyTicketImport \
withTicketCollisionCheck \
withSession \
class Import extends React.Component
  constructor: ->
    super arguments...
    @state =
      other: []
      thirdPartyTickets: []
      rates: []
      events: []
      source: ''
      step: 0
      formatConfirmed: false
      formatChecked: false
      importing: false
      progress: 0
      startTime: 0
      lastCompletion: 0
      count: 0
      tickets: []

  processFile: (evt) =>
    Papa.parse evt.target?.files[0], complete: @processData

  universeTokenFormat: (token) =>
    /^C[BCDFGHJKLMNPQRSTVWXYZ0123456789]{8}$/i.test token

  processData: (file) =>
    formatError = false
    data = file.data
    thirdPartyTickets = []
    rates = []
    events = []
    for item, index in data
      thirdPartyTicket = {}
      break unless item.length > 1
      if item.length != 6
        thirdPartyTicket = []
        return flash.error "This CSV is an unrecognized format. Please make sure the format is firstName,lastName,email,token,date,ticketType."
      if @universeTokenFormat(item[3]) && !formatError
        formatError = true
        flash.error "Token #{item[3]} matches the Universe format. This could mean the token matches one in the Universe system, which could cause problems Scanning In."
      for element, index in item
        switch index
          when 0
            thirdPartyTicket.firstName = element
          when 1
            thirdPartyTicket.lastName = element
          when 2
            thirdPartyTicket.email = element
          when 3
            thirdPartyTicket.token = element
          when 4
            thirdPartyTicket.event = element
            events.push({id: null, name: element}) if events.map((e) => e.name ).indexOf(element) == -1
          when 5
            thirdPartyTicket.name = element
            thirdPartyTicket.rate = element
            rates.push({id: null, name: element}) if rates.map((e) => e.name ).indexOf(element) == -1
      thirdPartyTickets.push thirdPartyTicket

    tokens = thirdPartyTickets.map((ci) -> ci.token)
    if (new Set(tokens)).size != tokens.length
      return flash.error "This CSV contained duplicate tokens. Please make sure all tokens are unique."

    @setState
      thirdPartyTickets: thirdPartyTickets
      rates:     rates
      events:    events
      count:     thirdPartyTickets.length

    @checkCollisions()

  setRate: (text, evt) =>
    rates = @state.rates
    rates.filter((rate) -> rate.name == text)[0].id = evt.target.value
    thirdPartyTickets = @state.thirdPartyTickets
    for thirdPartyTicket in thirdPartyTickets.filter((ci) -> ci.rate == text)
      thirdPartyTicket.rateId = evt.target.value
    @setState
      rates: rates,
      thirdPartyTickets: thirdPartyTickets

  setEvent: (text, evt) ->
    events = @state.events
    events.filter((event) -> event.name == text)[0].id = evt.target.value
    thirdPartyTickets = @state.thirdPartyTickets
    for thirdPartyTicket in thirdPartyTickets.filter((thirdPartyTicket) -> thirdPartyTicket.event == text)
      thirdPartyTicket.eventId = evt.target.value
    @setState
      events: events,
      thirdPartyTickets: thirdPartyTickets

  eventSelector: (type) =>
    <select className="form-control" onChange={@setEvent.bind @, type}>
      <option value={null}>Select an Event</option>
      {for key, event of @props.events
        <option key={event.id} value={event.id}>{event.when}</option>
      }
    </select>

  rateSelector: (type) =>
    <select className="form-control" onChange={@setRate.bind @, type}>
      <option value={null}>Select a Rate</option>
      {for key, rate of @props.rates
        <option key={rate.id} value={rate.id}>{rate.name}</option>
      }
    </select>

  updateField: (field, evt) =>
    @setState "#{field}": evt?.target?.value

  updateBool: (field, evt) =>
    @setState "#{field}": evt?.target?.checked

  preventClosing: (evt)->
    # Frantically try to stop the closing
    alert("Don't close that!")
    evt?.preventDefault?()
    evt?.stopPropagation?()
    evt?.cancelBubble = true
    evt?.stopImmediatePropagation?()
    evt?.returnValue = ''
    return "please don't close this, an upload is in progress"

  checkCollisions: =>
    console.log("Checking Collisions")
    tokens = @state.thirdPartyTickets.map((ci) -> ci.token)
    @props.ticketCollisionCheck(tokens)
    .then (result) =>
      result = result.data.ticket_collision_check
      if result.status == "error"
        if result.collisions.length > 5
          flash.error "More than 5 tokens already exist in Universe as either IDs, Tokens or Buyer Reference IDs. This list has either already been imported or is fatally unimportable."
        else
          for collision in result.collisions
            flash.error collision
        flash.error "List Not Importable, Collisions Exist on Universe. Contact Product Team for Help Resolving"
        @clear()

  upload: =>
    console.log("Beginning Data Upload of #{@state.thirdPartyTickets.length} Cost Items")
    window.addEventListener('beforeunload', @preventClosing)

    @setState startTime: Date.now(), importing: true

    # update the progress during the upload
    interval = setInterval () =>
      this.setState time: Date.now()
    , 1000

    NProgress.start()
    batch = []

    thirdPartyTickets = @state.thirdPartyTickets.slice()
    count = thirdPartyTickets.length
    current = 0
    ticket_batch = []

    promises = []
    for tpt in thirdPartyTickets
      thirdPartyTicket = {
        first_name: tpt.firstName,
        last_name: tpt.lastName,
        email: tpt.email,
        token: tpt.token,
        rate_id: tpt.rateId,
        event_id: tpt.eventId
      }
      ticket_batch.push(thirdPartyTicket)

      if ticket_batch.length == 250
        promises.push(new Promise( (resolve) =>
          @props.importThirdPartyTickets(@state.source, ticket_batch.slice())
          .then( (result) =>
            @setState progress: (@state.progress + ticket_batch.length), lastCompletion: Date.now()
            console.log("Processed 250 Cost Items", result)
            resolve()
          )
          ticket_batch = []
        ))

    Promise.all(promises).then( () =>
      if ticket_batch.length > 0
        @props.importThirdPartyTickets(@state.source, ticket_batch.slice())
        .then( (result) =>
          console.log("Last Cost Items Processed", result)
          console.log("Upload Complete")
          @setState progress: (@state.progress + ticket_batch.length), lastCompletion: Date.now()
          window.removeEventListener('beforeunload', @preventClosing)
          clearInterval(interval)
          NProgress.done()
          @nextPage()
        )
      else
        console.log("Last Cost Items Processed")
        console.log("Upload Complete")
        @setState progress: (@state.progress + ticket_batch.length), lastCompletion: Date.now()
        window.removeEventListener('beforeunload', @preventClosing)
        clearInterval(interval)
        NProgress.done()
        @nextPage()
    )

  nextPage: =>
    @setState
      valid: false
      step: @state.step + 1
      formatConfirmed: false

  clear: =>
    @setState
      thirdPartyTickets: []
      rates: []
      events: []
      source: ''
      step: 0

  predictTime: =>
    time = Math.floor((@state.count / 1000) * 60)

  formatTime: (time) =>
    time = @predictTime() if (time > @predictTime())
    minutes = Math.max(0, Math.floor(time / 60))
    seconds = Math.max(0, time - minutes * 60)
    return "#{minutes}min and #{Math.floor(seconds)}sec"

  estimateProgress: =>
    if @state.progress
      frequency = @state.progress / ((@state.lastCompletion - @state.startTime) / 1000) # completions per second
      timeSinceStart = (Date.now() - @state.startTime) / 1000 # time since start in seconds
      return Math.floor(frequency * timeSinceStart)
    else
      return Math.floor((((Date.now() - @state.startTime) / 1000) / 17) * 250)

  estimateTime: =>
    remaining = @state.count - @estimateProgress()
    pace = @estimateProgress() / ((Date.now() - @state.startTime) / 1000) # processed per second
    return remaining / pace

  importStep: =>
    <div>
      <div className="data-section">
        <h3>Instructions</h3>
        <p>Upload a CSV and input a Source (the original ticket sales provider).</p>
        <p>The CSV needs to be in the format:</p>
        <p className="csv-format">[FIRST_NAME],[LAST_NAME],[EMAIL],[TICKET_CODE],[EVENT_DATE],[TICKET_TYPE]</p>
        <p className="csv-format">Jess,Gotfrit,jessgotfrit@example.com,J23BDSA8SDKAFL3W,Aug 29 2022,VIP Level</p>
        <p>Please ensure the CSV matches this format!</p>
      </div>

      <div className="csv-importer data-section">
        <h3>Ticket CSV Importer</h3>
        <label>
          Ticket CSV File
          <input type="file" onChange={@processFile} accept=".csv"/>
        </label>
        <label>
          Ticket Source Provider
          <input type="text" placeholder="Ticket Provider Name" onChange={@updateField.bind @, 'source'}/>
        </label>
      </div>

      {if @state.count > 0 && @state.thirdPartyTickets.length > 0 && @state.source
        <div className="data-section">
          <h3>Preview</h3>
          <table>
            <thead>
              <tr>
                <td>FIRST NAME</td>
                <td>LAST NAME</td>
                <td>EMAIL</td>
                <td>TICKET CODE</td>
                <td>EVENT DATE</td>
                <td>TICKET TYPE</td>
              </tr>
            </thead>
            <tbody>
              {if @state.thirdPartyTickets[0]
                <tr>
                  <td>{@state.thirdPartyTickets[0].firstName}</td>
                  <td>{@state.thirdPartyTickets[0].lastName}</td>
                  <td>{@state.thirdPartyTickets[0].email}</td>
                  <td>{@state.thirdPartyTickets[0].token}</td>
                  <td>{@state.thirdPartyTickets[0].event}</td>
                  <td>{@state.thirdPartyTickets[0].rate}</td>
                </tr>
              }
              {if @state.thirdPartyTickets[@state.count - 1]
                <tr>
                  <td>{@state.thirdPartyTickets[@state.count - 1].firstName}</td>
                  <td>{@state.thirdPartyTickets[@state.count - 1].lastName}</td>
                  <td>{@state.thirdPartyTickets[@state.count - 1].email}</td>
                  <td>{@state.thirdPartyTickets[@state.count - 1].token}</td>
                  <td>{@state.thirdPartyTickets[@state.count - 1].event}</td>
                  <td>{@state.thirdPartyTickets[@state.count - 1].rate}</td>
                </tr>
              }
            </tbody>
          </table>
          <p>If they do not, please double check your CSV file and try again</p>
          <p><b>{@state.count}</b> tickets loaded from file.</p>
          <p>The ticket source is <b>{@state.source}</b>.</p>
          <label>
            <input type="checkbox" onChange={@updateBool.bind @, 'formatConfirmed'}/>
            Yes, I, <span>{@props.session.user.name}</span>, have triple checked the above information.
          </label>
          {if @state.formatConfirmed
            <a onClick={@nextPage} className="NextButton">Next</a>
          }
        </div>
      }
    </div>

  mapRatesStep: =>
    <div className="data-section">
      <h3>Map Ticket Types to Universe Rates</h3>
      <p>Map the various competitor Ticket Types from the CSV to the Ticket Rates on this Listing.</p>
      <p>This means that any Ticket of the Type selected will have a Cost Item created on Universe with the selected Rate</p>
      <table>
        <thead>
          <tr>
            <td>External Ticket Type</td>
            <td>Universe Rate</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {for rate in @state.rates
            <tr key={rate.name}>
              <td>{rate.name}</td>
              <td>
                {if rate.id
                  @props.rates.filter((r) => r.id == rate.id)[0].name
                }
              </td>
              <td>{@rateSelector(rate.name)}</td>
            </tr>
          }
        </tbody>
      </table>
      {if @state.rates.length == @state.rates.map((r) => r.id).filter((r) => r != null).length
        <div>
          <label>
            <input type="checkbox" onChange={@updateBool.bind @, 'formatConfirmed'}/>
            Yes, I, <span>{@props.session.user.name}</span>, have triple checked the above information.
          </label>

          {if @state.formatConfirmed
            <a onClick={@nextPage} className="NextButton">Next</a>
          }
        </div>
      }
    </div>

  mapEventStep: =>
    <div className="data-section">
      <h4>Map Ticket Times to Universe Events</h4>
      <p>Map the various competitor Ticket Dates from the CSV to the Ticket Event Dates on this Listing.</p>
      <p>This means that any Ticket for the Date selected will have a Cost Item created on Universe with the selected Event (time).</p>
      <table>
        <thead>
          <tr>
            <td>External Ticket Date</td>
            <td>Universe Event</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {for event in @state.events
            <tr key={event.name}>
              <td>{event.name}</td>
              <td>
                {if event.id
                  @props.events.filter((e) => e.id == event.id)[0].when
                }
              </td>
              <td>{@eventSelector(event.name)}</td>
            </tr>
          }
        </tbody>
      </table>
      {if @state.events.length == @state.events.map((r) => r.id).filter((r) => r != null).length
        <div>
          <label>
            <input type="checkbox" onChange={@updateBool.bind @, 'formatConfirmed'}/>
            Yes, I, <span>{@props.session.user.name}</span>, have triple checked the above information.
          </label>

          {if @state.formatConfirmed
            <a onClick={@nextPage} className="NextButton">Next</a>
          }
        </div>
      }
    </div>

  saveStep: =>
    <div className="data-section">
      <h4>Review and Save</h4>
      <p>Please check this sample of the first and last imported Cost Items for errors.</p>
      <table>
        <thead>
          <tr>
            <td>FIRST NAME</td>
            <td>LAST NAME</td>
            <td>EMAIL</td>
            <td>TOKEN</td>
            <td>ORIGINAL TIME</td>
            <td>EVENT TIME</td>
            <td>ORIGINAL TYPE</td>
            <td>RATE TYPE</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{@state.thirdPartyTickets[0].firstName}</td>
            <td>{@state.thirdPartyTickets[0].lastName}</td>
            <td>{@state.thirdPartyTickets[0].email}</td>
            <td>{@state.thirdPartyTickets[0].token}</td>
            <td>{@state.thirdPartyTickets[0].event}</td>
            <td>{@props.events.filter((e) => e.id == @state.thirdPartyTickets[0].eventId)[0].when}</td>
            <td>{@state.thirdPartyTickets[0].rate}</td>
            <td>{@props.rates.filter((r) => r.id == @state.thirdPartyTickets[0].rateId)[0].name}</td>
          </tr>
          <tr>
            <td>{@state.thirdPartyTickets[@state.count - 1].firstName}</td>
            <td>{@state.thirdPartyTickets[@state.count - 1].lastName}</td>
            <td>{@state.thirdPartyTickets[@state.count - 1].email}</td>
            <td>{@state.thirdPartyTickets[@state.count - 1].token}</td>
            <td>{@state.thirdPartyTickets[@state.count - 1].event}</td>
            <td>{@props.events.filter((e) => e.id == @state.thirdPartyTickets[@state.count - 1].eventId)[0].when}</td>
            <td>{@state.thirdPartyTickets[@state.count - 1].rate}</td>
            <td>{@props.rates.filter((r) => r.id == @state.thirdPartyTickets[@state.count - 1].rateId)[0].name}</td>
          </tr>
        </tbody>
      </table>
      <div>
        {unless @state.importing
          <div>
            <label>
              <input type="checkbox" onChange={@updateBool.bind @, 'formatChecked'}/>
              Yes, I, <span>{@props.session.user.name}</span>, have triple checked the above information.
            </label>

            {if @state.formatChecked
              <div>
                <label>
                  <input type="checkbox" onChange={@updateBool.bind @, 'formatConfirmed'}/>
                  Yes, I, <span>{@props.session.user.name}</span>, really, really, really have triple checked the above information.
                </label>

                {if @state.formatConfirmed
                  <div>
                    <p>Estimated Import Time: {@formatTime(@predictTime())}</p>
                    <a onClick={@upload} className="NextButton">Start Import</a>
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
      <div>
        {if @state.importing
          <div className="import-progress">
            <p>Progress: {Math.min(100, Math.floor(@estimateProgress() / @state.count * 100))}% ({@formatTime(@estimateTime())} remaining)</p>
            <div className="import-progress-bar">
              <div style={{width: "#{Math.min(Math.floor(@estimateProgress() / @state.count * 100))}%"}}></div>
            </div>
          </div>
        }
      </div>
    </div>

  finishedStep: =>
    <div className="data-section">
      <h4>Congratulations!</h4>
      <p>All done, Tickets were Imported Successfully!</p>
    </div>

  render: ->
    return <Spinner/> if @props.listingLoading
    <div className="Import">
      {switch @state.step
        when 0 then @importStep()
        when 1 then @mapRatesStep()
        when 2 then @mapEventStep()
        when 3 then @saveStep()
        when 4 then @finishedStep()}
    </div>
