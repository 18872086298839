import Model from '/lib/model'
import humanize from 'underscore.string/humanize'

export default \
class ReportLog extends Model
  @className: 'ReportLog'

  @belongsTo 'user',    model: 'User'

  @belongsTo 'host',    model: 'User'
  @belongsTo 'event',   model: 'Event'
  @belongsTo 'listing', model: 'Listing'

export states = [
  'new'
  'queued'
  'generating'
  'running'
  'delivered'
  'failed'
]

export state_options = value: state, label: humanize state for state in states
