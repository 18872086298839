import Model from '/lib/model'

import humanize       from 'underscore.string/humanize'
import { stripZone }  from '/lib/datetime'

import isSameDay  from 'date-fns/isSameDay'
import parse      from 'date-fns/parseISO'
import format     from 'date-fns/format'

export default \
class Event extends Model
  @className: 'Event'
  constructor: ->
    super arguments...
    @id               = null # "56994b80c6d1c6e7b70000a2",
    @start_stamp      = null
    @end_stamp        = null
    @tz               = null
    @state            = null
    @start_time       = null
    @end_time         = null
    @num_tickets      = null
    @num_cost_items   = null

  @hasMany 'tickets',     model: 'Ticket'
  @hasMany 'cost_items',  model: 'CostItem'
  @hasMany 'rates',       model: 'Rate'

  @belongsTo 'listing',   model: 'Listing'

  @computed 'startTime', ->
    stripZone @start_time

  @computed 'endTime', ->
    stripZone @end_time

  @computed 'when', ->
    start = parse @start_time
    end   = parse @end_time

    if isSameDay start, end
      "#{format(start, 'MMM d, yyyy h:mm a')} - #{format(end, 'h:mm a')}"
    else
      "#{format(start, 'MMM d, yyyy h:mm a')} - #{(format end, 'MMM d, yyyy h:mm a')}"

  @computed 'transitions', ->
    actions = state_actions[@state] or []

    # REMOVED FOR NOW SINCE THE ORDER FEE SERVICE IS BEING FLAKY - this option will always be present, even if it's not applicable
    # # filter out cancel_retaining_order_fee! if not present on the listing
    # actions = (a for a in actions when a isnt 'cancel_retaining_order_fee!') unless @listing?.order_fee

    ret = {}
    for tr in actions
      ret[tr] = transition_name_overrides?[tr] or humanize tr
    ret

export states = [
  'unbooked'
  'normal'
  'full'
  'hidden'
]

export state_options = value: state, label: humanize state for state in states

$w = (str)-> (t for t in str.split(' ') when t isnt '')
export state_actions =
  unbooked:   $w 'unbook cancel! cancel_retaining_order_fee!'
  normal:     $w 'cancel! cancel_retaining_order_fee! hide'
  full:       $w 'hide cancel! cancel_retaining_order_fee!'

export transition_name_overrides =
  'cancel!': "Cancel Event (Initiates Mass Refunds)"
  'cancel_retaining_order_fee!': "Cancel Event, Mass Refund, Universe Retains Per Order Fees"
