import React from 'react'

import flash from '/lib/flash'

import { withAttractions, withUpdateAttractions } from '../queries'

import debounce from 'underscore-es/debounce'

# components
import Spinner      from '/components/spinner'
import Toggle       from '/components/toggle'

export default \
withAttractions \
withUpdateAttractions \
class TicketmasterAttractions extends React.Component

  constructor: ->
    super arguments...
    @state =
      attractions: @props.listing.attractions
      changed: false
      edit: false
      saving: false

  search: debounce (term) ->
    @props.history.push @props.location.pathname, Object.assign {}, @props.location.state, attraction_search: term
  , 500

  debounceSearch: (evt) =>
    @search evt.target?.value

  removeAttraction: (id) =>
    attractions = @state.attractions.slice()
    attraction = attractions.filter (a) => a.id == id
    attractions.splice(attractions.indexOf(attraction), 1)
    @setState attractions: attractions, changed: true

  addAttraction: (attraction) =>
    attractions = @state.attractions.slice()
    attractions.push attraction
    @setState attractions: attractions, changed: true

  getAttractionIds: (attractions = null) =>
    if attractions == null
      attractions = @state.attractions
    attractions.map (attraction) => attraction.id

  getAttractionNames: (attractions = null) =>
    if attractions == null
      attractions = @state.attractions
    attractions.map (attraction) => attraction.name

  saveAttractions: =>
    @setState saving: true
    @props.updateAttractions(@props.listing.id, @getAttractionIds())
    .then((data) =>
      flash.success "Attractions Updated Successfully"
      @setState changed: false, edit: false, saving: false
    ).catch (err)->
      flash.error "Attraction update failed"

  isExistingAttraction: (attraction) =>
    return @getAttractionIds().indexOf(attraction.id) > -1

  showEdit: =>
    @setState edit: true

  hide_attractions_from_ticketmaster: ()=>
    {listing, errors} = await @props.rpc @props.listing.id, "toggle_hide_attractions_from_ticketmaster"
    if errors?.length
      flash.error errors
    else
      flash.success "Listing updated successfully"

  render: ->
    <div className="TicketmasterAttractions">
      <h1>Ticketmaster Attractions</h1>
      <h3>
        Current Attractions:
        <b>
          {if @props.listing.attractions?.length
            <ul>
            {for attraction in  @props.listing.attractions
              <li>{attraction.name}</li>
            }
            </ul>
          else
            <span> None</span>
          }
        </b>
      </h3>
      {if @state.edit && !@state.saving
        <div className="edit-attractions">
          <h3>Edit Attractions</h3>
          {if @state.attractions
            <div className="current-attractions">
              <h3>Current Attractions</h3>
              <div className="results">
                {if @state.attractions.length > 0
                  for attraction in @state.attractions
                    <div key={attraction.id} className="result"><button onClick={@removeAttraction.bind @, attraction.id}>Remove</button> {attraction.name}</div>
                else
                  <b>None</b>
                }
              </div>
            </div>
          }

          <div className="attraction-search">
            <h3>Search for Attractions</h3>
            <input
              className="form-control filter-input input-md"
              type="search"
              placeholder="Begin typing an attraction name..."
              onChange={@debounceSearch}
            />
            <div className="results">
              {if @props.attractionsLoading
                <Spinner/>
              else if @props.attractions.length > 0
                for attraction in @props.attractions
                  unless @isExistingAttraction(attraction)
                    <div key={attraction.id} className="result"><button onClick={@addAttraction.bind @, attraction}>Add</button> {attraction.name}</div>
              else
                <div>No Results Found</div>
              }
            </div>
          </div>
          {if @state.changed
            <button className="btn btn-primary btn-md" onClick={@saveAttractions}>Save Attractions</button>
          }
        </div>
      else if @state.saving
        <Spinner />
      else
        <div>
          <div className="btn-container">
            <button className="btn btn-default" onClick={@showEdit}>Edit Attractions</button>
          </div>
          <div>
              <Toggle
                value={!@props.listing.hide_attractions_from_ticketmaster}
                onChange={@hide_attractions_from_ticketmaster.bind @}
              />
              {' '}
              Display listing on Ticketmaster (Host) via these attractions pages. Changes may take a few minutes to apply.
            </div>
        </div>
      }
    </div>
