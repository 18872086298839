import { graphql } from 'react-apollo'
import Chargeback       from '/models/chargeback'

#
# Queries
#

import index    from './queries/index.gql'

export withChargebacks = graphql index,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.chargebacks or {}
    chargebacks: Chargeback.parse nodes or []
    chargebacksPagination: pagination
    chargebacksLoading: data.loading
