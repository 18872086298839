import React from 'react'
import './styles'

export default \
class Row extends React.Component
  @displayName: 'Row'

  render: ->
    { children, className='', props... } = @props
    <div className="Row #{className}" {props...} >
      {children}
    </div>
