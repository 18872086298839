import React from 'react'
import './styles'

import { Link, Route, Switch, Redirect } from 'react-router-dom'

import DocumentTitle from 'react-document-title'

# routes
import Summary      from './summary'
import Changes      from './changes'

# components
import UserAside    from '/components/user-aside'
import Spinner      from '/components/spinner'
import ExternalLink from '/components/external-link'
import {
  Tabs
  TabLink
  TabContent
} from '/components/tabs'

import { withWithdrawal } from './queries'

export default \
withWithdrawal \
class Withdrawal extends React.Component

  render: ->
    return <Spinner/> if @props.withdrawalLoading
    @withdrawal = @props.withdrawal
    <div className="Withdrawal">
      <DocumentTitle title={"Withdrawal #{@props.withdrawal.id}"}/>
      <header>
        <h1>Withdrawal {@withdrawal.id}</h1>
      </header>

      <main>

        <section className="content">
          <Tabs>
            <TabLink to={"/withdrawals/#{@withdrawal.id}/summary"}>Summary</TabLink>
            <TabLink to={"#{@props.match.url}/changes"}>Changes</TabLink>
            <TabContent>
              <Switch>
                <Route path='/withdrawals/:id/summary'     component={Summary}/>
                <Route path='/withdrawals/:id/changes'     component={Changes}/>
                <Redirect from="#{@props.match.url}" to="#{@props.match.url}/summary" />
              </Switch>
            </TabContent>

          </Tabs>
        </section>

        <UserAside id={@withdrawal.user.id} session={@props.session}/>
      </main>

    </div>
