import React from 'react'
import PropTypes  from 'prop-types'
import './styles'

import Select     from './select'
import State      from './state'
import Type       from './type'
import Role       from './role'
import Toggle     from './toggle'
import Text       from './text'
import Search     from './search'
import Amount     from './amount'
import Currency   from './currency'
import List       from './list'
import _Number    from './number'
import Attribute  from './attribute'
import Entity     from './entity'
import Tags       from './tags'

export default \
class Filters extends React.Component
  @Select:      Select
  @State:       State
  @Type:        Type
  @Role:        Role
  @Toggle:      Toggle
  @Text:        Text
  @Search:      Search
  @Amount:      Amount
  @Currency:    Currency
  @List:        List
  @Number:      _Number
  @Attribute:   Attribute
  @Entity:      Entity
  @Tags:        Tags

  render: ->
    <div className="Filters form-inline">
      <h3>Filters:</h3>
      {@props.children}
    </div>
