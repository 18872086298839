import Model from '/lib/model'
import currencies from '/lib/currencies'
import { formatMoney } from 'accounting'
import humanize from 'underscore.string/humanize'

export default \
class Withdrawal extends Model
  @className: 'Withdrawal'
  constructor: ->
    super arguments...
    @id                               = null # "5909f6eab861e20f64fbb6a2",
    @amount                           = null # 20.0,
    @currency                         = null # "CAD",
    @paid_at                          = null # "2016-04-05T14:15:52.993Z",
    @location                         = null # "New York, NY, USA",
    @ip_address                       = null # "63.173.78.131",
    @browser                          = null # "Mozilla/5.0 (Windows NT 6.1; WOW64; rv:45.0) Gecko/20100101 Firefox/45.0",
    @approved_at                      = null # "2017-06-20T22:53:08.467Z",

    @payable_to                       = null # "[wire transfer]",
    @paypal_email                     = null # nil,
    @mailing_address                  = null # "Carleton Entrepreneurship Association\r\nc/o Michael Cacho\r\n1125 Colonel By Drive\r\n715 Dunton Tower\r\nOttawa, ON\r\nK1S 5B6",
    @reference_id                     = null # "TDCT Cheque 1523",
    @type                             = null # "cheque",

    @created_at                       = null # "2014-03-13T20:49:46.661Z",
    @state                            = null # "paid",

    @first_name                       = null # "bob",
    @last_name                        = null # "jones",
    @email                            = null # "bob.jones@universe.com",
    @phone                            = null # 123 123 1234,
    @birthday                         = null # "2099-02-08T00:00:00.000Z",
    @country                          = null
    @address                          = null
    @postal_code                      = null
    @province                         = null
    @city                             = null

    @formatted_name                   = null
    @formatted_amount                 = null
    @formatted_payment_balance        = null
    @formatted_approvable_balance     = null

    @hyperwallet_token                = null # "pmt-26245bd6-76ea-41ad-988f-abe3f049dc18",
    @hyperwallet_user_token           = null # "usr-e95e7d5f-9eaf-43da-9875-036e7008d3dd"

  # Used for any relationship computations within this model
  @belongsTo 'user', model: 'User'

  @computed 'formattedPrice', ->
    return 'Free' unless @amount
    formatMoney @amount, symbol: currencies[@currency].replace @currency, ''

  @computed 'name', ->
    [ @first_name, @last_name ].join ' '

  @computed 'transitions', ->
    ret = {}
    for tr in state_actions[@state] or []
      ret[tr] = transition_name_overrides?[tr] or humanize tr
    ret

$w = (str)-> (t for t in str.split(' ') when t isnt '')
export state_actions =
  paid:         $w ''
  pending:      $w 'mark_as_approved mark_as_failed'
  approved:     $w ''
  failed:       $w ''
  error:        $w 'mark_as_pending'

export transition_name_overrides =
  mark_as_approved: 'Approve'
  mark_as_failed: 'Reject'
  mark_as_pending: 'Retry'

export states = [
  'paid'
  'pending'
  'approved'
  'failed'
  'error'
]

# This is for react-select, to format it
export state_options = value: state, label: humanize state for state in states
