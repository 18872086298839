import Model from '/lib/model'
import humanize from 'underscore.string/humanize'

import { countries, continents } from '/lib/countries'

export default \
class Country extends Model
  @className: 'Country'

export country_options = value: code, label: name for code, name of countries
export continent_options = value: code, label: name for code, name of continents
