import { graphql } from 'react-apollo'
import Advance       from '/models/advance'

#
# Queries
#

import index        from './queries/index.gql'

export withAdvances = graphql index,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.advances or {}
    advances: Advance.parse nodes or []
    advancesPagination: pagination
    advancesLoading: data.loading
