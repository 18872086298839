import union from 'underscore-es/union'

id = 0

export default (state=[], action)->
  { type, params... } = action
  switch type

    when 'FLASH_CLEAR'
      []

    when 'FLASH_ADD'
      params.id = id++
      union [ params ], state

    when 'FLASH_DISMISS'
      msg for msg in state when msg.id isnt params.id

    else
      state
