import React from 'react'
import './styles'

import { Link } from 'react-router-dom'

import Spinner      from '/components/spinner'
import Avatar       from '/components/avatar'
import ListingPhoto from '/components/listing-photo'
import StateLabel   from '/components/state-label'
import Na           from '/components/na'

export default \
class CostItemCard extends React.Component

  headerStyle: ->
    @listing = @props.listing or @props.cost_item.listing
    backgroundImage: "url(#{@listing.cover_photo.url})" if @listing.cover_photo?.url

  render: ->
    return <Spinner/> unless @cost_item  = @props.cost_item
    @listing    = @cost_item.listing

    <div className="CostItemCard">
      <header className="cost-item-card-header" style={@headerStyle()}>
        <div className="overlay">
          <Link to={"/listings/#{@listing.slug}"}>
            {if user = @cost_item.user or @cost_item.buyer
              <Avatar user={user} size={50}/>
            }
            <h2>
              {@listing.title}
            </h2>
          </Link>
          <StateLabel model={@cost_item}/>
        </div>
      </header>

      {@costItemInfo @cost_item}

    </div>


  costItemInfo: (cost_item)->
    <section className="cost-item-info" title="cost item info">
      <dl>

        <dt>Guest</dt>
        <dd>
          {cost_item.user?.name or <Na/>}
        </dd>

        <dt>Buyer</dt>
        <dd>
          {cost_item.buyer?.name or <Na/>}
        </dd>

      </dl>
    </section>
