import React from 'react'
import PropTypes from 'prop-types'
import './styles'

import parse  from 'date-fns/parseISO'
import format from 'date-fns/format'

export default \
class Datetime extends React.Component
  @propTypes:
    date:   PropTypes.string.isRequired
    inline: PropTypes.bool

  @defaultProps:
    inline: false

  render: ->
    return null unless @props.date
    return null unless date = parse @props.date
    <span className="Datetime" title={@props.date?.toString?()}>
      <span className="date">
        {format date, 'MMM dd, yyyy'}
      </span>
      {if @props.inline then ' ' else <br/>}
      <span className="time">
        {format date, 'h:mm a'}
      </span>
    </span>
