import React from 'react'
import Select       from './select'

import { withRouter }   from 'react-router-dom'

export default withRouter class ListFilter extends Select
  @defaultProps:
    placeholder:  'All Options'
    label:        'Label'
    name:         'name'

  value: ->
    @props.location.state?.filters?[@props.name] or []
