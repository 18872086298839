import React from 'react'
import PropTypes  from 'prop-types'

import Base         from './base'
import { Creatable } from '/components/react-select'

import { withRouter }   from 'react-router-dom'

export default \
withRouter \
class TagsFilter extends Base
  @propTypes:
    name:         PropTypes.string
    options:      PropTypes.array
    placeholder:  PropTypes.string

  @defaultProps:
    name:         'tags'
    options:      []
    placeholder:  'Please select'

  onChange: (values)=>
    filters = Object.assign {}, @props.location.state?.filters or {}
    filters["#{@props.name}"] = (o?.value for o in (values or []))
    super Object.assign {}, @props.location.state, {filters}, page: 1

  value: =>
    @props.location.state?.filters?["#{@props.name}"] or []

  options: =>
    opts = @props.options or []
    for val in @value() when not opts.find (el)-> el.value is val
      opts = [{label: val, value: val}, opts...]
    opts

  render: ->
    <div className="Filter form-group #{@props.type}">
      <label>{@label()}</label>
      <Creatable
        className="state-selector"
        options={@options()}
        multi={true}
        value={@value()}
        onChange={@onChange}
        placeholder={@props.placeholder}
      />
    </div>
