import React from 'react'
import PropTypes from 'prop-types'
import './styles'

import FaExternalLink from 'react-icons/lib/fa/external-link'

export default \
class ExternalLink extends React.Component
  @propTypes:
    url: PropTypes.string.isRequired

  render: ->
    <a className="ExternalLink" href={@props.url} target='_new'>
      <FaExternalLink/>
    </a>
