import Model from '/lib/model'

import humanize   from 'underscore.string/humanize'

export default \
class Email extends Model
  @className: 'Email'

  @belongsTo 'user',      model: 'User'

export states = 'delivering sent failed'.split ' '
export state_options = value: state, label: humanize state for state in states

export delivery_states = 'pending delivered bounced complaint'.split ' '
export delivery_state_options = value: delivery_state, label: humanize delivery_state for delivery_state in delivery_states

export types = 'access_key_upload_completed
async_payment_failed_email
async_payment_pending_email
buyer_passes_events_added
buyer_ticket_globee_capacity_reached
buyer_ticket_globee_overpaid
buyer_ticket_globee_underpaid
buyer_ticket_payment_plan
buyer_ticket_payment_plan_cancelled
buyer_ticket_virtual_instructions
buyer_ticket_unauthorized
buyer_waitlist_confirmation
buyer_waitlist_notification
cart_abandonment
charge_dispute_email
confirmation_instructions
credit_card_soon_expire
delayed_tickets_email
discount_code_upload_completed
error_ticket_warning
failed_withdrawal
flag_listing
gdpr_host_notify
gdpr_support_notify
guest_transfer_accepted
happening_soon_email
host_async_report
host_draft_listing_with_photo
host_expired_listing
host_first_chargeback_occurred
host_invoice_pdf_collection
host_listing_wishlisted
host_posted_listing
host_presubmitted_listing_free
host_presubmitted_listing_paid
host_reminder_event_confirmed_24
host_ticket_async_payment_cancelled
host_ticket_fulfilled
host_ticket_globee_capacity_reached
host_ticket_globee_overpaid
host_ticket_globee_underpaid
host_ticket_payment_plan_cancelled
host_ticket_payment_plan_failed
host_ticket_payment_success
host_ticket_pdf_collection
host_ticket_summary
host_ticket_unapproved
host_waitlist_notification
installment_successfully_paid
installment_unsuccessfully_paid
listing_followup_no_bookings
listing_followup_with_bookings
membership_invite
message_inquiry
message_reply
order_confirmation_email
order_invoice_confirmation_email
order_partially_cancelled
payment_plan_completed
payment_plan_installment_reminder
ping
private_message
recipient_transfer_cancelled
recipient_transfer_created
remaining_credits_email
reset_password_instructions
sales
secondary_buyer_cancellation_email
secondary_buyer_email
sender_transfer_accepted
sender_transfer_declined
sepa_credit_not_fully_paid
service_invoice_issued
service_invoice_paid
stripe_connect_account_disabled
support
support_message
ticket_cancelled_email
ticket_cancelled_no_refund
ticket_partially_refunded
ticket_partially_refunded_and_cancelled
ticket_partially_cancelled_no_refund
ticket_partially_refunded_no_cancellation
ticket_full_refund_no_cancellation
ticket_inquiry
ticket_push_payment_declined
ticket_reply
ticket_request_declined_email
ticket_transfer_email
transfer_host_cancelled
unlock_instructions
user_cost_item_reminder_confirmed_24
welcome_email
welcome_email_general'.split /\W/

export type_options = value: type, label: humanize type for type in types
