import React from 'react'
import PropTypes from 'prop-types'

import currencies from '/lib/currencies'
import { formatMoney } from 'accounting'

export default \
class Fee extends React.Component
  @propTypes:
    flat:           PropTypes.number
    percent:        PropTypes.number
    ticket_percent: PropTypes.number
    currency:       PropTypes.string.isRequired
    rebate:         PropTypes.bool

  render: ->
    return null unless @props.currency
    all_negative = @props.flat < 0 and @props.percent < 0 and @props.ticket_percent < 0
    any_positive = @props.flat? or @props.percent? or @props.ticket_percent?
    <span className="Fee" title={@props.currency}>
      {if all_negative then 'FALLBACK' else if any_positive then @fee() else 'INVALID'}
    </span>

  fee: ->
    return null if @props.rebate && @props.flat == 0 && @props.percent == 0 && @props.ticket_percent == 0
    [@formattedFlat(), @formattedPercent(), @formattedTicketPercent()].filter((w) => w).join(' + ')

  formattedFlat: ->
    "#{formatMoney @props.flat, symbol: currencies[@props.currency].replace @props.currency, ''}"

  formattedPercent: ->
    return '' if @props.rebate && @props.percent == 0
    "#{(@props.percent * 100).toFixed(2)}%"

  formattedTicketPercent: ->
    return '' unless @props.rebate && @props.ticket_percent?
    "#{(@props.ticket_percent * 100).toFixed(2)}% of ticket"
