import React from 'react'
import PropTypes from 'prop-types'
import './styles'

import flash from '/lib/flash'

import Models         from '/models/index'
import UserCard       from '/components/user-card'
import ReactMarkdown  from 'react-markdown'
import StateActions   from '/components/state-label/actions'

import Create from './create'

import {
  withRPC
} from './queries'

export default \
withRPC \
class Note extends React.Component
  @Create: Create
  @propTypes:
    note:   PropTypes.instanceOf(Models.Note).isRequired

  rpc: (id, method)=>
    {note, errors} = await @props.rpc id, method
    flash.error errors if errors?.length

  render: ->
    return null unless note = @props.note
    <div className="NoteComponent #{note.state}">
      <ReactMarkdown
        className="text"
        source={note.text}
      />
      <StateActions
        model={note}
        onClick={@rpc.bind @, note.id}
      />
      <UserCard user={note.user}/>
    </div>
