import React from 'react'

import flash from '/lib/flash'

import Modal          from 'react-bootstrap/lib/Modal'

import AttractionForm from './attraction-form'

import { withUpdateAttraction } from './queries'

export default \
withUpdateAttraction \
class Attractions extends React.Component
  constructor: ->
    super arguments...
    @state =
      modalOpen: false

  saveAttraction: (attraction)=>
    {attraction, errors} = await @props.updateAttraction attraction
    if errors?.length
      flash.error errors
    else
      flash.success "Attraction updated succesfully"
      @toggle()

  toggle: =>
    @setState modalOpen: not @state.modalOpen

  render: ->
    attraction = @props.attraction
    <span>
      <button className='btn btn-default' onClick={@toggle.bind @, "#{attraction.id}Open"}>
        Edit
      </button>
      <Modal
        show={@state.modalOpen}
        onHide={@toggle}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Attraction
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='clearfix'>
          <AttractionForm
            attraction={attraction}
            action={@saveAttraction}
          />
        </Modal.Body>
      </Modal>
    </span>
