import React from 'react'
import './styles'

import flash from '/lib/flash'

import { Link } from 'react-router-dom'

import Spinner        from '/components/spinner'
import List           from '/components/list'
import Filters        from '/components/filters'
import Pager          from '/components/pager/history-pager'
import Toggle         from '/components/toggle'

import Country, {
  continent_options
} from '/models/country'

import { base_currencies } from '/lib/currencies'

import {
  withCountries
  withRPC
} from './queries'

import withAbility    from '/lib/with-ability'

export default \
withCountries \
withRPC \
withAbility \
class Countries extends React.Component

  render: ->
    <div className="Countries">
      <header>
        <h1>Countries</h1>
      </header>
      <section className="content">
        {@filters()}
        {@list()}
        {@pager()}
      </section>
    </div>

  filters: ->
    <Filters>
      <Filters.Search/>
      <Filters.List
        placeholder='All Continents'
        label='Continent'
        name='continent'
        options={continent_options}
      />
      <Filters.Toggle
        label='Only Show Enabled'
        name='universe_available'
      />
      <span className="pull-right">
        <Pager meta={@props.countriesPagination} buffer={2}/>
      </span>
    </Filters>

  pager: ->
    <div className="text-center">
      <Pager meta={@props.countriesPagination} buffer={4}/>
    </div>

  toggle: (id, onoff)=>
    method = if onoff then 'enable!' else 'disable!'
    if @props.can method, 'Country'
      @props.rpc id, method
    else
      flash.error "You do not have permission for this action"

  toggle_force_host_to_pay_commission: (id, onoff)=>
    method = if onoff then 'force_absorb!' else 'unforce_absorb!'
    if @props.can method, 'Country'
      @props.rpc id, method
    else
      flash.error "You do not have permission for this action"

  list: ->
    return <Spinner/> if @props.countriesLoading
    <List>
      {@header()}
      {for country in @props.countries
        <List.Row key={country._key}>
          <List.Cell className="code">
            {country.code}
          </List.Cell>
          <List.Cell className="currency_code">
            {country.currency_code}
          </List.Cell>
          <List.Cell className="name">
            {country.name}
          </List.Cell>
          <List.Cell className="actions">
            <Toggle
              value={country.force_host_to_pay_commission}
              onChange={@toggle_force_host_to_pay_commission.bind @, country.id}
            />
          </List.Cell>
          <List.Cell className="actions">
            <Toggle
              value={country.universe_available}
              onChange={@toggle.bind @, country.id}
            />
          </List.Cell>
        </List.Row>
      }
    </List>

  header: ->
    <List.HeaderRow>
      <List.Header className="code">Code</List.Header>
      <List.Header className="currency_code">Currency</List.Header>
      <List.Header className="name">Name</List.Header>
      <List.Header className="enabled">Force host to pay commission</List.Header>
      <List.Header className="enabled">Enabled</List.Header>
    </List.HeaderRow>
