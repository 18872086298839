import React from 'react'
import './styles'

import { NavLink } from 'react-router-dom'
import ErrorBoundary from '/components/error-boundary'

export \
class Tabs extends React.Component
  @displayName: 'Tabs'
  tabs: ->
    elem for elem in @props.children when elem?.type?.displayName isnt 'TabContent'

  content: ->
    elem for elem in @props.children when elem?.type?.displayName is 'TabContent'

  render: ->
    <div className="Tabs">
      <nav className="tabs-navigation">
        <div className="tabs-menu">
          {tab for tab in @tabs()}
        </div>
      </nav>
      <ErrorBoundary>
        {content for content in @content()}
      </ErrorBoundary>
    </div>


export \
class Tab extends React.Component
  @displayName: 'Tab'
  render: ->
    <span className="tabs-menu-item">
      {@props.children}
    </span>

export \
class TabLink extends React.Component
  @displayName: 'TabLink'
  render: ->
    <span className="tabs-menu-item">
      <NavLink to={@props.to}>
        {@props.children}
      </NavLink>
    </span>

export \
class TabContent extends React.Component
  @displayName: 'TabContent'
  render: ->
    <article className="tab-panel">
      {@props.children}
    </article>
