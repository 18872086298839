import React from 'react'
import PropTypes from 'prop-types'

import humanize from 'underscore.string/humanize'

import Translator     from '/components/translator'
import ReactSelect    from '/components/react-select'

export default \
class FeatureMatrix extends React.Component
  @propTypes:
    org:      PropTypes.object.isRequired
    onChange: PropTypes.func.isRequired

  options: ->
    [
      label: 'Enabled'
      value: true
    ,
      label: 'Disabled'
      value: false
    ]

  onChange: (key, obj)->
    if obj?
      value = obj.value
    else
      value = null

    @props.onChange key, value

  renderFeatureTitle: (key)->
    return 'PayPal' if key == 'paypal'
    humanize key

  render: ->
    <table className="table table-striped">
      <thead>
        <tr>
          <th>Feature</th>
          <th>Plan</th>
          <th>Override</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {for key, val of @props.org.features
          <tr key={key}>
            <td>{@renderFeatureTitle key}</td>
            <td>
              <Translator value={@props.org.plan.features[key]}/>
            </td>
            <td>
              <ReactSelect
                value={@props.org.features_overrides[key]}
                onChange={@onChange.bind @, key}
                options={@options()}
                placeholder="plan default"
              />
            </td>
            <td>
              <Translator value={@props.org.features[key]}/>
            </td>
          </tr>
        }
      </tbody>
    </table>
