import { graphql } from 'react-apollo'

#
# Queries
#

  # No queries yet

#
# Mutations
#

import withdrawals_rpc              from '/mutations/withdrawals/rpc.gql'

export withWithdrawalsRPC = graphql withdrawals_rpc,
  props: ({ mutate })->
    withdrawals_rpc: (id, method)->
      {data: {withdrawal_rpc: {withdrawal, errors}}} = \
      await mutate variables: {id, method}
      {withdrawal, errors}
