import { graphql }   from 'react-apollo'
import Models        from '/models/index'

# ------------------ Queries ----------------------------------------
import icp_queue              from './queries/icp_queue.gql'
import icp_queue_manual_refs  from './queries/icp_queue_manual_refs.gql'

export withICPQueue = graphql icp_queue,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.icp_queue or {}
    icp_queue: Models.User.parse nodes or []
    icp_queuePagination: pagination
    icp_queueLoading: data.loading

export withICPQueueManualRefs = graphql icp_queue_manual_refs,
  props: ({props, data})->
    manual_refs: data.icp_queue_manual_refs or []
    icp_queue_manual_refsLoading: data.loading

# ---------------- Mutations ----------------------------------------
import updateUserIcp      from '/mutations/icp/update_icp.gql'

export withUpdateUserIcp = graphql updateUserIcp,
  props: ({ mutate })->
    updateIcp: (id, icp)->
      {data: {user_update_icp: {user, errors}}} = \
      await mutate variables: {id, icp}
      {user, errors}
