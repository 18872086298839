import { graphql } from 'react-apollo'
import { gql } from 'apollo-boost'
import Ability          from './ability'

query = gql "
  query ability{
    ability
  }
"

export default graphql query,
  props: ({props, data}) ->
    {can, cannot} = new Ability data.ability or []
