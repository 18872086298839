import React from 'react'
import PropTypes  from 'prop-types'

import Base         from './base'
import ReactSelect  from '/components/react-select'

export default \
class Select extends Base
  @propTypes:
    options:      PropTypes.array
    placeholder:  PropTypes.string

  @defaultProps:
    options:      []
    placeholder:  'Please select'

  onChange: (values)=>
    filters = Object.assign {}, @props.location.state?.filters or {}
    filters["#{@props.name}"] = (o?.value for o in (values or []))
    super Object.assign {}, @props.location.state, {filters}, page: 1

  value: ->
    @props.value or []

  render: ->
    <div className="Filter form-group #{@props.type}">
      <label>{@label()}</label>
      <ReactSelect
        className="state-selector"
        options={@props.options}
        multi={true}
        value={@value()}
        onChange={@onChange}
        placeholder={@props.placeholder}
      />
    </div>
