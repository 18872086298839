import React from 'react'
import PropTypes from 'prop-types'
import withAbility    from '/lib/with-ability'

Can = withAbility class Can extends React.Component
  @propTypes:
    action:   PropTypes.string.isRequired
    subject:  PropTypes.string.isRequired
    invert:   PropTypes.bool.isRequired
    message:  PropTypes.string

  @defaultProps:
    invert:   false
    message:  null

  allowed: =>
    res = @props.can @props.action, @props.subject
    unless @props.invert then res else not res

  render: ->
    if @allowed() then @props.children else @props.message

Can.Not = class Not extends Can
  @defaultProps:
    invert: true

export default Can
