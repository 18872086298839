import React from 'react'

import flash from '/lib/flash'

import { Link } from 'react-router-dom'

import { state_options } from '/models/ticket'

# components
import Pager          from '/components/pager/history-pager'
import Spinner        from '/components/spinner'
import Datetime       from '/components/datetime'
import StateActions   from '/components/state-label/actions'
import Filters        from '/components/filters'
import UserCard       from '/components/user-card'
import List           from '/components/list'
import Price          from '/components/price'
import NoteWarning    from '/components/note/warning'

import {
  withTickets
  withTicketsRPC
  withTicketsResend
} from './queries'

export default \
withTickets \
withTicketsRPC \
withTicketsResend \
class Tickets extends React.Component
  rpc: (id, method)=>
    return @resend_ticket id if method is 'resend_ticket'
    {ticket, errors} = await @props.tickets_rpc id, method
    flash.error errors if errors?.length

  resend_ticket: (id)=>
    {ticket, errors} = await @props.tickets_resend id
    if errors?.length
      flash.error errors
    else
      flash.success "Ticket re-sent successfully"

  render: ->
    <div className="Tickets">
      {@filters()}
      {if @props.ticketsLoading
        <Spinner/>
      else
        <div className="tickets">
          {@list()}
        </div>
      }
    </div>

  list: ->
    return <Spinner/> if @props.ticketsLoading
    <List>
      {for ticket in @props.tickets
        # ignore notes for the subject of this page
        ticket_notes = (n for n in ticket.related_notes when n.target_id isnt @props.match.params.id)

        <List.Row key={ticket._key}>
          <List.Cell className="id">
            <Link className="btn btn-default" to={"/tickets/#{ticket.id}"}>
              More info
            </Link>
            <NoteWarning notes={ticket_notes}/>
          </List.Cell>
          <List.Cell className="user">
            <UserCard user={ticket.user} />
          </List.Cell>
          <List.Cell className="rate">
            {ticket.rate?.name}
          </List.Cell>
          <List.Cell className="price">
            <Price amount={ticket.price} currency={ticket.src_currency}/>
          </List.Cell>
          <List.Cell className="created-at">
            <Datetime date={ticket.created_at}/>
          </List.Cell>
          <List.Cell className="actions">
            <StateActions
              model={ticket}
              onClick={@rpc.bind @, ticket.id}
            />
          </List.Cell>
        </List.Row>
      }
    </List>

  filters: ->
    <Filters>
      <Filters.Search/>
      <Filters.State options={state_options}/>
      <span className="pull-right">
        <Pager meta={@props.ticketsPagination} buffer={2}/>
      </span>
    </Filters>
