import { graphql }   from 'react-apollo'

#
# Mutations
#

import notes_rpc  from '/mutations/notes/rpc.gql'

export withRPC = graphql notes_rpc,
  props: ({ mutate })->
    rpc: (id, method)->
      {data: {note_rpc: {note, errors}}} = \
      await mutate variables: {id, method}
      {note, errors}
