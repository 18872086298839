import React from 'react'
import './styles'

import parse        from 'date-fns/parseISO'
import startOfDay   from 'date-fns/startOfDay'
import endOfDay     from 'date-fns/endOfDay'
import startOfHour  from 'date-fns/startOfHour'
import format       from 'date-fns/format'
import isSameDay    from 'date-fns/isSameDay'

import sortBy       from 'underscore-es/sortBy'
import groupBy      from 'underscore-es/groupBy'
import countBy      from 'underscore-es/countBy'
import uniq         from 'underscore-es/uniq'
import humanize     from 'underscore.string/humanize'

import seconds from '/lib/seconds'

import {
  ResponsiveContainer
  ComposedChart
  Bar
  XAxis
  YAxis
  Tooltip
} from 'recharts'

export default \
class DeliveryChart extends React.Component
  constructor: ->
    super arguments...
    @state =
      zoom_date: null

  reset: =>
    @setState zoom_date: null

  handleClick: (obj)=>
    return @reset() if @state.zoom_date
    @setState zoom_date: startOfDay obj.timegroup

  render: ->
    return null unless @props.stats?.length
    <div className="DeliveryChart">
      {@types_chart()}
      <button className="reset btn btn-default" onClick={@reset}>zoom out</button>
    </div>

  types_chart: =>
    return null unless data = @chart_data()

    [first, ..., last] = data

    margin  = seconds if @state.zoom_date then '1 hour' else '1 day'
    start   = startOfDay(first.timegroup).getTime() / 1000
    end     = endOfDay(last.timegroup).getTime() / 1000

    <ResponsiveContainer height={250} width={'100%'}>
      <ComposedChart data={data} margin={top: 20, right: 0, left: 0, bottom: 5}>
        <XAxis dataKey="timestamp" scale="time" type="number" domain={[start-margin, end+margin]} hide={true}/>
        <Tooltip labelFormatter={@formatDate} />
        {for type in @types()
          color = if type is 'delivered' then 'green' else '#FF4776'
          <Bar
            key={type}
            dataKey={type}
            name={humanize type}
            fill="#28AFFA"
            stackId={'types'}
            onClick={@handleClick}
          />
        }
        {for state in @delivery_states()
          color = if state is 'delivered' then '#00C2FF' else '#F03F6D'
          <Bar
            key={state}
            dataKey={state}
            name={humanize state}
            fill={color}
            stackId={'delivery_states'}
            onClick={@handleClick}
          />
        }
      </ComposedChart>
    </ResponsiveContainer>

  data: =>
    for obj in @props.stats
      [created_at, type, delivery_state, opens, clicks] = obj
      dt = parse created_at
      date = startOfDay dt
      hour = startOfHour dt
      timegroup = if @state.zoom_date then hour else date
      timestamp = timegroup.getTime() / 1000
      {
        created_at
        date
        hour
        timegroup
        timestamp
        type
        delivery_state
        opens
        clicks
        opened: !!opens
        clicked: !!clicks
      }

  chart_data: =>
    data = @data()
    if zd = @state.zoom_date
      data = (d for d in data when isSameDay d.date, zd)

    for timegroup, els of groupBy data, 'timegroup'
      {timegroup, timestamp, date} = els[0]
      {
        timegroup
        timestamp
        countBy(els, 'type')...
        countBy(els, 'delivery_state')...
      }

  types: =>
    uniq (row.type for row in @data())

  delivery_states: ->
    'pending bounced complaint delivered'.split ' '

  formatDate: (ts)=>
    if @state.zoom_date
      format (new Date(ts*1000)), 'MM/dd/yyyy HH:mm'
    else
      format (new Date(ts*1000)), 'MM/dd/yyyy'
