import Model from '/lib/model'
import humanize from 'underscore.string/humanize'
import { stripZone }  from '/lib/datetime'

export default \
class Rate extends Model
  @className: 'Rate'
  constructor: ->
    super arguments...
    @id                 = null
    @price              = null
    @src_price          = null
    @qty                = null
    @capacity           = null
    @name               = null
    @description        = null
    @start_stamp        = null
    @end_stamp          = null
    @listing_id         = null
    @client_group       = null
    @_type              = null
    @min_price          = null
    @src_min_price      = null
    @state              = null
    @min_tickets        = null
    @max_tickets        = null
    @discountable       = null
    @src_currency       = null
    @sort_index         = null
    @release_offset     = null
    @default_access_key = null
    @start_time         = null
    @end_time           = null
    @credits            = null
    @commission_code    = null

  @computed 'startTime', ->
    stripZone @start_time

  @computed 'endTime', ->
    stripZone @end_time

export states = [
  'active'
  'inactive'
  'locked'
]

export state_options = value: state, label: humanize state for state in states
