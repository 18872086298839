import React from 'react'

import { withRouter }   from 'react-router-dom'
import Pager from './index'

export default \
withRouter \
class HistoryPager extends React.Component
  constructor: ->
    super arguments...
    unless @props.location?.state?.page
      @props.history.replace @props.location.pathname, Object.assign {}, @props.location.state, page: 1

  setPage: (page)=>
    @props.history.push @props.location.pathname, Object.assign {}, @props.location.state, page: page

  render: ->
    <Pager {@props...} setPage={@setPage}/>
