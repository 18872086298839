import React      from 'react'
import PropTypes  from 'prop-types'
import './styles'

import uniqueId from 'underscore-es/uniqueId'

export default \
class Toggle extends React.Component
  @propTypes:
    id:       PropTypes.string
    onChange: PropTypes.func
    value:    PropTypes.bool
    danger:   PropTypes.bool
    size:     PropTypes.oneOf ['small', 'medium', 'large']
    disabled: PropTypes.bool

  @defaultProps:
    size: 'medium'
    value: false

  onChange: (evt)=>
    @props.onChange evt.target?.checked

  render: ->
    <span className="Toggle #{if @props.danger then 'danger' else ''}">
      <input
        className='toggle-switch'
        id={id = @props.id or uniqueId 'library_'}
        type='checkbox'
        checked={@props.value}
        onChange={@onChange}
        disabled={@props.disabled}
      />
      <label htmlFor={id} className={@props.size}/>
    </span>
