import React from 'react'
import PropTypes from 'prop-types'

import CommissionCode, {
  currency_options
  country_code_options
} from '/models/commission-code'

import humanize from 'underscore.string/humanize'

import ReactSelect  from '/components/react-select'
import Can          from '/components/can'
import ReactTooltip from 'react-tooltip'
import FaInfoCircle from 'react-icons/lib/fa/info-circle'

export default \
class NewCode extends React.Component
  @propTypes =
    commission_code:  PropTypes.object
    cancel:           PropTypes.func.isRequired
    save:             PropTypes.func.isRequired
    disabled:         PropTypes.bool.isRequired

  constructor: (props)->
    super arguments...
    @state =
      commission_code: CommissionCode.parse @props.commission_code or {}

  cancel: (evt)=>
    evt.preventDefault()
    @setState
      commission_code: CommissionCode.parse {}
    @props.cancel()

  save: (evt)=>
    evt.preventDefault()
    {errors} = await @props.save @state.commission_code
    @cancel evt unless errors?.length

  render: ->

    updateNewCode = (key, evt)=>
      # handle text inputs
      value = if evt?.target?.value? then evt?.target?.value else evt
      # handle react select inputs
      value = evt.value if evt?.value?
      # update the value
      @state.commission_code["#{key}"] = value
      # setState
      @setState commission_code: @state.commission_code

    labelHash =
      code: 'code'
      flat: 'flat'
      max: 'max'
      actual_percent: 'percent'
      actual_broker_fee: 'broker_fee'
      rebate_flat: 'flat'
      actual_rebate_percent: 'percent'
      actual_rebate_cost_item_percent: 'ticket percent'
      rebate_max: 'max'
      rebate_user_id: 'user_id'

    formRow = (name, value, placeholder, tooltip, disabled=false)=>
      if tooltip
        icon = <a data-tip data-for={name}><FaInfoCircle className="info-circle"/></a>
        message = <ReactTooltip id={name} type='dark' effect='solid'>
          <span>{tooltip}</span>
        </ReactTooltip>

      <div className="form-group" key={name}>
        <label className="col-sm-3 control-label commission-label">{humanize labelHash[name]} {icon}</label>
        {message}
        <div className="col-sm-9">
          <input
            type="text"
            className="form-control"
            placeholder={placeholder or name}
            defaultValue={value}
            onChange={updateNewCode.bind @, name}
            disabled={disabled}
          />
        </div>
      </div>

    <div className="NewCode">
      <form>
        {formRow 'code', @state.commission_code.code, 'CODE', null, @props.commission_code?}
        {formRow 'flat', @state.commission_code.flat, '0.00', null}
        {formRow 'max', @state.commission_code.max, 'default per currency', null}
        {formRow 'actual_percent', @state.commission_code.actual_percent, '0 %', null}
        <Can action='set_broker_fee' subject='CommissionCode'>
          {formRow 'actual_broker_fee', @state.commission_code.actual_broker_fee, '0 %', null}
        </Can>
        <div className="form-group" key='currencies'>
          <label className="col-sm-3 control-label">Currency</label>
          <div className="col-sm-9">
            <ReactSelect
              className="filter-selector state-selector"
              options={currency_options}
              multi={false}
              clearable={false}
              value={@state.commission_code.currency}
              onChange={updateNewCode.bind @, 'currency'}
              placeholder="Select currency"
              disabled={@props.commission_code?}
            />
          </div>
        </div>
        <div className="form-group" key='country'>
          <label className="col-sm-3 control-label">Country</label>
          <div className="col-sm-9">
            <ReactSelect
              className="filter-selector state-selector"
              options={country_code_options}
              multi={false}
              value={@state.commission_code.country}
              onChange={updateNewCode.bind @, 'country'}
              disabled={@props.commission_code?}
              placeholder="Apply to all Countries"
            />
          </div>
        </div>
        <h3>Rebates <a data-tip data-for="rebates-title"><FaInfoCircle className="info-circle"/></a></h3>
        <ReactTooltip id="rebates-title" type='dark' effect='solid'>
          <p>Rebates are calculated per ticket and are capped to the amount of our Service Fee collected per ticket. Rebates are not calculated on the processing fee. Offering a rebate to a client is subject to finance approval and details of the rebate MUST be in a signed contract to be valid. Commission codes with rebates attached are subject to approval before they can be applied. Rebates will not apply to Pay By Invoice, Globee, nor Cash orders.</p>
          <p>You MUST use a unique commission code for each rebate agreement.</p>
        </ReactTooltip>
        {formRow 'rebate_flat', @state.commission_code.rebate_flat, '0.00', null}
        {formRow 'actual_rebate_percent', @state.commission_code.actual_rebate_percent, '0 %', 'Percentage of the commission (less the broker fee) to rebate.'}
        {formRow 'actual_rebate_cost_item_percent', @state.commission_code.actual_rebate_cost_item_percent, '0 %', 'Percentage of face value of ticket (before service and broker fees) to rebate.'}
        {formRow 'rebate_max', @state.commission_code.rebate_max, 'commission amount', 'per ticket'}
        {formRow 'rebate_user_id', @state.commission_code.rebate_user_id, 'SLUG', "The funds for this rebate will be added to this User's Universe account balance IF they are the host and transacting on Universe Payments OR they are receiving a rebate for a listing where they are not the host. IF they are processing on Stripe Connect and the user receiving the rebate is the host, the rebate will be paid at time of payment directly through their Stripe account."}
        <div className='buttons'>
          <button className='btn btn-default' onClick={@cancel} disabled={@props.disabled}>cancel</button>
          <button className='btn btn-primary' onClick={@save} disabled={@props.disabled}>
            {if @props.commission_code then 'Save' else 'Create'}
          </button>
        </div>
      </form>
    </div>
