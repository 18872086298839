import React from 'react'
import PropTypes from 'prop-types'

import isSameDay  from 'date-fns/isSameDay'
import parse      from 'date-fns/parseISO'
import format     from 'date-fns/format'

export default \
class When extends React.Component

  @propTypes:
    start:  PropTypes.string.isRequired
    end:    PropTypes.string.isRequired
    tz:     PropTypes.string

  when: (start, end)=>
    if isSameDay(start, end)
      "#{format(start, 'MMM d, yyyy h:mm a')} - #{format(end, 'h:mm a')}"
    else
      "#{format(start, 'MMM d, yyyy h:mm a')} - #{(format end, 'MMM d, yyyy h:mm a')}"

  render: =>
    return null unless start = parse @props.start
    return null unless end = parse @props.end
    <div className="When">
      {@when start, end}
      {" (#{@props.tz})" if @props.tz?}
      {@props.children}
    </div>
