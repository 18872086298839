import React from 'react'
import PropTypes from 'prop-types'

import flash from '/lib/flash'

import Advance, { base_currency_options } from '/models/advance'
import {base_currencies} from '/lib/currencies'
import ReactSelect  from '/components/react-select'
import humanize from 'underscore.string/humanize'
import pick from 'underscore-es/pick'

export default \
class CreateAdvance extends React.Component
  @propTypes =
    action: PropTypes.func.isRequired
    cancel: PropTypes.func.isRequired
    user:   PropTypes.object.isRequired
    currency: PropTypes.oneOf(base_currencies)

  constructor: ->
    super arguments...
    @state =
      amount:         null
      description:    null
      currency:       @props.currency || null
      user_id:        @props.user.id

  createAdvance: (evt)=>
    evt.preventDefault()
    attrs = pick @state, 'amount', 'description', 'currency', 'user_id'
    advance = Advance.parse attrs
    unless advance.isValidForCreate
      flash.warning 'You need to provide amount, description, and currency.'
      return
    @props.action @props.cancel, attrs


  cancel: (evt)=>
    evt.preventDefault()
    @setState
      amount:         null
      description:    null
      currency:       null
      user_id:        null
    @props.cancel()


  render: ->
    updateNewAdvance = (key, evt)=>
      # handle text inputs
      value = if evt?.target?.value? then evt?.target?.value else evt
      # Converts amount into a float.
      value = parseFloat(value) if key in ['amount']
      # handle react select inputs
      value = evt.value if evt?.value?
      # set the state
      @setState "#{key}": value

    formRow = (name, value, placeholder, startFocus = false)=>
      <div className="form-group" key={name}>
        <label className="col-sm-2 control-label">{humanize name.replace 'actual_', ''}</label>
        <div className="col-sm-10">
          <input
            type="text"
            className="form-control"
            placeholder={placeholder or name}
            defaultValue={value}
            onChange={updateNewAdvance.bind @, name}
            autoFocus = {startFocus}
          />
        </div>
      </div>

    <div className="NewAdvance">
      <form>
        {formRow 'amount', @state.amount, '0.00', true}
        {formRow 'description', @state.flat, 'Required'}
        <div className="form-group" key='currency'>
          <label className="col-sm-2 control-label">Currencies</label>
          <div className="col-sm-10">
            <ReactSelect
              className="filter-selector state-selector"
              options={base_currency_options}
              value={@state.currency}
              onChange={updateNewAdvance.bind @, 'currency'}
              placeholder="Currency"
            />
          </div>
        </div>
        <div className='buttons'>
          <button className='btn btn-default' onClick={@cancel}>cancel</button>
          <button className='btn btn-primary' onClick={@createAdvance}>Create</button>
        </div>
      </form>
    </div>
