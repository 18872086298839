import React from 'react'
import './styles'

export default \
class Na extends React.Component
  @defaultProps:
    text: 'N/A'

  render: ->
    <span className="Na">{@props.text}</span>
