import React from 'react'
import flash from '/lib/flash'

# components
import { Link }     from 'react-router-dom'
import Spinner      from '/components/spinner'
import Toggle       from '/components/toggle'

import TicketmasterVenue from './components/ticketmaster_venue'
import TicketmasterAttractions from './components/ticketmaster_attractions'

import {
  withTicketmasterListing
  withRPC
} from './queries'

export default \
withTicketmasterListing \
withRPC \
class Ticketmaster extends React.Component

  toggle_mfol: (enable=false)=>
    method = if enable then 'show_on_mfol' else 'hide_on_mfol'

    {listing, errors} = await @props.rpc @props.listing.id, method
    if errors?.length
      flash.error errors
    else
      flash.success "Listing updated successfully"

  toggle_dcom: (enable=false)=>
    method = if enable then 'enable_dcom' else 'disable_dcom'

    {listing, errors} = await @props.rpc @props.listing.id, method
    if errors?.length
      flash.error errors
    else
      flash.success "Listing updated successfully"

  toggle_test_event: (enable=false)=>
    method = if enable then 'enable_test_event' else 'disable_test_event'

    {listing, errors} = await @props.rpc @props.listing.id, method
    if errors?.length
      flash.error errors
    else
      flash.success "Listing updated successfully"

  render: ->
    return <Spinner/> if @props.listingLoading
    <div className="Ticketmaster">
      {@test_event()}
      <hr />
      <TicketmasterVenue listing={@props.listing} location={@props.location} history={@props.history} rpc={@props.rpc}/>
      <TicketmasterAttractions listing={@props.listing} location={@props.location} history={@props.history} rpc={@props.rpc}/>
      <hr/>
      {@mfol()}
      <hr />
      {@dcom()}
    </div>

  mfol: ->
    <>
      <h1>MicroFlex OnLine</h1>
      <div className="alert alert-info" role="alert">
        This controls visibility of our events within the TicketMaster International system (MFOL)
      </div>
      {if @props.listing.user.allow_on_mfol
        <div className="alert alert-warning" role="alert">
          This value is currently overridden by the value set on the <Link to={"/users/#{@props.listing.user.slug}"}>Host</Link>.
        </div>
      }
      <Toggle
        value={@props.listing.allow_on_mfol || @props.listing.user.allow_on_mfol}
        onChange={@toggle_mfol}
        disabled={@props.listing.user.allow_on_mfol}
      />
      {' '}
      Allow on MFOL
    </>

  dcom: ->
    <>
      <h1>Distributed Commerce</h1>
      <div className="alert alert-info" role="alert">
        Toggling this on will integrate the listing with Distributed Commerce. Once enabled, the host will be able to partner with different third party vendors to sell tickets on their behalf. Learn more.
      </div>

      <Toggle
        value={@props.listing.allow_dcom}
        onChange={@toggle_dcom}
      />
      {' '}
      Make this listing a Distributed Commerce listing
    </>

  test_event: ->
    <>
      <h1> Test Event </h1>
      <div className="alert alert-info" role="alert">
        Toggling this on will mark this listing as a test event on the Ticketmaster APIs, including the Discovery and Partner APIs.
      </div>
      <Toggle
        value={@props.listing.test_event}
        onChange={@toggle_test_event}
      />
      {' '}
      Mark as test event
    </>
