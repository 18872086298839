import { graphql }   from 'react-apollo'
import Models        from '/models/index'

#
# Queries
#
import show         from './queries/show.gql'

export withTicket = graphql show,
  options: (props)->
    { id } = props
    variables: { id }
  props: ({props, data})->
    ticket: Models.Ticket.parse data.ticket
    ticketLoading: data.loading

#
# Mutations
#
import move         from '/mutations/tickets/move.gql'

export withTicketMove = graphql move,
  props: ({ mutate })->
    move_ticket: (id, event_id)->
      {data: {ticket_move: {ticket, errors}}} = \
      await mutate variables: {id, event_id}
      {ticket, errors}
