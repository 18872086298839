import Model from '/lib/model'

export default \
class Organization extends Model
  @className: 'Organization'
  constructor: ->
    super arguments...
    @id                                         = null # "5560124ae991953a76002101"
    @seats_designer_key                         = null # ""
    @seats_subaccount_id                        = null # ""
    @commission_code                            = null # ""
    @commission_code_attribute                  = null # ""
    @add_on_commission_code                     = null # ""
    @add_on_commission_code_attribute           = null # ""
    @order_fee                                  = {}

    @entity = null

    @force_host_to_pay_commission = null

  @belongsTo 'owner',   model: 'User'
  @belongsTo 'plan',    model: 'Plan'

  @belongsTo 'entity',    model: 'Entity'

  @belongsTo 'business_info', model: 'BusinessInfo'

  @computed 'seats_subaccount_valid', ->
    !! @seats_designer_key and !! @seats_subaccount_id
