import React from 'react'
import './styles'

import flash from '/lib/flash'

import { Link }         from 'react-router-dom'
import {icp_options}    from '/models/user'

# components
import ReactSelect    from '/components/react-select'
import UserCard       from '/components/user-card'
import Spinner        from '/components/spinner'
import Datetime       from '/components/datetime'
import Pager          from '/components/pager/history-pager'
import Filters        from '/components/filters'
import List           from '/components/list'

# Graphql
import {
  withICPQueue,
  withUpdateUserIcp,
  withICPQueueManualRefs
} from './queries'

export default \
withUpdateUserIcp \
withICPQueue \
withICPQueueManualRefs \
class ICPQueue extends React.Component

  updateIcp: (user_id, opt)->
    {user, errors} = await @props.updateIcp user_id, opt?.value
    if errors?.length
      flash.error errors
    else
      flash.success "User updated successfully"

  render: ->
    <div className="ICPQueue">
      <header>
        <h1> ICP Queue </h1>
      </header>
      <section className="content">
        {@filters()}
        {@list()}
        {@pager()}
      </section>
    </div>

  filters: ->
    <Filters>
      <Filters.Search/>
      {if @props.manual_refs?.length
        <Filters.List
          name='manual_ref'
          label={'Refs'}
          options={(label: ref, value: ref for ref in @props.manual_refs)}
        />
      }
      <span className="pull-right">
        <Pager meta={@props.icp_queuePagination} buffer={2}/>
      </span>
    </Filters>

  list: ->
    return <Spinner/> if @props.icp_queueLoading
    <List>
      {@header()}
      {for user in @props.icp_queue
        <List.Row key={user._key}>

          <List.Cell className="id">
            <Link className="btn btn-default" to={"/users/#{user.id}"}>
              More info
            </Link>
          </List.Cell>

          <List.Cell className="user">
            <UserCard user={user} />
          </List.Cell>

          <List.Cell className="manual_ref">
            {user.manual_ref}
          </List.Cell>

          <List.Cell className="created">
            <Datetime date={user.icp_queued_at} format='MMM d, yyyy'/>
          </List.Cell>

          <List.Cell className="actions">
            <ReactSelect
              className="filter-selector state-selector"
              options={icp_options}
              onChange={@updateIcp.bind @, user.id}
              value={user.icp}
              clearable={false}
              placeholder="Select ICP"
            />
          </List.Cell>

        </List.Row>
      }
    </List>

  header: ->
    <List.HeaderRow>
      <List.Header className="id">id</List.Header>
      <List.Header className="user">User</List.Header>
      <List.Header className="manual_ref">Manual Ref</List.Header>
      <List.Header className="created">Queued</List.Header>
      <List.Header className="actions">ICP tag</List.Header>
    </List.HeaderRow>

  pager: ->
    <div className="text-center">
      <Pager meta={@props.icp_queuePagination} buffer={4}/>
    </div>
