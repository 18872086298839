import React from 'react'

import ReactSelect  from '/components/react-select'
import flash from '/lib/flash'

import {
  withStewards
  withAddSteward
} from './queries'

export default \
withStewards \
withAddSteward \
class AddSteward extends React.Component
  addSteward: ({value: steward_id})=>
    {user, errors} = await @props.addSteward @props.host.id, steward_id
    if errors?.length
      flash.error errors
    else
      flash.success "Client Service Rep added"
      @props.close?()

  values: =>
    (s.id for s in @props.host.stewards or [])

  select_opts: =>
    (value: s.id, label: s.name for s in @props.stewards when s.id not in @values())

  render: ->
    return null if @props.stewardsLoading

    <ReactSelect
      className="add-steward"
      options={@select_opts()}
      onChange={@addSteward}
      placeholder="Add Client Service Rep"
    />
