import React from 'react'
import './styles'

export default \
class Translator extends React.Component

  text: ->
    switch @props.value
      when undefined, null
        "maybe"
      when true
        "yes"
      when false
        "no"
      else
        "#{@props.value}"

  render: ->
    <span className="Translator label #{@text()}">
      {@text()}
    </span>

