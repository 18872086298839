import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import Models from '/models/index'
import store from '/store'
import * as Sentry from '@sentry/browser'

import {IntlProvider} from 'react-intl'

if dsn = process.env.SENTRY_DSN
  Sentry.init { dsn, environment: process.env.NODE_ENV, release: process.env.COMMIT_REF }

import 'bootstrap-sass'

import Application from '/routes/application'
import { BrowserRouter, Route } from 'react-router-dom'

window.Models = Models

import { ApolloProvider, client } from "./lib/apollo"
import { Security } from '@okta/okta-react'

class Base extends React.Component
  render: ->
    <IntlProvider locale="en">
      <ApolloProvider client={client}>
        <Provider store={store}>
          <BrowserRouter>
            <Security
              issuer={process.env.OKTA_ISSUER}
              client_id={process.env.OKTA_CLIENT_ID}
              redirect_uri={"#{window.location.origin}/implicit/callback"}
              storage='sessionStorage'
            >
              <Route path="/" component={Application}/>
            </Security>
          </BrowserRouter>
        </Provider>
      </ApolloProvider>
    </IntlProvider>

ReactDOM.render `<Base/>`, document.getElementById 'application'
