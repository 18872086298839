import { graphql } from 'react-apollo'
import Models      from '/models/index'

#
# Queries
#

import show       from './queries/user.gql'
import stewards   from './queries/stewards.gql'

export withUser = graphql show,
  options: (props)->
    variables:
      id: props.id
  props: ({props, data})->
    user: Models.User.parse data.user
    userLoading: data.loading

export withStewards = graphql stewards,
  options: (props)->
    variables:
      filters:
        role: ['Admin', 'Sales', 'SalesAdmin', 'SuperAdmin']
      per_page: 500
  props: ({props, data})->
    stewards: Models.User.parse data?.users?.nodes or []
    stewardsLoading: data.loading


#
# Mutations
#

import users_rpc              from '/mutations/users/rpc.gql'
import updateUserEmail        from '/mutations/users/update_email.gql'
import updateUserIcp          from '/mutations/users/update_icp.gql'
import updateUserRole         from '/mutations/users/update_role.gql'
import updateUserManualRef    from '/mutations/users/update_manual_ref.gql'
import updateUserSignupSource from '/mutations/users/update_signup_source.gql'
import updateBulkMessageLimit from '/mutations/users/update_bulk_message_limit.gql'
import addSteward             from '/mutations/users/add_steward.gql'
import removeSteward          from '/mutations/users/remove_steward.gql'
import addToSafelist          from '/mutations/add_to_safelist.gql'

export withUsersRPC = graphql users_rpc,
  props: ({ mutate })->
    users_rpc: (id, method)->
      {data: {user_rpc: {user, errors}}} = \
      await mutate variables: {id, method}
      {user, errors}

export withUpdateUserEmail = graphql updateUserEmail,
  props: ({ mutate })->
    updateEmail: (id, email)->
      {data: {user_update_email: {user, errors}}} = \
      await mutate variables: {id, email}
      {user, errors}

export withUpdateUserIcp = graphql updateUserIcp,
  props: ({ mutate })->
    updateIcp: (id, icp)->
      {data: {user_update_icp: {user, errors}}} = \
      await mutate variables: {id, icp}
      {user, errors}

export withUpdateUserRole = graphql updateUserRole,
  props: ({ mutate })->
    updateRole: (id, role)->
      {data: {user_update_role: {user, errors}}} = \
      await mutate variables: {id, role}
      {user, errors}

export withUpdateUserManualRef = graphql updateUserManualRef,
  props: ({ mutate })->
    updateManualRef: (id, manual_ref)->
      {data: {user_update_manual_ref: {user, errors}}} = \
      await mutate variables: {id, manual_ref}
      {user, errors}

export withUpdateUserSignupSource = graphql updateUserSignupSource,
  props: ({ mutate })->
    updateSignupSource: (id, signup_source)->
      {data: {user_update_signup_source: {user, errors}}} = \
      await mutate variables: {id, signup_source}
      {user, errors}

export withAddSteward = graphql addSteward,
  props: ({ mutate })->
    addSteward: (host_id, steward_id)->
      {data: {user_add_steward: {user, errors}}} = \
      await mutate variables: {host_id, steward_id}
      {user, errors}

export withRemoveSteward = graphql removeSteward,
  props: ({ mutate })->
    removeSteward: (host_id, steward_id)->
      {data: {user_remove_steward: {user, errors}}} = \
      await mutate variables: {host_id, steward_id}
      {user, errors}

export withAddToSafelist = graphql addToSafelist,
  props: ({ mutate })->
    addToSafelist: (ip_address)->
      {data: {safelist_create: {ip_address, expires_at, errors}}} = \
      await mutate variables: {ip_address}
      {ip_address, expires_at, errors}

export withUpdateBulkMessageLimit = graphql updateBulkMessageLimit,
  props: ({ mutate })->
    updateBulkMessageLimit: (id, bulk_message_limit)->
      {data: {user_update_bulk_message_limit: {user, errors}}} = \
      await mutate variables: {id, bulk_message_limit}
      {user, errors}
