import { graphql } from 'react-apollo'
import Models           from '/models/index'


#
# Queries
#
import index    from './queries/index.gql'

export withTransfers = graphql index,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.transfers or {}
    transfers: Models.Transfer.parse nodes or []
    transfersPagination: pagination
    transfersLoading: data.loading

#
# Mutations
#
import transfers_rpc      from '/mutations/transfers/rpc.gql'

export withTransfersRPC = graphql transfers_rpc,
  props: ({ mutate })->
    transfers_rpc: (id, method)->
      {data: {transfer_rpc: {transfer, errors}}} = \
      await mutate variables: {id, method}
      {transfer, errors}
