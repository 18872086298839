import React from 'react'
import './styles'

import humanize from 'underscore.string/humanize'
import { Link } from 'react-router-dom'

# componennts
import Pager          from '/components/pager/history-pager'
import Spinner        from '/components/spinner'
import Filters        from '/components/filters'
import List           from '/components/list'
import UserCard       from '/components/user-card'
import EventCard      from '/components/event-card'
import Datetime       from '/components/datetime'
import StateLabel     from '/components/state-label'
import ListingPhoto from '/components/listing-photo'

# for filtering (react-select)
import { state_options } from '/models/report-log'
import {
  withReportLogs
} from './queries'

export default \
withReportLogs \
class ReportLogs extends React.Component
  render: ->
    <div className="ReportLogs">
      <header>
        <h1>Report Logs</h1>
      </header>
      <section className="content">
        {@filters()}
        {@list()}
        {@pager()}
      </section>
    </div>

  filters: ->
    <Filters>
      <Filters.State options={state_options}/>
      <span className="pull-right">
        <Pager meta={@props.report_logsPagination} buffer={2}/>
      </span>
    </Filters>

  list: ->
    return <Spinner/> if @props.report_logsLoading
    <List>
      {for rl in @props.report_logs

        <List.Row key={rl.id}>
          <List.Cell className="user">
            <UserCard user={rl.user} />
          </List.Cell>

          <List.Cell className="scope">
            {if rl.host
              <UserCard user={rl.host} />
            else if rl.event
              <EventCard event={rl.event} />
            else if rl.listing # TODO: make a listing card
              <Link to={"/listings/#{rl.listing.slug_param}"}>
                <ListingPhoto image_url={rl.listing.image_url} listing={rl.listing} size={40}/>
                {rl.listing.title}
              </Link>
            else
              "Multiple report targets"
            }
          </List.Cell>

          <List.Cell className="klass">
            <div className="report-details">
              <span className="name">{humanize rl.name}</span>
              {<span className="label label-xs label-async">async</span> if rl.async}
              <br/>
              <small className="klass"><code>{rl.klass}</code></small>
            </div>
          </List.Cell>

          <List.Cell className="created-at">
            <Datetime date={rl.created_at}/>
          </List.Cell>

          <List.Cell className="state">
            <StateLabel model={rl}/>
          </List.Cell>

        </List.Row>
      }
    </List>


  pager:->
    <div className="text-center">
      <Pager meta={@props.report_logsPagination} buffer={4}/>
    </div>
