export default \
class Model

  @parse: (data)->
    if Array.isArray data
      ( (new @).parse d for d in data )
    else
      (new @).parse data

  parse: (data)->
    @[key] = val for key, val of data when val?
    @


  @attr: (name, opts={})->
    klass = opts['class'] || String

    Object.defineProperties @,
      "_#{name}":
        enumerable: false
        writable: true
        value: null

    # get / set
    Object.defineProperties @prototype,
      "#{name}":
        get: ->
          @["_#{name}"]
        set: (data)->
          @["_#{name}"] = (new klass)

  @belongsTo: (name, opts={})->

    # actual data
    Object.defineProperties @,
      "_#{name}":
        enumerable: false
        writable: true
        value: null

    # get / set
    Object.defineProperties @prototype,
      "#{name}":
        get: ->
          @["_#{name}"]
        set: (data)->
          try
            model = do -> window.Models[opts.model]
            obj = model.parse data unless data?.constructor is model
            @["_#{name}"] = obj
            @["_#{name}"]
          catch
            @["_#{name}"] = data

  @hasOne: (name, opts={})->
    @belongsTo arguments...

  @hasMany: (name, opts={})->

    # actual data
    Object.defineProperties @,
      "_#{name}":
        enumerable: false
        writable: true
        value: []

    # get / set
    Object.defineProperties @prototype,
      "#{name}":
        get: ->
          @["_#{name}"]
        set: (things=[])->
          @["_#{name}"] = []
          for thing in things
            try
              model = do -> window.Models[opts.model]
              obj = model.parse thing unless thing?.constructor is model
              @["_#{name}"].push obj
            catch
              @["_#{name}"].push thing

          @["_#{name}"]

  @hasOne: (name, opts={})->
    @belongsTo(name, opts)

  @computed: (name, funcs={})->
    if 'function' is typeof funcs
      funcs = get: funcs

    Object.defineProperties @prototype,
      "#{name}":
        get: funcs.get
        set: funcs.set

  # binds a non-enumerable method to the instance
  @method: (name, func)->
    Object.defineProperties @prototype,
      "#{name}":
        enumerable: false
        writable: false
        value: func

  @computed '_key', ->
    [ @constructor.className, @id ].join '-'
