import { graphql }   from 'react-apollo'
import Models             from '/models/index'

#
# Queries
#
import advances                   from './queries/advances.gql'
import index                      from './queries/index.gql'
import show                       from './queries/show.gql'
import report                     from './queries/report.gql'
import tickets                    from './queries/tickets.gql'
import cost_items                 from './queries/cost-items.gql'
import listings                   from './queries/listings.gql'
import events                     from './queries/events.gql'
import withdrawals                from './queries/withdrawals.gql'
import chargebacks                from './queries/chargebacks.gql'
import emails                     from './queries/emails.gql'
import stripe_connect_permissions from './queries/stripe-connect-permissions.gql'
import balances                   from './queries/balances.gql'
import partnership_payments       from './queries/partnership_payments.gql'
import subscriptions              from './queries/subscriptions.gql'
import changes                    from './queries/changes.gql'
import organization_show          from './queries/organization.gql'
import plans                      from './queries/plans.gql'
import commission_codes           from './queries/commission_codes.gql'
import order_fee                  from './queries/order-fee.gql'
import order_level_fees           from './queries/order_level_fees.gql'
import notes                      from './queries/notes.gql'
import entities                   from './queries/entities.gql'

export withUsers = graphql index,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.users or {}
    users: Models.User.parse nodes or []
    usersPagination: pagination
    usersLoading: data.loading

export withUser = graphql show,
  options: (props)->
    { id } = props.match.params
    variables: { id }
  props: ({props, data})->
    { user, refetch } = data or {}
    user: Models.User.parse user
    userLoading: data.loading
    userRefetch: refetch

export withNotes = graphql notes,
  options: (props)->
    { id } = props.match.params
    { state } = props.location.state?.filters or {}
    variables:
      id: id
      states: state or []
  props: ({props, data})->
    notes: Models.Note.parse data.user?.notes or []
    notesLoading: data.loading
    notesRefetch: data.refetch

export withActiveNotes = graphql notes,
  options: (props)->
    { id } = props.match.params
    variables:
      id: id
      states: ['active']
  props: ({props, data})->
    notes: Models.Note.parse data.user?.notes or []
    notesLoading: data.loading
    notesRefetch: data.refetch

export withListings = graphql listings,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.user?.listings or {}
    listings: Models.Listing.parse nodes
    listingsPagination: pagination
    listingsLoading: data.loading

export withEvents = graphql events,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.user?.events or {}
    events: Models.Event.parse nodes
    eventsPagination: pagination
    eventsLoading: data.loading

export withTickets = graphql tickets,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.user?.tickets or {}
    tickets: Models.Ticket.parse nodes
    ticketsPagination: pagination
    ticketsLoading: data.loading

export withCostItems = graphql cost_items,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.user?.cost_items or {}
    cost_items: Models.CostItem.parse nodes
    cost_itemsPagination: pagination
    cost_itemsLoading: data.loading

export withWithdrawals = graphql withdrawals,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.user?.withdrawals or {}
    withdrawals: Models.Withdrawal.parse nodes
    withdrawalsPagination: pagination
    withdrawalsLoading: data.loading

export withChargebacks = graphql chargebacks,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.user?.chargebacks or {}
    chargebacks: Models.Chargeback.parse nodes or []
    chargebacksPagination: pagination
    chargebacksLoading: data.loading

export withAdvances = graphql advances,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.user?.advances or {}
    advances: Models.Advance.parse nodes or []
    advancesPagination: pagination
    advancesLoading: data.loading

export withReport = graphql report,
  options: (props)->
    { id } = props.match.params
    variables: { id }
  props: ({props, data})->
    report: Models.EventReport.parse data.user?.report
    reportLoading: data.loading

export withEmails = graphql emails,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.user?.emails or {}
    emails: Models.Email.parse nodes
    emailsPagination: pagination
    emailsLoading: data.loading

export withUserBalances = graphql balances,
  options: (props) ->
    variables:
      id: props.match.params.id
  props: ({props, data}) ->
    balances: Models.Balance.parse data.user?.balances
    balancesLoading: data.loading

export withUserPartnershipPayments = graphql partnership_payments,
  options: (props) ->
    variables:
      id: props.match.params.id
  props: ({props, data}) ->
    partnership_payments: Models.PartnershipPayment.parse data.user?.partnership_payments
    partnershipPaymentsLoading: data.loading

export withSubscriptions = graphql subscriptions,
  options: (props) ->
    variables:
      id: props.match.params.id
  props: ({props, data}) ->
    subscriptions: Models.Subscription.parse data.user?.subscriptions
    subscriptionsLoading: data.loading

export withStripeConnectPermissions = graphql stripe_connect_permissions,
  options: (props)->
    variables:
      id: props.match.params.id
  props: ({props, data})->
    stripeConnectPermissions: Models.StripeConnectPermission.parse data.user?.stripe_connect_permissions or {}
    stripeConnectPermissionsLoading: data.loading

export withChanges = graphql changes,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.user?.changes or {}
    changes: Models.Change.parse nodes or []
    changesPagination: pagination
    changesLoading: data.loading

export withPlans = graphql plans,
  props: ({props, data})->
    plans: Models.Plan.parse data.plans or []
    plansLoading: data.loading

export withOrganization = graphql organization_show,
  options: (props)->
    variables:
      id: props.match.params.id
  props: ({props, data})->
    organization: Models.Organization.parse data.user?.organization or {}
    organizationLoading: data.loading

export withCommissionCodes = graphql commission_codes,
  options: (props)->
    variables:
      filters:
        currency: (pair?.currency for pair in props.user?.commission_code_currencies or [])
        state: 'approved'
      per_page: 100000
  props: ({props, data})->
    codes: Models.CommissionCode.parse data.commission_codes?.nodes
    codesLoading: data.loading

export withOrderFee = graphql order_fee,
  options: (props)->
    variables:
      id: props.match.params.id
  props: ({props, data})->
    orderFee: data.user?.organization?.order_fee or {}
    orderFeeLoading: data.loading

export withOrderLevelCodes = graphql order_level_fees,
  options: (props)->
    variables:
      per_page: 100000
  props: ({props, data})->
    olfCodes: data.order_level_fees?.nodes or []
    codesLoading: data.loading

export withEntities = graphql entities,
  props: ({props, data})->
    entities: Models.Entity.parse data.entities
    entitiesLoading: data.loading

#
# Mutations
#

import users_rpc                                  from '/mutations/users/rpc.gql'
import tickets_rpc                                from '/mutations/tickets/rpc.gql'
import listings_rpc                               from '/mutations/listings/rpc.gql'
import withdrawals_rpc                            from '/mutations/withdrawals/rpc.gql'
import chargebacks_rpc                            from '/mutations/chargebacks/rpc.gql'
import cost_items_rpc                             from '/mutations/cost_items/rpc.gql'
import createAdvance                              from '/mutations/advances/create.gql'
import createPartnershipPayment                   from '/mutations/partnership_payments/create.gql'
import update_subscriptions                       from '/mutations/users/update_subscriptions.gql'
import org_set_feature                            from '/mutations/organizations/set_feature.gql'
import org_set_plan                               from '/mutations/organizations/set_plan.gql'
import update_organization_commission_code        from '/mutations/organizations/update_commission_code.gql'
import update_organization_add_on_commission_code from '/mutations/organizations/update_add_on_commission_code.gql'
import organization_update_fee_assignment         from '/mutations/organizations/fee_assignment_update.gql'
import organization_remove_fee_assignment         from '/mutations/organizations/fee_assignment_delete.gql'
import update_organization                        from '/mutations/organizations/update_organization.gql'
import create_business_info                       from '/mutations/business_infos/create_business_info.gql'
import update_business_info                       from '/mutations/business_infos/update_business_info.gql'
import delete_business_info                       from '/mutations/business_infos/delete_business_info.gql'
import tickets_resend                             from '/mutations/tickets/resend.gql'
import disable_all_presales                       from '/mutations/users/disable_all_presales.gql'
import sync_payment_methods                       from '/mutations/users/sync_payment_methods.gql'
import createServiceInvoice                       from '/mutations/invoices/create.gql'
import syncGilaData                               from '/mutations/users/sync_gila_data.gql'

export withUsersRPC = graphql users_rpc,
  props: ({ mutate })->
    users_rpc: (id, method)->
      {data: {user_rpc: {user, errors}}} = \
      await mutate variables: {id, method}
      {user, errors}

export withTicketsRPC = graphql tickets_rpc,
  props: ({ mutate })->
    tickets_rpc: (id, method)->
      {ticket: {ticket_rpc: {ticket, errors}}} = \
      await mutate variables: {id, method}
      {ticket, errors}

export withListingsRPC = graphql listings_rpc,
  props: ({ mutate })->
    listings_rpc: (id, method)->
      {listing: {listing_rpc: {listing, errors}}} = \
      await mutate variables: {id, method}
      {listing, errors}

export withWithdrawalsRPC = graphql withdrawals_rpc,
  props: ({ mutate })->
    withdrawals_rpc: (id, method)->
      {data: {withdrawal_rpc: {withdrawal, errors}}} = \
      await mutate variables: {id, method}
      {withdrawal, errors}

export withChargebacksRPC = graphql chargebacks_rpc,
  props: ({ mutate })->
    chargebacks_rpc: (id, method)->
      {data: {chargeback_rpc: {chargeback, errors}}} = \
      await mutate variables: {id, method}
      {chargeback, errors}

export withCreateAdvance = graphql createAdvance,
  props: ({ mutate })->
    createAdvance: (obj)->
      {data: {advance_create: {advance, errors}}} = \
      await mutate variables: obj
      {advance, errors}

export withCreatePartnershipPayment = graphql createPartnershipPayment,
  props: ({ mutate })->
    createPartnershipPayment: (obj)->
      {data: {partnership_payment_create: {partnership_payment, errors}}} = \
      await mutate variables: obj
      {partnership_payment, errors}

export withUpdateSubscriptions = graphql update_subscriptions,
  props: ({ mutate })->
    update_subscriptions: (id, subscription, is_subscribed)->
      {data: {user_update_subscriptions: {user, errors}}} = \
      await mutate variables: {id, subscription, is_subscribed}
      {user, errors}

export withTicketsResend = graphql tickets_resend,
  props: ({ mutate })->
    tickets_resend: (id)->
      {data: {ticket_resend: {ticket, errors}}} = \
      await mutate variables: {id}
      {ticket, errors}

export withOrgSetFeature = graphql org_set_feature,
  props: ({ mutate })->
    orgSetFeature: (id, feature, value)->
      {data: {organization_set_feature: {organization, errors}}} = \
      await mutate variables: {id, feature, value}
      {organization, errors}

export withOrgSetPlan = graphql org_set_plan,
  props: ({ mutate })->
    orgSetPlan: (id, plan_id)->
      {data: {organization_set_plan: {organization, errors}}} = \
      await mutate variables: {id, plan_id}
      {organization, errors}

export updateOrganizationCommissionCode = graphql update_organization_commission_code,
  props: ({ mutate })->
    updateOrganizationCommissionCode: (id, commission_code)->
      {data: {organization_update_commission_code: {organization, errors}}} = \
      await mutate variables: {id, commission_code}
      {organization, errors}

export updateOrganizationAddOnCommissionCode = graphql update_organization_add_on_commission_code,
  props: ({ mutate })->
    updateOrganizationAddOnCommissionCode: (id, add_on_commission_code)->
      {data: {organization_update_add_on_commission_code: {organization, errors}}} = \
      await mutate variables: {id, add_on_commission_code}
      {organization, errors}

export updateOrganizationOrderLevelCode = graphql organization_update_fee_assignment,
  props: ({ mutate })->
    updateOrganizationOrderLevelCode: (id, code)->
      {data: {organization_update_fee_assignment: {organization, errors}}} = \
      await mutate variables: {id, code}
      {organization, errors}

export deleteOrganizationOrderLevelCode = graphql organization_remove_fee_assignment,
  props: ({ mutate })->
    deleteOrganizationOrderLevelCode: (id)->
      {data: {organization_remove_fee_assignment: {organization, errors}}} = \
      await mutate variables: {id}
      {organization, errors}

export withUpdateOrganizationForceHostPaysCommission = graphql update_organization,
  props: ({ mutate })->
    updateOrganizationForceHostPaysCommission: (id, force_host_to_pay_commission)->
      {data: {organization_update: {organization, errors}}} = \
      await mutate variables: {id, organization: { force_host_to_pay_commission }}
      {organization, errors}

export withUpdateBusinessInfo = graphql update_business_info,
  props: ({ mutate })->
    updateBusinessInfo: (id, business_info)->
      { __typename, id, business_info... } = business_info
      business_info["vat"] = { business_info["_vat"]... }
      business_info["entity_id"] = business_info["_entity"]["id"]
      delete business_info["vat"]["__typename"]
      delete business_info["_vat"]
      delete business_info["_entity"]
      {data: {business_info_update: {business_info, errors}}} = \
      await mutate variables: {id, business_info}
      {business_info, errors}

export withCreateBusinessInfo = graphql create_business_info,
  props: ({ mutate })->
    createBusinessInfo: (business_info)->
      { __typename, business_info... } = business_info
      business_info["vat"] = { business_info["_vat"]... }
      business_info["entity_id"] = business_info["_entity"]["id"]
      delete business_info["vat"]["__typename"]
      delete business_info["_vat"]
      delete business_info["_entity"]
      {data: {business_info_create: {business_info, errors}}} = \
      await mutate variables: {business_info}
      {business_info, errors}

export withDeleteBusinessInfo = graphql delete_business_info,
  props: ({ mutate })->
    deleteBusinessInfo: (id)->
      {data: {business_info_delete: {business_info, errors}}} = \
      await mutate variables: {id}
      {business_info, errors}

export withDisableAllPresales = graphql disable_all_presales,
  props: ({ mutate })->
    disable_all_presales: (id)->
      {data: {user_disable_all_presales: {user, errors}}} = \
      await mutate variables: {id}
      {user, errors}

export withCostItemsRPC = graphql cost_items_rpc,
  props: ({ mutate })->
    cost_items_rpc: (id, method)->
      {data: {cost_item_rpc: {cost_item, errors}}} = \
      await mutate variables: {id, method}
      {cost_item, errors}

export withSyncPaymentMethods = graphql sync_payment_methods,
  props: ({ mutate })->
    syncPaymentMethods: (id)->
      {data: {user_sync_payment_methods: {user, errors}}} = \
      await mutate variables: {id}
      {user, errors}

export withCreateServiceInvoice = graphql createServiceInvoice,
  props: ({ mutate })->
    createServiceInvoice: (obj)->
      {data: {service_invoice_create: {service_invoice, errors}}} = \
      await mutate variables: obj
      {service_invoice, errors}

export withSyncGilaData = graphql syncGilaData,
  props: ({ mutate })->
    syncGilaData: (id)->
      {data: {user_sync_gila_data: {user, errors}}} = \
      await mutate variables: {id}
      {user, errors}
