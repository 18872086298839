import React from 'react'

import List from '/components/list'

export default \
class SeatsInfo extends React.Component
  render: ->
    <section>
      <List>
        <List.HeaderRow className="top">
          <List.Header>
            Seats.io Information
          </List.Header>
        </List.HeaderRow>
        <List.Row>
          <List.Cell>
            Seats.io Designer Key
          </List.Cell>
          <List.Cell>
            {@props.seats_designer_key || ""}
          </List.Cell>
        </List.Row>
        <List.Row>
          <List.Cell>
            Seats.io Subaccount ID
          </List.Cell>
          <List.Cell>
            {@props.seats_subaccount_id || ""}
          </List.Cell>
        </List.Row>
        <List.Row>
          <List.Cell>
            Seats.io Chart Key
          </List.Cell>
          <List.Cell>
            {@props.seats_chart_key || ""}
          </List.Cell>
        </List.Row>
      </List>
    </section>
