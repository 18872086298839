import React from 'react'
import PropTypes from 'prop-types'

export default \
class FraudFlag extends React.Component
  @propTypes:
    value: PropTypes.number.isRequired
    percent: PropTypes.number

  @defaultProps:
    threshold: 0.3

  render: ->
    <>
      {if @props.value > @props.threshold
        <span title="there is a high percentage of failed tickets here">
          {' 🚩'}
        </span>
      }
    </>
