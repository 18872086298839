import React from 'react'

import flash from '/lib/flash'

# components
import Spinner       from '/components/spinner'
import FeatureMatrix from './components/feature-matrix'
import ReactSelect   from '/components/react-select'
import List          from '/components/list'
import Fee           from '/components/fee'
import UserCard      from '/components/user-card'
import RulesCell     from '/components/order-fee/rules-cell'

import find  from 'underscore-es/find'

import {
  withUser
  withPlans
  withOrganization
  withOrderFee
  withOrgSetFeature
  withOrgSetPlan
  withCommissionCodes
  withOrderLevelCodes
  updateOrganizationCommissionCode
  updateOrganizationOrderLevelCode
  updateOrganizationAddOnCommissionCode
  deleteOrganizationOrderLevelCode
} from './queries'

export default \
withUser \
withPlans \
withOrganization \
withOrderFee \
withOrgSetFeature \
withOrgSetPlan \
withCommissionCodes \
withOrderLevelCodes \
updateOrganizationCommissionCode \
updateOrganizationOrderLevelCode \
updateOrganizationAddOnCommissionCode \
deleteOrganizationOrderLevelCode \
class Plan extends React.Component
  constructor: (props)->
    super arguments...
    @state =
      commissionCodeSelectDisabled: false
      orderFeeCodeSelectDisabled: false

  setOrgFeature: (feature, value)=>
    @props.orgSetFeature @props.organization.id, feature, value

  setOrgPlan: (obj)=>
    @props.orgSetPlan @props.organization.id, obj.value

  planOptions: ->
    (label: p.name, value: p.id for p in @props.plans)

  render: ->
    return <Spinner/> if @props.organizationLoading
    return <Spinner/> if @props.plansLoading

    <div className="Plan">
      <h2>
        Selected plan:
      </h2>
      <ReactSelect
        value={@props.organization.plan.id}
        options={@planOptions()}
        clearable={false}
        onChange={@setOrgPlan}
      />
      <br/>
      <br/>
      <div className="features">
        <FeatureMatrix
          org={@props.organization}
          onChange={@setOrgFeature}
        />
      </div>
      <hr/>
      <div className="CommissionCode">
        <h3>Account Service Fee</h3>
        <div>
          Set default pricing at an account level. This will override default plan fees and can be overwritten on a listing or ticket type level. Commission codes are currency and country specific; if the user creates an event in a new country or currency that the commission code set to their account is not configured to, they will fallback to default pricing according to their Plan setting.
        </div>
        {@commissionCode()}
      </div>
      <div className="CommissionCode">
        {@commissionCodeInfo @props.organization.commission_code}
      </div>
      <hr/>
      <div className="CommissionCode">
        <h3>Account Order Fee</h3>
        <div>
          {"Add a custom per order fee at an account level. Commission codes are currency and country specific; if the user creates an event in a new country or currency that the commission code set to their account is not configured to, they will fallback to default pricing according to their Plan setting."}
        </div>
        {@orderFeeCode()}
      </div>
      <div className="CommissionCode">
        {@orderFeeCodeInfo()}
      </div>
      <hr/>
      <div className="CommissionCode">
        <h3>Add-On Service Fee</h3>
        <div>
          {"Add a custom service fee for all of the user's add-ons. Commission codes are currency and country specific; if the user creates an event in a new country or currency that the commission code set to their account is not configured to, they will fallback to default pricing according to their Plan setting."}
        </div>
        {@addOnCommissionCode()}
        <div className="CommissionCode">
          {@commissionCodeInfo @props.organization.add_on_commission_code}
        </div>
      </div>
    </div>

  addOnCommissionCode: ->
    <div className="add-on-commission-code">
      <h5>Add-On Commission Code</h5>
      <ReactSelect
        className="rate-selector"
        name='commission-code'
        options={@commissionCodeOptions()}
        multi={false}
        value={@props.organization.add_on_commission_code_attribute}
        onChange={@updateOrganizationAddOnCommissionCode.bind @, @props.user.organization.id}
        placeholder={@props.organization.add_on_commission_code}
        disabled={@state.addOnCommissionCodeSelectDisabled}
      />
      <span>Please do not refresh while this field is updating. It may take a while.</span>
    </div>

  commissionCodeOptions: =>
    # uniq the set
    codes = new Set(cc.code for cc in @props.codes)
    # convert from Set -> Array -> Select format
    { value: code, label: code } for code in [...codes]

  orderLevelFeeCodeOptions: =>
    @props.olfCodes.map ({code}) -> value: code, label: code


  commissionCode: ->
    <div className="commission-code">
      <h5>Commission Code</h5>
      <ReactSelect
        className="rate-selector"
        name='commission-code'
        options={@commissionCodeOptions()}
        multi={false}
        value={@props.organization.commission_code_attribute}
        onChange={@updateOrganizationCommissionCode.bind @, @props.user.organization.id}
        placeholder={@props.organization.commission_code}
        disabled={@state.commissionCodeSelectDisabled}
      />
      <span>Please do not refresh while this field is updating. It may take a while.</span>
    </div>

  orderFeeCode: ->
    <div className="commission-code">
      <h5>Order Fee Code</h5>
      <ReactSelect
        className="rate-selector"
        name='order-level-code'
        options={@orderLevelFeeCodeOptions()}
        multi={false}
        value={@props.orderFee?.code || ''}
        onChange={@updateOrganizationOrderLevelCode.bind @, @props.user.organization.id}
        placeholder={"Select one"}
        disabled={@state.orderFeeCodeSelectDisabled}
      />
      <span>{"Please do not refresh while this field is updating. It may take a while."}</span>
    </div>

  commissionCodeInfo: (commissionCodeValue)=>
    return null unless commissionCodeValue
    return null unless @props.user.commission_code_currencies?.length
    <List>
      <List.HeaderRow>
        <List.Header className="currency">
          Currency
        </List.Header>
        <List.Header className="country">
          Country
        </List.Header>
        <List.Header className="fees">
          Processing Fee
        </List.Header>
        <List.Header className="fees">
          Service Fee
        </List.Header>
        <List.Header className="rebate-user">
          Rebate User
        </List.Header>
        <List.Header className="commission-code">
          Commission Code
        </List.Header>
      </List.HeaderRow>
      {for index, pair of @props.user.commission_code_currencies
        <List.Row key={index}>
          <List.Cell className="currency">
            {pair.currency}
          </List.Cell>
          <List.Cell className="country">
            {pair.country}
          </List.Cell>
          <List.Cell className="fees">
            {@calculateBrokerFee(commissionCodeValue, pair.currency, pair.country)}
          </List.Cell>
          <List.Cell className="fees">
            <Fee
              flat={@calculateFeeProps(commissionCodeValue, "flat", pair.currency, pair.country)}
              percent={@calculateFeeProps(commissionCodeValue, "percent", pair.currency, pair.country)}
              currency={pair.currency}
            />
            <small>
              <Fee
                flat={@calculateFeeProps(commissionCodeValue, "rebate_flat", pair.currency, pair.country)}
                percent={@calculateFeeProps(commissionCodeValue, "rebate_percent", pair.currency, pair.country)}
                ticket_percent={@calculateFeeProps(commissionCodeValue, "rebate_cost_item_percent", pair.currency, pair.country)}
                currency={pair.currency}
                rebate={true}
              />
            </small>
          </List.Cell>
          <List.Cell className="rebate-user">
            <UserCard user={@calculateFeeProps(commissionCodeValue, "rebate_user", pair.currency, pair.country)} />
          </List.Cell>
          <List.Cell className="commission-code">
            {@commissionCodeDisplay(commissionCodeValue, pair.currency, pair.country)}
          </List.Cell>
        </List.Row>
      }
    </List>

  orderFeeCodeInfo: ->
    return unless @props.orderFee?
    {code, rules} = @props.orderFee
    <List>
      <List.HeaderRow>
        <List.Header className="fees">
          Per Order Fees
        </List.Header>
        <List.Header className="code">
          Commission Code
        </List.Header>
      </List.HeaderRow>
      <List.Row>
        <List.Cell className="fees">
          <RulesCell rules={rules} />
        </List.Cell>
        <List.Cell className="code">
          {code}
        </List.Cell>
      </List.Row>
    </List>

  updateOrganizationCommissionCode: (organization_id, item) =>
    @setState commissionCodeSelectDisabled: true

    {organization, errors} = await @props.updateOrganizationCommissionCode organization_id, item?.value or ''

    if errors?.length
      flash.error errors
    else
      flash.success "Account commission code updated successfully"
    @setState commissionCodeSelectDisabled: false

  updateOrganizationOrderLevelCode: (id, code) =>
    @setState orderFeeCodeSelectDisabled: true

    { fee, errors } = if code
      await @props.updateOrganizationOrderLevelCode id, code.value
    else
      await @props.deleteOrganizationOrderLevelCode id

    if errors?.length
      flash.error errors
    else
      flash.success "Account order level fee code updated successfully"

    @setState orderFeeCodeSelectDisabled: false

  updateOrganizationAddOnCommissionCode: (organization_id, item) =>
    @setState addOnCommissionCodeSelectDisabled: true

    {organization, errors} = await @props.updateOrganizationAddOnCommissionCode organization_id, item?.value or ''

    if errors?.length
      flash.error errors
    else
      flash.success "Account Add-On commission code updated successfully"
    @setState addOnCommissionCodeSelectDisabled: false

  calculateFeeProps: (commission_code, type, currency, country) =>
    if commission_code
      code = @fallbackCode commission_code, currency, country
      if code then code[type] else null
    else
      -1

  calculateBrokerFee: (commission_code, currency, country) =>
    code = @fallbackCode commission_code, currency, country
    if code then "#{(code["broker_fee"] * 100).toFixed(2)}%" else 'FALLBACK'

  commissionCodeDisplay: (commission_code, currency, country) =>
    code = @fallbackCode commission_code, currency, country
    code?.code or 'FALLBACK'

  fallbackCode: (commission_code, currency, country) =>
    codes = (code for code in @props.codes when code?)

    ret = find codes, (code)=>
      code.code is commission_code and
      code.currency is currency and
      code.country is country

    ret ||= find codes, (code)=>
      code.code is commission_code and
      code.currency is currency and
      !code.country?

    ret ||= find codes, (code)=>
      code.code is commission_code and
      code.currency is currency

    ret ||= find codes, (code)=>
      code.code is @props.user.organization.plan.commission_code and
      code.currency is currency and
      code.country is country

    ret ||= find codes, (code)=>
      code.code is @props.user.organization.plan.commission_code and
      code.currency is currency and
      !code.country?

    ret
