import React from 'react'

import flash from '/lib/flash'

export default \
class FormChartKey extends React.Component
  submitChartKey: (evt)=>
    evt.preventDefault()

    {listing, errors} = await @props.updateChartKey @props.listingId, @input.value
    if errors?.length
      flash.error errors
      flash.error "Chart key update failed"
    else
      flash.success "Chart key updated successfully"

  render: ->
    <form className="form-inline" onSubmit={@submitChartKey}>
      <div className="input-group">
        <input
          type="text"
          className="form-control input-sm"
          placeholder="Insert Seats.io chart key (e.g. 9f950e09-d6bb-ca95-245a-16bbfad10799)"
          ref={(el)=> @input = el}
        />
        <span className="input-group-btn">
          <button type="submit" className="btn btn-sm btn-default" onClick={@submitChartKey}>Submit</button>
        </span>
      </div>
    </form>
