import React from 'react'
import PropTypes from 'prop-types'

import flash from '/lib/flash'

import PartnershipPayment, { base_currency_options } from '/models/partnership-payment'
import {base_currencies} from '/lib/currencies'
import ReactSelect  from '/components/react-select'
import humanize from 'underscore.string/humanize'
import pick from 'underscore-es/pick'

export default \
class CreatePartnershipPayment extends React.Component
  @propTypes =
    action: PropTypes.func.isRequired
    cancel: PropTypes.func.isRequired
    user:   PropTypes.object.isRequired
    currency: PropTypes.oneOf(base_currencies)

  constructor: ->
    super arguments...
    @state =
      amount:         null
      description:    null
      currency:       @props.currency || null
      user_id:        @props.user.id

  createPartnershipPayment: (evt)=>
    evt.preventDefault()
    attrs = pick @state, 'amount', 'description', 'currency', 'user_id'
    partnershipPayment = PartnershipPayment.parse attrs
    unless partnershipPayment.isValidForCreate
      flash.warning "You need to provide amount, description, and currency.\n\nDescription cannot be longer than 255 characters"
      return
    @props.action @props.cancel, attrs


  cancel: (evt)=>
    evt.preventDefault()
    @setState
      amount:         null
      description:    null
      currency:       null
      user_id:        null
    @props.cancel()


  render: ->
    updateNewPartnershipPayment = (key, evt)=>
      # handle text inputs
      value = if evt?.target?.value? then evt?.target?.value else evt
      # Converts amount into a float.
      value = parseFloat(value) if key in ['amount']
      # handle react select inputs
      value = evt.value if evt?.value?
      # set the state
      @setState "#{key}": value

    formRow = (params)=>
      { name } = params
      <div className="form-group" key={name}>
        <label className="col-sm-2 control-label">{humanize name.replace 'actual_', ''}</label>
        <div className="col-sm-10">
          {inputType(params)}
        </div>
      </div>

    inputType = (params) =>
      { name, value, placeholder, startFocus, type } = params
      if type == 'input'
        <input
          type="text"
          className="form-control"
          placeholder={placeholder or name}
          defaultValue={value}
          onChange={updateNewPartnershipPayment.bind @, name}
          autoFocus = {startFocus}
        />
      else if type == 'textarea'
        <textarea
          className="form-control"
          placeholder={placeholder or name}
          defaultValue={value}
          onChange={updateNewPartnershipPayment.bind @, name}
          autoFocus = {startFocus}
          rows={4}
          cols={50}
        />

    amountParams = () =>
       name: 'amount'
       value: @state.amount
       placeholder: '0.00'
       startFocus: true
       type: 'input'

    descriptionParams = () =>
       name: 'description'
       value: @state.description
       placeholder: 'Include explanation of Partnership Payment/agreement with host and listing(s) the partnership payment is for.'
       startFocus: false
       type: 'textarea'

    <div className="NewPartnershipPayment">
      <form>
        {formRow amountParams()}
        {formRow descriptionParams()}
        <div className="form-group" key='currency'>
          <label className="col-sm-2 control-label">Currencies</label>
          <div className="col-sm-10">
            <ReactSelect
              className="filter-selector state-selector"
              options={base_currency_options}
              value={@state.currency}
              onChange={updateNewPartnershipPayment.bind @, 'currency'}
              placeholder="Currency"
            />
          </div>
        </div>
        <div className='buttons'>
          <button className='btn btn-default' onClick={@cancel}>cancel</button>
          <button className='btn btn-primary' onClick={@createPartnershipPayment}>Create</button>
        </div>
      </form>
    </div>
